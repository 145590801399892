
import { defineComponent, computed } from 'vue'
import { INoteWidgetData } from '@/merge/Grid';
export default defineComponent({
    props: { config: Object, name: String, hideActions: Boolean },
    setup(p) {
        const textData = computed(() => {
            return p.config as INoteWidgetData | null | undefined;
        })
        return { textData }
    }
})
