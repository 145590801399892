<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <delete-entity-card
                :title="$t('deleteReportDialog.title')"
                :subtitle="$t('deleteReportDialog.subtitle')"
                :value="{ name: report.name }"
                :loading="$wait.is('deletingReport')"
            >

                <template #actions="{ isValid, formId, clear, loading, form }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <delete-report-button
                        :value="report"
                        :form="formId"
                        :clear="clear"
                        :loading="loading"
                        :valid="isValid"
                        v-wait-while="'deletingReport'"
                        @http2xx="close().then(() => handleSuccessfulDeletion(form))"
                    >
                    </delete-report-button>
                </template>
            </delete-entity-card>
        </template>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "deleteReportDialog.events.successfulDeletion": "Successfully deleted the report '{reportName}'",
        "deleteReportDialog.title": "Delete Report",
        "deleteReportDialog.subtitle": "Please enter the name of this report to delete it"
    }
}
</i18n>

<script>
import DeleteEntityCard from '@/components/Common/DeleteEntityCard.vue';
import DeleteReportButton from '@/components/Domain.Reports/DeleteReportButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { DeleteEntityCard, DeleteReportButton },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: null,
        },
    },
    methods: {
        handleSuccessfulDeletion(form) {
            toastSuccess(this.$t('deleteReportDialog.events.successfulDeletion', { reportName: form.Name }));
            this.$emit('http2xx', form);
        },
    },
};
</script>