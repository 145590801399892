
import moment from '@/lib/moment';
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import Config from '@/lib/Config';
const isCloudAccountActionsEnabled = Config.isFeatureEnabled('CloudAccountActions');
import { useTenantStore } from '../../stores/tenant';
import { mapStores } from 'pinia';

export default {
    props: {
        value: Object,
        cloudAccount: Object,
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editSelectedPrice: false,
            absoluteMarkupCheck: false,
            lastMonth: moment.utc().startOf('month').subtract(1, 'months').toDate(),
            lastMonthString: moment.utc().startOf('month').subtract(1, 'months').toISOString(),
            nextMonth: moment.utc().startOf('month').add(1, 'months').toDate(),
            setScriptId: false,
            scriptId: null,
            scripts: [],
        };
    },
    created() {
        this.getActions();
    },
    methods: {
        addNewMarkup() {
            if (!this.value || !this.value.rules.futureMarkups) return;
            const maxDate =
                this.value.rules.futureMarkups.length > 0
                    ? new Date(
                          Math.max(
                              ...this.value.rules.futureMarkups.map((el) => {
                                  return new Date(el.effectiveFromDate);
                              })
                          )
                      )
                    : this.nextMonth;
            this.value.rules.futureMarkups.push({
                id: -1,
                markupPercentage: this.value.rules.currentMarkup.markupPercentage,
                effectiveFromDate:
                    this.value.rules.futureMarkups.length > 0
                        ? moment(maxDate).add(1, 'months').toDate()
                        : this.nextMonth,
            });
            this.$emit('input', this.value);
        },
        deleteMarkup(markup, index) {
            if (!this.value || !this.value.rules.futureMarkups) return;
            this.value.rules.futureMarkups = this.value.rules.futureMarkups.filter((_, i) => i !== index);
            this.$emit('input', this.value);
        },
        getActions: waitFor('gettingActionScripts', async function () {
            this.scripts = await ApiV2.http.get('/api/actions').then((r) => r.data);
        }),
    },
    watch: {
        value: {
            handler() {
                if (!this.value || !this.value.tenant.id) {
                    this.absoluteMarkupCheck = false;
                } else {
                    const rules = this.value.rules;
                    this.absoluteMarkupCheck =
                        this.absoluteMarkupCheck || (rules.currentMarkup !== null && rules.currentMarkup.id);
                    this.editSelectedPrice = this.editSelectedPrice || rules.origin != null;
                    if (!this.value.rules.currentMarkup) this.value.rules.currentMarkup = {};
                    if (this.absoluteMarkupCheck && rules.currentMarkup !== null && !rules.currentMarkup.id) {
                        rules.currentMarkup.id = -1;
                    }
                    this.scriptId = this.value.scriptId;
                    this.setScriptId = !!this.value.scriptId;
                }
            },
            immediate: true,
            deep: true,
        },
        absoluteMarkupCheck(val) {
            this.value.rules.absoluteMarkupCheck = val;
        },
        editSelectedPrice(val) {
            if (!val && this.value.rules) this.value.rules.origin = null;
        },
    },
    computed: {
        prices() {
            const prices = [{ id: null, name: 'Base Cost' }];
            if (this.cloudAccount && this.cloudAccount.pricingOrigin) {
                this.cloudAccount.pricingOrigin.forEach((origin) => {
                    prices.push({ id: origin, name: origin });
                });
            }
            return prices;
        },
        isActionsEnabled() {
            return isCloudAccountActionsEnabled && this.tenantStore.hasTenantFeature('CloudAccountActions');
        },
        ...mapStores(useTenantStore),
    },
};
