
import CreateSnowflakeAccountDialog from '@/components/Settings.CloudAccounts/Snowflake/CreateSnowflakeAccountDialog.vue';
import UpdateSnowflakeCredentialsDialog from '@/components/Settings.CloudAccounts/Snowflake/UpdateSnowflakeCredentialsDialog.vue';
import DeleteCloudAccountDialog from '@/components/Settings.CloudAccounts/DeleteCloudAccountDialog.vue';
import EditCloudAccountNameDialog from '@/components/Settings.CloudAccounts/EditCloudAccountNameDialog.vue';
import EditCloudAccountCurrenciesDialog from '@/components/Settings.CloudAccounts/EditCloudAccountCurrenciesDialog.vue';
import SnowflakeAccountTable, {
    snowflakeCloudAccountTableFilter,
} from '@/components/Settings.CloudAccounts/Snowflake/SnowflakeAccountTable.vue';
import TaxonomyType from '@/stores/models/TaxonomyType';
import { ICloudAccountNavigation } from '@/stores/models/ICloudAccountNavigation';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { defineComponent, ref, computed } from 'vue';
import * as M from '@/models';

export default defineComponent({
    title() {
        return this.$t('settings.snowflake.title');
    },
    props: {
        cloudAccounts: Array,
    },
    components: {
        SnowflakeAccountTable,
        CreateSnowflakeAccountDialog,
        UpdateSnowflakeCredentialsDialog,
        DeleteCloudAccountDialog,
        EditCloudAccountNameDialog,
        EditCloudAccountCurrenciesDialog,
    },
    setup(p, { emit }) {
        const isCreating = ref(false);
        const isEditing = ref(false);
        const isEditingName = ref(false);
        const isEditingCurrencies = ref(false);
        const isDeleting = ref(false);
        const focusedAccount = ref<M.ICloudAccountModel | null>(null);

        const snowflakeAccounts = computed(() => {
            return p.cloudAccounts.filter(snowflakeCloudAccountTableFilter);
        });

        const onCloudAccountsChanged = () => {
            emit('onCloudAccountsChanged');
        };

        const startEditName = (account) => {
            focusedAccount.value = account;
            isEditingName.value = true;
        };
        const startEditCloudAccount = (account) => {
            focusedAccount.value = account;
            isEditing.value = true;
        };
        const startDeleteAccount = (account) => {
            focusedAccount.value = account;
            isDeleting.value = true;
        };
        const startEditCurrencies = (account) => {
            focusedAccount.value = account;
            isEditingCurrencies.value = true;
        };

        return {
            isCreating,
            isEditing,
            isEditingName,
            isEditingCurrencies,
            isDeleting,
            focusedAccount,
            snowflakeAccounts,
            onCloudAccountsChanged,
            startEditName,
            startEditCloudAccount,
            startDeleteAccount,
            startEditCurrencies,
        };
    },
    computed: {
        ...mapStores(useTenantStore),
    },
    methods: {
        handleDialogChange(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push('/settings/snowflake');
            this.$title = this.$t('settings.snowflake.title');
        },
        copyCloudAccount(item: M.ICloudAccountModel) {
            const navigationSource: ICloudAccountNavigation = {
                taxonomyType: TaxonomyType.settingsSnowflake,
                tenantId: null,
                cloudAccountId: null,
            };
            useCloudAccountNavigationStore().updateNavigationSource(navigationSource);
            const url = `/copy/cloudaccounts/${item.cloudAccountId}`;
            this.$router.push(url);
        },
    },
});
