<template>
    <v-card flat class="alert-info">
        <v-card-subtitle>
            <h2 class="subtitle-1 flex-grow-1">
                Recommendations are currently available for some limited account types.
                <a target="_blank" :href="`${docsUrl}/Using/Tracking/#recommendations`">Learn more</a>
            </h2>
        </v-card-subtitle>
    </v-card>
</template>

<script>
import Config from '@/lib/Config';

export default {
    data() {
        return { docsUrl: Config.get().DOCS_URL };
    },
};
</script>
