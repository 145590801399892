<template>
    <div class="pt-5">
        <cloud-account-copies-table :loading="$wait.is('getAccountCopies')" :items="copies">
            <template #top v-if="tenantStore.hasTenantFeature('CopyCloudAccount')">
                <v-toolbar flat color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" depressed :to="`/copy/cloudaccounts/${value ? value.cloudAccountId : ''}`">
                        <v-icon left>content_copy</v-icon>
                        {{ $t('dialogCloudAccount.copyCloudAccount') }}
                    </v-btn>
                </v-toolbar>
            </template>
            <template #[`item.Actions`]="{ item }">
                <v-menu offset-y bottom>
                    <template #activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="tenantStore.hasTenantFeature('CopyCloudAccount')"
                            :to="`/copy/cloudaccounts/${value ? value.cloudAccountId : ''}?copy=${item.Id}`"
                        >
                            <v-list-item-icon>
                                <v-icon>edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title> Edit Share </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="startDeleteCopy(item)">
                            <v-list-item-icon>
                                <v-icon>delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title> Delete Share </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </cloud-account-copies-table>
        <form-dialog v-model="showDeleteCopy" #default="{ close }">
            <delete-entity-card
                :loading="$wait.is('deletingCopy')"
                :value="copyToDelete"
                :title="$t('deleteCopyCard.title')"
                :subtitle="$t('deleteCopyCard.subtitle')"
            >
                <template #actions="{ isValid, clear, formId, loading }">
                    <back-button @click="close"></back-button>
                    <v-spacer></v-spacer>
                    <delete-cloud-account-copy-button
                        :cloudAccountId="value ? value.cloudAccountId : null"
                        :value="copyToDelete"
                        :valid="isValid"
                        :clear="clear"
                        :form="formId"
                        :loading="loading"
                        @http2xx="close().then(() => getAccountCopies(value.cloudAccountId))"
                    ></delete-cloud-account-copy-button>
                </template>
            </delete-entity-card>
        </form-dialog>
    </div>
</template>

<i18n>
{
    "en": {
        "deleteCopyCard.title": "Delete Copy",
        "deleteCopyCard.subtitle": "Warning: This process is irreversible! The copy cloud connection data will be deleted permanently and you will need to re-copy your account if you want to see your data again.",
        "dialogCloudAccount.copyCloudAccount": "Share Cloud Connection"
    }   
}
</i18n>

<script>
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2.ts';
import { useTenantStore } from '../../stores/tenant.ts';
import { mapStores } from 'pinia';
import CloudAccountCopiesTable from '@/components/Settings.CloudAccounts/CloudAccountCopiesTable.vue';
import DeleteCloudAccountCopyButton from '@/components/Settings.CloudAccounts/DeleteCloudAccountCopyButton.vue';
import DeleteEntityCard from '@/components/Common/DeleteEntityCard.vue';
export default {
    components: { CloudAccountCopiesTable, DeleteCloudAccountCopyButton, DeleteEntityCard },
    props: ['value', 'loading'],
    data() {
        return {
            copies: [],
            showDeleteCopy: false,
            copyToDelete: null,
            cloudAccountId: null,
        };
    },
    watch: {
        value: {
            handler(acc) {
                if (!acc) return;
                this.cloudAccountId = acc.id;
                this.getAccountCopies(acc.id);
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        ...mapStores(useTenantStore)
    },
    methods: {
        getAccountCopies: waitFor('getAccountCopies', async function getAccountCopies(id) {
            this.copies = await ApiV2.http.get(`/api/metadata/cloudaccounts/${id}/copies`).then((r) => r.data);
        }),
        startDeleteCopy(item) {
            this.copyToDelete = item;
            this.showDeleteCopy = true;
        },
    },
};
</script>