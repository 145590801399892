
import { defineComponent, ref, computed, watch } from 'vue';
import { ISearchEntityViewModel } from '@/components/Common/EntitySearchAutocomplete.vue';
import { ICustomViewResult, IDescriptorResult } from '@/models';
import EntityType, { toPlural } from '@/models/EntityType';
import { CloudProviderType } from '@/models';

const convert = (m: ISearchEntityViewModel): IDescriptorResult => ({ id: m.Id, name: m.Name });
const createEntity = (id: string, name: string, entityType: EntityType, index: number) =>
    ({
        Id: id,
        Name: name,
        Description: null,
        Provider: null,
        Type: entityType,
        EntityIndex: index,
    } as ISearchEntityViewModel);

const filters = (init) => {
    const filters = [] as EntityType[];
    if (init?.cloudAccountIds?.length > 0) {
        filters.push(EntityType.cloudAccount);
    }
    if (init?.subscriptionIds?.length > 0) {
        filters.push(EntityType.subscription);
    }
    if (init?.sysProductIds?.length > 0) {
        filters.push(EntityType.product);
    }
    if (init?.sysServiceIds?.length > 0) {
        filters.push(EntityType.service);
    }
    if (init?.regions?.length > 0) {
        filters.push(EntityType.region);
    }
    if (init?.geographies?.length > 0) {
        filters.push(EntityType.geography);
    }
    if (init?.productCategories?.length > 0) {
        filters.push(EntityType.productCategory);
    }
    if (init?.cloudProviderTypes?.length > 0) {
        filters.push(EntityType.cloudProvider);
    }
    if (init?.tagKeyValues?.length > 0) {
        filters.push(EntityType.tag);
    }
    if (init?.tagKeys?.length > 0) {
        filters.push(EntityType.tagKey);
    }
    return filters;
};

const createFilters = (currentFilters, array, entityType) => {
    const elementInArray = currentFilters?.find((element) => element === entityType);
    if (elementInArray && array?.length === 0) {
        const removeFilter = currentFilters.filter((filter) => filter !== entityType);
        return removeFilter;
    }
    if (!elementInArray && array?.length > 0) {
        currentFilters.push(entityType);
    }
    return currentFilters;
};

const getCloudAccounts = (cloudAccountIds) => {
    return (
        cloudAccountIds?.map((cloudAccountId: IDescriptorResult, index: number) =>
            createEntity(cloudAccountId.id, cloudAccountId.name, EntityType.cloudAccount, index)
        ) ?? []
    );
};

const getSubscriptions = (subscriptionIds) => {
    return (
        subscriptionIds?.map((subscriptionId: IDescriptorResult, index: number) =>
            createEntity(subscriptionId.id, subscriptionId.name, EntityType.subscription, index)
        ) ?? []
    );
};

const getProducts = (sysProductIds) => {
    return (
        sysProductIds?.map((productId: IDescriptorResult, index: number) =>
            createEntity(productId.id, productId.name, EntityType.product, index)
        ) ?? []
    );
};

const getServices = (sysServiceIds) => {
    return (
        sysServiceIds?.map((serviceId: IDescriptorResult, index: number) =>
            createEntity(serviceId.id, serviceId.name, EntityType.service, index)
        ) ?? []
    );
};

const getRegions = (regions?) => {
    return (
        regions?.map((region: string, index: number) => createEntity(region, region, EntityType.region, index)) ?? []
    );
};

const getGeographies = (geographies) => {
    return (
        geographies?.map((geography: string, index: number) =>
            createEntity(geography, geography, EntityType.geography, index)
        ) ?? []
    );
};

const getProductCategories = (productCategories) => {
    return (
        productCategories?.map((productCategory: string, index: number) =>
            createEntity(productCategory, productCategory, EntityType.productCategory, index)
        ) ?? []
    );
};

const getProviderTypes = (cloudProviderTypes) => {
    return (
        cloudProviderTypes?.map((cloudProviderType: string, index: number) =>
            createEntity(
                cloudProviderType,
                Object.values(CloudProviderType)[cloudProviderType],
                EntityType.cloudProvider,
                index
            )
        ) ?? []
    );
};

const getTagKeyValues = (tagKeyValues) => {
    return (
        tagKeyValues?.map((tagKeyValue: string, index: number) =>
            createEntity(tagKeyValue, tagKeyValue, EntityType.tag, index)
        ) ?? []
    );
};

const getTagKeys = (tagKeys) => {
    return (
        tagKeys?.map((existingTag: string, index: number) =>
            createEntity(existingTag, existingTag, EntityType.tagKey, index)
        ) ?? []
    );
};

export default defineComponent({
    props: { title: String, subtitle: String, loading: Boolean, init: Object },
    setup(p) {
        const isValid = ref(false);
        const id = ref<string>(p.init?.id ?? -1);
        const name = ref<string>(p.init?.name ?? '');

        const allFilterTypes = ref([
            EntityType.cloudAccount,
            EntityType.cloudProvider,
            EntityType.geography,
            EntityType.product,
            EntityType.service,
            EntityType.region,
            EntityType.tag,
            EntityType.tagKey,
            EntityType.subscription,
            EntityType.productCategory,
        ]);

        const filterTypes = ref<EntityType[]>(filters(p.init));

        const shouldHaveCloudAccountFilters = computed(() => filterTypes.value.includes(EntityType.cloudAccount));
        const cloudAccounts = ref<ISearchEntityViewModel[]>(getCloudAccounts(p.init?.cloudAccountIds));

        const shouldHaveSubscriptionFilters = computed(() => filterTypes.value.includes(EntityType.subscription));
        const subscriptions = ref<ISearchEntityViewModel[]>(getSubscriptions(p.init?.subscriptionIds));

        const shouldHaveProductFilters = computed(() => filterTypes.value.includes(EntityType.product));
        const products = ref<ISearchEntityViewModel[]>(getProducts(p.init?.sysProductIds));

        const shouldHaveServiceFilters = computed(() => filterTypes.value.includes(EntityType.service));
        const services = ref<ISearchEntityViewModel[]>(getServices(p.init?.sysServiceIds));

        const shouldHaveRegionFilters = computed(() => filterTypes.value.includes(EntityType.region));
        const regions = ref<ISearchEntityViewModel[]>(getRegions(p.init?.regions));

        const shouldHaveGeographyFilters = computed(() => filterTypes.value.includes(EntityType.geography));
        const geographies = ref<ISearchEntityViewModel[]>(getGeographies(p.init?.geographies));

        const shouldHaveProductCategoryFilters = computed(() => filterTypes.value.includes(EntityType.productCategory));
        const productCategories = ref<ISearchEntityViewModel[]>(getProductCategories(p.init?.productCategories));

        const shouldHaveProviderTypeFilters = computed(() => filterTypes.value.includes(EntityType.cloudProvider));
        const providerTypes = ref<ISearchEntityViewModel[]>(getProviderTypes(p.init?.cloudProviderTypes));

        const shouldHaveTagFilters = computed(() => filterTypes.value.includes(EntityType.tag));
        const tagKeyValues = ref<ISearchEntityViewModel[]>(getTagKeyValues(p.init?.tagKeyValues));

        const shouldHaveTagKeyFilters = computed(() => filterTypes.value.includes(EntityType.tagKey));
        const tagKeys = ref<ISearchEntityViewModel[]>(getTagKeys(p.init?.tagKeys));

        const form = computed(() => {
            return {
                id: id.value,
                name: name.value,
                cloudAccountIds: cloudAccounts.value.map(convert),
                subscriptionIds: subscriptions.value.map(convert),
                sysProductIds: products.value.map(convert),
                sysServiceIds: services.value.map(convert),
                regions: regions.value.map((c) => c.Name),
                geographies: geographies.value.map((c) => c.Id),
                productCategories: productCategories.value.map((c) => c.Name),
                cloudProviderTypes: providerTypes.value.map((c) => c.Id),
                tagKeyValues: tagKeyValues.value.map((c) => c.Id),
                tagKeys: tagKeys.value.map((t) => t.Id),
            } as ICustomViewResult;
        });
        return {
            id,
            name,
            cloudAccounts,
            subscriptions,
            products,
            services,
            regions,
            geographies,
            productCategories,
            providerTypes,
            tagKeyValues,
            tagKeys,
            isValid,
            form,
            filterTypes,
            shouldHaveCloudAccountFilters,
            shouldHaveGeographyFilters,
            shouldHaveProductCategoryFilters,
            shouldHaveProductFilters,
            shouldHaveProviderTypeFilters,
            shouldHaveRegionFilters,
            shouldHaveServiceFilters,
            shouldHaveSubscriptionFilters,
            shouldHaveTagFilters,
            shouldHaveTagKeyFilters,
            allFilterTypes,
        };
    },
    watch: {
        'init.id': function (newVal, oldVal) {
            this.id = newVal;
        },
        'init.name': function (newVal, oldVal) {
            this.name = newVal;
        },
        'init.cloudAccountIds': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.cloudAccount);
            this.cloudAccounts = getCloudAccounts(newVal);
        },
        'init.subscriptionIds': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.subscription);
            this.subscriptions = getSubscriptions(newVal);
        },
        'init.sysProductIds': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.product);
            this.products = getProducts(newVal);
        },
        'init.sysServiceIds': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.service);
            this.services = getServices(newVal);
        },
        'init.regions': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.region);
            this.regions = getRegions(newVal);
        },
        'init.geographies': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.geography);
            this.geographies = getGeographies(newVal);
        },
        'init.productCategories': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.productCategory);
            this.productCategories = getProductCategories(newVal);
        },
        'init.cloudProviderTypes': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.cloudProvider);
            this.providerTypes = getProviderTypes(newVal);
        },
        'init.tagKeyValues': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.tag);
            this.tagKeyValues = getTagKeyValues(newVal);
        },
        'init.tagKeys': function (newVal, oldVal) {
            this.filterTypes = createFilters(this.filterTypes, newVal, EntityType.tagKey);
            this.tagKeys = getTagKeys(newVal);
        },
    },
    computed: {
        displayFilterTypes() {
            return this.allFilterTypes.map((i: EntityType) => ({
                id: i,
                name: this.$t(`setCustomViewCard.${toPlural(i)}.title`),
            }));
        },
    },
});
