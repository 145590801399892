<template>
  <v-card class="mx-auto">
    <form-card-title :loading="loading">
      <template #title>
        <slot name="title">{{ title }}</slot>
      </template>
      <template #subtitle>
        <slot name="subtitle">{{ subtitle }}</slot>
      </template>
    </form-card-title>
    <slot name="body">
      <v-card-text class="mt-1">
        <slot name="text">
          <v-form
            :value="value"
            @input="$emit('input', $event)"
            :id="formId"
            ref="form"
            :lazy-validation="lazyValidation"
            @submit.prevent
          >
            <slot
              :isValid="value"
              :formId="formId"
              :reset="reset"
              :resetValidation="resetValidation"
            ></slot>
            <input type="hidden" />
          </v-form>
        </slot>
      </v-card-text>
    </slot>
    <form-card-actions>
      <slot
        name="actions"
        :formId="formId"
        :reset="reset"
        :resetValidation="resetValidation"
      ></slot>
    </form-card-actions>
  </v-card>
</template>

<script>
import FormCardActions from "@/components/Common/FormCardActions";
import FormCardTitle from "@/components/Common/FormCardTitle";
import UniqueIdentifier from "@/lib/UniqueIdentifier.ts";
export default {
  props: {
    loading: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    lazyValidation: {
      type: Boolean,
      default: true,
    },
  },
  components: { FormCardActions, FormCardTitle },
  data() {
    return { formId: UniqueIdentifier.create() };
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
