<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="15"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
    >
        <template #[`item.name`]="{ item }">
            <router-link :to="`/explore/services/${item.serviceId}`">
                {{ item.serviceName }}
            </router-link>
        </template>
        <template #[`item.subscriptionName`]="{ item }">
            {{ item.subscriptionName }}
        </template>
        <template #[`item.recommendationText`]="{ item }">
            <span class="text-pre-wrap">{{ item.recommendationText }}</span>
        </template>
        <template #[`item.averageMonthlySpend`]="{ item }">
            {{ item.averageMonthlySpend | currency(currencyCode) }}
        </template>
        <template #[`item.maximumEstimateImpact`]="{ item }">
            <span v-if="!item.maximumEstimateImpact"> None </span>
            <v-tooltip bottom v-else>
                <template #activator="{ on }">
                    <v-chip
                        label
                        color="success"
                        outlined
                        v-on="on"
                        v-if="item.maximumEstimateImpact === item.minimumEstimateImpact"
                    >
                        {{ item.maximumEstimateImpact | currency(currencyCode) }}
                    </v-chip>
                    <v-chip label color="success" outlined v-else v-on="on">
                        {{ item.minimumEstimateImpact | currency(currencyCode) }} -
                        {{ item.maximumEstimateImpact | currency(currencyCode) }}
                    </v-chip>
                </template>
                <span>These are the estimated savings based on your current spend.</span>
            </v-tooltip>
        </template>
        <template #[`item.averageMonthlyEmissions`]="{ item }">
            {{ item.averageMonthlyEmissions > 0 ? `${item.averageMonthlyEmissions}t CO2e` : 'None' }}
        </template>
        <template #[`item.maximumEstimateImpactEmissions`]="{ item }">
            <span v-if="!item.maximumEstimateImpactEmissions"> None </span>
            <v-tooltip bottom v-else>
                <template #activator="{ on }">
                    <v-chip
                        label
                        color="success"
                        outlined
                        v-on="on"
                        v-if="item.maximumEstimateImpactEmissions === item.minimumEstimateImpactEmissions"
                    >
                        {{ item.maximumEstimateImpactEmissions }}t CO2e
                    </v-chip>
                    <v-chip label color="success" outlined v-else v-on="on">
                        {{ item.minimumEstimateImpactEmissions }} - {{ item.maximumEstimateImpactEmissions }}t CO2e
                    </v-chip>
                </template>
                <span>These are the estimated emissions reductions based on your current consumption.</span>
            </v-tooltip>
        </template>
        <template #no-data> Good job! There are no actionable resources for this recommendation class. </template>
    </v-data-table>
</template>

<script>
export default {
    props: ['items', 'recommendation', 'currencyCode'],
    data() {
        return {
            sortBy: 'maximumEstimateImpact',
            sortDesc: true,
        };
    },
    computed: {
        headers() {
            const headers = [];
            if (this.items.some((i) => i.serviceName && i.serviceName.length > 0)) {
                headers.push({ text: 'Subscription', value: 'subscriptionName' });
                headers.push({ text: 'Service', align: 'start', sortable: true, value: 'serviceName' });
            } else {
                headers.push({ text: 'Subscription', align: 'start', sortable: true, value: 'subscriptionName' });
            }

            if (this.items.some((i) => i.recommendationText && i.recommendationText.length > 0)) {
                headers.push({ text: 'Description', value: 'recommendationText' });
            }
            if (this.items.some((i) => i.maximumEstimateImpact > 0)) {
                if (this.items.some((i) => i.averageMonthlySpend > 0)) {
                    headers.push({ text: 'Average Monthly Spend', value: 'averageMonthlySpend' });
                }
                headers.push({ text: 'Spend Impact', value: 'maximumEstimateImpact' });
            }
            if (this.items.some((i) => i.maximumEstimateImpactEmissions > 0)) {
                if (this.items.some((i) => i.averageMonthlyEmissions > 0)) {
                    headers.push({ text: 'Average Monthly Emissions', value: 'averageMonthlyEmissions' });
                }
                headers.push({ text: 'Emissions Impact', value: 'maximumEstimateImpactEmissions' });
            }

            return headers;
        },
    },
};
</script>
