
import { defineComponent } from "vue"

export default defineComponent({
    props: {
        items: {
            type: Array,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
});
