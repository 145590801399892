
import { defineComponent, computed, ref } from "vue";
import UsageFieldSelect from "../Domain.Usage/UsageFieldSelect.vue";
import { SimpleConditionTypes } from "./TagMappingConditionTypeSelect.vue";
import { RawUsageFields } from "@/models";

export default defineComponent({
  props: { value: String, type: String },
  components: { UsageFieldSelect },
  setup(p) {
    const isFieldType = computed(() => {
      return (
        p.type === SimpleConditionTypes.sourceRowField_insensitive ||
        p.type === SimpleConditionTypes.sourceRowField_sensitive
      );
    });
    const supportedTypes = ref([
      RawUsageFields.serviceName,
      RawUsageFields.serviceId,
      RawUsageFields.resourceName,
      RawUsageFields.meterName,
      RawUsageFields.meterId,
      RawUsageFields.productName,
      RawUsageFields.regionName,
      RawUsageFields.unit,
      RawUsageFields.altServiceName,
      RawUsageFields.altServiceId,
    ]);
    return { isFieldType, supportedTypes };
  },
});
