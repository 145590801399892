
import { defineComponent, ref } from 'vue';
import { useTenantStore, useWaitStore } from '@/stores';
import { mapStores } from 'pinia';
import { getPolicy, listPolicyResults, getMailingList, getPolicyResultUrl } from '@/lib/Api';

interface IResultItem {
    id: number;
    recordCount: number;
}

export default defineComponent({
    title(ctx) {
        return ctx.$t('viewPolicy.title');
    },
    computed: {
        ...mapStores(useTenantStore),
    },
    setup() {
        const { is } = useWaitStore();
        const policyId = ref(0);
        const resultId = ref(0);
        const policyName = ref('');
        const policyLevel = ref('');
        const policyRunTime = ref(new Date());
        const dimension = ref('');
        const emailsSent = ref(3);
        const results = ref<IResultItem[]>([]);
        const loadPageData = async (id: number, rid: number) => {
            const [policyResp, resultsResp] = await Promise.all([getPolicy(id), listPolicyResults(id)]);
            policyId.value = id;
            resultId.value = rid;
            policyName.value = policyResp.name;
            policyLevel.value = policyResp.severity;
            dimension.value = policyResp.dimension.label;
            const result = resultsResp.find((r) => r.id === rid);
            if (result) {
                policyRunTime.value = result.ranAt;
                results.value = Object.entries(result.resultPages).map(([id, recordCount]) => ({
                    id: Number(id),
                    recordCount,
                }));
            }
            const emails = policyResp.schedule.emailNotifyList.split(',');
            if (policyResp.mailingList) {
                const res = await getMailingList(policyResp.mailingList.id);
                const allEmails = new Set([...emails, ...res.emails]);
                emailsSent.value = allEmails.size;
            } else {
                emailsSent.value = emails.length;
            }
        };
        const downloadPage = async (index: number) => {
            const { downloadUrl } = await getPolicyResultUrl(policyId.value, resultId.value);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${policyName.value}_page${index}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        return {
            policyName,
            policyLevel,
            policyId,
            policyRunTime,
            dimension,
            emailsSent,
            results,
            loadPageData,
            downloadPage,
            wait: { is },
        };
    },
    watch: {
        $route: {
            handler(val) {
                if (!val) return;
                if (!val.params.id) return;
                this.loadPageData(Number(val.params.id), Number(val.params.rid));
            },
            immediate: true,
            deep: true,
        },
    },
});
