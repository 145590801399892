enum RawUsageFields {
    chargeDate = 'ChargeDate',
    sourceCost = 'SourceCost',
    cost = 'Cost',
    priceChannels = 'PriceChannels',
    currency = 'Currency',
    unit = 'Unit',
    unitsConsumed = 'UnitsConsumed',
    meterId = 'MeterId',
    meterName = 'MeterName',
    serviceId = 'ServiceId',
    serviceName = 'ServiceName',
    serviceTypes = 'ServiceTypes',
    regionName = 'RegionName',
    importId = 'ImportId',
    productName = 'ProductName',
    tags = 'Tags',
    resourceName = 'ResourceName',
    metaData = 'MetaData',
    altServiceId = 'AltServiceId',
    altServiceName = 'AltServiceName',
    altUsageItemType = 'AltUsageItemType',
    cO2e = 'CO2e',
    kWh = 'kWh',
    costView = 'CostView'
}

export default RawUsageFields;