var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-card',{attrs:{"title":_vm.$t('setGoogleJsonCredentialsCard.title'),"subtitle":_vm.$t('setGoogleJsonCredentialsCard.title'),"loading":_vm.loading},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" For a detailed break-down of how to configure and connect your Google Cloud connection please "),_c('a',{attrs:{"href":`${_vm.docsUrl}/Setup/GoogleCloud/`,"target":"_blank"}},[_vm._v(" follow these instructions.")])]},proxy:true},{key:"actions",fn:function({ formId, reset }){return [_vm._t("actions",null,{"form":{
                Name: _vm.name,
                UseTenantCurrencies: _vm.useTenantCurrencies,
                GoogleJsonUsageCredential: {
                    Token: _vm.jsonRulesText,
                    ClientDomain: _vm.dataSetId,
                    Container: _vm.tableId,
                    ProviderId: _vm.projectId,
                },
            },"formId":formId,"isValid":_vm.valid,"loading":_vm.loading,"clear":reset})]}}],null,true),model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":[_vm.$rules.required()],"disabled":_vm.disabled && _vm.disabled.Name,"required":"","autofocus":"","label":_vm.$t('name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('useTenantCurrencies'),"disabled":_vm.disabled && _vm.disabled.UseTenantCurrencies},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("info")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('useTenantCurrencies.tooltip')))])])]},proxy:true}]),model:{value:(_vm.useTenantCurrencies),callback:function ($$v) {_vm.useTenantCurrencies=$$v},expression:"useTenantCurrencies"}}),_c('v-file-input',{attrs:{"truncate-length":"15","label":_vm.$t('setGoogleJsonCredentialsCard.jsonCredentials')},on:{"change":_vm.handleJsonCredentialsFile}}),_c('v-textarea',{attrs:{"outlined":"","rules":[_vm.$rules.required()],"required":"","label":_vm.$t('setGoogleJsonCredentialsCard.jsonCredentials')},model:{value:(_vm.jsonRulesText),callback:function ($$v) {_vm.jsonRulesText=$$v},expression:"jsonRulesText"}}),_c('v-text-field',{attrs:{"rules":[_vm.$rules.required()],"required":"","label":_vm.$t('setGoogleJsonCredentialsCard.dataSetId')},model:{value:(_vm.dataSetId),callback:function ($$v) {_vm.dataSetId=$$v},expression:"dataSetId"}}),_c('v-text-field',{attrs:{"rules":[_vm.$rules.required()],"required":"","placeholder":"e.g. gcp_billing_export_resource_v1_...","label":_vm.$t('setGoogleJsonCredentialsCard.tableId')},model:{value:(_vm.tableId),callback:function ($$v) {_vm.tableId=$$v},expression:"tableId"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }