<template>
    <v-icon :left="left" :size="size">{{ iconValue }}</v-icon>
</template>

<script>
export default {
    props: {
        value: String,
        left: Boolean,
        size: [Number, String],
    },
    computed: {
        iconValue() {
            switch (this.value || '') {
                case 'Azure':
                    return 'cc-icon-vendor-azure';
                case 'Amazon':
                    return 'cc-icon-vendor-aws';
                case 'Google':
                    return 'cc-icon-vendor-gcp';
                case 'Alibaba':
                    return 'cc-icon-vendor-alicloud';
                case 'Oracle':
                    return 'cc-icon-vendor-oci';
                case 'Snowflake':
                    return 'cc-icon-vendor-snowflake';
                case '':
                default:
                    return 'cloud_queue';
            }
        },
    },
};
</script>