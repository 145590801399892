<template>
  <entity-search-autocomplete
    v-bind="$props"
    filled
    :types="['Tag']"
    :value="tagValueEntity"
    @input="handleTagValueSelection"
    :label="$t('tagValueAutocomplete.tagValue.label')"
    :prepend-icon="null"
    :search-prefix="tagKey ? tagKey + ':' : null"
    hide-details
    support-create
  ></entity-search-autocomplete>
</template>

<i18n>
{
    "en": {
        "tagValueAutocomplete.tagValue.label": "Value"
    }
}
</i18n>

<script>
import EntitySearchAutocomplete from "@/components/Common/EntitySearchAutocomplete.vue";

export default {
  props: {
    ...EntitySearchAutocomplete.props,
    value: String,
    tagKey: null,
  },
  components: { EntitySearchAutocomplete },
  computed: {
    tagValueEntity() {
      if (!this.value) return null;
      return {
        Id: this.value,
        Name: this.value,
        Description: "Tag",
        Provider: "",
        Type: "Tag",
        EntityIndex: 7,
      };
    },
  },
  methods: {
    handleTagValueSelection(tag) {
      if (!tag) {
        this.$emit("input", null);
      } else {
        let name = tag.Name;
        if (this.tagKey) {
          name = name.replace(this.tagKey + ":", "");
        }
        this.$emit("input", name);
      }
    },
  },
};
</script>
