<template>
    <fragment>
        <v-toolbar flat>
            <add-annotation-dialog
                v-model="dialogs.isAddingAnnotation"
                :serviceId="serviceId"
                @http2xx="handleSuccessfulAnnotationChange"
                #default="{ on }"
            >
                <v-btn
                    color="secondary"
                    v-on="on"
                    fab
                    depressed
                    small
                    class="ml-3"
                    v-show="annotations.length > 0 && tenantStore.hasPermission('CanContribute')"
                >
                    <v-icon>add</v-icon>
                </v-btn>
            </add-annotation-dialog>
        </v-toolbar>
        <annotations-timeline
            :annotations="annotations"
            @onAnnotationChangeSuccess="handleSuccessfulAnnotationChange"
            :serviceId="serviceId"
            :allowEdit="true"
            :loading="loading || $wait.is(['getAnnotations'])"
        >
            <template #no-data>
                <v-btn
                    color="secondary"
                    depressed
                    @click="dialogs.isAddingAnnotation = true"
                    v-if="tenantStore.hasPermission('CanContribute')"
                >
                    <v-icon left>add</v-icon>
                    Add
                </v-btn>
            </template>
        </annotations-timeline>
        <div v-if="hasMore" class="text-center">
            <v-btn small depressed text class="mt-2" @click="loadMore()">
                {{ $t('annotations.loadMoreButton') }}
            </v-btn>
        </div>
    </fragment>
</template>


<script>
import ApiV2 from '@/lib/ApiV2.ts';
import { waitFor } from '@/plugins/vue-wait';
import AnnotationsTimeline from '@/components/Domain.Annotations/AnnotationsTimeline.vue';
import AddAnnotationDialog from '@/components/Domain.Annotations/AddAnnotationDialog.vue';
import moment from '@/lib/moment';
import { useTenantStore } from '../../stores/tenant.ts';
import { mapStores } from 'pinia';

export default {
    props: ['value', 'loading'],
    components: { AnnotationsTimeline, AddAnnotationDialog },
    data() {
        return {
            annotations: [],
            serviceId: null,
            dialogs: {
                isAddingAnnotation: false,
            },
            page: 0,
            hasMore: false,
        };
    },
    watch: {
        value: {
            handler(svc) {
                if (!svc) return;
                this.serviceId = svc.serviceId;
                this.getAnnotations();
            },
            immediate: true,
        },
    },
    computed: {
        ...mapStores(useTenantStore)
    },
    methods: {
        resetPagination() {
            this.page = 0;
            this.annotations = [];
        },
        loadMore() {
            this.page += 1;
            this.getAnnotations();
        },
        getAnnotations: waitFor('getAnnotations', async function getAnnotations() {
            const fromDate = moment.utc().add(-13, 'months').format();
            const toDate = moment.utc().format();
            const serviceIds = `${this.serviceId}`;

            this.annotations = await ApiV2.http
                .get(`/api/annotations`, {
                    params: { fromDate, toDate, serviceIds },
                })
                .then((r) => r.data);
        }),
        handleSuccessfulAnnotationChange() {
            this.resetPagination();
            this.getAnnotations();
        },
    },
};
</script>