<template>
    <v-list-item v-on="$listeners" color="primary" style="height:100%">
        <v-list-item-content>
            <div :class="`${light ? 'grey--text' : 'primary--text'} body-2 mb-2 font-weight-light`">
                <slot name="top">{{ top }}</slot>
            </div>
            <v-list-item-title class="mb-2">
                <slot
                    ><span>{{ text }}</span></slot
                >
            </v-list-item-title>
            <div :class="`v-markdown caption ${light ? 'grey--text' : ''} text--darken-1`">
                <slot name="bottom">
                    <p>{{ bottom }}</p>
                </slot>
            </div>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: ['top', 'text', 'bottom', 'light'],
};
</script>