
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    setup(_, { emit }) {
        const showRegionDialog = ref(false);
        const showRegionId = ref<string | null>(null);
        const selectedRegion = ref<any>(null);

        const tryCloseDialog = (isOpen: boolean) => {
            if (!isOpen) {
                showRegionDialog.value = false;
                showRegionId.value = null;
                selectedRegion.value = null;
            }
            emit('input', isOpen);
        };

        const loadRegionDetails = waitFor('loadingRegions', async function (id: string) {
            try {
                selectedRegion.value = await ApiV2.http.get(`/api/regions/${id}`).then((r) => r.data);
                this.$title = selectedRegion.value.name;
            } catch (err) {
                toastError(this.$t('regionDialog.regionNotFound'));
                tryCloseDialog(false);
            }
        });

        return {
            showRegionDialog,
            showRegionId,
            selectedRegion,
            tryCloseDialog,
            loadRegionDetails,
        };
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showRegionId === to.params.regionId) return;
                this.showRegionId = to.params.regionId || null;
                this.showRegionDialog = !!this.showRegionId;
                if (this.selectedRegion && this.selectedRegion.regionId === this.showRegionId) return;
                if (!this.showRegionId) return;
                this.loadRegionDetails(this.showRegionId);
            },
            immediate: true,
            deep: true,
        },
    },
});
