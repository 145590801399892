<template>
    <set-alibaba-credentials-dialog
        :value="value"
        @input="$emit('input', $event)"
        :loading="$wait.is('creatingAlibabaAccount')"
        :edit="edit"
        :disabled="{ ...(disabled || {}), Name: true, UseTenantCurrencies: true }"
    >
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #actions="{ form, formId, isValid, clear, close }">
            <put-credential-button
                type="AlibabaUsageCredential"
                :value="{ ...edit, ...form }"
                :clear="clear"
                :valid="isValid"
                :form="formId"
                v-wait-while="'creatingAlibabaAccount'"
                @http2xx="acceptSubmit($event, close)"
            ></put-credential-button>
        </template>
    </set-alibaba-credentials-dialog>
</template>

<script>
import SetAlibabaCredentialsDialog from '@/components/Settings.CloudAccounts/Alibaba/SetAlibabaCredentialsDialog';
import PutCredentialButton from '@/components/Settings.CloudAccounts/PutCredentialButton';
export default {
    components: { SetAlibabaCredentialsDialog, PutCredentialButton },
    props: {
        value: Boolean,
        edit: Object,
        disabled: Object
    },
    methods: {
        async acceptSubmit($event, close) {
            await close();
            this.$emit('http2xx', $event);
        },
    },
};
</script>