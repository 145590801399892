
import { defineComponent, ref } from 'vue';
import { CloudProviderType, IGeographyModel } from '@/models';

export default defineComponent({
    setup(_, { emit }) {
        const showGeographyDialog = ref(false);
        const showGeographyId = ref<string | null>(null);
        const selectedGeography = ref<IGeographyModel>(null);

        const tryCloseDialog = (isOpen: boolean) => {
            if (!isOpen) {
                showGeographyDialog.value = false;
                showGeographyId.value = null;
                selectedGeography.value = null;
            }
            emit('input', isOpen);
        };

        const loadGeographyDetails = function (id: string) {
            const [providerType, geographyName] = id.split(':') as [CloudProviderType, string];
            selectedGeography.value = { providerType, geographyName };
            this.$title = geographyName;
        };

        return {
            showGeographyDialog,
            showGeographyId,
            selectedGeography,
            tryCloseDialog,
            loadGeographyDetails,
        };
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showGeographyId === to.params.geographyId) return;
                this.showGeographyId = to.params.geographyId || null;
                this.showGeographyDialog = !!this.showGeographyId;
                if (this.selectedGeography && this.selectedGeography.geographyId === this.showGeographyId) return;
                if (!this.showGeographyId) return;
                this.loadGeographyDetails(this.showGeographyId);
            },
            immediate: true,
            deep: true,
        },
    },
});
