<template>
    <fragment>
        <v-alert border="left" text type="warning" elevation="0" class="mt-3">
            {{ $t('serviceDialog.virtualTags.sourceDescription') }}
        </v-alert>
        <virtual-tag-table :loading="$wait.is(['getServiceVirtualTags', 'addServiceTag'])" :items="virtualTags">
            <template #default="{ isValid, formId, form, loading, reset }">
                <v-btn
                    color="primary"
                    :disabled="!isValid || loading"
                    :loading="loading"
                    :form="formId"
                    text
                    @click="addServiceTag(form).then(reset)"
                >
                    <v-icon left>add</v-icon>
                    Add
                </v-btn>
            </template>
            <template #item="{ item }">
                <v-btn text color="error" :loading="$wait.is('deleteServiceTag')" @click="deleteServiceTag(item)">
                    <v-icon left>delete</v-icon>
                    Drop
                </v-btn>
            </template>
        </virtual-tag-table>
    </fragment>
</template>

<i18n>
{
    "en": {
        "serviceDialog.virtualTags.sourceDescription": "Virtual tags are a stop gap solution that should be used sparingly. Best practices dictate tagging your consumption in the cloud vendor of choice instead. However, in the cases where historical spend has not been tagged properly you can use virtual tags to cover untracked spend.",
        "serviceDialog.virtualTags.errorCreating": "There was an error creating this virtual tag. Please check that there are no conflicting tag keys already on this service.",
        "serviceDialog.virtualTags.successCreating": "Your virtual tag has been successfully created. These rules will be applied during the next import at the end of the day.",
        "serviceDialog.virtualTags.errorDeleting": "There was an error deleting this virtual tag. Please refresh this page and try again.",
        "serviceDialog.virtualTags.successDeleting": "Your virtual tag has been successfully deleted for this period. This update in rules will be applied during the next import at the end of the day."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2.ts';
import { waitFor } from '@/plugins/vue-wait';
import VirtualTagTable from '@/components/Domain.VirtualTags/VirtualTagTable.vue';
import { toastError, toastSuccess } from '@/components/Common/Toast.vue';
export default {
    props: ['value', 'loading'],
    components: { VirtualTagTable },
    data() {
        return {
            virtualTags: [],
        };
    },
    watch: {
        value: {
            handler(svc) {
                if (!svc) return;
                this.getServiceTags();
            },
            immediate: true,
        },
    },
    methods: {
        getServiceTags: waitFor('getServiceVirtualTags', async function () {
            this.virtualTags = await ApiV2.http
                .get(
                    `/api/metadata/subscriptions/${this.value.subscriptionId}/services/${this.value.serviceId}/virtualtags`
                )
                .then((r) => r.data);
        }),
        addServiceTag: waitFor('addServiceTag', async function (newTag) {
            try {
                await ApiV2.http.post(
                    `/api/metadata/subscriptions/${this.value.subscriptionId}/services/${this.value.serviceId}/virtualtags`,
                    newTag
                );
                this.getServiceTags();
                toastSuccess(this.$t('serviceDialog.virtualTags.successCreating'));
            } catch (err) {
                toastError(this.$t('serviceDialog.virtualTags.errorCreating'));
            }
        }),
        deleteServiceTag: waitFor('deleteServiceTag', async function (tagToDelete) {
            try {
                await ApiV2.http.delete(
                    `/api/metadata/subscriptions/${this.value.subscriptionId}/services/${this.value.serviceId}/virtualtags/${tagToDelete.tagKey}/${tagToDelete.year}/${tagToDelete.month}`
                );
                this.getServiceTags();
                toastSuccess(this.$t('serviceDialog.virtualTags.successDeleting'));
            } catch (err) {
                toastError(this.$t('serviceDialog.virtualTags.errorDeleting'));
            }
        }),
    },
};
</script>