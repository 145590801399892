<template>
    <form-card :title="$t('setAzureEaCredentialsCard.title')" :loading="loading" v-model="valid">
        <template #subtitle>
            <v-icon color="warning" left>warning</v-icon>
            The recommended connection method for Azure enterprise agreements is now to setup Azure ARM credentials instead. The credentials below are from the legacy EA portal which is only active for Azure China tenants and is slowly being phased out. If you still have valid EA Global API keys they will work, but you will not be able to regenerate them.
        </template>
        <v-text-field
            v-model="name"
            :rules="nameRules"
            :disabled="disabled && disabled.Name"
            required
            autofocus
            :label="$t('name')"
        ></v-text-field>
        <v-checkbox
            v-model="useTenantCurrencies"
            :label="$t('useTenantCurrencies')"
            :disabled="disabled && disabled.UseTenantCurrencies"
        >
            <template #append>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn icon small v-on="on" v-bind="attrs">
                            <v-icon>info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('useTenantCurrencies.tooltip') }}</span>
                </v-tooltip>
            </template>
        </v-checkbox>
        <v-text-field
            v-model.trim="eaApiKey"
            placeholder="e.g. eyJhbGciOiJ..."
            :append-icon="showEaApiKey ? 'visibility_off' : 'visibility'"
            @click:append="() => (showEaApiKey = !showEaApiKey)"
            :type="showEaApiKey ? 'text' : 'password'"
            :label="$t('setAzureEaCredentialsCard.eaApiKey')"
            :rules="eaApiKeyRules"
            required
        ></v-text-field>
        <v-text-field
            v-model.trim="enrollmentNumber"
            v-if="enrollmentNumber"
            :label="$t('setAzureEaCredentialsCard.enrollmentNumber')"
            disabled
        ></v-text-field>
        <v-text-field
            v-model="expiryDate"
            v-if="expiryDate"
            :label="$t('setAzureEaCredentialsCard.expiryDate')"
            disabled
        ></v-text-field>
        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :form="{
                    Name: name,
                    UseTenantCurrencies: useTenantCurrencies,
                    EaPortalApiCredential: { Token: eaApiKey },
                }"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "setAzureEaCredentialsCard.title": "Set Azure EA Credentials",
        "setAzureEaCredentialsCard.enrollmentNumber": "Enrollment Number",
        "setAzureEaCredentialsCard.expiryDate": "Expiry Date",
        "setAzureEaCredentialsCard.eaApiKey": "EA Api Key",
        "setAzureEaCredentialsCard.name.required": "An name for this cloud connection is required",
        "setAzureEaCredentialsCard.eaApiKey.required": "An ea api key is required for this cloud connection",
        "setAzureEaCredentialsCard.eaApiKey.parseError": "The ea api key supplied is not valid",
        "setAzureEaCredentialsCard.eaApiKey.expired": "The ea api key supplied has expired."
    }
}
</i18n>

<script>
import parseJwt from '@/lib/JwtParser.ts';
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            name: '',
            nameRules: [(v) => !!v || this.$t('setAzureEaCredentialsCard.name.required')],
            useTenantCurrencies: false,
            enrollmentNumber: '',
            expiryDate: null,
            showEaApiKey: false,
            eaApiKey: '',
            eaApiKeyRules: [
                (v) => !!v || this.$t('setAzureEaCredentialsCard.eaApiKey.required'),
                (v) => {
                    if (!v) return true;
                    const jwt = parseJwt(v);
                    if (!jwt || jwt.iss !== 'ea.microsoftazure.com' || !jwt.EnrollmentNumber) {
                        return this.$t('setAzureEaCredentialsCard.eaApiKey.parseError');
                    }
                    if (jwt.exp && jwt.exp < Date.now() / 1000) {
                        return this.$t('setAzureEaCredentialsCard.eaApiKey.expired');
                    }
                    return true;
                },
            ],
        };
    },
    watch: {
        eaApiKey(val) {
            if (!val) {
                this.enrollmentNumber = '';
                this.expiryDate = null;
                return;
            }
            const jwt = parseJwt(val);
            if (!jwt) {
                this.enrollmentNumber = '';
                this.expiryDate = null;
                return;
            }
            this.enrollmentNumber = jwt.EnrollmentNumber;
            this.expiryDate = new Date(jwt.exp * 1000);
        },
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                if (val.eaPortalApiCredential) {
                    this.eaApiKey = val.eaPortalApiCredential.token;
                } else {
                    this.eaApiKey = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

