const GroupValue = "GroupKey:";

export class Recommendation {
  public id: string;
  public name: string;
  public description: string;
  public detailsUrl: string;
  public results: RecommendationResult[];
  public showResults: boolean;
  public minimumEstimateImpact: number;
  public maximumEstimateImpact: number;
  public minimumEstimateImpactEmissions: number;
  public maximumEstimateImpactEmissions: number;
  public averageMonthlySpend: number;
  public averageMonthlyEmissions: number;
  public category: string;
  public environmentalImpact: number;
  public cloudProviderType: string | null;

  constructor(rec) {
    this.id = rec.id;
    this.name = rec.name;
    this.description = rec.description;
    this.detailsUrl = rec.detailsUrl;
    this.results = rec.results.map(res => new RecommendationResult(res));
    this.showResults = false;
    this.minimumEstimateImpact = rec.minimumEstimateImpact;
    this.maximumEstimateImpact = rec.maximumEstimateImpact;
    this.minimumEstimateImpactEmissions = rec.minimumEstimateImpactEmissions;
    this.maximumEstimateImpactEmissions = rec.maximumEstimateImpactEmissions;
    this.averageMonthlySpend = rec.averageMonthlySpend;
    this.averageMonthlyEmissions = rec.averageMonthlyEmissions;
    this.category = rec.category;
    this.environmentalImpact = rec.environmentalImpact;
    this.cloudProviderType = rec.cloudProviderType;
  }

  get numberOfResults() {
    if (!this.results) return 0;
    else return this.results.reduce((accum, rec) => accum + (rec.isInFilter ? 1 : 0), 0);
  }

  get cost() {
    if (!this.results) return 0;
    else return this.results.reduce((accum, rec) => accum + (rec.isInFilter ? rec.cost : 0), 0);
  }

  get hasResultsToShow() {
    return this.results && !!this.results.find((res) => res.isInFilter);
  }

  get costDisplay() {
    return this.cost.toFixed(2);
  }
}

export class RecommendationResult {
  public id: string;
  public productName: string;
  public recommendationId: string;
  public subscription: string;
  public subscriptionName: string;
  public serviceName: string;
  public cost: number;
  public tags: any;
  public cloudAccountType: string;
  public snoozedOn: Date;
  public isInFilter: boolean;
  public minimumEstimateImpact: number;
  public maximumEstimateImpact: number;
  public minimumEstimateImpactEmissions: number;
  public maximumEstimateImpactEmissions: number;
  public averageMonthlySpend: number;
  public averageMonthlyEmissions: number;
  public serviceId: string;
  public recommendationText: string;

  constructor(res) {
    this.id = res.id;
    this.productName = res.productName;
    this.recommendationId = res.recommendationId;
    this.subscription = res.subscriptionId;
    this.subscriptionName = res.subscriptionName;
    this.serviceName = res.serviceName;
    this.serviceId = res.serviceId;
    this.cost = res.cost;
    this.tags = res.tags;
    this.cloudAccountType = res.cloudAccountType;
    this.snoozedOn = res.snoozedOn;
    this.isInFilter = true;
    this.minimumEstimateImpact = res.minimumEstimateImpact;
    this.maximumEstimateImpact = res.maximumEstimateImpact;
    this.minimumEstimateImpactEmissions = res.minimumEstimateImpactEmissions;
    this.maximumEstimateImpactEmissions = res.maximumEstimateImpactEmissions;
    this.averageMonthlySpend = res.averageMonthlySpend;
    this.averageMonthlyEmissions = res.averageMonthlyEmissions;
    this.recommendationText = res.recommendationText;
  }

  get costDisplay() {
    return this.cost.toFixed(2);
  }
}

export class RecommendationsFilterItem {
  public value: string;
  public name: string;
  constructor(value, name) {
    this.value = value;
    this.name = name;
  }
}

export class RecommendationsGroupFilterItem {
  public value: string;
  public name: string;
  public childItems: any[];
  constructor(groupName, childItems) {
    // This is necessary because of the way TreeView works in vuetify < 20.0
    // All values in the tree are selected.  We only want leaf (the child items)
    this.value = GroupValue + groupName;
    this.name = groupName;

    this.childItems = childItems;
  }
}

export class RecommendationsFilter {
  public possibleItems: any[];
  public selectField: string;
  public showAllOrAny: string;
  public allOrAny: string;
  public useNestedList: boolean;
  public internalSelectedValues: any[];
  public icon: string;
  public singularName: string;
  public pluralName: string;

  constructor($possibleItems, $selectField, $allOrAny, $useNestedList) {
    this.possibleItems = $possibleItems;
    this.selectField = $selectField;
    this.showAllOrAny = $allOrAny;
    this.allOrAny = $allOrAny || "Any";
    this.useNestedList = $useNestedList || false;
    this.internalSelectedValues = [];
    this.icon = "";
    this.singularName = "[[MENU]]";
    this.pluralName = "[[MENU]]";
  }

  get selectedValues() {
    return this.useNestedList ? this.internalSelectedValues.filter((val) => !val.startsWith(GroupValue)) : this.internalSelectedValues;
  }

  public clearAll() {
    this.internalSelectedValues = [];
  }

  public selectAll() {
    this.internalSelectedValues = this.possibleItems.map((item) => item.value);
  }

  public isValueSelected(value) {
    return this.internalSelectedValues.indexOf(value) > -1;
  }

  public select(value) {
    if (!this.isValueSelected(value)) this.internalSelectedValues.push(value);
  }

  public filter(result) {
    if (!result) throw new Error("No result provided to filter()");

    if (this.possibleItems.length === 1 || this.selectedValues.length === 0) {
      return true;
    }

    if (Array.isArray(result[this.selectField])) {
      const valuesInObjectToFilter = result[this.selectField];

      // Intersection finds the common values.
      const sameValues = [this.selectedValues, valuesInObjectToFilter].reduce((a, b) => a.filter(c => b.includes(c)));
      if (this.allOrAny === "Any") {
        // Any: If there are *any* values in the intersection of these two arrays then the result should be included.
        return sameValues.length > 0;
      } else {
        // All: Check for ALL values existing in the valuesInObjectToFilter.
        // Difference returns the values in selectedValues that are *not* present in sameValues.
        // If there are no values in the resulting array, then the valuesInObjectToFilter must contain *ALL* the values
        return [this.selectedValues, sameValues].reduce((a, b) => a.filter(c => !b.includes(c)));
      }
    } else {
      return this.selectedValues.indexOf(result[this.selectField]) > -1;
    }
  }

  get isFiltered() {
    return this.selectedValues && this.selectedValues.length > 0;
  }

  get hasOptions() {
    return this.optionCount > 0;
  }

  get optionCount() {
    if (!this.possibleItems) return 0;
    // Options that can be selected are on the leaf nodes when using nested.
    if (this.useNestedList) {
      return this.possibleItems.map((i) => (i.childItems ? i.childItems.length : 0)).reduce((a, b) => a + b, 0);
    } else {
      return this.possibleItems.length;
    }
  }

  get menuName() {
    if (!this.selectedValues || this.selectedValues.length === 0) return this.pluralName;
    if (this.selectedValues.length === 1) return `${this.selectedValues.length} ${this.singularName}`;
    else return `${this.selectedValues.length} ${this.pluralName}`;
  }
}

export class RecommendationsFilterManager {
  public filters: any[];

  constructor(filters) {
    this.filters = filters;
  }

  public clearFilters(recommendations) {
    this.filters.forEach((x) => x.clearAll());
    if (recommendations) {
      this.filter(recommendations);
    }
  }

  public clearAllFilter(filter, recommendations) {
    filter.clearAll();
    this.filter(recommendations);
  }

  public selectAllFilter(filter, recommendations) {
    filter.selectAll();
    this.filter(recommendations);
  }

  public filterWithInfo(filterIndex, selectedValuesForFilter, recommendations) {
    this.filters[filterIndex].internalSelectedValues = selectedValuesForFilter;
    this.filter(recommendations);
  }

  public filter(recommendations) {
    return recommendations.map((recommendation) => {
      recommendation.results.forEach((result) => {
        let isInFilter = true;
        for (const filter of this.filters) {
          isInFilter = isInFilter && filter.filter(result);
        }
        result.isInFilter = isInFilter;
      });

      return recommendation.results.filter((r) => r.isInFilter).length;
    });
  }

  get isFiltered() {
    for (const filter of this.filters) {
      if (filter.isFiltered) {
        return true;
      }
    }
    return false;
  }
}
