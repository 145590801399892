
import { useTenantStore } from '@/stores/tenant';
import { defineComponent, computed, ref } from 'vue';
import { ICustomViewResult } from '@/models';
export default defineComponent({
    title(ctx) {
        if (ctx.value) {
            return ctx.value.Name;
        } else {
            return ctx.$t('views.dialogCustomView.title');
        }
    },
    props: {
        value: Object,
        loading: {
            default: false,
            type: Boolean,
        },
    },
    setup(p) {
        const tab: string = null;
        const tenantStore = useTenantStore();
        const tabs = computed(() =>
            [
                { key: 'usage', icon: 'insights', text: 'views.dialogCustomView.tabs.usage' },
            ].filter((t) => t)
        );
        const isEditingName = ref(false);
        const customView = computed(() => {
            return p.value as ICustomViewResult; 
        });
        return { tab, tabs, tenantStore, isEditingName, customView };
    }
});
