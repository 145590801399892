
import { computed, defineComponent, ref } from 'vue';
import { storeToRefs } from '@/stores';
import { useGridStore } from '@/stores/grid';
import Grid from '@/components/Grid/Grid.vue';
import { CCWidget, WidgetTypes } from '@/merge/Grid';
import { ISavedReportResultParams } from '@/models';
import { getCustomDashboardDoc, putCustomDashboard } from '@/lib/Api';
import WidgetGridFormDialog from '@/components/Grid/WidgetGridFormDialog.vue';
export default defineComponent({
    components: { Grid, WidgetGridFormDialog },
    setup() {
        const gridStore = useGridStore();
        const { gridDoc, currentDocKey } = storeToRefs(gridStore);
        const pageTitle = computed(() => {
            return gridDoc?.value?.name || '...';
        });
        const onDeleteWidget = async (w: CCWidget) => {
            await gridStore.removeGridElement(w.id);
        };
        const addReportWidget = async (
            name: string,
            widgetType: WidgetTypes,
            r: ISavedReportResultParams | null | undefined
        ) => {
            if (!r) return;
            isPutting.value = false;
            await gridStore.addFeedElement(name, widgetType, r, { x: 0, y: 0, w: 12, h: 14 });
        };
        const updateReportWidget = async (
            name: string,
            widgetType: WidgetTypes,
            r: ISavedReportResultParams | null | undefined
        ) => {
            if (!r) return;
            isPutting.value = false;
            await gridStore.updateFeedElement(focusedWidget.value.id, name, widgetType, r);
            focusedWidget.value = null;
            
        };
        const handleSave = async (
            name: string,
            widgetType: WidgetTypes,
            r: ISavedReportResultParams | null | undefined
        ) => {
            if (focusedWidget.value) {
                await updateReportWidget(name, widgetType, r);
            } else {
                await addReportWidget(name, widgetType, r);
            }

            if (gridDoc.value) {
                const doc = gridDoc.value;
                await putCustomDashboard(currentDocKey.value, { name: doc.name, description: doc.description }, doc);
            }
        };
        const hitSave = async () => {
            const doc = await gridStore.commitCachedValues();
            await putCustomDashboard(currentDocKey.value, { name: doc.name, description: doc.description }, doc);
        };
        const focusedWidget = ref<CCWidget | null>(null);
        const isPutting = ref(false);
        const onEditReportWidget = (w: CCWidget) => {
            isPutting.value = true;
            focusedWidget.value = w;
        };
        const load = async (id:string) => {
            const doc = await getCustomDashboardDoc(id);
            await gridStore.putGrid(id, doc);
            await gridStore.load(id);
        };
        const startCreate = () => {
            isPutting.value = true;
            focusedWidget.value = null;
        }
        return {
            load,
            addReportWidget,
            hitSave,
            isPutting,
            onEditReportWidget,
            onDeleteWidget,
            handleSave,
            focusedWidget,
            pageTitle,
            startCreate
        };
    },
    watch: {
        $route: {
            async handler(newRoute, oldRoute) {
                if (oldRoute && oldRoute.name === 'customdashboard') {
                    await this.hitSave();
                }
                await this.load(newRoute.params.id);
            },
            immediate: true,
            deep: true,
        },
    },
    async beforeDestroy() {
        await this.hitSave();
    },
});
