
import { getTrends, getTrendsSummary } from "@/lib/Api";
import { waitFor } from "@/plugins/vue-wait";
import moment from "@/lib/moment";
import { defineComponent } from "vue";
import { useTenantStore } from "../stores/tenant";
import { mapStores } from "pinia";
import ServiceComparisonsTable from "@/components/Domain.Services/ServiceComparisonsTable.vue";
import UsageComparisonOptionsToolbar from "@/components/Domain.Usage/UsageComparisonOptionsToolbar.vue";
import ProductComparisonsTable from "@/components/Domain.Products/ProductComparisonsTable.vue";
import { PluralEntityType } from "@/models";
import TrendBarChart, {
  convertProductCompareResultToDatasets,
  convertServiceCompareResultToDatasets,
  IClickEvent,
} from "@/components/Domain.Usage/TrendBarChart.vue";

export default defineComponent({
  title(ctx) {
    return ctx.$t("trendsDashboard.title");
  },
  components: {
    ServiceComparisonsTable,
    UsageComparisonOptionsToolbar,
    ProductComparisonsTable,
    TrendBarChart,
  },
  computed: {
    lastUsage() {
      return this.tenantStore.lastUsageDateForTenant
        ? moment.utc(this.tenantStore.lastUsageDateForTenant).format("DD MMMM YYYY")
        : moment.utc().format("DD MMMM YYYY");
    },
    ...mapStores(useTenantStore),
  },
  created() {
    // Set data properties from tenant store. This must be done after computed properties have loaded.
    this.currentPeriodMax = moment
      .utc(this.tenantStore.lastUsageDateForTenant)
      .format("YYYY-MM-DD");
    this.compareConfiguration.toDate = this.$route.query.toDate
      ? moment.utc(this.$route.query.toDate)
      : this.tenantStore.lastUsageDateForTenant
      ? moment.utc(this.tenantStore.lastUsageDateForTenant).toDate()
      : moment.utc().toDate();
    this.loadProductComparisons();
    this.refreshSummaries();
  },
  data() {
    return {
      averageDailySpend: 0,
      sevenDayTrend: {
        activePeriodSpend: 0,
        priorPeriodSpend: 0,
        deltaSpend: 0,
        deltaPercentage: 0,
      },
      thirtyDayTrend: {
        activePeriodSpend: 0,
        priorPeriodSpend: 0,
        deltaSpend: 0,
        deltaPercentage: 0,
      },
      sixtyDayTrend: {
        activePeriodSpend: 0,
        priorPeriodSpend: 0,
        deltaSpend: 0,
        deltaPercentage: 0,
      },
      thisMonthName: moment.utc().format("MMMM"),
      lastMonthName: moment.utc().add(-1, "months").format("MMMM"),
      currentPeriodMax: null,
      totalNewThisMonthCount: 0,
      totalNewLastMonthCount: 0,
      spendLimits: [],
      recentServices: [],
      usageRecords: [],
      comparisons: [],
      fromDate: null,
      trendRecords: { chartseries: [] },
      trendDatasets: { labels: [], datasets: [] },
      compareConfiguration: {
        segregateBy: this.$route.query.segregateBy || "products",
        period: this.$route.query.period || "30d",
        toDate: moment.utc().toDate(),
      },
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (!val) return;
        if (val.query.segregateBy)
          this.compareConfiguration.segregateBy = val.query.segregateBy;
        if (val.query.period) this.compareConfiguration.period = val.query.period;
        if (val.query.toDate)
          this.compareConfiguration.toDate = new Date(val.query.toDate);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    loadProductComparisons: waitFor(
      "loadProductComparisons",
      async function loadCompare() {
        const params = {
          period: this.compareConfiguration.period,
          toDate: this.compareConfiguration.toDate.toISOString(),
        };
        if (this.compareConfiguration.segregateBy === PluralEntityType.products) {
          const result = await getTrends(PluralEntityType.products, params);
          this.trendDatasets = convertProductCompareResultToDatasets(result);
          this.comparisons = result.entities || [];
        } else if (this.compareConfiguration.segregateBy === PluralEntityType.services) {
          const result = await getTrends(PluralEntityType.services, params);
          this.trendDatasets = convertServiceCompareResultToDatasets(result);
          this.comparisons = result.entities || [];
        } else {
          throw new Error("Unsupported segregation in trends dashboard");
        }
      }
    ),
    refreshSummaries: waitFor("refreshSummaries", async function refreshSummaries() {
      this.sevenDayTrend = await this.loadTrendSummary(7);
      this.thirtyDayTrend = await this.loadTrendSummary(30);
      this.sixtyDayTrend = await this.loadTrendSummary(60);
    }),
    loadTrendSummary: waitFor(
      "loadTrendSummary",
      async function loadTrendSummary(days: number) {
        const lastUsageDate = this.tenantStore.lastUsageDateForTenant
          ? moment.utc(this.tenantStore.lastUsageDateForTenant).format("YYYY-MM-DD")
          : moment.utc().format("YYYY-MM-DD");
        return await getTrendsSummary(PluralEntityType.subscriptions, {
          days,
          lastUsageDate,
        });
      }
    ),
    handleChartClick(event: IClickEvent) {
      if (this.compareConfiguration.segregateBy === PluralEntityType.services) {
        this.$router.push(`/trends/services/${event.entityId}`);
      } else if (this.compareConfiguration.segregateBy === PluralEntityType.products) {
        this.$router.push(`/trends/products/${event.entityId}`);
      }
    },
    handleDialogChange(isOpen) {
      if (isOpen) return;
      this.$router.push("/trends");
      this.$title = this.$t("trendsDashboard.title");
    },
  },
});
