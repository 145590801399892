<template>
    <form-card
        title="Set FX Rate"
        subtitle="FX Rates are effective from a given period until another FX Rate is provided in the future."
        :loading="loading"
        v-model="valid"
    >
        <v-alert color="warning" text>
            <v-icon color="warning">warning</v-icon>
            Changes to FX Rates will only take effect as usage is imported. Usage is only imported up to 2 months prior.
            If you need more control over FX rates please contact support.</v-alert
        >
        <v-select
            v-model="sourceCode"
            :items="currencyCodes"
            label="Source Currency"
            :disabled="disabled && disabled.sourceCurrencyCode"
        ></v-select>
        <v-select
            v-model="targetCurrencyCode"
            :items="currencyCodes"
            label="Target Currency"
        ></v-select>
        <v-text-field
            type="number"
            v-model="fxRate"
            :disabled="disabled && disabled.fxRate"
            label="FX Rate"
        ></v-text-field>
        <v-subheader class="px-0">Effective From...</v-subheader>
        <v-date-picker
            class="pl-0"
            v-model="effectiveFrom"
            full-width
            landscape
            type="month"
            :disabled="disabled && (disabled.effectiveFromYear || disabled.effectiveFromMonth)"
        ></v-date-picker>
        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :form="formData"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<script>
import CurrencyService from '@/lib/Currencies';
export default {
    props: ['loading', 'value', 'disabled'],
    async created() {
        this.currencyCodes = await CurrencyService.getAllCurrencyCodes();
    },
    data() {
        return {
            valid: false,
            sourceCode: 'USD',
            targetCurrencyCode: 'AUD',
            fxRate: 1.4,
            effectiveFrom: new Date().toISOString().substring(0, 10),
            currencyCodes: [],
        };
    },
    computed: {
        formData() {
            if (!this.valid) return {};
            else {
                const effectiveFrom = new Date(this.effectiveFrom);
                return {
                    sourceCurrencyCode: this.sourceCode,
                    targetCurrencyCode: this.targetCurrencyCode,
                    fxRate: this.fxRate,
                    effectiveFromYear: effectiveFrom.getFullYear(),
                    effectiveFromMonth: effectiveFrom.getMonth() + 1,
                };
            }
        },
    },
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.sourceCode = 'USD';
                    this.targetCurrencyCode = 'AUD';
                    this.fxRate = 1.4;
                    this.effectiveFrom = new Date().toISOString().substring(0, 10);
                } else {
                    this.sourceCode = val.sourceCurrencyCode;
                    this.targetCurrencyCode = val.targetCurrencyCode;
                    this.fxRate = val.fxRate;
                    this.effectiveFrom = new Date(val.effectiveFromYear, val.effectiveFromMonth, 1)
                        .toISOString()
                        .substring(0, 10);
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style>
.pl-0 .v-picker__title {
    padding-left: 0;
}
</style>