
import { defineComponent, ref } from 'vue';
import { useTenantStore, useWaitStore } from '@/stores';
import { mapStores } from 'pinia';
import { getPolicy, listPolicyResults, putMailingList, getPolicyResultUrl } from '@/lib/Api';
import ModalListInput from '@/components/Common/ModalListInput.vue';

type Severity = 'Critical' | 'Warning' | 'Information';

interface IRecentResult {
    id: number;
    policyName: string;
    policyId: number;
    count: number;
    severity: Severity;
    lastRun: Date;
}

interface IMailingListViewModel {
    id: number;
    name: string;
    emails: string[];
}

export default defineComponent({
    components: { ModalListInput },
    title(ctx) {
        return ctx.$t('viewPolicy.title');
    },
    computed: {
        ...mapStores(useTenantStore),
    },
    methods:{
        policyDescription1(description: string) {
            if (description != null) return description.split('\r\n')[0];
            else return '';
        },
        policyDescription2(description: string) {
            if (description != null) return description.split('\r\n')[1];
            else return '';
        },
        policyDescription3(description: string) {
            if (description != null) return description.split('\r\n')[2];
            else return '';
        },
    },
    setup() {
        const { is } = useWaitStore();
        const loading = ref(false);
        const policyName = ref('');
        const policyLevel = ref('');
        const customViewDescription = ref('');
        const deltaStrategyDesc = ref('');
        const strategySummary = ref('');
        const newSpendStrategyDesc = ref('');
        const regionalSpendStrategyDesc = ref('');
        const dimension = ref('');
        const schedule = ref('');
        const scheduleDescription = ref('');
        const recentResults = ref<IRecentResult[]>([]);
        const mailingList = ref<IMailingListViewModel>();
        const loadPageData = async (id: number) => {
            const [policyResp, resultsResp] = await Promise.all([getPolicy(id), listPolicyResults(id)]);
            policyName.value = policyResp.name;
            policyLevel.value = policyResp.severity;
            policyResp.description;
            customViewDescription.value = policyResp.customView?.filterDescription || 'All Tenant Spend';
            deltaStrategyDesc.value = policyResp.deltaSpendStrategy?.description;
            newSpendStrategyDesc.value = policyResp.newSpendStrategy?.description;
            regionalSpendStrategyDesc.value = policyResp.regionalSpendStrategy?.description;
            strategySummary.value = policyResp.strategySummary;
            dimension.value = policyResp.dimension.label;
            schedule.value = policyResp.schedule.frequency;
            scheduleDescription.value = policyResp.schedule.description;
            recentResults.value = resultsResp.map((r) => ({
                id: r.id,
                policyName: policyName.value,
                policyId: id,
                dimension: r.dimensionLabel,
                schedule: r.scheduleDescription,
                count: r.resultCount,
                lastRun: r.ranAt,
                severity: policyLevel.value as Severity,
            }));

            if (policyResp.mailingList) {
                mailingList.value = {
                    id: policyResp.mailingList.id,
                    name: policyResp.mailingList.name,
                    emails: policyResp.mailingList.emails,
                };
            }
        };
        const downloadPage = async (id: number, policyId: number) => {
            const { downloadUrl } = await getPolicyResultUrl(policyId, id);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${policyName.value}_page.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        const updateMailingListEmails = async (ml: IMailingListViewModel, emails: string[]) => {
            try {
                loading.value = true;
                ml.emails = emails;
                await putMailingList(ml);
            } finally {
                loading.value = false;
            }
        };
        return {
            policyName,
            policyLevel,
            customViewDescription,
            strategySummary,
            deltaStrategyDesc,
            newSpendStrategyDesc,
            regionalSpendStrategyDesc,
            dimension,
            schedule,
            recentResults,
            mailingList,
            loadPageData,
            downloadPage,
            updateMailingListEmails,
            scheduleDescription,
            wait: { is },
        };
    },
    watch: {
        $route: {
            handler(val) {
                if (!val) return;
                if (!val.params.id) return;
                this.loadPageData(Number(val.params.id));
            },
            immediate: true,
            deep: true,
        },
    },
});
