<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :items="matchingBuckets"
    :loading="loading"
    :search-input.sync="search"
    :rules="rules"
    hide-no-data
    hide-selected
    :label="label"
    return-object
  >
    <template #prepend-inner>
      <v-icon v-if="!loading">search</v-icon>
      <v-progress-circular v-else :width="3" size="24" color="primary" indeterminate></v-progress-circular>
    </template>
  </v-autocomplete>
</template>

<script>
import Api from "@/lib/ApiV2";
import EventBus from "@/lib/EventBus";
export default {
  props: ["value", "accessKeyId", "secretAccessKey", "region", "label", "rules"],
  computed: {
    matchingBuckets() {
      const bucketNames = (this.allS3Buckets || [])
        .map((b) => b.bucketName)
        .sort();
      if (!this.search) return bucketNames;
      return [this.search]
        .concat(bucketNames.filter((b) => b.startsWith(this.search)))
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
    },
    bucketCredentials() {
      return [this.accessKeyId, this.secretAccessKey, this.region].join();
    }
  },
  watch: {
    bucketCredentials() {
      this.errored = false;
      this.allS3Buckets = null;
    },
    async search(val) {
      if (!val) return;
      if (this.loading) return;
      if (this.allS3Buckets) return;
      if (!this.accessKeyId) return;
      if (!this.secretAccessKey) return;
      if (this.errored) return;

      this.loading = true;
      try {
        this.allS3Buckets = await Api.http
          .post("/api/aws/s3/buckets", {
              ProviderId: this.accessKeyId,
              Token: this.secretAccessKey,
              RegionCode: this.region
          })
          .then((r) => r.data);
      } catch (err) {
        EventBus.$emit(
          "$toast.error",
          "We could not request S3 buckets with the credentials you have provided. This could indicate your credentials have expired."
        );
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      allS3Buckets: null,
      errored: false,
      loading: false,
      search: "",
    };
  },
};
</script>