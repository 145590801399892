<template>
    <v-card flat :light="light">
        <slot></slot>
    </v-card>
</template>

<script>
export default {
    name: 'CloudctrlCardBase',
    props: {
        light: {
            type: Boolean,
            default: false
        }
    }
};
</script>

