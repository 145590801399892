
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useTenantStore, storeToRefs } from '@/stores';
import createTagSpendAreaChart, {
    convertTagSpendResponseToDatasets,
    IClickEvent
} from '@/chartjs/createTagSpendAreaChart';
export { convertTagSpendResponseToDatasets, IClickEvent };

export default defineComponent({
    props: {
        data: Object,
    },
    setup(p, { emit }) {
        const root = ref<HTMLDivElement>(null);
        const canvas = ref<HTMLCanvasElement>(null);
        const legend = ref<HTMLDivElement>(null);
        const canvasContainer = ref<HTMLDivElement>(null);

        const { currencyCode } = storeToRefs(useTenantStore());
        const renderChart = () => {
            if (!p.data) return;
            if (!root.value) return;
            root.value.innerHTML = "";
            root.value.classList.add('chart-container');

            canvas.value = document.createElement("canvas");
            canvas.value.style.height = "400px";
            canvas.value.classList.add('chart-canvas');

            legend.value = document.createElement('div');
            legend.value.id =  Math.floor(Math.random() * 1000000).toString();
            legend.value.innerHTML ="";
            legend.value.classList.add('chart-legend');
            legend.value.style.maxHeight = '400px';

            canvasContainer.value = document.createElement('div');
            canvasContainer.value.classList.add('chart-canvas-container');

            canvasContainer.value.appendChild(canvas.value);
            root.value.appendChild(canvasContainer.value);
            root.value.appendChild(legend.value);

            const chart = createTagSpendAreaChart(canvas.value, {
                currencyCode: currencyCode.value,
                onClick(event) {
                    emit('click', event);
                },
            },
            legend.value.id);
            chart.data = p.data as any;
            chart.update();
        };
        onMounted(() => {
            renderChart();
        });
        watch(
            () => p.data,
            () => renderChart(),
            { immediate: true, deep: true }
        );
        return { root, canvas, renderChart };
    },
    watch: {
        '$vuetify.theme.dark': {
            handler() {
                this.renderChart();
            },
        },
    },
});
