
import { defineComponent } from 'vue';
import { getCustomView } from '@/lib/Api';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
export default defineComponent({
    data() {
        return {
            showCustomViewDialog: false,
            showCustomViewId: null,
            selectedCustomView: null,
        };
    },
    methods: {
        tryCloseDialog(isOpen) {
            if (!isOpen) {
                this.showCustomViewDialog = false;
                this.showCustomViewId = null;
                this.selectedCustomView = null;
            }
            this.$emit('input', isOpen);
        },
        loadCustomViewDetail: waitFor('loadingCustomView', async function (id:string) {
            try {
                this.selectedCustomView = await getCustomView(id);
                this.$title = this.selectedCustomView.name;
            } catch (err) {
                toastError(this.$t('customViewDialog.customViewNotFound'));
                this.tryCloseDialog(false);
            }
        }),
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showCustomViewId === to.params.customViewId) return;
                this.showCustomViewId = to.params.customViewId || null;
                this.showCustomViewDialog = !!this.showCustomViewId;
                if (this.selectedCustomView && this.selectedCustomView.Id === this.showCustomViewId) return;
                if (!this.showCustomViewId) return;
                this.loadCustomViewDetail(this.showCustomViewId);
            },
            immediate: true,
            deep: true,
        },
    },
});
