
import { defineComponent, computed } from "vue";
import { IConditionalActionRuleResult } from "@/models";

export default defineComponent({
  props: { value: Object, edit: Boolean, title: String },
  setup(p) {
    const mapping = computed(() => p.value as IConditionalActionRuleResult | null);
    return { mapping };
  },
});
