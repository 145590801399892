
import { getCustomerSummary } from "@/lib/Api";
import CustomersChart, {
  convertCustomerUsageSummaryToDatasets,
} from "@/components/View.CustomerUsage/CustomerUsageBarChart.vue";
import CustomersTable from "@/components/View.CustomerUsage/CustomerTable.vue";
import Downloader from "@/lib/Downloader";
import EventBus from "@/lib/EventBus";
import moment from "@/lib/moment";
import { useTenantStore } from "@/stores/tenant";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  title(ctx) {
    return ctx.$t("views.customerUsage.title");
  },
  components: {
    CustomersChart,
    CustomersTable,
  },
  methods: {
    exportCustomerCsv() {
      const table = this.$refs["customers-table"];
      const headers = table.headers;
      const headerValues = headers.map((i) => i.value);
      const csvValues = table.transformedItems.map((i) => headerValues.map((k) => i[k]));
      csvValues.unshift(headers.map((i) => i.text));
      Downloader.exportCSV(csvValues, this.$t("views.customerUsage.exportFileName"));
    },
  },
  data() {
    return {
      summary: null,
      chartDatasets: { labels: [], datasets: [] },
      startPeriod: moment.utc().subtract(6, "months").startOf("month").toDate(),
      endPeriod: moment.utc().toDate(),
    };
  },
  computed: {
    totalRevenue() {
      if (!this.summary) return 0.0;
      return this.summary.totalRevenue;
    },
    totalProfit() {
      if (!this.summary) return 0.0;
      return this.summary.totalProfit;
    },
    totalMargin() {
      if (!this.summary) return 0.0;
      return this.summary.totalMargin;
    },
    numberCustomers() {
      if (!this.summary) return 0.0;
      return this.summary.customers.length;
    },
    startPeriodString() {
      return moment.utc(this.startPeriod).format("MMM YYYY");
    },
    endPeriodString() {
      return moment.utc(this.endPeriod).format("MMM YYYY");
    },
    ...mapStores(useTenantStore),
  },
  async created() {
    try {
      this.$wait.start("loadingCustomerStats");
      const data = await getCustomerSummary(this.startPeriod, this.endPeriod);
      this.summary = data;
      this.chartDatasets = convertCustomerUsageSummaryToDatasets(data, !this.tenantStore.hasTenantFeature('HideProfit'));
    } catch (err) {
      EventBus.$emit("$toast.error", "There was an error loading your customer summary.");
    } finally {
      this.$wait.end("loadingCustomerStats");
    }
  },
});
