<template>
    <form-card :title="$t('setRhipeCredentialsCard.title')" :loading="loading" v-model="valid">
        <template #subtitle>
            You can get your client credentials from the
            <a target="blank" href="https://www.prismportal.online/customers">Customer Management </a> page.
        </template>
        <v-text-field
            v-model="name"
            :rules="nameRules"
            :disabled="disabled && disabled.Name"
            required
            autofocus
            :label="$t('name')"
        ></v-text-field>
        <v-checkbox
            v-model="useTenantCurrencies"
            :label="$t('useTenantCurrencies')"
            :disabled="disabled && disabled.UseTenantCurrencies"
        >
            <template #append>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn icon small v-on="on" v-bind="attrs">
                            <v-icon>info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('useTenantCurrencies.tooltip') }}</span>
                </v-tooltip>
            </template>
        </v-checkbox>
        <v-text-field
            v-model.trim="clientId"
            :rules="clientIdRules"
            :counter="36"
            required
            :label="$t('setRhipeCredentialsCard.clientId')"
        ></v-text-field>
        <v-text-field
            v-model.trim="clientSecret"
            :rules="clientSecretRules"
            :append-icon="showClientSecret ? 'visibility_off' : 'visibility'"
            @click:append="() => (showClientSecret = !showClientSecret)"
            :type="showClientSecret ? 'text' : 'password'"
            required
            :label="$t('setRhipeCredentialsCard.clientSecret')"
        ></v-text-field>
        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :form="{
                    Name: name,
                    UseTenantCurrencies: useTenantCurrencies,
                    RhipeApiCredential: { ProviderId: clientId, Token: clientSecret },
                }"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "setRhipeCredentialsCard.title": "Set Rhipe Client Credentials",
        "setRhipeCredentialsCard.name.required": "An name for this cloud connection is required",
        "setRhipeCredentialsCard.clientId": "Client ID",
        "setRhipeCredentialsCard.clientId.required": "An client ID is required",
        "setRhipeCredentialsCard.clientId.notGuid": "The provided client ID is not a valid guid",
        "setRhipeCredentialsCard.clientSecret": "Client Secret",
        "setRhipeCredentialsCard.clientSecret.required": "An client secret is required"
    }
}
</i18n>

<script>
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            name: '',
            nameRules: [(v) => !!v || this.$t('setRhipeCredentialsCard.name.required')],
            useTenantCurrencies: false,
            clientId: '',
            clientIdRules: [
                (v) => !!v || this.$t('setRhipeCredentialsCard.clientId.required'),
                (v) => guidRegex.test(v) || this.$t('setRhipeCredentialsCard.clientId.notGuid'),
            ],
            clientSecret: '',
            clientSecretRules: [(v) => !!v || this.$t('setRhipeCredentialsCard.clientSecret.required')],
            showClientSecret: false,
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                if (val.rhipeApiCredential) {
                    this.clientId = val.rhipeApiCredential.providerId;
                    this.clientSecret = val.rhipeApiCredential.token;
                } else {
                    this.clientId = '';
                    this.clientSecret = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>