
import { emailRegex } from '@/plugins/validation';

export default {
    props: { value: Array, hideDetails: Boolean, disabled: Boolean, label: String },
    data() {
        return { items: [...(this.value || [])], searchText: null };
    },
    methods: {
        isValidEmail(searchText) {
            return emailRegex.test(searchText);
        },
        addEmail(searchText) {
            this.items.push(searchText);
            this.$emit('input', [...this.value, searchText]);
            this.searchText = null;
        },
    },
};
