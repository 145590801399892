<template>
    <usage-comparisons-table :items="items" :loading="loading" :currency="currency" :type="type">
        <template #itemName="{ item }">
            <router-link :to="`${routePrefix}/services/${item.serviceId}`">
                {{ item.serviceName }}
            </router-link>
        </template>
    </usage-comparisons-table>
</template>

<script>
import UsageComparisonsTable from '@/components/Domain.Usage/UsageComparisonsTable.vue';
export default {
    components: { UsageComparisonsTable },
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        currency: String,
        routePrefix: {
            type: String,
            default: '/explore'
        },
        type: {
            type: String,
            default: 'service',
        },
    },
};
</script>

<style scoped>
.v-data-table /deep/ tbody /deep/ tr:hover:not(.v-data-table__expanded__content) {
    background: transparent !important;
}
</style>
