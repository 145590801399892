<template>
    <v-app>
        <router-view @hideChinaFooter="showChineseFooter = false" />
        <toast></toast>
        <v-footer fixed v-if="showChineseFooter">
            <div style="width: 500px; margin: 0 auto">
                <a href="http://beian.miit.gov.cn" style="float: left; display: inline; margin-right: 1.5em"
                    >沪ICP备16016808号</a
                >
                <a
                    target="_blank"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102006691"
                    style="display: inline-block; text-decoration: none; height: 20px; float: left; line-height: 20px"
                >
                    <img src="/images/备案编号图标.png" style="float: left" />
                    <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">
                        沪公网安备 31010102006691号
                    </p>
                </a>
            </div>
        </v-footer>
    </v-app>
</template>

<script>
import Toast from '@/components/Common/Toast';
import Config from '@/lib/Config';
export default {
    components: { Toast },
    data() {
        return {
            dialog: true,
            showChineseFooter: Config.isChineseJurisdiction(),
        };
    },
};
</script>