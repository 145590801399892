<template>
    <form-dialog v-model="isOpen">
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <set-recommendation-settings-card :value="value" :loading="loading">
                <template #default="actionBindings">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <slot v-bind="actionBindings" :close="close"></slot>
                </template>
            </set-recommendation-settings-card>
        </template>
    </form-dialog>
</template>

<script>
import SetRecommendationSettingsCard from '@/components/View.Recommendations/SetRecommendationSettingsCard.vue';
export default {
    components: { SetRecommendationSettingsCard },
    data() {
        return { isOpen: false };
    },
    props: {
        value: Object,
        loading: {
            type: Boolean,
            default: false
        }
    },
};
</script>