<template>
    <v-dialog :value="true"
              no-click-animation
              persistent
              max-width="800"
              scrollable
              hide-overlay
              :fullscreen="$vuetify.breakpoint.xsOnly">
        <plan-card></plan-card>
    </v-dialog>
</template>

<script>
    import PlanCard from '@/components/Account.SelfService/SelectPlanCard.vue';

    export default {
        data() {
            return {};
        },
        components: { PlanCard },
    };

</script>