<template>
    <fragment>
        <v-chip outlined label class="mr-1">
            <entity-type-icon left value="Subscription"></entity-type-icon>
            Subscription: {{ value.subscriptionName }}
        </v-chip>
        <v-chip outlined label class="mr-1" v-for="(regionName, i) in value.regionNames || []" :key="i">
            <v-icon left>place</v-icon>
            {{ regionName }}
        </v-chip>
        <v-chip
            v-if="value.remoteId && value.remoteId.startsWith('/subscriptions')"
            color="blue"
            outlined
            label
            class="mr-1"
            :href="`https://portal.azure.com/#resource${value.remoteId}`"
            target="_top"
        >
            <v-icon left>insert_link</v-icon>
            {{ $t('serviceMetadataChips.viewInAzure') }}
        </v-chip>
    </fragment>
</template>

<i18n>
{
    "en": {
        "serviceMetadataChips.viewInAzure": "View in Azure"
    }
}
</i18n>

<script>
export default {
    props: {
        value: Object,
    },
};
</script>