<template>
    <div>
        <content-card title="Features" :caption="'The features this customer has when viewing their usage data.'">
            <v-list>
                <v-list-item v-for="feature in tenantFeatures" :key="feature.featureId">
                    <v-list-item-action>
                        <v-checkbox 
                            :disabled="!tenantStore.hasPermission('CanManageBilling') || $wait.is(`togglingFeature${feature.featureId}`)"
                            :loading="$wait.is(`togglingFeature${feature.featureId}`)" v-model="feature.isEnabled"
                            @change="toggleFeature(feature.featureId, $event)"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :title="feature.featureDescription">
                            {{ feature.featureLabel }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </content-card>
    </div>
</template>

<script>
import ApiV2 from '@/lib/ApiV2';
import { toastError, toastSuccess } from '@/components/Common/Toast.vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';

export default {
    props: ['customerId', 'tenantFeatures'],
    computed: {
        ...mapStores(useTenantStore),
    },
    methods: {
        async toggleFeature(fid, isEnabled) {
            try {
                this.$wait.start(`togglingFeature${fid}`);
                const value = { isEnabled };
                await ApiV2.http.post(`/api/customers/${this.customerId}/features/${fid}`, value).then(() => {
                    toastSuccess('Successfully updated features.');
                });
            } catch (err) {
                toastError('There was an error changing this feature');
            } finally {
                this.$wait.end(`togglingFeature${fid}`);
            }
        },
    }
}
</script>