
import { defineComponent, ref, watch, computed } from 'vue';
import {
    calculateOptionsDescription,
    convertSpendOptionsToQuery,
} from '../Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import SpendUsageLineChart, {
    convertUsageResponseToDatasets,
    convertEventsToAnnotations,
    UsageReportTypes,
} from '../Domain.Usage/SpendUsageLineChart.vue';
import CarbonLineChart, { convertUsageResponseToEmissionsDatasets } from '../Domain.Carbon/CarbonLineChart.vue';
import { useFilterStore } from '@/stores';
import ApiV2 from '@/lib/ApiV2';
import { getSpend, getForecast } from '@/lib/Api';
import { ChartDataset, Point } from 'chart.js';
import {
    generateSpendUsageOptionsFromReport,
    ISavedReportResultParams,
    PluralEntityType,
    SpendFieldNames,
} from '@/models';
import hash from 'object-hash';

export default defineComponent({
    props: { config: Object, name: String, hideActions: Boolean },
    components: { SpendUsageLineChart, CarbonLineChart },
    setup(props) {
        const filterStore = useFilterStore();

        const usageOptions = ref({
            ...filterStore.options,
        });

        const focusedSegregation = ref<number>(0);
        const segregations = ref<string[]>([]);
        const dataset = ref('Usage');
        const costViews = ref(['Actual', 'Amortized']);

        watch(
            () => props.config,
            (opt) => {
                if (!opt) return;
                const report = opt as ISavedReportResultParams;
                segregations.value = (report.segregateBy || '').split(',');
                const params = generateSpendUsageOptionsFromReport(report);
                const segregateBy = segregations.value[focusedSegregation.value] as PluralEntityType;
                usageOptions.value = { ...params, segregateBy };
                dataset.value = report.dataset || 'Usage';
                costViews.value = (report.costViews || ['Actual']).map((cv) => (cv === 'Utilized' ? 'Amortized' : cv));
            },
            { immediate: true, deep: true }
        );

        watch(
            () => focusedSegregation.value,
            (v) => {
                const segregateBy = segregations.value[v] as PluralEntityType;
                if (usageOptions.value.segregateBy !== segregateBy) {
                    usageOptions.value = { ...usageOptions.value, segregateBy };
                }
            },
            { immediate: false }
        );

        const handleDatasetClicked = () => {
            //emit('onEdit');
        };

        const newChartData = ref({
            labels: [] as string[],
            datasets: [] as ChartDataset<'bar' | 'line', Point[]>[],
        });
        let maxDate: string = undefined;

        const lastHash = ref<string | null>(null);
        const annotations = ref([]);
        const isLoadingUsage = ref(false);
        const loadUsage = async (toTrends: (searchParams: URLSearchParams) => void) => {
            if (isLoadingUsage.value) return;
            const optionsHash = hash([ usageOptions.value, dataset.value, costViews.value ]);
            if (lastHash.value === optionsHash) return;
            lastHash.value = optionsHash;
            
            try {
                isLoadingUsage.value = true;
                const params = convertSpendOptionsToQuery(usageOptions.value);
                if (!params) return;

                maxDate = params.toDate
                const fields = dataset.value === 'Usage' 
                    ? [SpendFieldNames.Charges] 
                    : [SpendFieldNames.CO2e, SpendFieldNames.kWh];

                const results = await Promise.all(
                    costViews.value.map((cv) =>
                        getSpend({ ...params, costView: cv, segregateBy: usageOptions.value.segregateBy, fields })
                    )
                );
                
                if (dataset.value === 'Usage') {
                    const datasets = results.map((r, i) => {
                        const costView = costViews.value[i];
                        const labelSuffix = costView === 'Actual' ? 'Actual Spend' : 'Utilized Spend';
                        const stackGroup = costView.toLowerCase();
                        return convertUsageResponseToDatasets(r, {
                            labelSuffix,
                            stackGroup,
                            reportType: UsageReportTypes.charges,
                        });
                    });
                    newChartData.value = {
                        labels: datasets.map((d) => d.labels).reduce((a, b) => [...a, ...b], []),
                        datasets: datasets.map((d) => d.datasets).reduce((a, b) => [...a, ...b], []),
                    } as any;
                } else {
                    const datasets = results.map((r, i) => {
                        const costView = costViews.value[i];
                        const labelSuffix = costView === 'Actual' ? 'Actual Spend' : 'Utilized Spend';
                        const stackGroup = costView.toLowerCase();
                        return convertUsageResponseToEmissionsDatasets(r, { labelSuffix, stackGroup  });
                    });
                    newChartData.value = {
                        labels: datasets.map((d) => d[0].labels).reduce((a, b) => [...a, ...b], []),
                        datasets: datasets.map((d) => d[0].datasets).reduce((a, b) => [...a, ...b], []),
                    } as any;
                }

                const spikeEventsResponse = await ApiV2.http
                    .get(`/api/events?eventType=SpikeEventAlert`, { params })
                    .catch((err) => {
                        if (err.response.status === 400) return { data: {} };
                        throw err;
                    })
                    .then((r) => r.data);

                annotations.value = convertEventsToAnnotations(spikeEventsResponse, (xMin, xMax) => {
                    const toDate = new Date(xMax);
                    const numDaysBetween = Math.ceil((xMax - xMin) / (1000 * 3600 * 24));
                    const period = `${numDaysBetween}d`;
                    const segregateBy = usageOptions.value.segregateBy === 'services' ? 'services' : 'products';
                    const searchParams = new URLSearchParams({
                        toDate: toDate.toISOString(),
                        period,
                        segregateBy,
                    });
                    toTrends(searchParams);
                });
            } finally {
                isLoadingUsage.value = false;
            }
        };
        const loadTriggers = computed(() => {
            return [ usageOptions.value, dataset.value, costViews.value ];
        });

        return {
            usageOptions,
            handleDatasetClicked,
            annotations,
            newChartData,
            loadUsage,
            isLoadingUsage,
            focusedSegregation,
            segregations,
            lastHash,
            dataset,
            loadTriggers,
            maxDate
        };
    },
    methods: {
        toTrends(searchParams: URLSearchParams) {
            this.$router.push(`/trends?${searchParams.toString()}`);
        },
    },
    watch: {
        loadTriggers: {
            handler() {
                this.loadUsage(this.toTrends);
            },
            immediate: true,
        },
    },
    computed: {
        chartTitle() {
            return this.name || calculateOptionsDescription(this.usageOptions, (...params) => this.$t(...params));
        },
    },
});
