
import SetSnowflakeCredentialsDialog from '@/components/Settings.CloudAccounts/Snowflake/SetSnowflakeCredentialsDialog.vue';
import PutCredentialButton from '@/components/Settings.CloudAccounts/PutCredentialButton.vue';
export default {
    components: { SetSnowflakeCredentialsDialog, PutCredentialButton },
    props: {
        value: Boolean,
        edit: Object,
        disabled: Object,
    },
    setup(_, { emit }) {
        const acceptSubmit = async ($event: any, close: () => Promise<void>) => {
            await close();
            emit('http2xx', $event);
        };
        return { acceptSubmit };
    },
};
