
import { defineComponent, ref } from 'vue';
import ActionScriptInput, { DEFAULT_SCRIPT_TEXT } from '@/components/Domain.Actions/ActionScriptInput.vue';
export const DEFAULT_SCRIPT_NAME = 'My New Usage Script';

export const KNOWN_SCRIPT_TYPES = [
    { id: 'ImportUsageTransformation', name: 'Usage Transform', disabled: false },
    { id: 'BillingTransformation', name: 'Billing Transform (Coming Soon)', disabled: true },
];

export const KNOWN_DELIVERY_TYPES = [
    { id: 'Script', name: 'Script', disabled: false },
    { id: 'WebHook', name: 'Web Hook (Coming Soon)', disabled: true },
];

export interface IPriceStreamViewModel {
    effectiveFrom:Date;
    effectiveTo: Date | null;
    
}

export interface IFormData {
    actionType: string;
    deliveryType: string;

    // For Script Delivery Types
    actionName: string;
    scriptText: string;

    // Price Stream ID
    priceStreamRules: IPriceStreamViewModel[];
}

export enum FlowType {
    importUsageTransformation = 'ImportUsageTransformation',
}

export enum DeliveryType {
    script = 'Script',
    webHook = 'WebHook',
    priceStream = 'PriceStream',
}

export default defineComponent({
    components: { ActionScriptInput },
    props: {
        loading: Boolean,
        init: Object,
        title: String,
        subtitle: String,
    },
    setup() {
        const isValid = ref(true);
        const scriptText = ref(DEFAULT_SCRIPT_TEXT);
        const knownScriptTypes = KNOWN_SCRIPT_TYPES;
        const knownDeliveryTypes = KNOWN_DELIVERY_TYPES;
        const actionType = ref(FlowType.importUsageTransformation);
        const deliveryType = ref(DeliveryType.script);
        const actionName = ref(DEFAULT_SCRIPT_NAME);
        const priceStreamRules = ref<IPriceStreamViewModel[]>([]);

        return {
            isValid,
            knownScriptTypes,
            actionType,
            knownDeliveryTypes,
            deliveryType,
            actionName,
            scriptText,
            priceStreamRules,
        };
    },
    computed: {
        formData(): IFormData {
            return {
                scriptText: this.scriptText,
                actionName: this.actionName,
                actionType: this.actionType,
                deliveryType: this.deliveryType,
                priceStreamRules: this.priceStreamRules,
            };
        },
    },
    watch: {
        init: {
            handler(val) {
                this.actionType = val?.actionType || 'ImportUsageTransformation';
                this.actionName = val?.actionName || DEFAULT_SCRIPT_NAME;
                this.scriptText = val?.scriptText || DEFAULT_SCRIPT_TEXT;
            },
            immediate: true,
            deep: true,
        },
    },
});
