
import SetAlibabaCredentialsCard from '@/components/Settings.CloudAccounts/Alibaba/SetAlibabaCredentialsCard.vue';
export default {
    props: ['value', 'loading', 'disabled', 'edit'],
    components: { SetAlibabaCredentialsCard },
    methods: {
        open() {
            this.$emit('input', true);
        },
    },
};
