<template>
    <content-card
        title="Select a plan to start your trial subscription"
        title-icon="star"
        :loading="$wait.is('loadingplans')"
    >
        <v-container v-if="!$wait.is('loadingplans')" fill-height>
            <v-row align="center" justify="center" class="mb-8">
                <v-btn-toggle v-model="isMonthly" mandatory>
                    <v-btn small outlined :value="true">Monthly</v-btn>
                    <v-btn small outlined :value="false">Yearly</v-btn>
                </v-btn-toggle>
            </v-row>
            <v-row fill-height>
                <v-col v-for="product in products" :key="product.stripeProductId">
                    <v-card height="100%">
                        <v-card-title>{{ product.name }}</v-card-title>
                        <v-card-text>
                            <v-row align="center" justify="center" class="ma-4" v-if="isMonthly">
                                <v-col class="text-h3" align="center">
                                    ${{ product.monthlyPlan.amount }}<br />
                                    <v-btn
                                        small
                                        color="primary"
                                        @click="planSelected(`${product.monthlyPlan.stripePriceId}`)"
                                        :loading="$wait.is('redirecting')"
                                        >start trial</v-btn
                                    >
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" class="ma-4" v-if="!isMonthly">
                                <v-col class="text-h3" align="center">
                                    ${{ product.yearlyPlan.amount }}<br />
                                    <v-btn
                                        small
                                        color="primary"
                                        @click="planSelected(`${product.yearlyPlan.stripePriceId}`)"
                                        :loading="$wait.is('redirecting')"
                                        >start trial</v-btn
                                    >
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <ul>
                                        <li v-for="(value, propertyName) in product.features" :key="propertyName">
                                            {{ propertyName }}: {{ value }}
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-8">
                <v-col class="text-caption"
                    >All prices listed in Australian Dollars and exclude GST. Payments are processed by our subscription
                    provider Stripe.</v-col
                >
            </v-row>
        </v-container>
    </content-card>
</template>

<script>
import ApiV2 from '@/lib/ApiV2';
import ContentCard from '@/components/Common/ContentCard.vue';
import { waitFor } from '@/plugins/vue-wait';

export default {
    data() {
        return {
            products: null,
            isMonthly: true,
        };
    },
    components: { ContentCard },
    created() {
        this.loadAvailablePlans();
    },
    methods: {
        loadAvailablePlans: waitFor('loadingplans', async function () {
            this.products = await ApiV2.http.get(`api/billing/selfservice/plans`).then((r) => r.data);
        }),
        async planSelected(planId) {
            this.$wait.start('redirecting');
            const checkoutLink = await ApiV2.http
                .get(`api/billing/selfservice/checkout-session?planId=${planId}`)
                .then((r) => r.data);
            window.location = checkoutLink.checkoutUrl;
        },
    },
};
</script>