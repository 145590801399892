
import Config from '@/lib/Config';
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            name: '',
            useTenantCurrencies: false,
            dataSetId: '',
            tableId: '',
            jsonRulesText: '',
            docsUrl: Config.get().DOCS_URL,
        };
    },
    computed: {
        projectId() {
            if (!this.jsonRulesText) return null;
            try {
                const jsonRules = JSON.parse(this.jsonRulesText);
                return jsonRules['project_id'] || null;
            } catch {
                return null;
            }
        },
    },
    methods: {
        async handleJsonCredentialsFile(file: File) {
            if (!file) return;
            this.jsonRulesText = await file.text();
        },
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                if (val.googleJsonUsageCredential) {
                    this.jsonRulesText = val.googleJsonUsageCredential.token;
                    this.tableId = val.googleJsonUsageCredential.container;
                    this.dataSetId = val.googleJsonUsageCredential.clientDomain;
                } else {
                    this.jsonRulesText = '';
                    this.tableId = '';
                    this.dataSetId = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
