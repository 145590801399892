
import { defineComponent, computed } from 'vue';
import ProductManageMenu from '@/components/Domain.Products/ProductManageMenu.vue';
import { useTenantStore, useDatasetState } from '@/stores/tenant';

export default defineComponent({
    components: { ProductManageMenu },
    props: {
        me: Object,
        value: Object,
        loading: Boolean,
    },
    setup() {
        const tab: string = null;
        const tenantStore = useTenantStore();
        const tabs = computed(() => {
            const { hasUsageDataset, hasEmissionsDataset } = useDatasetState(tenantStore);
            return [
                hasUsageDataset ? { key: 'usage', icon: 'insights', text: 'views.dialogProduct.tabs.usage' } : null,
                hasEmissionsDataset ? { key: 'carbon', icon: 'co2', text: 'views.dialogProduct.tabs.carbon' } : null,
                hasUsageDataset ?{ key: 'trends', icon: 'compare_arrows', text: 'views.dialogProduct.tabs.trends' } : null,
            ].filter((t) => t);
        });

        return { tab, tabs, tenantStore };
    },
});
