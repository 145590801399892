
import { ISpendByTagResult, ITagSpendLine } from '@/models';

export interface INamespaceSpendTableItemViewModel {
    cluster: string;
    namespace: string;
    total: number;
}

export const convertTagResultToNamespaceSpend = (
    clusterName: string, result: ISpendByTagResult
): INamespaceSpendTableItemViewModel[] => {
    if (!result?.data?.length) {
        return [];
    }
    const rowMap = new Map<string, INamespaceSpendTableItemViewModel>();
    result.data.filter((item: ITagSpendLine) => {
        const cluster = item.rowDimensions[0];
        return cluster.tagValue === clusterName;
    }).forEach((item: ITagSpendLine) => {
        const namespace = item.rowDimensions[2];
        if (!namespace.tagValue?.trim()) return;

        if (!rowMap.has(namespace.tagValue)) {
            const row: INamespaceSpendTableItemViewModel = {
                cluster: item.rowDimensions[0].tagValue,
                namespace: namespace.tagValue,
                total: 0
            };
            rowMap.set(namespace.tagValue, row);
        }
        const row = rowMap.get(namespace.tagValue);
        const totalSpend = Math.round(item.totalSpend * 100) / 100;
        row.total += totalSpend; // total per namespace
    });
    return [...rowMap.entries()].map(([, v]) => v);
}

export default {
    props: {
        items: Array,
        currencyCode: String,
        loading: Boolean,
    },
    computed: {
        totalSpend() {
            return this.items.reduce((total: number, item: INamespaceSpendTableItemViewModel) => total + item.total, 0);
        },
        headers() {
            if (!this.items) return [];

            return [
                {
                    text: this.$t("dashboard.namespaces.namespaceColumnHeading"),
                    value: `namespace`,
                    sortable: true,
                    index: 1,
                },
                {
                    text: this.$t("dashboard.namespaces.totalColumnHeading"),
                    value: `total`,
                    sortable: true,
                    index: 2,
                }
            ];
        }
    }
}
