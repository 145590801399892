<template>
    <v-btn
        type="submit"
        color="secondary"
        depressed
        :loading="loading"
        :disabled="!valid"
        :form="form"
        @click.prevent="submit()"
    >
        {{ $t('generateApiKeyButton.generateText') }}
    </v-btn>
</template>

<i18n>
{
    "en": {
        "generateApiKeyButton.generateText": "Generate",
        "generateApiKeyButton.error": "There was an issue generating an api key."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2';

import { toastError } from '@/components/Common/Toast.vue';

export default {
    props: ['clear', 'valid', 'form'],
    data() {
        return { loading: false };
    },
    methods: {
        async submit() {
            this.$emit('start');
            this.loading = true;
            try {
                const response = await ApiV2.http
                    .post('/api/apikeys', { type: 'Impersonation', version: 'V2', permissions: null, profileId: 0 })
                    .then((r) => {
                        return r.data;
                    });

                this.clear();
                this.$emit('http2xx', response);
            } catch (err) {
                toastError(this.$t('generateApiKeyButton.error'));
                this.$emit('http4xx', err);
            } finally {
                this.loading = false;
                this.$emit('stop');
            }
        },
    },
};
</script>
