<template>
    <v-list subheader>
        <v-list-item-group v-model="selected" active-class="primary--text" multiple>
            <fragment v-for="(group, groupIndex) in menuItems" :key="groupIndex">
                <v-subheader inset :key="'group-title-' + groupIndex">
                    {{ $t(group.title) }}
                </v-subheader>
                <v-divider inset class="mb-1"></v-divider>
                <v-list-item :to="item.to" v-for="(item, i) in group.items" :key="i">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </fragment>
        </v-list-item-group>
    </v-list>
</template>

<i18n>
{
    "en": {
        "navMenuList.subheaders.costAndUsage": "Costs and Usage",
        "navMenuList.subheaders.tracking": "Tracking",
        "navMenuList.subheaders.reporting": "Reporting",
        "navMenuList.subheaders.customers": "Customers",
        "navMenuList.subheaders.settings": "Settings",
        "navMenuList.financeDashboard": "Overview",
        "navMenuList.opsDashboard": "Operations",
        "navMenuList.trendsDashboard": "Trends",
        "navMenuList.reservationsDashboard": "Reservations",
        "navMenuList.costExplorer": "Cost Explorer",
        "navMenuList.reports": "Reports",
        "navMenuList.customViews": "Custom Views",
        "navMenuList.budgets": "Budgets & Spend Limits",
        "navMenuList.spendLimits": "Spend Limits",
        "navMenuList.customReports": "Custom Reports",
        "navMenuList.tagOverview": "Tag Reports",
        "navMenuList.regionOverview": "Cost Map",
        "navMenuList.customersOverview": "Customers Report",
        "navMenuList.manage": "Manage",
        "navMenuList.spendLimits": "Spend Limits",
        "navMenuList.recommendations": "Recommendations",
        "navMenuList.accounts": "Accounts",
        "navMenuList.users": "Users",
        "navMenuList.support": "Contact Support",
        "navMenuList.dashboarding": "Custom Dashboards",
        "navMenuList.credentialTooltip": "There are one or more cloud connection with invalid or expired credentials.",
        "navMenuList.cloudIntelligence": "Cloud Intelligence",
        "navMenuList.subheaders.energy": "Emissions and Energy",
        "navMenuList.carbon.overview": "Overview",
        "navMenuList.carbon.explorer": "Emissions Explorer",
        "navMenuList.carbon.map": "Energy Map",
        "navMenuList.watchdog": "Watchdog",
        "navMenuList.kubernetes": "Kubernetes"
    }
}
</i18n>

<script>
import { useTenantStore, useDatasetState } from '@/stores/tenant.ts';
import { mapStores } from 'pinia';
import FeatureNames from '@/lib/FeatureNames';

export default {
    props: {
        hasInvalidCredentials: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            expandReports: false,
            expandOptimizations: false,
            expandPricing: false,
            selected: [],
            FeatureNames,
        };
    },
    computed: {
        ...mapStores(useTenantStore),
        menuItems() {
            // Conditional logic for displaying menu items based on tenant features
            const { hasUsageDataset, hasEmissionsDataset } = useDatasetState(this.tenantStore);
            const hasCostAndUsageToggle = hasUsageDataset;
            const hasSpendLimitsFeature = hasUsageDataset;
            const hasTagOverviewFeature = hasUsageDataset;
            const hasKubernetesFeature = hasUsageDataset && this.tenantStore.hasTenantFeature('Kubernetes');
            const hasEmissionsAndEnergyFeature =
                hasEmissionsDataset && this.tenantStore.hasTenantFeature('EmissionsAndEnergy');

            const hasRecommendationsViewFeature =
                (hasUsageDataset || hasEmissionsDataset) && this.tenantStore.features.includes('Recommendations/View');
            const hasBudgetsFeature = hasUsageDataset && this.tenantStore.hasTenantFeature('Budgets');
            const hasWatchdogFeature = hasUsageDataset && this.tenantStore.hasTenantFeature('Watchdog');
            const hasCustomReportsFeature =
                (hasUsageDataset || hasEmissionsDataset) && this.tenantStore.hasTenantFeature('CustomReports');
            const hasGridFeature =
                hasCostAndUsageToggle &&
                hasEmissionsAndEnergyFeature &&
                hasCustomReportsFeature &&
                this.tenantStore.hasTenantFeature('Grids');
            const hasCopyCloudAccountFeature =
                hasUsageDataset && this.tenantStore.features.includes('CopyCloudAccount');
            const prefix = this.$route.params.baseTenantId ? `/${this.$route.params.baseTenantId}`:'';
            return [
                hasCostAndUsageToggle
                    ? {
                          title: 'navMenuList.subheaders.costAndUsage',
                          items: [
                              { to: `${prefix}/finance`, icon: 'savings', title: 'navMenuList.financeDashboard' },
                              { to: `${prefix}/explore`, icon: 'insights', title: 'navMenuList.costExplorer' },
                              { to: `${prefix}/trends`, icon: 'analytics', title: 'navMenuList.trendsDashboard' },
                              hasKubernetesFeature
                                  ? { to: `${prefix}/kubernetes`, icon: 'cc-icon-kubernetes', title: 'navMenuList.kubernetes' }
                                  : null,
                              { to: `${prefix}/reservations`, icon: 'book', title: 'navMenuList.reservationsDashboard' },
                              { to: `${prefix}/regionoverview`, icon: 'place', title: 'navMenuList.regionOverview' },
                          ].filter(Boolean), // Filter out null items
                      }
                    : null,
                hasEmissionsAndEnergyFeature
                    ? {
                          title: 'navMenuList.subheaders.energy',
                          items: [
                              { to: `${prefix}/emissions/overview`, icon: 'co2', title: 'navMenuList.carbon.overview' },
                              {
                                  to: `${prefix}/emissions/explore`,
                                  icon: 'insights',
                                  title: 'navMenuList.carbon.explorer',
                              },
                              { to: `${prefix}/emissions/map`, icon: 'place', title: 'navMenuList.carbon.map' },
                          ],
                      }
                    : null,
                {
                    title: 'navMenuList.subheaders.tracking',
                    items: [
                        hasRecommendationsViewFeature
                            ? { to: `${prefix}/recommendations`, icon: 'bar_chart', title: 'navMenuList.recommendations' }
                            : null,
                        hasBudgetsFeature
                            ? { to: `/customviews`, icon: 'table_view', title: 'navMenuList.customViews' }
                            : null,
                        hasSpendLimitsFeature || hasBudgetsFeature
                            ? {
                                  to: `${prefix}/budgets`,
                                  icon: 'price_check',
                                  title: hasBudgetsFeature ? 'navMenuList.budgets' : 'navMenuList.spendLimits',
                              }
                            : null,
                        hasWatchdogFeature
                            ? { to: `${prefix}/watchdog`, icon: 'visibility', title: 'navMenuList.watchdog' }
                            : null,
                    ].filter(Boolean), // Filter out null items
                },
                {
                    title: 'navMenuList.subheaders.reporting',
                    items: [
                        hasCustomReportsFeature
                            ? { to: `${prefix}/custom/reports`, icon: 'addchart', title: 'navMenuList.customReports' }
                            : null,
                        hasTagOverviewFeature
                            ? { to: `${prefix}/tagoverview`, icon: 'sell', title: 'navMenuList.tagOverview' }
                            : null,
                        hasGridFeature
                            ? { to: `${prefix}/dashboarding`, icon: 'dashboard', title: 'navMenuList.dashboarding' }
                            : null,
                    ].filter(Boolean), // Filter out null items
                },
                {
                    title: 'navMenuList.subheaders.customers',
                    items:
                        hasCopyCloudAccountFeature && this.tenantStore.hasPermission('CanContribute')
                            ? [
                                  { to: `${prefix}/customers/manage`, icon: 'settings', title: 'navMenuList.manage' },
                                  {
                                      to: `${prefix}/customers/usage`,
                                      icon: 'content_copy',
                                      title: 'navMenuList.customersOverview',
                                  },
                              ]
                            : [],
                },
            ].filter((group) => group && group.items.length > 0);
        },
    },
};
</script>
