import Vue from 'vue';
import { Auth0Client, GetIdTokenClaimsOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions, LogoutOptions, RedirectLoginOptions, IdToken } from '@auth0/auth0-spa-js';
import * as Auth0Instance from './auth0Instance';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = s => window.history.replaceState({}, document.title, window.location.pathname);

let instance: Vue & IAuth0Vue & IAuth0VueData | null = null;

export interface IAuth0Vue {
    loginWithPopup(popupOptions: any, config: any): Promise<void>;
    handleRedirectCallback(): Promise<void>;
    loginWithRedirect(o: RedirectLoginOptions<any>): Promise<void>;
    registerWithRedirect(o: RedirectLoginOptions<any>): Promise<void>;
    getIdTokenClaims(o: GetIdTokenClaimsOptions): Promise<IdToken>;
    getTokenSilently(o: GetTokenSilentlyOptions): Promise<string>;
    getTokenWithPopup(o: GetTokenWithPopupOptions): Promise<string>;
    logout(o: LogoutOptions): void | Promise<void>;
}

export interface IAuth0VueData {
    loading: boolean;
    isAuthenticated: boolean;
    user: Record<any, any>;
    auth0Client: null | Auth0Client;
    popupOpen: false;
    error: null | Error;
}

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
    if (instance) return instance;

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data(): IAuth0VueData {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null
            };
        },
        methods: {
            /** Authenticates the user using a popup window */
            async loginWithPopup(popupOptions: any, config: any) {
                this.popupOpen = true;

                try {
                    await (this.auth0Client as Auth0Client).loginWithPopup(popupOptions, config);
                    this.user = await (this.auth0Client as Auth0Client).getUser();
                    this.isAuthenticated = await (this.auth0Client as Auth0Client).isAuthenticated();
                    this.error = null;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.popupOpen = false;
                }

                this.user = await (this.auth0Client as Auth0Client).getUser();
                this.isAuthenticated = true;
            },

            /** Handles the callback when logging in using a redirect */
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    await (this.auth0Client as Auth0Client).handleRedirectCallback();
                    this.user = await (this.auth0Client as Auth0Client).getUser();
                    this.isAuthenticated = true;
                    this.error = null;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                }
            },
            /** Authenticates the user using the redirect method */
            loginWithRedirect(o: RedirectLoginOptions<any>) {
                return (this.auth0Client as Auth0Client).loginWithRedirect(o);
            },
            /** Returns all the claims present in the ID token */
            getIdTokenClaims(o: GetIdTokenClaimsOptions): Promise<IdToken> {
                return (this.auth0Client as Auth0Client).getIdTokenClaims(o);
            },
            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            getTokenSilently(o: GetTokenSilentlyOptions): Promise<string> {
                return (this.auth0Client as Auth0Client).getTokenSilently(o);
            },
            /** Gets the access token using a popup window */

            getTokenWithPopup(o: GetTokenWithPopupOptions): Promise<string> {
                return (this.auth0Client as Auth0Client).getTokenWithPopup(o);
            },
            /** Logs the user out and removes their session on the authorization server */
            logout(o: LogoutOptions): void | Promise<void> {
                return (this.auth0Client as Auth0Client).logout(o);
            }
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            // Create a new instance of the SDK client using members of the given options object
            const client = await Auth0Instance.getInstance();
            this.auth0Client = client;

            try {
                // If the user is returning to the app after authentication..
                if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                    // handle the redirect and retrieve tokens
                    const { appState } = await (this.auth0Client as Auth0Client).handleRedirectCallback();
                    this.error = null;

                    // Notify subscribers that the redirect callback has happened, passing the appState
                    // (useful for retrieving any pre-authentication state)
                    onRedirectCallback(appState);
                }
            } catch (e) {
                this.error = e;
            } finally {
                // Initialize our internal authentication state
                this.isAuthenticated = await (this.auth0Client as Auth0Client).isAuthenticated();
                this.user = await (this.auth0Client as Auth0Client).getUser();
                this.loading = false;
            }
        }
    });

    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    install(vue: any, options: any) {
        vue.prototype.$auth = useAuth0(options);
    }
};
