import Granularity from "./Granularity";

export enum SpendFieldNames {
    CO2e = "CO2e",
    kWh = "kWh",
    Spend = "Spend",
    Charges = "Charges",
    Credits = "Credits"
}

export default interface ISpendParameters {
    fromDate: string;
    toDate: string;
    granularity: Granularity.daily | Granularity.monthly;
    topXResults?: number | null;
    tagKeyValue?: string;
    tagKeyValues?: string;
    productIds?: string;
    regionNames?: string;
    geographyNames?: string;
    productCategories?: string;
    cloudAccountIds?: string;
    subscriptionIds?: string;
    serviceIds?: string;
    topBy?: string;
    providerType?: string | null;
    savedViewId?: string | null;
    costView?: string | null;
    reservationId?: string | null;
}