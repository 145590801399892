
import { defineComponent } from 'vue';
import ColumnChartGridWidget from './ColumnChartGridWidget.vue';
import LineChartGridWidget from './LineChartGridWidget.vue';
import TableGridWidget from './TableGridWidget.vue';
import PieChartGridWidget from './PieChartGridWidget.vue';
import TextualGridWidget from './TextualGridWidget.vue';
import EmbedGridWidget from './EmbedGridWidget.vue';
export default defineComponent({
    components: { ColumnChartGridWidget, LineChartGridWidget, TableGridWidget, PieChartGridWidget, TextualGridWidget, EmbedGridWidget },
    props: { name: String, type: String, config: Object, hideActions: Boolean },
});
