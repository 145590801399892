
import { watch, computed, toRefs, reactive } from 'vue';
import { defineComponent } from 'vue';
import DateRangeSelect from '@/components/Domain.Usage/DateRangeSelect.vue';
import TimezoneSelect from '@/components/Common/TimezoneSelect.vue';
import EmailsAutocomplete from '@/components/Common/EmailsAutocomplete.vue';
import { ISearchEntityViewModel } from '@/components/Common/EntitySearchAutocomplete.vue';
import moment from '@/lib/moment';
import AuthClient from '@/lib/Auth';
import { Granularity, PluralEntityType, ISavedReportResult } from '@/models';
import DateKey from '@/lib/DateKey';
import { useTenantStore, useDatasetState } from '@/stores/tenant';
import { getCustomViews } from '@/lib/Api';

export default defineComponent({
    components: { DateRangeSelect, TimezoneSelect, EmailsAutocomplete },
    props: {
        loading: Boolean,
        value: Object,
    },
    setup(props) {
        const tenantStore = useTenantStore();
        const { hasUsageDataset, hasEmissionsDataset } = useDatasetState(tenantStore);

        const state = reactive({
            isCreatingSchedule: false,
            reportId: null,
            reportName: null,
            isValid: false,
            dateRangeKey: '30d',
            topXResults: 10,
            dates: [],
            searchEntities: [],
            schedulingStartDate: new Date(),
            schedulingEndDate: moment().add(1, 'years').toDate(),
            deliverInEmail: false,
            recipients: [AuthClient.userEmail],
            frequency: 'Days',
            timeOfDay: 0,
            timezone: 0,
            monthlyFrequencyValue: 1,
            selectedDaysOfWeek: ['Monday'],
            daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            granularity: Granularity.daily,
            usePredefinedView: false,
            views: [],
            selectedView: null,
            isViewingSchedule: false,
            tagSelections: [],
            costViews: ['Actual'],
            dataset: 'Usage',
            datasets: [
                hasUsageDataset ? { name: 'Usage', id: 'Usage' } : null,
                hasEmissionsDataset ? { name: 'Energy & Emissions', id: 'EnergyAndEmissions' } : null,
            ].filter(r => r),
            availableTagsToInclude: [],
            fieldsToInclude: [],
            availableFieldsToInclude: [
                { name: 'Cloud Vendor', id: PluralEntityType.cloudProviders },
                { name: 'Subscription Name', id: PluralEntityType.subscriptions },
                { name: 'Product Category', id: PluralEntityType.productCategories },
                { name: 'Product Name', id: PluralEntityType.products },
                { name: 'Service', id: PluralEntityType.services },
                { name: 'Meter', id: PluralEntityType.meters },
                { name: 'Geography', id: PluralEntityType.geographies },
                { name: 'Region', id: PluralEntityType.regions },
            ],
            tagSearch: null,
            isAddingTag: false,
        });

        getCustomViews().then((v) => (state.views = v));

        watch(
            () => [tenantStore.tagKey1, tenantStore.tagKey2, tenantStore.tagKey3],
            ([tagKey1, tagKey2, tagKey3]) => {
                if (state.availableTagsToInclude.length) return;
                state.availableTagsToInclude = [tagKey1, tagKey2, tagKey3].filter((t) => t);
            },
            { immediate: true }
        );

        watch(
            () => props.value,
            (val: ISavedReportResult | null | undefined) => {
                if (!val) return;
                state.reportId = val.id;
                state.reportName = val.name;
                state.dateRangeKey = val.dateRangeKey;

                const dateRangeKey = new DateKey(state.dateRangeKey);
                const fromDate = val.fromDate ? new Date(val.fromDate) : null;
                const toDate = val.toDate ? new Date(val.toDate) : null;
                state.dates = dateRangeKey.getEffectiveDateRange(fromDate, toDate);

                state.topXResults = val.topXResults || 10;
                state.searchEntities = (val.filters || []).map((r) => ({
                    Id: r.id,
                    Name: r.name,
                    Type: r.type,
                }));
                state.costViews = val.costViews || ['Actual'];

                state.granularity = val.granularity;
                state.usePredefinedView = !!val.savedViewId;
                state.selectedView = val.savedViewId || null;
                const segregateByParts = val.segregateBy.split(',');
                state.fieldsToInclude = segregateByParts.filter((s) => !s.startsWith('tags/'));
                state.tagSelections = segregateByParts
                    .filter((s) => s.startsWith('tags/'))
                    .map((s) => s.substring('tags/'.length));

                const allTags = [...state.availableTagsToInclude, ...state.tagSelections];
                state.availableTagsToInclude = allTags.filter((value, index) => allTags.indexOf(value) === index);
                state.dataset = val.dataset || 'Usage';

                if (val.schedule) {
                    state.deliverInEmail = true;
                    state.frequency = val.schedule.frequency;
                    state.recipients = val.schedule.recipients;
                    state.schedulingStartDate = moment(val.schedule.effectiveFrom).toDate();
                    state.schedulingEndDate = moment(val.schedule.effectiveTo).toDate();
                    state.timeOfDay = val.schedule.scheduledHour;
                    state.monthlyFrequencyValue = val.schedule.scheduledDay;
                    state.timezone = 0;
                    state.selectedDaysOfWeek = val.schedule.daysOfWeek;
                }
            },
            { immediate: true, deep: true }
        );

        watch(
            () => state.dateRangeKey,
            (key) => {
                if (key === 'custom') return;
                const dateRangeKey = new DateKey(key);
                state.dates = dateRangeKey.getEffectiveDateRange(null, null);
            },
            { immediate: true }
        );

        const form = computed(() => {
            const segregateBy = (state.fieldsToInclude || [])
                .concat((state.tagSelections || []).map((t) => `tags/${t}`))
                .join(',');
            const formData: Partial<ISavedReportResult> = {
                name: state.reportName,
                dateRangeKey: state.dateRangeKey,
                topXResults: state.topXResults,
                fromDate: state.dates[0] || null,
                toDate: state.dates[1] || new Date(),
                segregateBy,
                filters: (state.searchEntities || []).map((e) => ({
                    id: e.Id,
                    name: e.Name,
                    type: e.Type,
                })),
                schedule: null,
                savedViewId: state.usePredefinedView ? state.selectedView : null,
                granularity: state.granularity,
                costViews: state.costViews || ['Actual'],
                dataset: state.dataset
            };
            if (state.deliverInEmail) {
                formData.schedule = {
                    frequency: state.frequency,
                    recipients: state.recipients,
                    effectiveFrom: state.schedulingStartDate.toISOString(),
                    effectiveTo: state.schedulingEndDate.toISOString(),
                    scheduledHour: state.timeOfDay,
                    scheduledDay: state.monthlyFrequencyValue,
                    timezoneOffset: state.timezone,
                    daysOfWeek: state.selectedDaysOfWeek,
                    subject: null,
                    messageBody: null,
                    scheduleId: null,
                };
            }
            return formData;
        });

        const addTag = (tag: ISearchEntityViewModel) => {
            if (!state.availableTagsToInclude.includes(tag.Id)) state.availableTagsToInclude.push(tag.Id);
            state.isAddingTag = false;
        };

        return { ...toRefs(state), form, tenantStore, addTag };
    },
});
