<template>
    <img
        :src="logoImageSrc"
        :width="width"
        style="margin: 16px auto auto auto"
        aria-label="Application logo"
        title="Application logo"
        role="presentation"
    />
</template>

<script>
import Config from '@/lib/Config';
export default {
    props: {
        width: {
            type: Number,
            default: undefined,
        },
        dark: Boolean,
    },
    computed: {
        logoImageSrc() {
            const cfg = Config.get();
            return this.$vuetify.theme.dark || this.dark ? cfg.LOGOS.DARK : cfg.LOGOS.LIGHT;
        },
    },
};
</script>