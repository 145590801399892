
import CostSummaryWidget from '@/components/Domain.Usage/CostSummaryWidget.vue';
import ServiceMetadataChips from '@/components/Domain.Services/ServiceMetadataChips.vue';
import ServiceManageMenu from '@/components/Domain.Services/ServiceManageMenu.vue';
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import moment from '@/lib/moment';
import { useTenantStore, useDatasetState } from '@/stores/tenant';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
    components: { ServiceMetadataChips, ServiceManageMenu, CostSummaryWidget },
    props: {
        me: Object,
        value: Object,
        loading: {
            default: false,
            type: Boolean,
        },
    },
    setup() {
        const tab: string = null;
        const tenantStore = useTenantStore();
        const tabs = computed(() => {
            const { hasUsageDataset, hasEmissionsDataset } = useDatasetState(tenantStore);
            return [
                hasUsageDataset ? { key: 'usage', icon: 'insights', text: 'views.dialogService.tabs.usage' } : null,
                hasEmissionsDataset ? { key: 'carbon', icon: 'co2', text: 'views.dialogService.tabs.carbon' } : null,
                { key: 'tags', icon: 'sell', text: 'views.dialogService.tabs.tags' },
                { key: 'virtualtags', icon: 'sell', text: 'views.dialogService.tabs.virtualtags' },
                { key: 'annotations', icon: 'chat', text: 'views.dialogService.tabs.annotations' },
                hasUsageDataset ? { key: 'raw', icon: 'visibility', text: 'views.dialogService.tabs.raw' } : null,
            ].filter((t) => t);
        });
        const forecast = ref({
            actualTotal: 0,
            projectedTotal: 0,
            pastTotal: 0,
        });
        return { tab, tabs, tenantStore, forecast };
    },
    methods: {
        loadServiceProjections: waitFor('loadingSvcForecast', async function loadServiceProjections(id) {
            const toDate = moment.utc().endOf('month');
            const params = { toDate: toDate.format(), serviceIds: [id].join(), granularity: 'Daily' };
            const response = await ApiV2.http
                .get('/api/forecast', { params })
                .catch((err) => {
                    if (err.response.status === 400) return { data: {} };
                    throw err;
                })
                .then((r) => r.data);
            const year = toDate.format('YYYY');
            const month = toDate.format('MM');
            const previousDate = moment.utc().subtract(1, 'months').endOf('month');
            const prevYear = previousDate.format('YYYY');
            const prevMonth = previousDate.format('MM');
            this.forecast.actualTotal = response.monthlySpendTotals?.[year]?.[month] || 0;
            this.forecast.projectedTotal =
                this.forecast.actualTotal + response.monthlyForecastTotals?.[year]?.[month] || 0;
            this.forecast.pastTotal = response.monthlySpendTotals?.[prevYear]?.[prevMonth] || 0;
        }),
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.loadServiceProjections(val.serviceId);
            },
            immediate: true,
        },
    },
});
