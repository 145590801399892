
import moment from '@/lib/moment';
import Downloader from '@/lib/Downloader';
import { ISpendParameters, ICO2EResult, ICO2EAndConsumptionGroup, ISpendResult, ISpendAndConsumptionGroup } from '@/models';

export interface ICarbonTableItemViewModel {
    id: string;
    name: string;
    totalCO2e: number;
    totalKwH: number;
    isOther: boolean;
    totalConsumption: number;
    unit: string;
}

export interface ICarbonTableSlotViewModel {
    header: string;
    year: string;
    month: string;
    itemSlotName: string;
    itemValuePropName: string;
}

export interface ICarbonTableRecordsViewModel {
    monthSlots: ICarbonTableSlotViewModel[];
    records: ICarbonTableItemViewModel[];
}

export function convertToEmissionsItemRecords(params:ISpendParameters, response: ISpendResult<ISpendAndConsumptionGroup> | null): ICarbonTableRecordsViewModel {
    const monthSlots = slotsForMonthColumns(params);

    const records = [];
    for (const r of response.groupings) {
        const record: ICarbonTableItemViewModel | { [key: string]: number } = {
            id: r.id,
            name: r.name,
            totalCO2e: r.totalCo2e || 0.0,
            totalKwH: r.totalKwH || 0.0,
            totalConsumption: r.totalConsumption,
            isOther: r.isOther,
            unit: r.unit || '<NA>',
        };
        for (const slot of monthSlots) {
            const monthlyCO2eForYear = (r.monthlyCO2eTotals || {})[slot.year] || {};
            const monthlyKwHForYear = (r.monthlyKwHTotals || {})[slot.year] || {};
            if (monthlyCO2eForYear) {
                record[slot.itemValuePropName] = monthlyCO2eForYear[slot.month] || 0;
                record[slot.itemValuePropName + '_kWh'] = monthlyKwHForYear[slot.month] || null;
            } else {
                record[slot.itemValuePropName] = 0;
                record[slot.itemValuePropName + '_kWh'] = null;
            }
        }
        records.push(record);
    }

    return { monthSlots, records };
}

export function convertToItemRecords(
    params: ISpendParameters,
    response: ICO2EResult<ICO2EAndConsumptionGroup>
): ICarbonTableRecordsViewModel {
    const monthSlots = slotsForMonthColumns(params);

    const records = [];
    for (const r of response.groupings) {
        const record: ICarbonTableItemViewModel | { [key: string]: number } = {
            id: r.id,
            name: r.name,
            totalCO2e: r.totalCo2e,
            totalKwH: r.totalKwH,
            totalConsumption: r.totalConsumption,
            isOther: r.isOther,
            unit: r.unit || '<NA>',
        };
        for (const slot of monthSlots) {
            const monthlyCO2eForYear = (r.monthlyCO2eTotals || {})[slot.year] || {};
            const monthlyKwHForYear = (r.monthlyKwHTotals || {})[slot.year] || {};
            if (monthlyCO2eForYear) {
                record[slot.itemValuePropName] = monthlyCO2eForYear[slot.month] || 0;
                record[slot.itemValuePropName + '_kWh'] = monthlyKwHForYear[slot.month] || null;
            } else {
                record[slot.itemValuePropName] = 0;
                record[slot.itemValuePropName + '_kWh'] = null;
            }
        }
        records.push(record);
    }

    return { monthSlots, records };
}

function slotsForMonthColumns(params: ISpendParameters): ICarbonTableSlotViewModel[] {
    const fromDate = moment.utc(params.fromDate);
    const toDate = moment.utc(params.toDate);
    const fromMonth = moment.utc(params.fromDate).startOf('month');
    const toMonth = moment.utc(params.toDate).startOf('month');

    const slots = [];
    let date = fromMonth;
    for (let i = 0; !date.isAfter(toMonth); ++i) {
        const monthYear = date.format('MMM YYYY');
        const endOfMonth = date.endOf('month');

        let partialFromDayOfMonth;
        if (fromDate.isAfter(date, 'day')) {
            // The filter from date is after the start of this month.
            partialFromDayOfMonth = fromDate.format('D');
        }

        let partialToDayOfMonth;
        if (toDate.isBefore(endOfMonth, 'day')) {
            // The filter to date is before the end of this month.
            partialToDayOfMonth = toDate.format('D');
        }

        let header: string;
        if (partialFromDayOfMonth && partialToDayOfMonth) {
            // This month is partial on both sides.
            header = `${partialFromDayOfMonth}-${partialToDayOfMonth} ${monthYear}`;
        } else if (partialFromDayOfMonth) {
            // This month is partial, starting somewhere in the middle.
            header = `${partialFromDayOfMonth}-${endOfMonth.format('D')} ${monthYear}`;
        } else if (partialToDayOfMonth) {
            // This month is partial, ending somewhere in the middle.
            header = `${date.format('D')}-${partialToDayOfMonth} ${monthYear}`;
        } else {
            // This is a full month's worth of charges.
            header = monthYear;
        }

        const slot = {
            header,
            // Keys for navigating the API response:
            year: date.format('YYYY'),
            month: date.format('MM'),
            // Vue data table slots:
            itemSlotName: `item.month${i}`,
            itemValuePropName: `month${i}`,
        };
        slots.push(slot);
        date = date.add(1, 'M');
    }
    return slots;
}

export default {
    props: {
        items: Object,
        loading: Boolean,
        segregateBy: String,
        nameHeaderTitle: String,
        tagKey: String,
        cardTitleText: String,
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        disableLinks: {
            type: Boolean,
            default: false,
        },
        hideDefaultFooter: Boolean,
        hideExport: Boolean,
    },
    data() {
        return {
            sortBy: 'totalCO2e',
            sortDesc: true,
        };
    },
    computed: {
        headers() {
            const headers = [{ text: this.nameHeaderTitle || 'Name', value: 'name' }];
            const monthSlots = this.items?.monthSlots || [];
            for (const slot of monthSlots) {
                headers.push({ text: slot.header, value: slot.itemValuePropName });
            }
            headers.push({ text: 'Total', value: 'totalCO2e' });
            return headers;
        },
        records() {
            return this.items?.records || [];
        },
        monthSlots() {
            return this.items?.monthSlots || [];
        },
    },
    methods: {
        exportCsv() {
            const headerValues = this.headers.map((i) => i.value);
            const csvValues = this.records.map((i) => headerValues.map((k) => i[k]));
            csvValues.unshift(this.headers.map((i) => i.text));
            Downloader.exportCSV(csvValues, 'usage-by-' + this.segregateBy + '.csv');
        },
    },
};
