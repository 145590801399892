<template>
    <form-card :title="$t('setAzureArmCredentialsCard.title')" :loading="loading" v-model="valid">
        <template #subtitle
            >Configure your Azure Resource Manager credentials. These credentials correspond to a single Azure AD app
            that you need to configure in your tenant. You can find more information on how to configure these
            credentials
            <a
                target="blank"
                :href="`${docsUrl}/Setup/Azure`"
                >here </a
            >.
        </template>
        <v-text-field
            v-model="name"
            :disabled="disabled && disabled.Name"
            :rules="nameRules"
            required
            autofocus
            :label="$t('name')"
        ></v-text-field>
        <v-checkbox
            v-model="useTenantCurrencies"
            :label="$t('useTenantCurrencies')"
            style="width: 300px"
            :disabled="disabled && disabled.UseTenantCurrencies"
        >
            <template #append>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn icon small v-on="on" v-bind="attrs">
                            <v-icon>info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('useTenantCurrencies.tooltip')}}</span>
                </v-tooltip>
            </template></v-checkbox
        >
        <v-checkbox
            v-model="copyRemoteTags"
            :label="$t('setAzureArmCredentialsCard.copyRemoteTags')"
            style="width: 300px"
            :disabled="disabled && disabled.CopyRemoteTags"
        >
            <template #append>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn icon small v-on="on" v-bind="attrs">
                            <v-icon>info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('setAzureArmCredentialsCard.copyRemoteTags.tooltip')}}</span>
                </v-tooltip>
            </template></v-checkbox
        >
        <v-text-field
            v-model.trim="tenantId"
            :rules="tenantIdRules"
            required
            :label="$t('setAzureArmCredentialsCard.tenantId')"
        ></v-text-field>
        <v-text-field
            v-model.trim="clientId"
            :rules="clientIdRules"
            :counter="36"
            required
            :label="$t('setAzureArmCredentialsCard.clientId')"
        ></v-text-field>
        <v-text-field
            v-model.trim="clientSecret"
            :rules="clientSecretRules"
            :append-icon="showClientSecret ? 'visibility_off' : 'visibility'"
            @click:append="() => (showClientSecret = !showClientSecret)"
            :type="showClientSecret ? 'text' : 'password'"
            required
            :label="$t('setAzureArmCredentialsCard.clientSecret')"
        ></v-text-field>

        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :form="{
                    Name: name,
                    UseTenantCurrencies: useTenantCurrencies,
                    Features: copyRemoteTags ? 'CopyRemoteTags' : null,
                    AzureActiveDirectoryApplicationCredential: {
                        ClientDomain: tenantId,
                        ProviderId: clientId,
                        Token: clientSecret,
                    },
                }"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "setAzureArmCredentialsCard.title": "Set Azure Credentials",
        "setAzureArmCredentialsCard.name.required": "A name for this cloud connection is required",
        "setAzureArmCredentialsCard.tenantId": "Tenant ID",
        "setAzureArmCredentialsCard.tenantId.required": "Tenant ID is required",
        "setAzureArmCredentialsCard.tenantId.notGuid": "Tenant ID is not a valid GUID",
        "setAzureArmCredentialsCard.copyRemoteTags": "Load Current Azure Tag Hierarchy",
        "setAzureArmCredentialsCard.copyRemoteTags.tooltip": "Do you want to load the current subscription and resource group tags?",
        "setAzureArmCredentialsCard.clientId": "Client ID (App ID)",
        "setAzureArmCredentialsCard.clientId.required": "Client ID is required",
        "setAzureArmCredentialsCard.clientId.notGuid": "Client ID is not a valid GUID",
        "setAzureArmCredentialsCard.clientSecret": "Client Secret",
        "setAzureArmCredentialsCard.clientSecret.required": "Client Secret is required"
    }
}
</i18n>

<script>
import Config from '@/lib/Config';
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            docsUrl: Config.get().DOCS_URL,
            valid: false,
            name: '',
            nameRules: [(v) => !!v || this.$t('setAzureArmCredentialsCard.name.required')],
            useTenantCurrencies: false,
            copyRemoteTags: false,
            tenantId: '',
            tenantIdRules: [
                (v) => !!v || this.$t('setAzureArmCredentialsCard.tenantId.required'),
                (v) => guidRegex.test(v) || this.$t('setAzureArmCredentialsCard.tenantId.notGuid'),
            ],
            clientId: '',
            clientIdRules: [
                (v) => !!v || this.$t('setAzureArmCredentialsCard.clientId.required'),
                (v) => guidRegex.test(v) || this.$t('setAzureArmCredentialsCard.clientId.notGuid'),
            ],
            clientSecret: '',
            clientSecretRules: [(v) => !!v || this.$t('setAzureArmCredentialsCard.clientSecret.required')],
            showClientSecret: false,
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                this.copyRemoteTags = (val.features || '').split(',').includes('CopyRemoteTags');
                if (val.azureActiveDirectoryApplicationCredential) {
                    this.clientId = val.azureActiveDirectoryApplicationCredential.providerId;
                    this.clientSecret = val.azureActiveDirectoryApplicationCredential.token;
                    this.tenantId = val.azureActiveDirectoryApplicationCredential.clientDomain;
                } else {
                    this.clientId = '';
                    this.clientSecret = '';
                    this.tenantId = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>