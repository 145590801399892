<template>
    <v-icon :color="iconColor" :left="left" :right="right">{{ iconValue }}</v-icon>
</template>

<script>
export default {
    props: {
        value: Number,
        compare: Number,
        upGood: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        iconValue() {
            if (this.value > this.compare) {
                return 'arrow_upward';
            } else if (this.value < this.compare) {
                return 'arrow_downward';
            } else {
                return 'hourglass_empty';
            }
        },
        iconColor() {
            if (this.value > this.compare) {
                if (!this.upGood) {
                    return 'error';
                } else {
                    return 'success';
                }
            } else if (this.value < this.compare) {
                if (!this.upGood) {
                    return 'success';
                } else {
                    return 'error';
                }
            } else {
                return null;
            }
        },
    },
};
</script>