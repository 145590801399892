
import CreateCloudAccountMenu from "@/components/Settings.CloudAccounts/CreateCloudAccountMenu.vue";
import CreateAzureCloudAccountDialog from "@/components/Settings.CloudAccounts/Azure/CreateAzureCloudAccountDialog.vue";
import CreateAzureEaCloudAccountDialog from "@/components/Settings.CloudAccounts/Azure/CreateAzureEaCloudAccountDialog.vue";
import CreateRhipeCloudAccountDialog from "@/components/Settings.CloudAccounts/Distributors/CreateRhipeCloudAccountDialog.vue";
import UpdateAzureArmCredentialsDialog from "@/components/Settings.CloudAccounts/Azure/UpdateAzureARMCredentialsDialog.vue";
import UpdateAzureEaCredentialsDialog from "@/components/Settings.CloudAccounts/Azure/UpdateAzureEaCredentialsDialog.vue";
import UpdateRhipeCredentialsDialog from "@/components/Settings.CloudAccounts/Distributors/UpdateRhipeCredentialsDialog.vue";
import DeleteCloudAccountDialog from "@/components/Settings.CloudAccounts/DeleteCloudAccountDialog.vue";
import EditCloudAccountNameDialog from "@/components/Settings.CloudAccounts/EditCloudAccountNameDialog.vue";
import EditCloudAccountCurrenciesDialog from "@/components/Settings.CloudAccounts/EditCloudAccountCurrenciesDialog.vue";
import AzureCloudAccountsTable, {
  azureCloudAccountTableFilter,
} from "@/components/Settings.CloudAccounts/Azure/AzureCloudAccountsTable.vue";
import TaxonomyType from "@/stores/models/TaxonomyType";
import { ICloudAccountNavigation } from "@/stores/models/ICloudAccountNavigation";
import { useCloudAccountNavigationStore } from "@/stores/cloudAccountNavigation";
import { useTenantStore } from "../../stores/tenant";
import { mapStores } from "pinia";
import Config from "@/lib/Config";
import FeatureNames from "@/lib/FeatureNames";
import { CloudAccountType } from "@/models";
import { defineComponent } from "vue";

export default defineComponent({
  title() {
    return this.$t("settings.azure.title");
  },
  props: {
    cloudAccounts: Array,
  },
  components: {
    CreateAzureCloudAccountDialog,
    CreateAzureEaCloudAccountDialog,
    CreateRhipeCloudAccountDialog,
    UpdateAzureArmCredentialsDialog,
    UpdateAzureEaCredentialsDialog,
    UpdateRhipeCredentialsDialog,
    DeleteCloudAccountDialog,
    AzureCloudAccountsTable,
    EditCloudAccountNameDialog,
    EditCloudAccountCurrenciesDialog,
    CreateCloudAccountMenu,
  },
  data() {
    const cloudAccountTypes = [CloudAccountType.azure, CloudAccountType.azureEa];
    if (Config.isFeatureEnabled(FeatureNames.rhipeEnabled)) {
      cloudAccountTypes.push(CloudAccountType.rhipe);
    }
    return {
      isCreatingAzureArmAccount: false,
      isEditingAzureArmAccount: false,

      isCreatingAzureEaAccount: false,
      isEditingAzureEaAccount: false,

      isCreatingRhipeAccount: false,
      isEditingRhipeAccount: false,

      isEditingAccountName: false,
      isEditingAccountCurrencies: false,
      isDeletingAccount: false,
      focusedAccount: null,

      cloudAccountTypes,
    };
  },
  computed: {
    azureCloudAccounts() {
      return this.cloudAccounts.filter(azureCloudAccountTableFilter);
    },
    ...mapStores(useTenantStore),
  },
  methods: {
    handleDialogChange(isOpen) {
      if (isOpen) return;
      this.$router.push("/settings/azure");
      this.$title = this.$t("settings.azure.title");
    },
    onCloudAccountsChanged() {
      this.$emit("onCloudAccountsChanged");
    },
    startCreateAccount(type) {
      switch (type) {
        case "Azure":
          this.isCreatingAzureArmAccount = true;
          break;
        case "AzureEa":
          this.isCreatingAzureEaAccount = true;
          break;
        case "Rhipe":
          this.isCreatingRhipeAccount = true;
          break;
        default:
          throw new Error("Unsupported Azure type");
      }
    },
    startEditCloudAccount(account) {
      this.focusedAccount = account;
      switch (account.type) {
        case "Azure":
          this.isEditingAzureArmAccount = true;
          break;
        case "AzureEa":
          this.isEditingAzureEaAccount = true;
          break;
        case "Rhipe":
          this.isEditingRhipeAccount = true;
          break;
        default:
          throw new Error("Unsupported Azure type");
      }
    },
    startEditName(account) {
      this.focusedAccount = account;
      this.isEditingAccountName = true;
    },
    startEditCurrencies(account) {
      this.focusedAccount = account;
      this.isEditingAccountCurrencies = true;
    },
    startDeleteAccount(account) {
      this.focusedAccount = account;
      this.isDeletingAccount = true;
    },
    startEditArmConnection(account) {
      this.focusedAccount = account;
      this.isEditingAzureArmAccount = true;
    },
    copyCloudAccount(item) {
      const navigationSource: ICloudAccountNavigation = {
        taxonomyType: TaxonomyType.settingsAzure,
        tenantId: null,
        cloudAccountId: null,
      };
      useCloudAccountNavigationStore().updateNavigationSource(navigationSource);
      const url = `/copy/cloudaccounts/${item.cloudAccountId}`;
      this.$router.push(url);
    },
  },
});
