<template>
    <http-post-button
        v-bind="$props"
        v-on="$listeners"
        :value="value"
        href="/api/customers"
        version="v2"
        @http4xx="onError"
    >
        <v-icon left>done</v-icon>{{ $t('addCustomerButton.submit') }}
    </http-post-button>
</template>

<i18n>
{
    "en": {
        "addCustomerButton.errorMessage": "There was an error encountered while creating this customer. If everything looks right please contact support.",
        "addCustomerButton.submit": "Submit"
    }
}
</i18n>

<script>
import { toastError } from '@/components/Common/Toast.vue';
import HttpPostButton from '@/components/Common/HttpPostButton.vue';

export default {
    props: {
        ...HttpPostButton.props,
        value: {
            type: Object,
        },
    },
    methods: {
        onError() {
            toastError(this.$t('addCustomerButton.errorMessage'));
        },
    },
};
</script>