
import { defineComponent, ref, watch } from 'vue';
import * as ChartFactory from '@/highcharts/MapFactory';
import { IRegionCO2EResult, IRegionResult } from '@/models';

export interface IRegionClickedEventData {
    regionName: string;
    providerType: string;
}

export enum SupportedSlices {
    co2e = 'co2e',
    kwh = 'kwh',
    intensity = 'intensity',
}

export const convertRegionResultsToChartSeries = (
    costs: IRegionCO2EResult,
    regions: IRegionResult[],
    type: SupportedSlices
) => {
    const regionTotals = costs.data.reduce((agg, val) => {
        const key = `${val.providerType}:${val.regionName}`.toUpperCase();
        if (type === SupportedSlices.co2e) agg[key] = val.co2e;
        else if (type === SupportedSlices.kwh) agg[key] = val.kwH;
        else if (type === SupportedSlices.intensity) agg[key] = val.co2e / val.kwH || 0;
        return agg;
    }, {});
    let suffix: string;
    if (type === SupportedSlices.co2e) suffix = 'CO2e';
    else if (type === SupportedSlices.intensity) suffix = 'intensity';
    else if (type === SupportedSlices.kwh) suffix = 'kWh';
    const series = Object.values(
        regions.reduce((obj, region) => {
            const regionTotalKey = `${region.providerType}:${region.name}`.toUpperCase();
            if (!regionTotals[regionTotalKey]) return obj;

            const providerType = region.providerType;
            const regionData = (obj[providerType] =
                obj[providerType] || ChartFactory.createMapSeriesRecord(providerType, suffix));

            if (region.lat) {
                regionData.data.push(
                    ChartFactory.createMapSeriesChildRecord(
                        region.id,
                        region.name || 'Unknown',
                        providerType,
                        region.lat,
                        region.long,
                        regionTotals[regionTotalKey]
                    )
                );
            }
            return obj;
        }, {})
    );
    series.unshift({
        name: 'Countries',
        color: '#E0E0E0',
        enableMouseTracking: false,
        showInLegend: false,
    });
    return series;
};

export default defineComponent({
    props: { items: Array, intensity: Boolean },
    setup(p, { emit }) {
        const FIXED_OPTIONS = ChartFactory.createMapChart({
            events: {
                pointClicked(eventData) {
                    const outgoingEventData: IRegionClickedEventData = {
                        regionName: eventData.name,
                        providerType: eventData.series.name,
                    };
                    emit('click', outgoingEventData);
                },
            },
        });
        const standardOptions = ref({ ...FIXED_OPTIONS });
        const intensityOptions = ref({ ...FIXED_OPTIONS, colorAxis: [ChartFactory.createCarbonColorAxis(0.00064)] });
        const hideForAMoment = ref(false);
        watch(
            () => p.items,
            (items: any) => {
                standardOptions.value.series = items;
                intensityOptions.value.series = items;
            },
            { immediate: true, deep: true }
        );
        return { standardOptions, intensityOptions, hideForAMoment };
    },
});
