<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="renderedDateString"
                :label="label"
                placeholder="e.g. yyyy-MM-dd"
                :prepend-icon="prependIcon"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="!use || disabled"
                :dense="dense"
                :solo="solo"
                :outlined="outlined"
                :flat="flat"
                :hide-details="hideDetails"
                :rules="rules"
                :filled="filled"
            >
                <template #prepend v-if="optional">
                    <v-checkbox v-model="use" class="mt-0"></v-checkbox>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            ref="picker"
            :value="datePickerFormValue"
            @input="save"
            :min="min"
            :max="max"
            @change="save"
            :type="type"
            :no-title="noTitle"
            :range="range"
            :disabled="disabled"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import moment from '@/lib/moment';
export default {
    props: {
        value: [Date, Object, String, Array],
        optional: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        solo: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'Date',
        },
        prependIcon: {
            type: String,
            default: 'event',
        },
        type: {
            type: String,
            default: 'date',
        },
        rules: {
            type: Array,
            default: () => [],
        },
        noTitle: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: String,
            default: '1950-01-01',
        },
        max: {
            type: String,
            default: '2100-01-01',
        },
        filled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        menu: false,
        use: true,
    }),
    computed: {
        datePickerFormValue() {
            if (!this.value) return null;
            if (this.range) {
                return this.value.map((i) => i.toISOString().substr(0, 10));
            } else {
                return this.value.toISOString().substr(0, 10);
            }
        },
        renderedDateString() {
            if (!this.value) return null;
            if (this.type === 'date') {
                if (this.range) {
                    return this.value
                        .filter((i) => !!i)
                        .map((i) => moment.utc(i).format("MMMM Do 'YY"))
                        .join(' - ');
                } else {
                    return moment.utc(this.value).format("MMMM Do 'YY");
                }
            } else if (this.type === 'month') {
                if (this.range) {
                    return this.value
                        .filter((i) => !!i)
                        .map((i) => moment.utc(i).format("MMMM 'YY"))
                        .join('-');
                } else {
                    return moment.utc(this.value).format("MMMM 'YY");
                }
            } else {
                if (this.range) {
                    return this.value
                        .filter((i) => !!i)
                        .map((i) => i.toISOString())
                        .join(' - ');
                } else {
                    return this.value.toISOString();
                }
            }
        },
    },
    watch: {
        menu(val) {
            if (val) setTimeout(() => (this.$refs.picker.activePicker = 'MONTH'));
        },
        use(val) {
            if (!val) this.$emit('input', null);
        },
    },
    methods: {
        save(formInput) {
            if (this.range) {
                const newInput = formInput.map((i) => (i ? new Date(i) : null)).filter((i) => !!i);
                this.$emit('input', newInput);
                if (newInput && newInput.length > 1) this.$refs.menu.save(formInput);
            } else {
                this.$refs.menu.save(formInput);
                this.$emit('input', new Date(formInput));
            }
        },
    },
};
</script>
