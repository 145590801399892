import { render, staticRenderFns } from "./RegionCarbonChartCard.vue?vue&type=template&id=f1a8b5b8&"
import script from "./RegionCarbonChartCard.vue?vue&type=script&lang=ts&"
export * from "./RegionCarbonChartCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports