<template>
    <v-btn
        type="submit"
        color="error"
        depressed
        :disabled="!valid || loading"
        :loading="loading"
        :form="form"
        @click.prevent="submit()"
    >
        <v-icon left>clear</v-icon>Delete
    </v-btn>
</template>

<script>
import ApiV2 from '@/lib/ApiV2';
import EventBus from '@/lib/EventBus';

export default {
    props: {
        clear: Function,
        valid: Boolean,
        form: String,
        value: Object,
        entityType: String,
        resourceApi: String,
    },
    data() {
        return { loading: false };
    },
    methods: {
        async submit() {           
            this.$emit('start');
            this.loading = true;
            const url= this.resourceApi + "/" + this.value.id;
            try {
                const response = await ApiV2.http
                    .delete(url, {                        
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((r) => r.data);
                this.clear();
                this.$emit('http2xx', response);
            } catch (err) {
                EventBus.$emit(
                    '$toast.error',
                    'There was an error encountered while deleting this ' + this.entityType +'. If everything looks right please contact support so we can help you :)'
                );
                this.$emit('http4xx', err);
            } finally {
                this.loading = false;
                this.$emit('stop');
            }
        },
    },
};
</script>