<template>
  <http-patch-button
    v-bind="$props"
    v-on="$listeners"
    :href="annotationId ? `/api/annotations/${annotationId}` : null"
    :value="getPatchChanges()"   
    isJsonPatch
    version="v2"
    @http4xx="onError"
  >
    <v-icon left>done</v-icon>{{ $t('patchAnnotationsButton.text') }}  
  </http-patch-button>
</template>

<i18n>
{
    "en": {
        "patchAnnotationsButton.text": "Edit Annotation",
        "patchAnnotationsButton.errorMessage": "There was an error encountered while updating this annotation. If anything does not look right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from "@/components/Common/Toast.vue";
import HttpPatchButton from "@/components/Common/HttpPatchButton.vue";

export default {
  components: { HttpPatchButton },
  props: {
    ...HttpPatchButton.props,
    value: {
      type: Object
    },
    annotationId: [String, Number],
  },
  methods: {
    onError() {
      toastError(this.$t('patchAnnotationsButton.errorMessage'));
    },
    getPatchChanges() {
        return [{ op: 'replace', path: '/annotationDate', value: this.value.AnnotationDate },
        { op: 'replace', path: '/text', value: this.value.Text }];
    },
  },
};
</script>