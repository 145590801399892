<template>
    <http-post-button 
        v-bind="$props" 
        v-on="$listeners" 
        href="/api/customViews" 
        version="v2" 
        @http4xx="onError">
        {{ $t('postCustomViewButton.saveAndView') }}
    </http-post-button>
</template>

<i18n>
{
    "en": {
        "postCustomViewButton.errorMessage": "There was an error encountered while creating this customView. If everything looks right please contact support so we can help you :)",
        "postCustomViewButton.saveAndView": "Save and View"
    }
}
</i18n>

<script>
import { toastError } from '@/components/Common/Toast.vue';
import HttpPostButton from '@/components/Common/HttpPostButton.vue';

export default {
    props: { ...HttpPostButton.props },
    methods: {
        onError() {
            toastError(this.$t('postCustomViewButton.errorMessage'));
        },
    },
};
</script>