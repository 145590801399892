
import CreateAmazonWsCloudAccountDialog from "@/components/Settings.CloudAccounts/Amazon/CreateAmazonWSCloudAccountDialog.vue";
import CreateAmazonAssumeRoleCloudAccountDialog from "@/components/Settings.CloudAccounts/Amazon/CreateAmazonAssumeRoleCloudAccountDialog.vue";
import UpdateAmazonWsCredentialDialog from "@/components/Settings.CloudAccounts/Amazon/UpdateAmazonWSCredentialDialog.vue";
import UpdateAmazonAssumeRoleCredentialsDialog from "@/components/Settings.CloudAccounts/Amazon/UpdateAmazonAssumeRoleCredentialsDialog.vue";
import DeleteCloudAccountDialog from "@/components/Settings.CloudAccounts/DeleteCloudAccountDialog.vue";
import EditCloudAccountNameDialog from "@/components/Settings.CloudAccounts/EditCloudAccountNameDialog.vue";
import EditCloudAccountCurrenciesDialog from "@/components/Settings.CloudAccounts/EditCloudAccountCurrenciesDialog.vue";
import AmazonWsCloudAccountsTable, {
  amazonCloudAccountTableFilter,
} from "@/components/Settings.CloudAccounts/Amazon/AmazonWSCloudAccountsTable.vue";
import CreateAmazonAccountMenu from "@/components/Settings.CloudAccounts/Amazon/CreateAmazonAccountMenu.vue";
import TaxonomyType from "@/stores/models/TaxonomyType";
import { ICloudAccountNavigation } from "@/stores/models/ICloudAccountNavigation";
import { useCloudAccountNavigationStore } from "@/stores/cloudAccountNavigation";
import { useTenantStore } from "@/stores/tenant";
import { mapStores } from "pinia";
import { defineComponent } from "vue";
import { CloudCredentialType } from "@/models";
import Config from '@/lib/Config';

export default defineComponent({
  props: {
    cloudAccounts: Array,
  },
  components: {
    CreateAmazonWsCloudAccountDialog,
    CreateAmazonAssumeRoleCloudAccountDialog,
    UpdateAmazonWsCredentialDialog,
    UpdateAmazonAssumeRoleCredentialsDialog,
    DeleteCloudAccountDialog,
    AmazonWsCloudAccountsTable,
    EditCloudAccountNameDialog,
    EditCloudAccountCurrenciesDialog,
    CreateAmazonAccountMenu,
  },
  title() {
    return this.$t("settings.amazon.title");
  },
  data() {
    return {
      isCreatingAwsAccount: false,
      isCreatingAwsRoleBasedAccount: false,
      isEditingAwsAccount: false,
      isEditingAwsRoleBasedAccount: false,
      isEditingAwsAccountName: false,
      isEditingAccountCurrencies: false,
      isDeletingAwsAccount: false,
      focusedAccount: null,
      supportsRoleBasedAccount: Config.isFeatureEnabled('EnabledAwsAssumeRole')
    };
  },
  computed: {
    amazonCloudAccounts() {
      return this.cloudAccounts.filter(amazonCloudAccountTableFilter);
    },
    ...mapStores(useTenantStore),
  },
  methods: {
    handleCreateNewAmazonAccount(
      key: CloudCredentialType.awsAssumeRoleUsage | CloudCredentialType.awsIamUsage
    ) {
      if (key === CloudCredentialType.awsAssumeRoleUsage) {
        this.isCreatingAwsRoleBasedAccount = true;
      } else if (key === CloudCredentialType.awsIamUsage) {
        this.isCreatingAwsAccount = true;
      }
    },
    handleDialogChange(isOpen) {
      if (isOpen) return;
      this.$router.push("/settings/amazon");
      this.$title = this.$t("settings.amazon.title");
    },
    onCloudAccountsChanged() {
      this.$emit("onCloudAccountsChanged");
    },
    startEditName(account) {
      this.focusedAccount = account;
      this.isEditingAwsAccountName = true;
    },
    startEditCurrencies(account) {
      this.focusedAccount = account;
      this.isEditingAccountCurrencies = true;
    },
    startEditCloudAccountLegacy(account) {
      this.focusedAccount = account;
      this.isEditingAwsAccount = true;
    },
    startEditCloudAccountNew(account) {
      this.focusedAccount = account;
      this.isEditingAwsRoleBasedAccount = true;
    },
    copyCloudAccount(item) {
      const navigationSource: ICloudAccountNavigation = {
        taxonomyType: TaxonomyType.settingsAWS,
        tenantId: null,
        cloudAccountId: null,
      };
      useCloudAccountNavigationStore().updateNavigationSource(navigationSource);
      const url = `/copy/cloudaccounts/${item.cloudAccountId}`;
      this.$router.push(url);
    },
    startDeleteAccount(account) {
      this.focusedAccount = account;
      this.isDeletingAwsAccount = true;
    },
  },
});
