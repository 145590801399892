<template>
    <form-card :title="$t('setAmazonWsCredentialsCard.title')" :loading="loading" v-model="valid">
        <template #subtitle>
            Configure your AWS Credentials. This setup can be a bit involved so we recommend reading our
            <a target="blank" :href="`${docsUrl}/Setup/AWS/`">AWS setup guide </a>.
        </template>
        <v-text-field
            v-model="name"
            :disabled="disabled && disabled.Name"
            :rules="nameRules"
            required
            autofocus
            :label="$t('name')"
        ></v-text-field>
        <v-checkbox
            v-model="useTenantCurrencies"
            :label="$t('useTenantCurrencies')"
            :disabled="disabled && disabled.UseTenantCurrencies"
        >
            <template #append>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn icon small v-on="on" v-bind="attrs">
                            <v-icon>info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('useTenantCurrencies.tooltip') }}</span>
                </v-tooltip>
            </template>
        </v-checkbox>
        <v-text-field
            v-model.trim="accessKeyId"
            placeholder="e.g. AKIAIOSFODNN7EXAMPLE"
            :label="$t('setAmazonWsCredentialsCard.accessKeyId')"
            :rules="accessKeyIdRules"
            :counter="20"
            required
        ></v-text-field>
        <v-text-field
            v-model.trim="secretAccessKey"
            placeholder="e.g. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"
            :append-icon="showSecretAccessKey ? 'visibility_off' : 'visibility'"
            @click:append="() => (showSecretAccessKey = !showSecretAccessKey)"
            :type="showSecretAccessKey ? 'text' : 'password'"
            :label="$t('setAmazonWsCredentialsCard.secretAccessKey')"
            :rules="secretAccessKeyRules"
            :counter="40"
            required
        ></v-text-field>
        <s3-region-autocomplete
            v-model="regionCode"
            :label="$t('setAmazonWsCredentialsCard.regionCode')"
            :rules="[() => true]"
        >
        </s3-region-autocomplete>
        <s3-bucket-autocomplete
            v-model.trim="s3BucketName"
            :accessKeyId="accessKeyId"
            :secretAccessKey="secretAccessKey"
            :region="regionCode"
            :label="$t('setAmazonWsCredentialsCard.s3BucketName')"
            :rules="s3BucketNameRules"
        ></s3-bucket-autocomplete>

        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :form="{
                    Name: name,
                    UseTenantCurrencies: useTenantCurrencies,
                    AwsIamUsageCredential: {
                        ProviderId: accessKeyId,
                        Token: secretAccessKey,
                        Container: s3BucketName,
                        RegionCode: regionCode,
                    },
                }"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "setAmazonWsCredentialsCard.title": "Set AWS IAM Access Key Credentials",
        "setAmazonWsCredentialsCard.name.required": "An name for this cloud connection is required",

        "setAmazonWsCredentialsCard.accessKeyId": "Access Key ID",
        "setAmazonWsCredentialsCard.accessKeyId.required": "Access Key ID is required",
        "setAmazonWsCredentialsCard.accessKeyId.notKeyId": "Access Key IDs comprise of 20 alphanumeric uppercase characters",

        "setAmazonWsCredentialsCard.regionCode": "S3 Region",

        "setAmazonWsCredentialsCard.secretAccessKey": "Secret Access Key",
        "setAmazonWsCredentialsCard.secretAccessKey.required": "Secret Access Key is required",
        "setAmazonWsCredentialsCard.secretAccessKey.notSecretKey": "Secret Access Keys comprise of 40 upper and lower case characters",

        "setAmazonWsCredentialsCard.s3BucketName": "S3 Bucket Name (Reports)",
        "setAmazonWsCredentialsCard.s3BucketName.required": "S3 Bucket Name is required",
        "setAmazonWsCredentialsCard.s3BucketName.notBucketName": "The provided key is not a valid S3 bucket name"
    }
}
</i18n>

<script>
import S3BucketAutocomplete from '@/components/Settings.CloudAccounts/Amazon/S3BucketAutocomplete.vue';
import S3RegionAutocomplete from '@/components/Settings.CloudAccounts/Amazon/S3RegionAutocomplete.vue';
import Config from '@/lib/Config';
const accessKeyIdRegex = /[A-Z0-9]{20}/;
const secretAccessKeyRegex = /[A-Za-z0-9/+=]{40}/;
const bucketNameRegex = /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/;
export default {
    components: { S3BucketAutocomplete, S3RegionAutocomplete },
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            docsUrl: Config.get().DOCS_URL,
            name: '',
            nameRules: [(v) => !!v || this.$t('setAmazonWsCredentialsCard.name.required')],
            useTenantCurrencies: false,
            accessKeyId: '',
            accessKeyIdRules: [
                (v) => !!v || this.$t('setAmazonWsCredentialsCard.accessKeyId.required'),
                (v) => accessKeyIdRegex.test(v) || this.$t('setAmazonWsCredentialsCard.accessKeyId.notKeyId'),
            ],
            regionCode: '',
            showSecretAccessKey: false,
            secretAccessKey: '',
            secretAccessKeyRules: [
                (v) => !!v || this.$t('setAmazonWsCredentialsCard.secretAccessKey.required'),
                (v) =>
                    secretAccessKeyRegex.test(v) || this.$t('setAmazonWsCredentialsCard.secretAccessKey.notSecretKey'),
            ],
            s3BucketName: '',
            s3BucketNameRules: [
                (v) => !!v || this.$t('setAmazonWsCredentialsCard.s3BucketName.required'),
                (v) => bucketNameRegex.test(v) || this.$t('setAmazonWsCredentialsCard.s3BucketName.notBucketName'),
            ],
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                if (val.awsIamUsageCredential) {
                    this.accessKeyId = val.awsIamUsageCredential.providerId;
                    this.secretAccessKey = val.awsIamUsageCredential.token;
                    this.regionCode = val.awsIamUsageCredential.regionCode;
                    this.s3BucketName = val.awsIamUsageCredential.container;
                } else {
                    this.accessKeyId = '';
                    this.secretAccessKey = '';
                    this.s3BucketName = '';
                    this.regionCode = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>