
import { defineComponent, computed } from 'vue';
import { useTenantStore } from '@/stores/tenant';
import RegionMetadataChips from '@/components/Domain.Regions/RegionMetadataChips.vue';

export default defineComponent({
    components: { RegionMetadataChips },
    props: {
        me: Object,
        value: Object,
        loading: Boolean,
    },
    setup() {
        const tab: string = null;
        const tenantStore = useTenantStore();
        const tabs = computed(() =>
            [
                { key: 'usage', icon: 'insights', text: 'views.dialogRegion.tabs.usage' },
                tenantStore.hasTenantFeature('EmissionsAndEnergy')
                    ? { key: 'carbon', icon: 'co2', text: 'views.dialogRegion.tabs.carbon' }
                    : null,
            ].filter((t) => t)
        );
        return { tab, tabs, tenantStore };
    },
});
