<template>
    <fragment>
        <page-header :title="$t('customerManage.title', { customerName: customer ? customer.name : '...' })"
            icon="content_copy" extended back-to="/customers/manage/" back-to-text="Back to Customers">
            <v-tabs align-with-title v-model="tab">
                <v-tab key="shares"> Shared Data </v-tab>
                <v-tab key="permissions"> Features </v-tab>
                <v-tab key="users"> Users </v-tab>
            </v-tabs>
        </page-header>
        <v-container>
            <v-tabs-items v-model="tab">
                <v-tab-item key="shares">
                    <shared-data :customerId="customerId" :customer="customer"
                        @getTenantCustomers="getTenantCustomers()" @loadCustomer="loadCustomer()"></shared-data>
                </v-tab-item>
                <v-tab-item key="permissions">
                    <features :customerId="customerId" :tenantFeatures="tenantFeatures"></features>
                </v-tab-item>
                <v-tab-item key="users">
                    <users></users>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </fragment>
</template>

<i18n>
{
    "en": {
        "customerManage.title": "Customer - {customerName}",        
        "customerManage.failedToLoad": "There was an issue loading this customer."       
        }
}
</i18n>

<script>
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import { toastError } from '@/components/Common/Toast.vue';
import Users from '@/views/CustomersManage/CustomerManage/Users.vue';
import SharedData from '@/views/CustomersManage/CustomerManage/SharedData.vue';
import Features from '@/views/CustomersManage/CustomerManage/Features.vue';


export default {
    title(ctx) {
        return ctx.$t('customerManage.title', { customerName: ctx.customer ? ctx.customer.name : '...' });
    },
    components: {
        SharedData,
        Features,
        Users,
    },
    methods: {
        getTenantCustomers: waitFor('getTenantCustomers', async function getTenantCustomers() {
            this.customers = await ApiV2.http.get(`/api/customers`).then((r) => r.data);
        }),
        loadCustomer: waitFor('loadingCustomer', async function loadingCustomer() {
            try {
                this.customer = await ApiV2.http.get(`/api/customers/${this.customerId}`).then((r) => r.data);
                this.$title = this.$t('customerManage.title', { customerName: this.customer.name });
            } catch (err) {
                toastError(this.$t('customerManage.failedToLoad'));
            }
        }),
        loadCustomerFeatures: waitFor('loadingCustomerFeatures', async function loadingCustomerFeatures() {
            try {
                this.tenantFeatures = await ApiV2.http
                    .get(`/api/customers/${this.customerId}/features`)
                    .then((r) => r.data);
            } catch (err) {
                toastError('There was an error loading the tenant features');
            }
        }),
    },
    data() {
        return {
            tab: 'shares',
            customerId: null,
            customer: {
                name: '...',
                sharedDataCloudAccounts: [
                    {
                        rules: {
                            origin: null,
                            currentMarkup: {
                                id: null,
                                markupPercentage: null,
                                effectiveFromDate: null,
                            },
                        },
                    },
                ],
            },
            tenantFeatures: []
        };
    },
    watch: {
        $route: {
            handler(to) {
                if (!to) return;
                this.customerId = to.params.id;
                this.loadCustomer();
                this.loadCustomerFeatures();
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
