import ApiV2 from '@/lib/ApiV2';
import { ICachedTenantDetails } from '@/models';
import { defineStore } from 'pinia';

export interface IAccountData {
    email: string;
    tenants: ICachedTenantDetails[];
}

export const useAccountStore = defineStore({
    id: 'account',
    state: (): IAccountData => ({
        email: null,
        tenants: []
    }),
    getters: {
        numTenants: (ctx) => ctx.tenants.length
    },
    actions: {
        async loadAccountTenants() {
            this.tenants = await ApiV2.http.get('/api/metadata/tenants').then(r => r.data);
        }
    }
});