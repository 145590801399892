<template>
    <v-btn
        type="submit"
        color="secondary"
        depressed
        :disabled="!valid || loading"
        :loading="loading"
        :form="form"
        @click.prevent="submit()"
    >
        <v-icon left>email</v-icon>Invite
    </v-btn>
</template>

<script>
import ApiV2 from '@/lib/ApiV2';
import { toastError } from '@/components/Common/Toast.vue';

export default {
    props: {
        value: Object,
        clear: Function,
        valid: Boolean,        
        form: String,
        prefix: {
            type: String,
            default: "/api/metadata/tenant/current/users"
        }
    },
    data() {
        return { loading: false };
    },
    methods: {
        async submit() {
            this.$emit('start');
            this.loading = true;
            try {
                const newUsers = [this.value];
                const response = await ApiV2.http
                    .post(this.prefix, newUsers)
                    .then((r) => r.data);
                this.clear();
                this.$emit('http2xx', response);
            } catch (err) {
                toastError(
                    'There was an error encountered while creating this user. If everything looks right please contact support so we can help you :)'
                );
                this.$emit('http4xx', err);
            } finally {
                this.loading = false;
                this.$emit('stop');
            }
        },
    },
};
</script>