<template>
    <form-card :title="title" :subtitle="subtitle" :loading="loading" v-model="valid">
        <text-date-picker
            :label="$t('annotationCard.annotationDate')"
            v-model="annotationDate"
            :value="annotationDate"
        >
        </text-date-picker>

        <div class="pt-2">
            <v-textarea 
                outlined
                v-model="annotation"
                :rules="[ $rules.required(), $rules.maxLength(500) ]"
                :label="$t('annotationCard.annotation')"
                :value="annotation"
                persistent-hint
                rows="8"
                required
            ></v-textarea>
        </div>
        <template #actions="{ formId }">
            <slot
                name="actions"
                :isValid="valid"
                :clear="resetForm"
                :formId="formId"
                :loading="loading"
                :form="{ Text: annotation, AnnotationDate: annotationDate }"
            ></slot>
        </template>
    </form-card>
</template>
<i18n>
{
    "en": {
        "annotationCard.annotation": "Annotation",  
        "annotationCard.annotationDate": "Annotation Date"
    }
}
</i18n>


<script>
import moment from '@/lib/moment';

export default {
    props: ['value', 'loading', 'title', 'subtitle'],
    data() {
        return {
            valid: true,
            annotation: '',
            annotationDate: moment(),
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.annotation = val.text;
                this.annotationDate = moment.utc(val.date).local();
            },
            immediate: true
        },
    },
    methods: {
        resetForm() {
            this.valid = true,
            this.annotation = '',
            this.annotationDate = moment();
        }
    }
};
</script>