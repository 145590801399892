<template>
  <card-base>
    <v-container justify-center fluid>
      <v-layout>
        <v-flex xs12 class="text-center">
          <div>
            <h1 class="headline font-weight-light">{{title}}</h1>
            <span class="body-2 font-weight-regular">{{description}}</span>
            <br>
            <span class="caption">{{caption}}</span>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </card-base>
</template>

<script>
import CardBase from "@/components/Common/ContentCardBase.vue";
export default {
  components: { CardBase },
  props: [ 'title', 'description', 'caption' ]
};
</script>

<style scoped>
.icon-section {
  background: #eff1f5;
}
</style>
