
import Config from '@/lib/Config';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

let instance: null | Auth0Client = null;
export const getInstance = async () => {
    if (instance) return instance;
    const config = Config.get();
    instance = await createAuth0Client({
        domain: config.AUTH0_DOMAIN,
        client_id: config.AUTH0_CLIENTID,
        redirect_uri: window.location.origin,
        audience: config.AUTH0_AUDIENCE
    });
    return instance;
};