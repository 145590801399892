
import { waitFor } from '@/plugins/vue-wait';
import UserContentCard from '@/components/View.CustomersManage/CustomerManage/UserContentCard.vue';
import CreateUpdateFormDialog from '@/components/View.CustomersManage/CustomerManage/CreateUpdateFormDialog.vue';
import DeleteFormDialog from '@/components/View.CustomersManage/CustomerManage/DeleteFormDialog.vue';
import { getCustomerUsersApi } from '@/lib/Api';
import { IUser } from '@/models';
import { defineComponent, ref } from 'vue';
import { Route } from 'vue-router';


export default defineComponent({
    components: {
        UserContentCard,
        CreateUpdateFormDialog,
        DeleteFormDialog
    },
    setup() {

        const users = ref<IUser[]>();
        const customerId = ref('');
        const prefix = ref('');
        const userToEdit = ref<IUser>(null);
        const userToDelete = ref<IUser>(null);
        const createOrUpdateUser = ref(false);
        const deleteUser = ref(false);

        const startCreateAccount = () => {
            userToEdit.value = null;
            createOrUpdateUser.value = true;
        }
        const startEditAccount = (user) => {
            userToEdit.value = user;
            createOrUpdateUser.value = true;
        }
        const startDeleteAccount = (user) => {
            userToDelete.value = user;
            deleteUser.value = true;
        }

        const handleNewRoute = (to: Route) => {
            if (!to) return;
            customerId.value = to.params.id;
            prefix.value = `/api/customers/${customerId.value}/users`;
        }

        return {
            handleNewRoute,
            startCreateAccount,
            startEditAccount,
            startDeleteAccount,
            users,
            prefix,
            customerId,
            userToDelete,
            userToEdit,
            deleteUser,
            createOrUpdateUser,
        };
    },
    methods: {
        getUsers: waitFor('getUsersFlag', async function() {
            this.users = await getCustomerUsersApi(this.customerId);
        }),        
    },
    watch: {
        $route: {
            handler(to) {
                this.handleNewRoute(to);
                this.getUsers();
            },
            immediate: true,
            deep: true,
        }
    },
});
