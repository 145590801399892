
import ApiV2 from '@/lib/ApiV2';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import SpendUsageBarChart, {
    convertUsageResponseToDatasets,
    UsageReportTypes,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import CarbonBarChart, { convertUsageResponseToEmissionsDatasets } from '@/components/Domain.Carbon/CarbonBarChart.vue';
import CarbonTable, {
    ICarbonTableRecordsViewModel,
    convertToEmissionsItemRecords,
} from '@/components/Domain.Carbon/CarbonTable.vue';
import { useTenantStore, useWaitStore, storeToRefs } from '@/stores';
import {
    ISavedReportResult,
    ISpendUsageTableRecordsViewModel,
    generateSpendParametersFromReport,
    IMailingListGetPutModel,
    SpendFieldNames,
    PluralEntityType,
} from '@/models';
import { defineComponent, ref } from 'vue';
import { getSpend, getMailingLists, deliverSavedReport } from '@/lib/Api';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';

export default defineComponent({
    components: { SpendUsageTable, SpendUsageBarChart, CarbonBarChart, CarbonTable },
    setup() {
        const tenantStore = useTenantStore();
        const { currencyCode } = storeToRefs(tenantStore);
        const { is, whilst } = useWaitStore();

        const reportId = ref<string | null>();
        const report = ref<ISavedReportResult | null>(null);
        const usageTableItems = ref<ISpendUsageTableRecordsViewModel | null>(null);
        const emissionsTableItems = ref<ICarbonTableRecordsViewModel | null>(null);

        const isUpdating = ref(false);
        const newChartData = ref({ labels: [], datasets: [] });
        let maxDate: string = undefined;
        const showChart = ref(false);
        const dataset = ref('Usage');

        const isMailing = ref(false);
        const wasTruncatedTo = ref<number | null>(null);
        const selectedMailingListId = ref<number | null>(null);
        const mailingLists = ref<IMailingListGetPutModel[]>([]);

        getMailingLists().then((m) => (mailingLists.value = m));

        const deliverReportToMailingList = async () => {
            if (!reportId.value) return;
            if (!selectedMailingListId.value) return;
            isMailing.value = false;
            await deliverSavedReport(reportId.value, [], selectedMailingListId.value);
        };

        const loadReportUsage = async () => {
            await whilst('loadingReportUsage', async () => {
                const options = report.value;
                if (!options) return;
                dataset.value = report.value.dataset || 'Usage';

                const params = generateSpendParametersFromReport(options);
                const costViews = (options.costViews || ['Actual']).map((cv) => (cv === 'Utilized' ? 'Amortized' : cv));
                const usageParams = {
                    ...params,
                    segregateBy: options.segregateBy,
                };
                maxDate = params.toDate
                wasTruncatedTo.value = null;
                const fields =
                    dataset.value === 'Usage' ? [SpendFieldNames.Charges] : [SpendFieldNames.CO2e, SpendFieldNames.kWh];
                const results = await Promise.all(
                    costViews.map((cv) => getSpend({ ...usageParams, costView: cv, fields }))
                );
                if (options.segregateBy.split(',').length < 2 && costViews.length < 2) {
                    const datasets = results.map((r, i) => {
                        if ((r.limitedSet || 0) > (wasTruncatedTo.value || 0)) {
                            wasTruncatedTo.value = r.limitedSet;
                        }
                        const costView = costViews[i];
                        const labelSuffix = costView === 'Actual' ? 'Actual Spend' : 'Utilized Spend';
                        const stackGroup = costView.toLowerCase();
                        if (dataset.value === 'Usage') {
                            return convertUsageResponseToDatasets(r, {
                                labelSuffix,
                                stackGroup,
                                reportType: UsageReportTypes.charges,
                            });
                        } else {
                            const [x] = convertUsageResponseToEmissionsDatasets(r);
                            return x;
                        }
                    });
                    newChartData.value = {
                        labels: datasets.map((d) => d.labels).reduce((a, b) => [...a, ...b], []),
                        datasets: datasets.map((d) => d.datasets).reduce((a, b) => [...a, ...b], []),
                    };
                    showChart.value = true;
                } else {
                    newChartData.value = { labels: [], datasets: [] };
                    showChart.value = false;
                }
                if (dataset.value === 'Usage') {
                    const segregateByParts = options.segregateBy.split(',');
                    const segregateBy =
                        segregateByParts.length > 1
                            ? (segregateByParts as PluralEntityType[])
                            : (segregateByParts[0] as PluralEntityType);
                    const records = results.map((r, i) => {
                        const costView = costViews[i];
                        return SpendUsageTableFunctions.convertToItemRecords(params, segregateBy, r, costView);
                    });
                    usageTableItems.value = records[0];
                    usageTableItems.value.records = records.map((r) => r.records).reduce((a, b) => [...a, ...b], []);
                } else {
                    const records = results.map((r) => {
                        return convertToEmissionsItemRecords(params, r);
                    });
                    emissionsTableItems.value = records[0];
                    emissionsTableItems.value.records = records
                        .map((r) => r.records)
                        .reduce((a, b) => [...a, ...b], []);
                }
            });
        };
        const loadReport = async () => {
            await whilst('loadingReport', async () => {
                report.value = await ApiV2.http.get(`/api/savedreports/${reportId.value}`).then((r) => r.data);
            });
            await loadReportUsage();
        };

        return {
            reportId,
            report,
            usageTableItems,
            currencyCode,
            isUpdating,
            loadReportUsage,
            loadReport,
            wait: { is, whilst },
            newChartData,
            showChart,
            tenantStore,
            dataset,
            emissionsTableItems,
            wasTruncatedTo,
            selectedMailingListId,
            mailingLists,
            isMailing,
            deliverReportToMailingList,
            maxDate,
        };
    },

    watch: {
        $route: {
            handler(to) {
                if (!to) return;
                this.reportId = to.params.id;
                this.loadReport();
            },
            immediate: true,
            deep: true,
        },
    },
    title(ctx) {
        return ctx.report ? ctx.report.name : 'Report';
    },
    methods: {
        handleSeriesClicked(meta) {
            this.$router.push(`/explore/${this.report.segregateBy}/${meta.metaId}/usage`);
        },
        handleDialogChange(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push(`/custom/reports/${this.report ? this.report.id : ''}`);
            this.$title = this.report ? this.report.name : 'Report';
        },
    },
});
