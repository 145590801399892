<template>
  <form-card
    :loading="loading"
    v-model="valid"
    :title="title || $t('chooseTenantCard.title.default')"
    :subtitle="subtitle || items.length > 0 ? $t('chooseTenantCard.subtitle.default') : $t('chooseTenantCard.subtitle.none')"
    :lazy-validation="false"
  >
    <v-skeleton-loader :loading="loading"
                        type="list-item@4">
        <tenant-radio-group v-model="tenantId" :tenants="items" :hide-create="hideCreate" v-if="items.length > 0" :rules="[$rules.required()]"></tenant-radio-group>
        <empty-state icon="warning" :message="$t('chooseTenantCard.contact')" v-else>
            <v-btn color="secondary" :href="`mailto:${mailToAddress}`" text>{{mailToAddress}}</v-btn>
        </empty-state>
    </v-skeleton-loader>
    <template #actions="{ formId, reset }">
      <slot
        name="actions"
        :isValid="!!tenantId && valid"
        :clear="reset"
        :form="{ tenantId }"
        :formId="formId"
        :loading="loading"
      >
      </slot>
    </template>
  </form-card>
</template>

<i18n>
{
    "en": {
        "chooseTenantCard.title.default": "Choose Tenant", 
        "chooseTenantCard.subtitle.default": "Please choose a tenant",
        "chooseTenantCard.subtitle.none": "No tenants available",
        "chooseTenantCard.contact": "Please contact support"
    }   
}
</i18n>

<script>
import TenantRadioGroup from "@/components/Domain.Tenants/TenantRadioGroup.vue";
import Config from '@/lib/Config';
export default {
  components: { TenantRadioGroup },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    hideCreate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      tenantId: null,
      mailToAddress: Config.get().SUPPORT_EMAIL,
    };
  },
};
</script>
