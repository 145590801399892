<template>
    <v-combobox
        v-if="!errorFallback"
        :loading="$wait.is('getCurrencies')"
        :value="value"
        @input="$emit('input', $event)"
        :items="currencyCodes"
        :label="$t('currencyComboBox.currency')"
        :rules="rules"
    ></v-combobox>
    <v-text-field
        v-else
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :label="$t('currencyComboBox.currency')"
        :placeholder="$t('currencyComboBox.fallbackPlaceholder')"
    ></v-text-field>
</template>

<i18n>
{
    "en": {
        "currencyComboBox.currency": "Currency",
        "currencyComboBox.fallbackPlaceholder": "Please enter the currency code of your choosing e.g. AUD"
    }
}
</i18n>

<script>
import Currencies from '@/lib/Currencies.ts';
import { waitFor } from '@/plugins/vue-wait';
export default {
    props: ['value', 'rules'],
    data() {
        return {
            currencyCodes: [],
            errorFallback: false,
        };
    },
    created: waitFor('getCurrencies', async function () {
        try {
            this.currencyCodes = await Currencies.getAllCurrencyCodes();
        } catch (err) {
            this.errorFallback = true;
        }
    }),
};
</script>