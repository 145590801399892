<template>
    <v-row no-gutters>
        <v-col cols="6">
            <v-list dense>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>trending_up</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ projectedTotal | currency(currencyCode) }} </v-list-item-title>
                        <v-list-item-subtitle class="grey--text">
                            projected {{ new Date() | dateFormat('MMMM') }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>history</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title> {{ pastTotal | currency(currencyCode) }} </v-list-item-title>
                        <v-list-item-subtitle class="grey--text">
                            vs. {{ oneMonthAgo | dateFormat('MMMM') }} total
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col cols="6">
            <v-list dense>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="error" v-if="percentageOf >= 100">error</v-icon>
                        <v-icon color="warning" v-else-if="percentageOf > 90">warning</v-icon>
                        <v-icon v-else>savings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <fragment v-if="!spendLimit">
                            <v-list-item-title>
                                <span data-testid="spendLimit">N/A</span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="grey--text"> spend limit </v-list-item-subtitle>
                        </fragment>
                        <fragment v-else>
                            <v-list-item-title>
                                <span data-testid="spendLimit">{{ spendLimit | currency(currencyCode) }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="grey--text"> spend limit </v-list-item-subtitle>
                        </fragment>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>update</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ actualTotal | currency(currencyCode) }} </v-list-item-title>
                        <v-list-item-subtitle class="grey--text">
                            {{ new Date() | dateFormat('MMMM') }} so far
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>

<script>
import moment from '@/lib/moment';

export default {
    props: {
        currencyCode: String,
        projectedTotal: Number,
        actualTotal: Number,
        pastTotal: Number,
        spendLimit: Number,
    },
    computed: {
        percentageOf() {
            if (!this.spendLimit) return 0;
            return ((100 / this.spendLimit) * this.projectedTotal).toFixed(2);
        },
        oneMonthAgo() {
            return moment().subtract(1, 'month');
        },
    },
};
</script>