
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    setup(_, { emit }) {
        const showProductDialog = ref(false);
        const showProductId = ref<string | null>(null);
        const selectedProduct = ref<any>(null);

        const tryCloseDialog = (isOpen: boolean) => {
            if (!isOpen) {
                showProductDialog.value = false;
                showProductId.value = null;
                selectedProduct.value = null;
            }
            emit('input', isOpen);
        };

        const loadProductDetails = waitFor('loadingProducts', async function (id: string) {
            try {
                selectedProduct.value = await ApiV2.http.get(`/api/metadata/products/${id}`).then((r) => r.data);
                this.$title = selectedProduct.value.productName;
            } catch (err) {
                toastError(this.$t('productDialog.productNotFound'));
                tryCloseDialog(false);
            }
        });

        return {
            showProductDialog,
            showProductId,
            selectedProduct,
            tryCloseDialog,
            loadProductDetails,
        };
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showProductId === to.params.productId) return;
                this.showProductId = to.params.productId || null;
                this.showProductDialog = !!this.showProductId;
                if (this.selectedProduct && this.selectedProduct.productId === this.showProductId) return;
                if (!this.showProductId) return;
                this.loadProductDetails(this.showProductId);
            },
            immediate: true,
            deep: true,
        },
    },
});
