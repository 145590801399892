<template>
    <form-dialog v-model="isOpen">
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <form-card :title="$t('createCustomer.title')" :loading="loading" v-model="isFormValid">
                <v-text-field
                    :label="$t('createCustomer.customerName.label')"
                    v-model="name"
                    :rules="[$rules.required()]"
                >
                </v-text-field>
                <currency-combo-box v-model="displayCurrencyCode" :rules="[ $rules.required() ]" />
                <template #actions="{ isValid, loading, formId, reset }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <add-customer-button
                        :value="formData"
                        :clear="reset"
                        :form="formId"
                        :valid="isValid"
                        :loading="loading"
                        @http2xx="close().then(() => handleSuccessfulCustomerAdd($event))"
                    />
                </template>
                <v-checkbox :label="$t('createCustomer.customerIsNotForProfit.label')" v-model="isNotForProfit"></v-checkbox>
            </form-card>
        </template>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "createCustomer.title": "Create Customer",
        "createCustomer.customerName.label": "Customer Name",
        "createCustomer.successfulAdd": "Customer successfully added.",
        "createCustomer.customerIsNotForProfit.label": "Customer is a registered charity or not-for-profit."
    }
}
</i18n>

<script>
import AddCustomerButton from '@/components/View.CustomersManage/AddCustomerButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
import { useAccountStore } from '@/stores/account.ts';
import CurrencyComboBox from '@/components/Domain.Currencies/CurrencyComboBox.vue';
export default {
    data() {
        return { isOpen: false, name: '', isFormValid: true, displayCurrencyCode: '', isNotForProfit: false };
    },
    components: {
        AddCustomerButton, CurrencyComboBox
    },
    computed: {
        formData() {
            return { name: this.name, displayCurrencyCode: this.displayCurrencyCode,  isNotForProfit: this.isNotForProfit };
        },
    },
    props: {
        value: Object,
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleSuccessfulCustomerAdd(response) {
            toastSuccess(this.$t('createCustomer.successfulAdd'));
            useAccountStore().loadAccountTenants();
            this.$emit('http2xx', response);
        },
    },
};
</script>