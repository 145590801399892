<template>
    <div>
        <v-menu v-model="isOpen" offset-y bottom>
            <template #activator="bindings">
                <slot v-bind="bindings">
                    <v-btn x-small depressed class="ml-2" v-on="bindings.on">
                        manage<v-icon right x-small>menu_open</v-icon>
                    </v-btn>
                </slot>
            </template>
            <v-list>
                <v-list-item @click="isEditingSpendLimit = true">
                    <v-list-item-icon>
                        <v-icon>savings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('tagKeyValueManageMenu.editSpendLimit') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!isDimension" @click="tryPromoteToDimension">
                    <v-list-item-icon>
                        <v-icon>publish</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('tagKeyValueManageMenu.promoteToDimension') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else @click="tryDemoteToTagKey">
                    <v-list-item-icon>
                        <v-icon>unpublished</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('tagKeyValueManageMenu.demoteToTagKey') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
        <edit-tag-key-value-spend-limit-dialog
            v-model="isEditingSpendLimit"
            :tagkeyvalue="value"
            @http2xx="$emit('input', $event)"
        />
    </div>
</template>

<i18n>
{
    "en": {
        "tagKeyValueManageMenu.editSpendLimit": "Set Spend Limit",
        "tagKeyValueManageMenu.promoteToDimension": "Promote to Dimension!",
        "tagKeyValueManageMenu.demoteToTagKey": "Demote to Tag Key!"
    }
}
</i18n>

<script>
import { defineComponent, ref, computed } from 'vue';
import EditTagKeyValueSpendLimitDialog from '@/components/Domain.Tags/EditTagKeyValueSpendLimitDialog.vue';
import { enableDimension, disableDimension } from '@/lib/Api';
import { useTenantStore, storeToRefs } from '@/stores';

export default defineComponent({
    components: { EditTagKeyValueSpendLimitDialog },
    props: {
        value: Object,
    },
    setup(p) {
        const isOpen = ref(false);
        const isEditingSpendLimit = ref(false);
        const tenantStore = useTenantStore();
        const { dimensions } = storeToRefs(tenantStore);
        const isDimension = computed(() => {
            if (!p.value) return false;
            const [ tagKey ] = p.value.tagKeyValue.split(':');
            return Object.values(dimensions.value).includes(tagKey);
        }) 
        const tryPromoteToDimension = async () => {
            if (!p.value) return;
            const [ tagKey ] = p.value.tagKeyValue.split(':');
            await enableDimension(tagKey);
            await tenantStore.loadTenant();
        }
        const tryDemoteToTagKey = async () => {
            if (!p.value) return;
            const [ tagKey ] = p.value.tagKeyValue.split(':');
            if (window.confirm(`Are you sure you want to demote ${tagKey} to Tag Key?`)) {
                await disableDimension(tagKey);
                await tenantStore.loadTenant();
            }
        }
        return { dimensions, isOpen, isDimension, isEditingSpendLimit, tryPromoteToDimension, tryDemoteToTagKey };
    },
});
</script>
