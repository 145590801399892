<template>
    <div>
        <page-header :title="$t('copyCloudAccount.index.title', { cloudAccountName })" icon="content_copy"></page-header>
        <v-stepper v-model="currentStep" class="elevation-0">
            <v-stepper-header>
                <fragment v-for="(val, i) in ['chooseTenant', 'pricing', 'filters', 'finish']" :key="i">
                    <v-stepper-step :complete="currentStep > i + 1" :step="i + 1" color="secondary">{{
                        $t(`copyCloudAccount.steps.${val}`)
                    }}</v-stepper-step>
                    <v-divider></v-divider>
                </fragment>
            </v-stepper-header>
            <v-container>
                <v-container>
                    <router-view v-model="copyConfiguration" :cloudAccount="cloudAccount" :cloudAccounts="cloudAccounts"
                        :tenants="tenants" :editing="editing"
                        :loading="$wait.is(['loadingCopyConfiguration', 'loadingCloudAccounts', 'loadingTenants'])"
                        @changeCloudAccountId="changeCloudAccount($event)" #default="{ isValid }">
                        <back-button route></back-button>
                        <v-spacer></v-spacer>
                        <v-btn v-if="currentStep !== 4" :disabled="!isValid" @click="$router.push(nextStepRoute)"
                            color="secondary">
                            Continue
                        </v-btn>
                        <v-btn v-else :disabled="!isValid || $wait.is('submittingCopyCloudAccount')"
                            :loading="$wait.is('submittingCopyCloudAccount')" color="secondary"
                            @click="submitConfiguration()">
                            Submit
                        </v-btn>
                    </router-view>
                </v-container>
            </v-container>
        </v-stepper>
    </div>
</template>

<i18n>
{
    "en": {
        "copyCloudAccount.index.title": "Sharing {cloudAccountName}",
        "copyCloudAccount.steps.chooseTenant": "Share Details",
        "copyCloudAccount.steps.pricing": "Pricing",
        "copyCloudAccount.steps.filters": "Filters",
        "copyCloudAccount.steps.finish": "Finish",
        "copyCloudAccount.success.added": "Shared cloud connection into customer",
        "copyCloudAccount.success.updated": "Updated shared cloud connection rules"
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import moment from '@/lib/moment';
import { toastSuccess, toastError } from '@/components/Common/Toast.vue';
import TaxonomyType from '@/stores/models/TaxonomyType';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';
import { mapStores } from 'pinia';

const DEFAULT_RULES = {
    origin: null,
    currentMarkup: {
        id: null,
        markupPercentage: null,
        effectiveFromDate: moment.utc().startOf('month').subtract(13, 'months').toDate(),
    },
    futureMarkups: [],
    tagAllocations: [],
    absoluteMarkupCheck: false,
};
export default {
    title(ctx) {
        return ctx.$t('copyCloudAccount.index.title', { cloudAccountName: 'Account' });
    },
    data() {
        return {
            cloudAccountId: null,
            cloudAccounts: [],
            tenantId: null,
            tenants: [],
            cloudAccount: null,
            editing: false,
            copyConfiguration: {
                id: null,
                name: null,
                tenant: {},
                scriptId: null,
                rules: DEFAULT_RULES,
                useTenantCurrencies: false,
                shareParentConnection: false,
            },
            currentStep: 1,
        };
    },
    computed: {
        ...mapStores(useCloudAccountNavigationStore),
        cloudAccountName() {
            return this.cloudAccount ? this.cloudAccount.name : '...';
        },
        nextStepRoute() {
            switch (this.currentStep) {
                case 1:
                    return 'pricingRules';
                case 2:
                    return 'tags';
                case 3:
                    return 'finish';
                default:
                    return '';
            }
        },
    },
    created() {
        this.loadTenants();
        this.loadCloudAccounts();
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.params.cloudAccountId) this.cloudAccountId = to.params.cloudAccountId;
                if (to.params.tenantId) this.tenantId = to.params.tenantId;
                const fromStep = from ? from.meta.step || 0 : 0;
                this.currentStep = to.meta.step;
                if (fromStep != this.currentStep - 1 && fromStep != this.currentStep + 1) {
                    this.$router.push('chooseTenant');
                }
                if (this.cloudAccountId) {
                    this.cloudAccount = this.cloudAccounts.find((c) => c.id === this.cloudAccountId) || null;
                }
                if (this.tenantId) {
                    const matchingTenant = this.tenants.find((c) => c.tenantId === this.tenantId) || null;
                    if (matchingTenant) {
                        this.copyConfiguration.tenant = {
                            id: matchingTenant.tenantId,
                            name: matchingTenant.tenantName,
                        };
                    }
                }
                const copyCloudAccountId = to.query.copy;
                if (copyCloudAccountId) {
                    this.loadCopyConfiguration(this.cloudAccountId, copyCloudAccountId);
                    this.editing = true;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        loadCopyConfiguration: waitFor('loadingCopyConfiguration', async function (id, copy) {
            this.copyConfiguration = await ApiV2.http
                .get(`/api/metadata/cloudaccounts/${id}/copies/${copy}`)
                .then((r) => {
                    const rules = r.data.rules || DEFAULT_RULES;
                    if (rules.currentMarkup) {
                        rules.effectiveFromDate = moment.utc(rules.effectiveFromDate);
                    }
                    const futureDates = [];
                    if (rules.futureMarkups.length > 0) {
                        rules.futureMarkups.forEach((el) => {
                            futureDates.push({
                                id: el.id,
                                markupPercentage: el.markupPercentage,
                                effectiveFromDate: moment.utc(el.effectiveFromDate),
                            });
                        });
                        rules.futureMarkups = futureDates;
                    }
                    r.data.rules = rules;
                    return r.data;
                });
        }),
        loadCloudAccounts: waitFor('loadingCloudAccounts', async function () {
            this.cloudAccounts = await ApiV2.http.get('/api/metadata/cloudaccounts').then((r) => r.data);
            if (this.cloudAccountId) {
                this.cloudAccount = this.cloudAccounts.find((c) => c.id === this.cloudAccountId) || null;
            }
        }),
        changeCloudAccount(id) {
            this.cloudAccountId = id;
            if (this.cloudAccountId) {
                this.cloudAccount = this.cloudAccounts.find((c) => c.id === this.cloudAccountId) || null;
            }
        },
        loadTenants: waitFor('loadingTenants', async function () {
            this.tenants = await ApiV2.http.get('/api/metadata/tenants/current/children').then((r) => r.data);
            if (this.tenantId) {
                const matchingTenant = this.tenants.find((c) => c.tenantId === this.tenantId) || null;
                if (matchingTenant) {
                    this.copyConfiguration.tenant = { id: matchingTenant.tenantId, name: matchingTenant.tenantName };
                }
            }
        }),
        submitConfiguration: waitFor('submittingCopyCloudAccount', async function () {
            try {
                if (!this.copyConfiguration.rules.absoluteMarkupCheck) {
                    this.copyConfiguration.rules.currentMarkup = null;
                }

                if (this.copyConfiguration.id) {
                    await ApiV2.http.put(
                        `/api/metadata/cloudaccounts/${this.cloudAccountId}/copies/${this.copyConfiguration.id}`,
                        this.copyConfiguration
                    );
                    toastSuccess(this.$t('copyCloudAccount.success.updated'));
                } else {
                    await ApiV2.http.post(
                        `/api/metadata/cloudaccounts/${this.cloudAccountId}/copy/${this.copyConfiguration.tenant.id}`,
                        this.copyConfiguration
                    );
                    toastSuccess(this.$t('copyCloudAccount.success.added'));
                }

                let url = null;
                switch (this.cloudAccountNavigationStore.taxonomyType) {
                    case TaxonomyType.manage:
                        url = `/customers/manage/${this.cloudAccountNavigationStore.tenantId}`;
                        break;
                    case TaxonomyType.settingsAzure:
                        url = `/settings/azure`;
                        break;
                    case TaxonomyType.settingsAzureCloudAccount:
                        url = `/settings/azure/${this.cloudAccountNavigationStore.cloudAccountId}/copies`;
                        break;
                    case TaxonomyType.settingsAWS:
                        url = `/settings/amazon`;
                        break;
                    case TaxonomyType.settingsAWSCloudAccount:
                        url = `/settings/amazon/${this.cloudAccountNavigationStore.cloudAccountId}/copies`;
                        break;
                    case TaxonomyType.settingsOracle:
                        url = `/settings/oracle`;
                        break;
                    case TaxonomyType.settingsOracleCloudAccount:
                        url = `/settings/oracle/${this.cloudAccountNavigationStore.cloudAccountId}/copies`;
                        break;
                    default:
                        url = '/finance';
                }
                this.$router.push(url);
            } catch (err) {
                toastError('There was an error encountered sharing this cloud connection');
            }
        }),
    },
};
</script>

