<template>
    <form-card
        :title="$t('setUserCard.title')"
        :subtitle="$t('setUserCard.subtitle')"
        :loading="loading"
        v-model="valid"
    >
        <v-text-field v-model="name" :rules="[$rules.required()]" required autofocus :label="$t('name')"></v-text-field>
        <v-text-field
            v-model="email"
            :disabled="(disabled && disabled.Email) || isEditMode"
            :rules="[$rules.required(), $rules.email()]"
            required
            :label="$t('email')"
        ></v-text-field>
        <v-list>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canRead" disabled></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setUserCard.canRead.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('setUserCard.canRead.subtitle') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canContribute"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setUserCard.canContribute.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('setUserCard.canContribute.subtitle') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canAdminister"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setUserCard.canAdminister.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('setUserCard.canAdminister.subtitle') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canManageBilling"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setUserCard.canManageBilling.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('setUserCard.canManageBilling.subtitle') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template #actions="{ formId }">
            <slot
                name="actions"
                :form="formData"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>
<i18n>
{
    "en": {
        "setUserCard.title": "Set User Details",
        "setUserCard.subtitle": "Please enter the details for this user. New users will be sent an invite email directly.",
        "setUserCard.canRead.title": "Reader",
        "setUserCard.canRead.subtitle": "Can read all non-administrative data in this tenant.",
        "setUserCard.canContribute.title": "Contributor",
        "setUserCard.canContribute.subtitle": "Can perform non-administrative write functions like setting spend limits and adding annotations.",
        "setUserCard.canAdminister.title": "Administrator",
        "setUserCard.canAdminister.subtitle": "Can read and write administrative data, such as adding users to a tenant and creating new cloud connections.",
        "setUserCard.canManageBilling.title": "Billing Administrator",
        "setUserCard.canManageBilling.subtitle": "Can manage billing functions of the tenant."
    }
}
</i18n>

<script>
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: true,
            name: '',
            email: '',
            canRead: true,
            canAdminister: false,
            canContribute: true,
            canManageBilling: false,
            isEditMode: false,
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.reset();
                } else {
                    this.isEditMode = true;
                    this.email = val.email || null;
                    this.name = val.fullName || null;
                    if (val.permissions) {
                        this.canRead = val.permissions.includes('CanRead');
                        this.canAdminister = val.permissions.includes('CanAdminister');
                        this.canContribute = val.permissions.includes('CanContribute');
                        this.canManageBilling = val.permissions.includes('CanManageBilling');
                    }
                }
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        formData() {
            const permissions = [];
            if (this.canRead) permissions.push('CanRead');
            if (this.canAdminister) permissions.push('CanAdminister');
            if (this.canContribute) permissions.push('CanContribute');
            if (this.canManageBilling) permissions.push('CanManageBilling');
            return {
                email: this.email,
                fullName: this.name,
                permissions
            };
        }
    },
    methods: {
        reset() {
            this.isEditMode = false;
            this.email = null;
            this.name = null;
            this.canRead = true;
            this.canContribute = true;
            this.canAdminister = false;
            this.canManageBilling = false;
        }
    }
};
</script>
