<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <delete-entity-card
                :title="$t('deleteCustomViewDialog.title')"
                :subtitle="$t('deleteCustomViewDialog.subtitle')"
                :value="{ name: customView?.name }"
                :loading="$wait.is('deletingCustomView')"
                :warning-text="$t('deleteCustomViewDialog.warningText')"
            >
                <template #actions="{ isValid, formId, clear, loading, form }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <delete-customView-button
                        :value="customView"
                        :form="formId"
                        :clear="clear"
                        :loading="loading"
                        :valid="isValid"
                        v-wait-while="'deletingCustomView'"
                        @http2xx="close().then(() => handleSuccessfulDeletion(form))"
                    >
                    </delete-customView-button>
                </template>
            </delete-entity-card>
        </template>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "deleteCustomViewDialog.events.successfulDeletion": "Successfully deleted the custom view '{customViewName}'",
        "deleteCustomViewDialog.title": "Delete Custom View",
        "deleteCustomViewDialog.subtitle": "Please enter the name of this custom view to delete it.",
        "deleteCustomViewDialog.warningText": "Deleting this custom view will also delete all associated budgets if any."
    }
}
</i18n>

<script>
import DeleteEntityCard from '@/components/Common/DeleteEntityCard.vue';
import DeleteCustomViewButton from '@/components/Domain.CustomViews/DeleteCustomViewButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { DeleteEntityCard, DeleteCustomViewButton },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        customView: {
            type: Object,
            default: null,
        },
    },
    methods: {
        handleSuccessfulDeletion(form) {
            toastSuccess(this.$t('deleteCustomViewDialog.events.successfulDeletion', { customViewName: form.Name }));
            this.$emit('http2xx', form);
            this.$emit('deleteComplete');
        },
    },
};
</script>
