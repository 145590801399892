<template>
    <v-menu offset-y bottom>
        <template #activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
            </v-btn>
        </template>
        <v-list>
            <router-link :to="`/watchdog/${value.id}/edit`">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('policyMenu.edit') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <delete-policy-dialog
                v-model="isDeleting"
                :policy="value"
                #default="{ on }"
                @http2xx="$emit('http2xx', $event)"
            >
                <v-list-item v-on="on">
                    <v-list-item-icon>
                        <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('policyMenu.delete') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </delete-policy-dialog>
        </v-list>
    </v-menu>
</template>

<i18n>
{
    "en": {
        "policyMenu.delete": "Delete",
        "policyMenu.edit": "Edit"
    }
}
</i18n>

<script>
import DeletePolicyDialog from '@/components/Domain.Policies/DeletePolicyDialog.vue';
export default {
    components: {
        DeletePolicyDialog,
    },
    data() {
        return {
            isDeleting: false,
        };
    },
    props: {
        value: Object,
    },
};
</script>