
import { defineComponent, ref } from 'vue';
import { IProductCategoryModel } from '@/models';

export default defineComponent({
    setup(_, { emit }) {
        const showProductCategoryDialog = ref(false);
        const showProductCategoryId = ref<string | null>(null);
        const selectedProductCategory = ref<IProductCategoryModel>(null);

        const tryCloseDialog = (isOpen: boolean) => {
            if (!isOpen) {
                showProductCategoryDialog.value = false;
                showProductCategoryId.value = null;
                selectedProductCategory.value = null;
            }
            emit('input', isOpen);
        };

        const loadProductCategoryDetails = function (id: string) {
            const productCategoryName = id
                .split(':')
                .reverse()
                .find((x: string) => x);
            selectedProductCategory.value = { productCategoryName };
            this.$title = productCategoryName;
        };

        return {
            showProductCategoryDialog,
            showProductCategoryId,
            selectedProductCategory,
            tryCloseDialog,
            loadProductCategoryDetails,
        };
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showProductCategoryId === to.params.productCategoryId) return;
                this.showProductCategoryId = to.params.productCategoryId || null;
                this.showProductCategoryDialog = !!this.showProductCategoryId;
                if (
                    this.selectedProductCategory &&
                    this.selectedProductCategory.productCategoryId === this.showProductCategoryId
                )
                    return;
                if (!this.showProductCategoryId) return;
                this.loadProductCategoryDetails(this.showProductCategoryId);
            },
            immediate: true,
            deep: true,
        },
    },
});
