
import { defineComponent, ref, watch } from "vue";
import * as ChartFactory from "@/highcharts/MapFactory";
import { IRegionCostResult, IRegionResult } from "@/models";

export interface IRegionClickedEventData {
  regionName: string;
  providerType: string;
}

export const convertRegionResultsToChartSeries = (
  costs: IRegionCostResult,
  regions: IRegionResult[],
  currencyCode: string,
  currencySymbol: string
) => {
  const regionTotals = costs.data.reduce((agg, val) => {
    const key = `${val.providerType}:${val.regionName}`.toUpperCase();
    agg[key] = val.totalCost;
    return agg;
  }, {});
  const series = Object.values(
    regions.reduce((obj, region) => {
      const regionTotalKey = `${region.providerType}:${region.name}`.toUpperCase();
      if (!regionTotals[regionTotalKey]) return obj;

      const providerType = region.providerType;
      const regionData = (obj[providerType] =
        obj[providerType] || ChartFactory.createMapSeriesRecord(providerType, currencyCode, currencySymbol));

      if (region.lat) {
        regionData.data.push(
          ChartFactory.createMapSeriesChildRecord(
            region.id,
            region.name || "Unknown",
            providerType,
            region.lat,
            region.long,
            regionTotals[regionTotalKey]
          )
        );
      }
      return obj;
    }, {})
  );
  series.unshift({
    name: "Countries",
    color: "#E0E0E0",
    enableMouseTracking: false,
    showInLegend: false,
  });
  return series;
};

export default defineComponent({
  props: { items: Array },
  setup(p, { emit }) {
    const chartOptions = ref(
      ChartFactory.createMapChart({
        events: {
          pointClicked(eventData) {
            const outgoingEventData: IRegionClickedEventData = {
              regionName: eventData.name,
              providerType: eventData.series.name,
            };
            emit("click", outgoingEventData);
          },
        },
      })
    );
    watch(
      () => p.items,
      (items: any) => {
        chartOptions.value.series = items;
      },
      { immediate: true, deep: true }
    );
    return { chartOptions };
  },
});
