<template>
    <v-alert border="top" color="primary" colored-border
        ><div class="text-h6">* Additional Recommendations</div>
        <h2 class="subtitle-1 flex-grow-1">
            {{ $t('recommendationsnotavailable.higherplan') }}
            <span v-if="isWhiteLabelled === true">{{ $t('support') }}</span>
            <a v-else target="_blank" href="mailto:sales@cloudctrl.com.au">{{ $t(recommendationsnotavailable.salesteam) }}}}</a>
            for more details.
        </h2>
    </v-alert>
</template>

<i18n>
{
    "en": {
        "recommendationsnotavailable.higherplan": "Some recommendations are not available on your current subscription, contact",
        "recommendationsnotavailable.salesteam": "sales team"
    }
}
</i18n>

<script>
export default {
    props: {
        isWhiteLabelled: Boolean,
    },
};
</script>