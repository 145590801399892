
import DeleteSharedCopyButton from '@/components/Settings.CloudAccounts/DeleteCloudAccountCopyButton.vue';
import TaxonomyType from '@/stores/models/TaxonomyType';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';

export default {
    props: ['customerId', 'customer'],
    components: {
        DeleteSharedCopyButton,
    },
    data() {
        return {
            showDeleteCopy: false,
            copyToDelete: null,
            sortBy: 'customerName',
        };
    },
    methods: {
        getTenantCustomers() {
            this.$emit('getTenantCustomers');
        },
        loadCustomer() {
            this.$emit('loadCustomer');
        },
        startDeleteCopy(item) {
            this.copyToDelete = { Name: item.name, Id: item.id, ParentCloudAccountId: item.parentCloudAccountId };
            this.showDeleteCopy = true;
        },
    },
    computed: {
        createNewShare() {
            return `/customers/manage/${this.customerId}/shareddata`;
        },
        headers() {
            return [
                {
                    text: this.$t('customerManage.sharedCloudAccountName'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('customerManage.sourceCloudAccount'),
                    value: 'parentCloudAccountName',
                    sortable: true,
                },
                {
                    text: this.$t('customerManage.origin'),
                    value: 'rules.origin',
                    sortable: true,
                },
                {
                    text: this.$t('customerManage.currentMarkup'),
                    value: 'rules.currentMarkup.markupPercentage',
                    align: 'end',
                    sortable: true,
                },
                {
                    text: this.$t('customerManage.actualMargin'),
                    value: 'current90DayMargin',
                    align: 'end',
                    sortable: true,
                },
                {
                    text: this.$t('customerManage.actions'),
                    value: 'Actions',
                    align: 'end',
                    sortable: false,
                },
            ];
        },
        ...mapStores(useTenantStore),
    },
    created() {
        const navigationHistory = {
            taxonomyType: TaxonomyType.manage,
            tenantId: this.customerId,
            cloudAccountId: null,
        };
        const store = useCloudAccountNavigationStore();
        store.updateNavigationSource(navigationHistory);
    },
};
