<template>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="tableEntities"
        item-key="id"
        :items-per-page.sync="itemsPerPage"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
    >
        <template #no-data> You do not have administrative rights to any tenants. </template>
        <template #[`item.SubscribeToAlerts`]="{ item }">
            <email-subscription-checkbox
                v-model="item.subscribeToAlerts"
                :tenantId="item.id"
                emailType="Alerts"
            ></email-subscription-checkbox>
        </template>
        <template #[`item.SubscribeToSpendSummaries`]="{ item }">
            <email-subscription-checkbox
                v-model="item.subscribeToSpendSummaries"
                :tenantId="item.id"
                emailType="SpendSummaries"
            ></email-subscription-checkbox>
        </template>
        <template #[`item.Actions`]="{ item }">
            <slot name="item.menu" :item="item"></slot>
        </template>
    </v-data-table>
</template>

<script>
import EmailSubscriptionCheckbox from '@/components/Account.ManageTenants/EmailSubscriptionCheckbox.vue';
export default {
    props: ['items', 'loading', 'fields'],
    components: { EmailSubscriptionCheckbox },
    computed: {
        tableEntities() {
            if (!this.items) return [];
            return this.items.filter((i) => i.isAdmin).map((i) => i.tenant);
        },
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: 'Display Currency',
                    value: 'displayCurrencyCode',
                    sortable: true,
                },
                {
                    text: 'Receive Alerts',
                    value: 'SubscribeToAlerts',
                    sortable: true,
                },
                {
                    text: 'Receive Spend Summaries',
                    value: 'SubscribeToSpendSummaries',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ].filter((r) => !this.fields || this.fields.includes(r.value));
        },
    },
    data() {
        return {
            allStatuses: true,
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
        };
    },
};
</script>

<style scoped>
.v-data-table /deep/ tbody /deep/ tr:hover:not(.v-data-table__expanded__content) {
    background: transparent !important;
}
</style>
