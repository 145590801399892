<template>
    <fragment>
        <v-container>
            <content-card :title="$t('views.users.title')" :caption="$t('views.users.subtitle')" class="pb-1">
                <template #title-actions>
                    <v-btn color="secondary" depressed class="mr-3" @click="startCreateAccount()">
                        <v-icon left>email</v-icon> {{ $t('invite') }}
                    </v-btn>
                </template>
                <users-table :items="users" :loading="$wait.is('getUsers')">
                    <template #[`item.menu`]="{ item }">
                        <v-menu offset-y v-if="!item.IsMe">
                            <template #activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list light>
                                <v-list-item @click="startEditAccount(item)" v-if="!item.isTenantCreator">
                                    <v-list-item-icon>
                                        <v-icon>edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="startDeleteAccount(item)">
                                    <v-list-item-icon>
                                        <v-icon>delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <span v-else>N/A</span>
                    </template>
                </users-table>
                <template #actions>
                    <slot name="actions"></slot>
                </template>
            </content-card>
        </v-container>
        <form-dialog v-model="dialogs.createOrUpdateUser" #default="{ close }">
            <set-user-card :loading="$wait.is('createUser')" :value="userToEdit">
                <template #actions="{ form, formId, isValid, clear }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <add-user-button v-if="!userToEdit" :value="form" :clear="clear" :valid="isValid" :form="formId"
                        :prefix="prefix" v-wait-while="'createUser'" @http2xx="close().then(getUsers)">
                    </add-user-button>
                    <update-user-button v-else :value="form" :clear="clear" :valid="isValid" :form="formId"
                        :prefix="prefix" v-wait-while="'createUser'" @http2xx="close().then(getUsers)">
                    </update-user-button>
                </template>
            </set-user-card>
        </form-dialog>
        <form-dialog v-model="dialogs.deleteUser" #default="{ close }">
            <delete-user-card :loading="$wait.is('deleteUser')" :value="userToDelete"
                :title="$t('users.DeleteUserTitle')" :subtitle="$t('users.DeleteUserSubtitle')" :simple="true">
                <template #actions="{ isValid, formId, clear }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <delete-user-button :value="userToDelete" :clear="clear" :valid="isValid" :form="formId"
                        :prefix="prefix" v-wait-while="'deleteUser'" @http2xx="close().then(getUsers)">
                    </delete-user-button>
                </template>
            </delete-user-card>
        </form-dialog>
    </fragment>
</template>

<i18n>
{
    "en": {
        "views.users.title": "User Management",
        "views.users.subtitle": "The users that have some level of visibility of this tenant.",
        "users": "Users",
        "users.DeleteUserTitle": "Delete User",
        "users.DeleteUserSubtitle": "This user will be removed from this tenant. If this was a mistake you can re-invite them and their access will be renewed."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import SetUserCard from '@/components/Settings.Users/SetUserCard.vue';
import UsersTable from '@/components/Settings.Users/UsersTable.vue';
import AddUserButton from '@/components/Settings.Users/AddUserButton.vue';
import UpdateUserButton from '@/components/Settings.Users/UpdateUserButton.vue';
import DeleteUserCard from '@/components/Common/DeleteEntityCard.vue';
import DeleteUserButton from '@/components/Settings.Users/DeleteUserButton.vue';

export default {
    title(ctx) {
        return ctx.$t('views.users.title');
    },
    components: {
        SetUserCard,
        UsersTable,
        AddUserButton,
        UpdateUserButton,
        DeleteUserButton,
        DeleteUserCard,
    },
    data() {
        return {
            me: null,
            users: [],
            userToDelete: null,
            userToEdit: null,
            dialogs: {
                deleteUser: false,
                createOrUpdateUser: false,
            },
            prefix: "/api/metadata/tenant/current/users",
        };
    },
    methods: {
        startCreateAccount() {
            this.userToEdit = null;
            this.dialogs.createOrUpdateUser = true;
        },
        startDeleteAccount(user) {
            this.userToDelete = user;
            this.dialogs.deleteUser = true;
        },
        startEditAccount(user) {
            this.userToEdit = user;
            this.dialogs.createOrUpdateUser = true;
        },
        getUsers: waitFor('getUsers', async function () {
            this.users = await ApiV2.http.get('/api/metadata/tenants/current/users').then((r) => r.data);
        }),
    },
    created() {
        this.getUsers();
    },
};
</script>
