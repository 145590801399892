
import { useWaitStore } from '@/stores';
import { defineComponent, ref } from 'vue';
import * as Api from '@/lib/Api';
import { IBudgetResult } from '@/models';
import BudgetHistoryChart, {
    convertBudgetResultResponseToDatasets,
} from '@/components/Domain.Budgets/BudgetHistoryChart.vue';

export default defineComponent({
    components: { BudgetHistoryChart },
    title(ctx) {
        return ctx.$t('budgetdetails.title', { budgetName: this.budget ? this.budget.name : '...' });
    },
    setup() {
        const budgetId = ref<number | null>();
        const { is, whilst } = useWaitStore();
        const budget = ref<IBudgetResult | null>();
        const chartDatasets = ref<any>({ labels: [], datasets: [] });
        const loadBudget = async () =>
            await whilst('gettingBudget', async function () {
                budget.value = await Api.getBudget(budgetId.value);
                chartDatasets.value = convertBudgetResultResponseToDatasets(budget.value);
            });
        return {
            budgetId,
            budget,
            loadBudget,
            wait: { is, whilst },
            chartDatasets,
        };
    },
    methods: {
        getColor(variance) {
            if (variance < 0) return 'green';
            else if (variance == 0) return 'orange';
            else return 'red';
        },
    },
    watch: {
        $route: {
            handler(to) {
                if (!to) return;
                this.budgetId = to.params.id;
                this.loadBudget();
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        headers() {
            const headers = [
                { text: this.$t('budgetdetails.table.period'), value: 'period', sortable: false },
                { text: this.$t('budgetdetails.table.amount'), value: 'amount', sortable: false },
                { text: this.$t('budgetdetails.table.actual'), value: 'actual', sortable: false },
                { text: this.$t('budgetdetails.table.varianceamount'), value: 'variancedollars', sortable: false },
                { text: this.$t('budgetdetails.table.variancepercentage'), value: 'variancepercent', sortable: false },
            ];
            return headers;
        },
    },
});
