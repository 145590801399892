var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"mt-3",attrs:{"value":_vm.value ? _vm.value.valid : true},on:{"input":function($event){_vm.$emit('input', { ...(_vm.value || {}), valid: $event })}}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"list-item@6"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(!_vm.value || !_vm.value.data)?_c('empty-state',{attrs:{"icon":"warning","message":"There was an error processing this form."}}):(!_vm.value.data.length)?_c('empty-state',{attrs:{"icon":"warning","message":_vm.$t('tagForm.noTags')}},[(!_vm.hideCreate)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.addNewTag()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('tagForm.newTag'))+" ")],1):_vm._e()],1):_c('fragment',[_vm._l((_vm.value.data),function(tag,i){return _c('v-row',{key:i},[_c('v-col',{attrs:{"cols":"5"}},[_c('tag-key-autocomplete',{attrs:{"disabled":!tag.Sources ||
                                !tag.Sources.length ||
                                tag.Sources.length > 1 ||
                                tag.Sources[0] !== 'CloudControl'},on:{"input":function($event){return _vm.$emit('input', _vm.value)}},model:{value:(tag.Key),callback:function ($$v) {_vm.$set(tag, "Key", $$v)},expression:"tag.Key"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('tag-value-autocomplete',{attrs:{"tag-key":tag.Key,"disabled":!tag.Sources ||
                                !tag.Sources.length ||
                                tag.Sources.length > 1 ||
                                tag.Sources[0] !== 'CloudControl'},on:{"input":function($event){return _vm.$emit('input', _vm.value)}},model:{value:(tag.Value),callback:function ($$v) {_vm.$set(tag, "Value", $$v)},expression:"tag.Value"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","color":"error","disabled":!tag.Sources ||
                                !tag.Sources.length ||
                                tag.Sources.length > 1 ||
                                tag.Sources[0] !== 'CloudControl'},on:{"click":function($event){return _vm.deleteTag(tag, i)}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[(!_vm.hideCreate)?_c('v-btn',{attrs:{"color":"secondary","text":"","block":""},on:{"click":function($event){return _vm.addNewTag()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('tagForm.newTag'))+" ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_vm._t("default")],2)],1)],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }