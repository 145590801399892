
import { defineComponent, computed, ref } from 'vue';
import { ICloudAccountModel } from '@/models';
import CustomCloudAccountsTable, {
    customCloudAccountTableFilter,
} from '@/components/Settings.CloudAccounts/Custom/CustomCloudAccountsTable.vue';
import CreateCustomCloudAccountCard from '@/components/Common/UpdateEntityNameCard.vue';
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import DeleteCloudAccountDialog from '@/components/Settings.CloudAccounts/DeleteCloudAccountDialog.vue';
import EditCloudAccountNameDialog from '@/components/Settings.CloudAccounts/EditCloudAccountNameDialog.vue';
import EditCloudAccountCurrenciesDialog from '@/components/Settings.CloudAccounts/EditCloudAccountCurrenciesDialog.vue';
import { useTenantStore } from '../../stores/tenant';

export default defineComponent({
    components: {
        CustomCloudAccountsTable,
        CreateCustomCloudAccountCard,
        DeleteCloudAccountDialog,
        EditCloudAccountNameDialog,
        EditCloudAccountCurrenciesDialog,
    },
    props: { cloudAccounts: Array },
    setup(props, { emit }) {
        const isAdding = ref(false);
        const isEditingName = ref(false);
        const isEditingAccountCurrencies = ref(false);
        const isDeleting = ref(false);
        const onCloudAccountsChanged = () => emit('onCloudAccountsChanged');
        const focusedAccount = ref<ICloudAccountModel | null>(null);
        const customCloudAccounts = computed(() => {
            return (props.cloudAccounts || ([] as ICloudAccountModel[])).filter(customCloudAccountTableFilter);
        });
        const createCustomAccount = waitFor('creatingCustomCloudAccount', async function fn(formData: any) {
            await ApiV2.http.post('/api/metadata/cloudaccounts', {
                type: 'Custom',
                name: formData.Name,
            });
            onCloudAccountsChanged();
            isAdding.value = false;
        });
        const startEditName = (account: ICloudAccountModel) => {
            focusedAccount.value = account;
            isEditingName.value = true;
        };
        const startDeleteAccount = (account: ICloudAccountModel) => {
            focusedAccount.value = account;
            isDeleting.value = true;
        };
        const startEditCurrencies = (account: ICloudAccountModel) => {
            focusedAccount.value = account;
            isEditingAccountCurrencies.value = true;
        };
        const tenantStore = useTenantStore();
        return {
            customCloudAccounts,
            isAdding,
            createCustomAccount,
            isEditingName,
            isEditingAccountCurrencies,
            isDeleting,
            focusedAccount,
            onCloudAccountsChanged,
            startEditName,
            startEditCurrencies,
            startDeleteAccount,
            tenantStore
        };
    },
});
