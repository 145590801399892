
import { defineComponent, ref } from "vue";
import ReportCard from "@/components/Domain.Reports/ReportCard.vue";
import PutReportButton from "@/components/Domain.Reports/PutReportButton.vue";
import { getSavedReport } from "@/lib/Api";
import { Route } from "vue-router";
import { ISavedReportResult } from "@/models";

export default defineComponent({
  components: { ReportCard, PutReportButton },
  setup() {
    const report = ref<ISavedReportResult | null>(null);
    return { report };
  },
  watch: {
    $route: {
      async handler(route: Route) {
        this.report = await getSavedReport(route.params.id);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleSuccessfulCreation($event:any) {
      this.$router.push(`/custom/reports/${$event.id}`);
    },
  },
});
