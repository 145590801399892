
import { getCustomView } from '@/lib/Api';
import { computed, defineComponent, ref } from 'vue';
import { toastError } from '@/components/Common/Toast.vue';
import { ICustomViewSummaryResult } from '@/models';
import DeleteCustomViewDialog from '@/components/Domain.CustomViews/DeleteCustomViewDialog.vue';
import PutCustomViewDialog from '@/components/Domain.CustomViews/PutCustomViewDialog.vue';

export default defineComponent({
    components: { PutCustomViewDialog, DeleteCustomViewDialog },
    data() {
        return {
            currentPage: 1,
            pageCount: 0,
            itemsPerPage: 10,
            indexDictionary: {},
        };
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        loading: Boolean,
        routePrefix: {
            type: String,
            default: '/customviews',
        },
    },
    setup(p) {
        const focusedCustomView = ref<ICustomViewSummaryResult | null>(null);

        const records = computed(() => {
            if (!p.items) return [];
            return p.items.map((b: ICustomViewSummaryResult) => ({ ...b }));
        });

        const isEditingCustomView = ref(false);
        const startEditCustomView = (customView: ICustomViewSummaryResult) => {
            isEditingCustomView.value = true;
            focusedCustomView.value = customView;
        };

        const isDeletingCustomView = ref(false);
        const startDeleteCustomView = (customView: ICustomViewSummaryResult) => {
            isDeletingCustomView.value = true;
            focusedCustomView.value = customView;
        };

        return {
            records,
            startDeleteCustomView,
            isDeletingCustomView,
            focusedCustomView,
            isEditingCustomView,
            startEditCustomView,
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t('customViewsTable.name'), value: 'name' },
                { text: this.$t('customViewsTable.filters'), value: 'filterDescription' },
                { text: '', value: 'loading', width: '10', sortable: false },
                { text: this.$t('customViewsTable.history'), value: 'last12Weeks', width: '150' },
                { text: this.$t('customViewsTable.lastMonth'), value: 'lastMonthActual', align: 'right' },
                { text: this.$t('customViewsTable.currentMonth'), value: 'currentMonthForecast', align: 'right' },
                { text: this.$t('customViewsTable.difference'), value: 'forecastDeltaSpend', align: 'center' },
                { text: this.$t('customViewsTable.budgets'), value: 'budgetStatus', align: 'center' },
                { text: this.$t('customViewsTable.actions'), value: 'actions', align: 'center', width: '5 %' },
            ];
        },
    },
    watch: {
        currentPage(newValue) {
            this.loadExtraDataForCurrentPage(newValue, this.itemsPerPage);
        },
        itemsPerPage(newItemsPerPage) {
            this.loadExtraDataForCurrentPage(this.currentPage, newItemsPerPage);
        },
        loading: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!val) {
                    this.indexDictionary = {};
                    this.records.forEach((item, index) => {
                        this.indexDictionary[item.id] = index;
                    });
                    this.loadExtraDataForCurrentPage(this.currentPage, this.itemsPerPage);
                }
            },
        },
    },
    methods: {
        handleEditComplete() {
            this.isEditingCustomView = false;
            this.focusedCustomView = null;
        },
        handleDeleteComplete() {
            this.isDeletingCustomView = false;
            this.focusedCustomView = null;
        },
        loadExtraDataForCurrentPage(currentPage, itemsPerPage) {
            const startIdx = (currentPage - 1) * itemsPerPage;
            const endIdx = startIdx + itemsPerPage;
            const itemsOnCurrentPage = this.records.slice(startIdx, endIdx);
            if (!this.loading) {
                this.loadDetailedData(itemsOnCurrentPage);
            }
        },
        async loadDetailedData(updatedItems) {
            try {
                const promises = updatedItems
                    .filter((item) => !item.lastMonthActual)
                    .map((item) => getCustomView(item.id));

                if (promises.length == 0) return;
                const extraDataArray = await Promise.all(promises);

                extraDataArray.forEach((additionalData) => {
                    if (additionalData) {
                        const itemIndex = this.indexDictionary[additionalData.id];
                        if (itemIndex !== undefined) {
                            this.$set(this.records[itemIndex], 'currencyCode', additionalData.currencyCode);
                            this.$set(this.records[itemIndex], 'last12Weeks', additionalData.last12Weeks);
                            this.$set(this.records[itemIndex], 'lastMonthActual', additionalData.lastMonthActual);
                            this.$set(
                                this.records[itemIndex],
                                'currentMonthForecast',
                                additionalData.currentMonthForecast
                            );
                            this.$set(this.records[itemIndex], 'forecastDeltaSpend', additionalData.forecastDeltaSpend);
                            this.$set(
                                this.records[itemIndex],
                                'forecastDeltaPercentage',
                                additionalData.forecastDeltaPercentage
                            );
                            this.$set(this.records[itemIndex], 'budgetStatus', additionalData.budgetStatus);
                        }
                    }
                });
            } catch (error) {
                toastError(this.$t('customViewsTable.failedToLoad'));
            }
        },
    },
});
