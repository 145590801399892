<template>
  <http-patch-button
    v-bind="$props"
    :value="patches"
    :href="`/api/metadata/tagkeyvalue/${(value || {}).tagKeyValue}`"
    v-on="$listeners"
    @http4xx="onError"
  >
  </http-patch-button>
</template>

<i18n>
{
    "en": {
        "patchTagButton.errorMessage": "There was an error encountered while patching this tag keyvalue. If everything looks right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from "@/components/Common/Toast.vue";
import HttpPatchButton from "@/components/Common/HttpPatchButton.vue";

export default {
  props: {
    ...HttpPatchButton.props,
    form: {
      type: String
    },
    value: {
      type: Object,
    },
    patches: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onError() {
      toastError(this.$t("patchTagButton.errorMessage"));
    },
  },
};
</script>