<template>
    <content-card title="Customers" :caption="`Total: ${items.length}`">
        <v-data-table
            :headers="headers"
            :items="transformedItems"
            :items-per-page="15"
            :sort-by.sync="sortBy"
            :loading="loading"
        >
            <template #[`item.thisMonthRevenue`]="{ item }">
                {{ item.thisMonthRevenue | currency(currencyCode) }}
            </template>
            <template #[`item.thisMonthCost`]="{ item }">
                {{ item.thisMonthCost | currency(currencyCode) }}
            </template>
            <template #[`item.thisMonthProfit`]="{ item }">
                {{ item.thisMonthProfit | currency(currencyCode) }}
            </template>
            <template #[`item.thisMonthMargin`]="{ item }"> {{ item.thisMonthMargin.toFixed(2) }}% </template>
            <template #[`item.lastMonthRevenue`]="{ item }">
                {{ item.lastMonthRevenue | currency(currencyCode) }}
            </template>
            <template #[`item.lastMonthCost`]="{ item }">
                {{ item.lastMonthCost | currency(currencyCode) }}
            </template>
            <template #[`item.lastMonthProfit`]="{ item }">
                {{ item.lastMonthProfit | currency(currencyCode) }}
            </template>
            <template #[`item.lastMonthMargin`]="{ item }"> {{ item.lastMonthMargin.toFixed(2) }}% </template>
        </v-data-table>
    </content-card>
</template>

<script>
import moment from '@/lib/moment';
export default {
    props: ['items', 'currencyCode', 'loading', 'hideProfit'],
    data() {
        return { sortBy: 'customerName' };
    },
    computed: {
        transformedItems() {
            if (!this.items) return [];

            return this.items.map((i) => {
                const thisMonth = i.monthlyChargeSummaries[i.monthlyChargeSummaries.length - 1] || {
                    Revenue: 0.0,
                    Cost: 0.0,
                    Margin: 0.0,
                    Profit: 0.0,
                };
                const lastMonth = i.monthlyChargeSummaries[i.monthlyChargeSummaries.length - 2] || {
                    Revenue: 0.0,
                    Cost: 0.0,
                    Margin: 0.0,
                    Profit: 0.0,
                };
                return {
                    customerId: i.customerId,
                    customerName: i.customerName,
                    thisMonthRevenue: thisMonth.revenue,
                    thisMonthCost: thisMonth.cost,
                    thisMonthProfit: thisMonth.profit,
                    thisMonthMargin: thisMonth.margin,
                    lastMonthRevenue: lastMonth.revenue,
                    lastMonthCost: lastMonth.cost,
                    lastMonthProfit: lastMonth.profit,
                    lastMonthMargin: lastMonth.margin,
                };
            });
        },
        headers() {
            const thisMonthName = moment.utc().format("MMMM 'YY");
            const lastMonthName = moment.utc().subtract(1, 'month').format("MMMM 'YY");
            let headers = {
                customerName: 'Customer Name',
                thisMonthRevenue: `${thisMonthName} Revenue`,
                thisMonthCost: `${thisMonthName} Cost`,
                thisMonthProfit: `${thisMonthName} Profit`,
                thisMonthMargin: `${thisMonthName} Margin`,
                lastMonthRevenue: `${lastMonthName} Revenue`,
                lastMonthCost: `${lastMonthName} Cost`,
                lastMonthProfit: `${lastMonthName} Profit`,
                lastMonthMargin: `${lastMonthName} Margin`,
            };

            if(this.hideProfit === true) {
                headers = {
                    customerName: 'Customer Name',
                    thisMonthCost: `${thisMonthName} Cost`,
                    lastMonthCost: `${lastMonthName} Cost`,
                };
            }

            return Object.keys(headers).map((h) => ({
                text: headers[h],
                value: h,
                sortable: true,
            }));
        },
    },
};
</script>
