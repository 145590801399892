<template>
    <content-card class="my-2">
        <template slot="title-actions" v-if="!hideExport">
            <v-btn color="secondary" text @click="exportCsv()">
                <v-icon left>cloud_download</v-icon>
                {{ $t('usageComparisonsTable.exportBtnText') }}
            </v-btn>
        </template>
        <v-data-table
            :items="items"
            :headers="headers"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, -1],
            }"
        >
            <template #no-data>
                <div v-if="!items"><v-icon left>query_builder</v-icon>Waiting for query</div>
                <div v-else>
                    <v-icon color="warning" left>warning</v-icon>
                    <span class="warning--text">There is no data for this tag</span>
                </div>
            </template>
            <template #[`item.name`]="{ item }"><slot name="itemName" :item="item"></slot></template>
            <template #[`item.deltaSpend`]="{ item }">
                {{ item.deltaSpend | currency(currency) }}
                <comparison-icon
                    :value="item.activePeriodSpend"
                    :compare="item.priorPeriodSpend"
                    right
                ></comparison-icon>
                {{ (item.deltaPercentage * 100).toFixed(2) }}%</template
            >
            <template #[`item.priorPeriodSpend`]="{ item }">{{ item.priorPeriodSpend | currency(currency) }}</template>
            <template #[`item.activePeriodSpend`]="{ item }">{{ item.activePeriodSpend | currency(currency) }}</template>
        </v-data-table>
    </content-card>
</template>

<i18n>
{
    "en": {
        "usageComparisonsTable.headers.name": "Name",
        "usageComparisonsTable.headers.difference": "Difference",
        "usageComparisonsTable.headers.past": "Past",
        "usageComparisonsTable.headers.current": "Current",
        "usageComparisonsTable.exportBtnText": "Export CSV"
    }
}
</i18n>

<script>
import Downloader from '@/lib/Downloader';

export default {
    props: {
        items: Array,
        currency: String,
        type: String,
        hideExport: Boolean,
    },
    computed: {
        headers() {
            const headers = [
                { text: this.$t('usageComparisonsTable.headers.name'), value: 'name', sortable: false },
                { text: this.$t('usageComparisonsTable.headers.difference'), value: 'deltaSpend', sortable: false },
                { text: this.$t('usageComparisonsTable.headers.past'), value: 'priorPeriodSpend', sortable: false },
                { text: this.$t('usageComparisonsTable.headers.current'), value: 'activePeriodSpend', sortable: false },
            ];
            return headers;
        }
    },
    methods: {
        exportCsv() {
            const headers = [
                { text: this.$t('usageComparisonsTable.headers.difference'), value: 'deltaSpend' },
                { text: this.$t('usageComparisonsTable.headers.past'), value: 'priorPeriodSpend' },
                { text: this.$t('usageComparisonsTable.headers.current'), value: 'activePeriodSpend' },
            ];

            if (this.type == 'subcription') {
                headers.unshift({ text: this.$t('usageComparisonsTable.headers.name'), value: 'subscriptionName' });
            } else if (this.type == 'product') {
                headers.unshift({ text: this.$t('usageComparisonsTable.headers.name'), value: 'productName' });
            } else if (this.type == 'service') {
                headers.unshift({ text: this.$t('usageComparisonsTable.headers.name'), value: 'serviceName' });
            }

            const headerValues = headers.map((i) => i.value);
            const csvValues = this.items.map((i) => headerValues.map((k) => i[k]));
            csvValues.unshift(headers.map((i) => i.text));
            Downloader.exportCSV(csvValues, 'usagecomparison-by-' + this.type + '.csv');
        },
    },
};
</script>

<style scoped>
.v-data-table /deep/ tbody /deep/ tr:hover:not(.v-data-table__expanded__content) {
    background: transparent !important;
}
</style>
