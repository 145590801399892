<template>
    <v-skeleton-loader :loading="loading" type="actions" class="mb-3">
        <v-row dense>
            <slot></slot>
        </v-row>
    </v-skeleton-loader>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>