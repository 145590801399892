<template>
    <fragment>
        <page-header :title="$t('resourceExplorer.title')" icon="insights" extended needs-filters>
            <v-tabs v-model="tab" background-color="transparent" align-with-title>
                <v-tab v-for="t in tabs" :key="t.key" :to="t.to" :disabled="$wait.is('gettingUsageRecords')">
                    <v-icon small left v-if="t.icon">{{ t.icon }}</v-icon>
                    {{ t.title }}
                </v-tab>
            </v-tabs>
        </page-header>
        <v-container>
            <template>
                <div class="text-center pa-4">
                    <form-dialog v-model="rangeSelectedDialog" :width="300">
                        <template #activator="activatorBindings">
                            <slot name="activator" v-bind="activatorBindings"></slot>
                        </template>
                        <template #default="{ close }">
                            <form-card :title="$t('resourceExplorer.rangeSelectedDialog.dialogTitle')" :subtitle="trendDialogSubtitle">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>Total for period</v-list-item-title>
                                            <v-list-item-subtitle>{{ currentRangeEvent?.rangeTotal | currency(tenantStore.currencyCode) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>Average for period</v-list-item-title>
                                            <v-list-item-subtitle>{{ avgTotal | currency(tenantStore.currencyCode) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-btn
                                                block
                                                color="secondary"
                                                type="submit"
                                                @click.prevent="processRangeEvent()"
                                            >
                                                <v-icon left>analytics</v-icon>
                                                Go to Trends
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <template>
                                    <back-button @click="close()"></back-button>
                                </template>
                            </form-card>
                        </template>
                    </form-dialog>
                </div>
            </template>
            <template>
                <div class="text-center pa-4">
                    <form-dialog v-model="annotationDialog" :width="500">
                        <template #activator="activatorBindings">
                            <slot name="activator" v-bind="activatorBindings"></slot>
                        </template>
                        <template #default="{ close }">
                            <form-card :title="$t('resourceExplorer.annotationDialog.dialogTitle')" :subtitle="annotationDialogSubtitle">
                                <annotations-timeline
                                    :annotations="selectedAnnotations"
                                    :allowEdit="false"
                                    :showNavigate="true"
                                    :showProduct="true"/>
        
                                <template>
                                    <back-button @click="close()"></back-button>
                                </template>
                            </form-card>
                        </template>
                    </form-dialog>
                </div>
            </template>
            <content-card :title="chartTitle" title-icon="savings" :loading="$wait.is('gettingUsageRecords')">
                <template #title-actions>
                  <v-btn icon @click="starred = !starred" aria-label="Star explorer tab" title="Star explorer tab" role="button">
                    <v-icon :color="starred ? 'yellow': undefined">star</v-icon>
                  </v-btn>
                </template>
                <v-toolbar flat>
                    <SpendUsageOptionsToolbarForm
                        v-model="usageOptions"
                        @input="$emit('input', $event)"
                        :loading="$wait.is('gettingUsageRecords')"
                        :date-supported-keys="['30d', '60d', '6m', 'custom']"
                        hide-segregation
                    >
                    </SpendUsageOptionsToolbarForm>
                </v-toolbar>
                <SpendUsageBarChart
                    :max-date="maxDate"
                    :data="newChartData"
                    :annotations="annotations"
                    :granularity="usageOptions.granularity"
                    :allow-range-selection="true"
                    @click="handleDatasetClicked"
                    @onRangeSelection="onRangeSelection"
                ></SpendUsageBarChart>
            </content-card>
            <SpendUsageUnitToggle v-if="showUnitToggle" v-model="unitOfDisplay" :value="unitOfDisplay">
            </SpendUsageUnitToggle>
            <SpendUsageTable
                :items="groupingItems"
                :currency-code="tenantStore.currencyCode"
                :segregate-by="usageOptions.segregateBy"
                :name-header-title="currentHeaderName"
                :tag-key="tagKey"
                :unitOfDisplay="unitOfDisplay"
            >
            </SpendUsageTable>
            
        </v-container>
        <router-view @input="handleDialogChange" />
    </fragment>
</template>

<i18n>
{
    "en": {
        "resourceExplorer.title": "Cost Explorer",
        "resourceExplorer.tabs.productcategories": "Product Categories",
        "resourceExplorer.tabs.resources": "Services",
        "resourceExplorer.tabs.subscriptions": "Subscriptions",
        "resourceExplorer.tabs.cloudproviders": "Cloud Providers",
        "resourceExplorer.tabs.cloudaccounts": "Cloud Connections",
        "resourceExplorer.tabs.products": "Products",
        "resourceExplorer.headers.productCategories": "Product Category",
        "resourceExplorer.headers.subscriptions": "Subscription Name",
        "resourceExplorer.headers.cloudAccounts": "Cloud Connection Name",
        "resourceExplorer.headers.products": "Product Name",
        "resourceExplorer.headers.services": "Service Name",
        "resourceExplorer.headers.tags": "Tag Value",
        "resourceExplorer.toast.navigationError": "Detailed information not yet available for this dimension",
        "resourceExplorer.rangeSelectedDialog.dialogTitle": "Range Information",
        "resourceExplorer.annotationDialog.dialogTitle": "Annotations"
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2.ts';
import { waitFor } from '@/plugins/vue-wait';
import { useTenantStore, useFilterStore } from '@/stores';
import UpdateActionScriptCard, { IFormData } from '@/components/Domain.Actions/SetActionScriptCard.vue';
import { mapStores } from 'pinia';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import SpendUsageBarChart, {
    convertProjectionsResponseToDataset,
    convertUsageResponseToDatasets,
    convertEventsToAnnotations,
    convertAnnotatesToAnnotations,
    UsageReportTypes
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import SpendUsageOptionsToolbarForm, {
    convertSpendOptionsToQuery,
    calculateOptionsDescription,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import { getForecast } from '@/lib/Api';
import { PluralEntityType } from '@/models';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';
import SpendUsageUnitToggle from '@/components/Domain.Usage/SpendUsageUnitToggle.vue';
import { UnitOfDisplay } from '@/enums/UnitOfDisplay.enum';
import AnnotationsTimeline from '@/components/Domain.Annotations/AnnotationsTimeline.vue';
import { setUserPreferences, getUserPreferences } from "@/lib/UserPreferences";
import moment from '@/lib/moment';

const getSegregateByFromRoute = (route) => {
    let path = route.path.toLowerCase();
    if(route.params.baseTenantId) {
        path = path.split('/').filter( x=> x != route.params.baseTenantId).join('/');
    }
    if (path.startsWith('/explore/services')) {
        return PluralEntityType.services;
    } else if (path.startsWith('/explore/subscriptions')) {
        return PluralEntityType.subscriptions;
    } else if (path.startsWith('/explore/cloudaccounts')) {
        return PluralEntityType.cloudAccounts;
    } else if (path.startsWith('/explore/products')) {
        return PluralEntityType.products;
    } else if (path.startsWith('/explore/tagkeys')) {
        return PluralEntityType.tags;
    } else if (path.startsWith('/explore/productcategories')) {
        return PluralEntityType.productCategories;
    } else if (path.startsWith('/explore/cloudproviders')) {
        return PluralEntityType.cloudProviders;
    } else {
        return PluralEntityType.subscriptions;
    }
};

export default {
    components: {
        SpendUsageBarChart,
        SpendUsageUnitToggle,
        SpendUsageTable,
        SpendUsageOptionsToolbarForm,
        AnnotationsTimeline
    },
    title(ctx) {
        return ctx.$t('resourceExplorer.title');
    },
    data() {
        const filterOptions = useFilterStore();
        return {
            tab: null,
            tagKey: this.$route.params.tagkey,
            usageOptions: {
                ...filterOptions.options,
                segregateBy: getSegregateByFromRoute(this.$route),
            },
            usageRecords: null,
            groupingItems: null,
            fromDate: null,
            datasetIndexes: {},
            newChartData: { labels: [], datasets: [] },
            maxDate: undefined,
            annotations: [],
            unitOfDisplay: UnitOfDisplay.cost,
            rangeSelectedDialog: false,
            currentRangeEvent: undefined,
            selectedAnnotations: [],
            annotationDialog: false,
            starred: false,
        };
    },
    destroyed() {
        this.filterStore.reset();
    },
    watch: {
        usageOptions: {
            handler(options) {
                if (!options) return;
                if (this.$wait.is('gettingUsageRecords')) return;
                this.filterStore.setFilter(options);
                this.getUsageRecords(options);
            },
            immediate: true,
            deep: true,
        },
        $route: {
            async handler(route) {
                if (!route) return;
                this.tagKey = route.params.tagkey;
                const newSegregateBy = getSegregateByFromRoute(route);
                if (this.usageOptions.segregateBy !== newSegregateBy) {
                    this.usageRecords = null;
                    this.groupingItems = null;
                    this.usageOptions.segregateBy = newSegregateBy;
                }
                const newTabKey = this.tabKey;
                const preferences = await getUserPreferences();
                preferences.costExplorerTab = newTabKey;
                this.starred = preferences.starredCostExplorerTab === preferences.costExplorerTab;
                await setUserPreferences(preferences);
            },
            immediate: true,
            deep: true,
        },
        async starred(isStarred) {
            const preferences = await getUserPreferences();
            preferences.starredCostExplorerTab = isStarred
                ? this.tabKey
                : this.tabKey === preferences.starredCostExplorerTab
                ? null
                : preferences.starredCostExplorerTab;
            await setUserPreferences(preferences);
        },
        tagKey: {
            handler(newTagKey, oldTagKey) {
                if (!oldTagKey) return;
                this.getUsageRecords(this.usageOptions);
            },
        },
    },
    computed: {
        tabKey() {
            const newSegregateBy = getSegregateByFromRoute(this.$route);
            return newSegregateBy === 'tags'
                    ? `tagkeys/${encodeURIComponent(this.tagKey)}`
                    : newSegregateBy.toLowerCase();
        },
        chartTitle() {
            return calculateOptionsDescription(this.usageOptions, (...params) => this.$t(...params));
        },
        trendDialogSubtitle() {
            if(this.currentRangeEvent) {
                const start = moment(this.currentRangeEvent.xMin);
                const end = moment(this.currentRangeEvent.xMax);
                return `${start.format('MMMM DD')} - ${end.format('MMMM DD')}`;
            }
            return undefined;

        },
        annotationDialogSubtitle() {
            if(this.selectedAnnotations && this.selectedAnnotations.length > 0) {
                const dates = this.selectedAnnotations.map(x => {
                    const date = new Date(x.annotationDate);
                    date.setHours(0,0,0,0);
                    return date;
                }).sort((a,b) => a.getTime() - b.getTime());
            
                const start = moment(dates[0]);
                const end = moment(dates[dates.length - 1]);
                if(start.isSame(end)) {
                    return start.format('MMMM DD');
                }
                return `${start.format('MMMM DD')} - ${end.format('MMMM DD')}`;
            }
            return undefined;

        },
        avgTotal() {
            if(this.currentRangeEvent) {
                const start = moment(this.currentRangeEvent.xMin).startOf('day');
                const end = moment(this.currentRangeEvent.xMax).endOf('day');
                if(this.currentRangeEvent.rangeTotal && this.currentRangeEvent.rangeTotal > 0) {
                    const daysBetween = end.diff(start, 'days') > 0 ? end.diff(start, 'days') + 1 : 1;
                    return this.currentRangeEvent.rangeTotal / daysBetween;
                }
            }
            return 0;
        },
        currentHeaderName() {
            return this.headerName(this.usageOptions.segregateBy);
        },
        tabs() {
            const prefix = this.$route.params.baseTenantId ? `/${this.$route.params.baseTenantId}`:'';
            const tabs = [
                {
                    title: this.$t('resourceExplorer.tabs.productcategories'),
                    key: 'productcategories',
                    to: `${prefix}/explore/productcategories`,
                    icon: 'category',
                },
                {
                    title: this.$t('resourceExplorer.tabs.products'),
                    key: 'products',
                    to: `${prefix}/explore/products`,
                    icon: 'shopping_cart',
                },
                {
                    title: this.$t('resourceExplorer.tabs.resources'),
                    key: 'resources',
                    to: `${prefix}/explore/services`,
                    icon: 'memory',
                },
                {
                    title: this.$t('resourceExplorer.tabs.subscriptions'),
                    key: 'subscriptions',
                    to: `${prefix}/explore/subscriptions`,
                    icon: 'cloud_queue',
                },
                {
                    title: this.$t('resourceExplorer.tabs.cloudaccounts'),
                    key: 'cloudaccounts',
                    to: `${prefix}/explore/cloudaccounts`,
                    icon: 'cloud_download',
                },
                {
                    title: this.$t('resourceExplorer.tabs.cloudproviders'),
                    key: 'cloudproviders',
                    to: `${prefix}/explore/cloudproviders`,
                    icon: 'apartment',
                },
            ];
            if (this.tenantStore.tagKey1) {
                tabs.push({
                    title: this.tenantStore.tagKey1,
                    key: this.tenantStore.tagKey1,
                    to: `${prefix}/explore/tagkeys/${encodeURIComponent(this.tenantStore.tagKey1)}`,
                    icon: 'sell',
                });
            }
            if (this.tenantStore.tagKey2) {
                tabs.push({
                    title: this.tenantStore.tagKey2,
                    key: this.tenantStore.tagKey2,
                    to: `${prefix}/explore/tagkeys/${encodeURIComponent(this.tenantStore.tagKey2)}`,
                    icon: 'sell',
                });
            }
            if (this.tenantStore.tagKey3) {
                tabs.push({
                    title: this.tenantStore.tagKey3,
                    key: this.tenantStore.tagKey3,
                    to: `${prefix}/explore/tagkeys/${encodeURIComponent(this.tenantStore.tagKey3)}`,
                    icon: 'sell',
                });
            }
            if (this.tenantStore.dimensions) {
                const pinned = this.tenantStore.dimensions.filter((d) => d && d.pinned);
                pinned.forEach((d) => {
                    tabs.push({
                        title: d.tagKey,
                        key: d.tagKey,
                        to: `${prefix}/explore/tagkeys/${encodeURIComponent(d.tagKey)}`,
                        icon: 'sell',
                    });
                });
            }

            return tabs;
        },
        ...mapStores(useTenantStore),
        ...mapStores(useFilterStore),
        showUnitToggle() {
            return this.filterStore.$state.options.segregateBy == PluralEntityType.meters;
        },
    },
    methods: {
        handleDialogChange(isOpen) {
            const prefix = this.$route.params.baseTenantId ? `/${this.$route.params.baseTenantId}`:'';
            this.filterStore.setFilter({ segregateBy: getSegregateByFromRoute(this.$route) });
            this.usageOptions = { ...this.filterStore.options };
            if (isOpen) return;
            if (this.usageOptions.segregateBy === 'tags') {
                this.$router.push(`${prefix}/explore/tagkeys/${encodeURIComponent(this.tagKey)}`);
            } else {
                this.$router.push(`${prefix}/explore/${this.usageOptions.segregateBy}`);
            }
            this.$title = this.$t('resourceExplorer.title');
        },
        headerName(by) {
            return this.$t('resourceExplorer.headers.' + by);
        },
        onRangeSelection(event) {
            this.rangeSelectedDialog = true;
            this.currentRangeEvent = event;
        },
        processRangeEvent() {
            const event = this.currentRangeEvent;
            const toDate = new Date(event.xMax);
            const numDaysBetween = Math.ceil((event.xMax - event.xMin) / (1000 * 3600 * 24));
            const period = `${numDaysBetween}d`;
            const segregateBy = this.usageOptions.segregateBy === 'services' ? 'services' : 'products';
            const searchParams = new URLSearchParams({
                toDate: toDate.toISOString(),
                period,
                segregateBy,
            });

            this.rangeSelectedDialog = false;
            this.$router.push(`/trends?${searchParams.toString()}`);
        },
        getUsageRecords: waitFor('gettingUsageRecords', async function getUsageRecords(options) {
            const params = convertSpendOptionsToQuery(options);
            if (!params) return;
            if (this.usageOptions.segregateBy === 'tags' && !params.tagKeyValue) {
                params.tagKeyValue = this.tagKey ? `${this.tagKey}:` : null;
            }
            const [usageResponse, projectionsResponse, spikeEventsResponse] = await Promise.all([
                ApiV2.http
                    .get(
                        `/api/spend/${options.segregateBy}` +
                            (options.segregateBy == 'tags' ? `/${encodeURIComponent(this.tagKey)}` : ``),
                        { params }
                    )
                    .then((r) => r.data),
                getForecast(params, options.dateRangeKey),
                ApiV2.http
                    .get(`/api/events?eventType=SpikeEventAlert`, { params })
                    .catch((err) => {
                        if (err.response.status === 400) return { data: {} };
                        throw err;
                    })
                    .then((r) => r.data),
            ]);
            this.fromDate = new Date(params.fromDate);
            const projectionDataset = convertProjectionsResponseToDataset(projectionsResponse, params.granularity);
            const usageDataset = convertUsageResponseToDatasets(usageResponse, { reportType: UsageReportTypes.charges });

            const createdAnnotations = await ApiV2.http
                .get(`/api/annotations`, {
                    params: { 
                        fromDate: params.fromDate, 
                        toDate: params.toDate, 
                        cloudAccountIds: params.cloudAccountIds, 
                        subscriptionIds: params.subscriptionIds, 
                        serviceIds: params.serviceIds, 
                        tagKeyValue: params.tagKeyValue},
                })
                .then((r) => r.data);

                const chartAnnotations = convertAnnotatesToAnnotations(createdAnnotations, [...projectionDataset.datasets, ...usageDataset.datasets], params.granularity, (xMin, xMax) => {
                    if(!this.rangeSelectedDialog) {
                        const toDate = new Date(xMax).getTime();
                        const fromDate = new Date(xMin).getTime();
                        this.selectedAnnotations = createdAnnotations.filter(x => {
                            const date = new Date(x.annotationDate).getTime();
                            return date >= fromDate && date <= toDate;
                            });
                        this.annotationDialog = true;
                    }
                });

            const spikeAnnotations = convertEventsToAnnotations(spikeEventsResponse, (xMin, xMax) => {
                const toDate = new Date(xMax);
                const numDaysBetween = Math.ceil((xMax - xMin) / (1000 * 3600 * 24));
                const period = `${numDaysBetween}d`;
                const segregateBy = this.usageOptions.segregateBy === 'services' ? 'services' : 'products';
                const searchParams = new URLSearchParams({
                    toDate,
                    period,
                    segregateBy,
                });
                this.$router.push(`/trends?${searchParams.toString()}`);
            });
            this.newChartData = {
                labels: [...projectionDataset.labels, ...usageDataset.labels],
                datasets: [...projectionDataset.datasets, ...usageDataset.datasets],
            };
            this.maxDate = projectionsResponse?.maxUsageDate || params.toDate
            this.annotations = [...chartAnnotations, ...spikeAnnotations];
            this.groupingItems = SpendUsageTableFunctions.convertToItemRecords(
                params,
                this.usageOptions.segregateBy,
                usageResponse,
                this.usageOptions.costView
            );
        }),
    
        handleDatasetClicked({ datasetGroupId, isOther }) {
            const prefix = this.$route.params.baseTenantId ? `/${this.$route.params.baseTenantId}`:'';
            if (isOther) {
                this.usageOptions.topXResults = this.usageOptions.topXResults + 10;
            } else if (this.usageOptions.segregateBy === 'tags') {
                const key = encodeURIComponent(this.tagKey);
                let id = encodeURIComponent(datasetGroupId);
                if(datasetGroupId == this.tagKey && datasetGroupId) {
                    id = encodeURIComponent(`${datasetGroupId}:(Blanks)`)
                }
                const route = `${prefix}/explore/tagkeys/${key}/tag/${id && key != id ? id : "(Blanks)"}/usage`;
                this.$router.push(route);
            } else {
                this.$router.push(`${prefix}/explore/${this.usageOptions.segregateBy}/${datasetGroupId}`);
            }
        },        
    },
};
</script>
