
import "ag-grid-enterprise";
import { LicenseManager } from '@ag-grid-enterprise/core'
import Config from '@/lib/Config';

try {
    const cfg = Config.get();
    LicenseManager.setLicenseKey(cfg.AG_GRID_KEY);
} catch (err) {
    console.error(err);
}

import "ag-grid-community/styles/ag-grid.css";
import "@/scss/ag-grid.scss";
export { AgGridVue } from "ag-grid-vue";
export { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
export { ColDef } from "@ag-grid-community/core";