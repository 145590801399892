<template>
    <v-checkbox :input-value="value" @change="onValueChanged($event)" :loading="loading" :disabled="loading"> </v-checkbox>
</template>

<script>
import Api from '@/lib/ApiV2.ts';
import { toastError, toastSuccess } from '@/components/Common/Toast.vue';
export default {
    props: ['value', 'tenantId', 'emailType'],
    data() {
        return { loading: false };
    },
    methods: {
        onValueChanged(event) {
            try {
                if (this.loading) return;
                this.loading = true;
                if (!event) {
                    Api.http
                        .post(`/api/emails/${this.emailType}`)
                        .then(() => {
                            this.loading = false;
                            toastSuccess('Successfully unsubscribed from these emails.');
                        });
                } else {
                    Api.http
                        .delete(`/api/emails/${this.emailType}`)
                        .then(() => {
                            this.loading = false;
                            toastSuccess('Successfully subscribed to these emails.');
                        });
                }
                this.$emit('input', !this.value);
            } catch (err) {
                toastError('There was an issue updating this tenant');
            }
        },
    },
};
</script>