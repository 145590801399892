<template>
    <v-dialog :value="true"
              no-click-animation
              persistent
              max-width="800"
              scrollable
              hide-overlay
              :fullscreen="$vuetify.breakpoint.xsOnly"
              :loading="$wait.is('creatingTenant')">
        <create-tenant-card>
            <template #actions="{ form, formId, isValid, clear }">
                <v-btn to="/logout">
                    <v-icon left>login</v-icon>
                    {{ $t('views.selfService.createTenant.logout') }}
                </v-btn>
                <v-spacer></v-spacer>
                <post-create-tenant-button :value="{ ...form }"
                                           :clear="clear"
                                           :valid="isValid"
                                           :form="formId"
                                           v-wait-while="'creatingTenant'"
                                           @http2xx="tenantCreated"></post-create-tenant-button>
            </template>
        </create-tenant-card>
    </v-dialog>

</template>

<i18n>
{
    "en": {
        "views.selfService.createTenant.logout": "Logout"
    }
}
</i18n>

<script>
    import CreateTenantCard from '@/components/Account.SelfService/CreateTenantCard.vue';
    import PostCreateTenantButton from '@/components/Account.SelfService/PostCreateTenantButton.vue';
    import { useTenantStore } from '../../stores/tenant.ts';
    import { useAccountStore } from '@/stores/account.ts';
    import { mapStores } from 'pinia';
    export default {
        data() {
            return {};
        },
        components: { CreateTenantCard, PostCreateTenantButton },
        computed: {
            ...mapStores(useTenantStore)
        },
        methods: {
            async tenantCreated(newTenantIdentifier) {
                this.$wait.start('creatingTenant');
                await this.tenantStore.changeTenantContext(newTenantIdentifier.tenantId, newTenantIdentifier.userProfileId);
                useAccountStore().loadAccountTenants();
                this.$router.push('/selfservice/plans');
            }
        },
    };
</script>