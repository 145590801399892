
import { defineComponent, computed, PropType } from 'vue';
import { IGptChatMessage } from '@/models/GptChatModels'

export default defineComponent({
    props: { message: Object as PropType<IGptChatMessage>, chatName: String },
    setup(p, { emit }) {
        const questionList = computed(() => {
            if (p.message.isUser) return null;
            const matches = /(\[[\s\S]*\])/g.exec(p.message.message);

            if (!matches || !matches[1]) return null; 

            const questions = (JSON.parse(matches[1]) as Array<string>).map(x => format(x));
            return questions
        });

        const ask = (question:string) => {
            emit('ask', question);
        };

        const format = (message: string | any) => {
            if (typeof message !== 'string') return message;

            return message
                .replaceAll('&', '&amp;')
                .replaceAll('<', '&lt;')
                .replaceAll('>', '&gt;')
                .replaceAll('"', '&quot;')
                .replaceAll("'", '&#039;')
                .replace(/```([^`]+)```/g, "<pre class=\"code\">$1</pre>")
                .replace(/`([^`]+)`/, "<span class=\"code\">$1</span>");
            
        }

        return { questionList, ask, format };
    }
});
