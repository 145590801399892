
import { useTenantStore } from '@/stores/tenant';
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        me: Object,
        value: Object,
        loading: {
            default: false,
            type: Boolean,
        },
    },
    setup() {
        const tab: string = null;
        const tenantStore = useTenantStore();
        const tabs = computed(() =>
            [
                { key: 'usage', icon: 'insights', text: 'views.dialogReservation.tabs.usage' },
            ].filter((t) => t)
        );
        return { tab, tabs, tenantStore };
    },
});
