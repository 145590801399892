<template>
    <v-container>
        <content-card :title="$t('settings.google.title')" :caption="$t('settings.google.subtitle')">
            <template #title-actions>
                <create-google-account-dialog v-model="isCreatingGcpAccount" @http2xx="onCloudAccountsChanged">
                    <template #activator="{ on }">
                        <v-btn color="secondary" depressed class="mr-3" v-on="on">
                            <v-icon left>add</v-icon> {{ $t('add') }}
                        </v-btn>
                    </template>
                </create-google-account-dialog>
            </template>
            <google-cloud-accounts-table :items="googleCloudAccounts" :loading="$wait.is('getCloudAccounts')">
                <template #[`item.menu`]="{ item }">
                    <v-menu>
                        <template #activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="startEditName(item)">
                                <v-list-item-icon>
                                    <v-icon>edit</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('edit name') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="startEditCurrencies(item)">
                                <v-list-item-icon>
                                    <v-icon>currency_exchange</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('edit currencies') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="startEditCloudAccount(item)" v-if="item.Type !== 'SelfReferential'">
                                <v-list-item-icon>
                                    <v-icon>miscellaneous_services</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('edit usage connection') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                :to="`/copy/cloudaccounts/${item.cloudAccountId}`"
                                v-if="tenantStore.hasTenantFeature('CopyCloudAccount')"
                            >
                                <v-list-item-icon>
                                    <v-icon>content_copy</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('copy cloud connection') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="startDeleteAccount(item)" v-if="item.Type !== 'SelfReferential'">
                                <v-list-item-icon>
                                    <v-icon>delete_forever</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </google-cloud-accounts-table>
            <delete-cloud-account-dialog
                v-model="isDeletingGcpAccount"
                :account="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            ></delete-cloud-account-dialog>
            <update-google-json-credentials-dialog
                v-model="isEditingGcpAccount"
                :edit="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            ></update-google-json-credentials-dialog>
            <edit-cloud-account-name-dialog
                v-model="isEditingGcpAccountName"
                :account="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            ></edit-cloud-account-name-dialog>
            <edit-cloud-account-currencies-dialog
                v-model="isEditingAccountCurrencies"
                :account="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            ></edit-cloud-account-currencies-dialog>
            <router-view @input="handleDialogChange" />
        </content-card>
    </v-container>
</template>

<i18n>
{
    "en":{
        "settings.google.title": "Google Cloud Connections",
        "settings.google.subtitle": "All cloud connections importing GCP usage data in this tenant."
    }
}
</i18n>

<script>
import CreateGoogleAccountDialog from '@/components/Settings.CloudAccounts/Google/CreateGoogleAccountDialog.vue';
import UpdateGoogleJsonCredentialsDialog from '@/components/Settings.CloudAccounts/Google/UpdateGoogleJsonCredentialsDialog.vue';
import DeleteCloudAccountDialog from '@/components/Settings.CloudAccounts/DeleteCloudAccountDialog.vue';
import EditCloudAccountNameDialog from '@/components/Settings.CloudAccounts/EditCloudAccountNameDialog.vue';
import EditCloudAccountCurrenciesDialog from '@/components/Settings.CloudAccounts/EditCloudAccountCurrenciesDialog.vue';
import GoogleCloudAccountsTable, {
    googleCloudAccountTableFilter,
} from '@/components/Settings.CloudAccounts/Google/GoogleCloudAccountsTable.vue';
import { useTenantStore } from '../../stores/tenant.ts';
import { mapStores } from 'pinia';

export default {
    title() {
        return this.$t('settings.google.title');
    },
    props: {
        cloudAccounts: Array,
    },
    components: {
        GoogleCloudAccountsTable,
        CreateGoogleAccountDialog,
        DeleteCloudAccountDialog,
        EditCloudAccountNameDialog,
        EditCloudAccountCurrenciesDialog,
        UpdateGoogleJsonCredentialsDialog,
    },
    data() {
        return {
            isCreatingGcpAccount: false,
            isEditingGcpAccount: false,
            isEditingGcpAccountName: false,
            isEditingAccountCurrencies: false,
            isDeletingGcpAccount: false,
            focusedAccount: null,
        };
    },
    computed: {
        googleCloudAccounts() {
            return this.cloudAccounts.filter(googleCloudAccountTableFilter);
        },
        ...mapStores(useTenantStore)
    },
    methods: {
        handleDialogChange(isOpen) {
            if (isOpen) return;
            this.$router.push('/settings/google');
            this.$title = this.$t('settings.google.title');
        },
        onCloudAccountsChanged() {
            this.$emit('onCloudAccountsChanged');
        },
        startEditName(account) {
            this.focusedAccount = account;
            this.isEditingGcpAccountName = true;
        },
        startEditCloudAccount(account) {
            this.focusedAccount = account;
            this.isEditingGcpAccount = true;
        },
        startDeleteAccount(account) {
            this.focusedAccount = account;
            this.isDeletingGcpAccount = true;
        },
        startEditCurrencies(account) {
            this.focusedAccount = account;
            this.isEditingAccountCurrencies = true;
        },
    },
};
</script>