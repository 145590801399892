
import { waitFor } from "@/plugins/vue-wait";
import { getTrends } from "@/lib/Api";
import { PluralEntityType } from "@/models";
import ServiceComparisonsTable from "@/components/Domain.Services/ServiceComparisonsTable.vue";
import UsageComparisonOptionsToolbar from "@/components/Domain.Usage/UsageComparisonOptionsToolbar.vue";
import { defineComponent, ref } from "vue";
import { useTenantStore } from "@/stores/tenant";

export default defineComponent({
  components: { ServiceComparisonsTable, UsageComparisonOptionsToolbar },
  props: {
    value: Object,
    loading: Boolean,
  },
  setup(props) {
    const comparisons = ref([]);
    const configuration = ref({
      segregateBy: PluralEntityType.services,
      period: "month",
      toDate: new Date(),
    });
    const loadServiceComparisons = waitFor(
      "loadServiceComparisons",
      async function loadServiceComparisonsFn() {
        if (!props.value) return;
        const params = {
          productIds: props.value.productInd,
          period: configuration.value.period,
          toDate: configuration.value.toDate.toISOString(),
        };
        const segregateBy = configuration.value.segregateBy as
          | PluralEntityType.services
          | PluralEntityType.products;
        const response = await getTrends(segregateBy, params);
        comparisons.value = response.entities;
      }
    );

    const tenantStore = useTenantStore();

    return {
      comparisons,
      configuration,
      loadServiceComparisons,
      tenantStore,
    };
  },
  watch: {
    value: {
      handler(val) {
        if (!val) return;
        this.loadServiceComparisons();
      },
      immediate: true,
    },
  },
});
