<template>
    <form-card :title="title" :subtitle="subtitle" :loading="loading" v-model="valid">
        <v-text-field
            type="number"
            v-model="monthlySpendLimit"
            persistent-hint
            :label="$t('updateEntitySpendLimitCard.monthlySpendLimit.title')"
            required
        ></v-text-field>
        <template #actions="{ formId, reset }">
            <slot
                :isValid="valid"
                :clear="reset"
                :formId="formId"
                :loading="loading"
                :form="{ monthlySpendLimit: Number(monthlySpendLimit) }"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "updateEntitySpendLimitCard.monthlySpendLimit.title": "Monthly Spend Limit"
    }
}
</i18n>

<script>
export default {
    props: {
        value: Object,
        loading: {
            default: false,
            type: Boolean,
        },
        title: {
            default: 'Update Spend Limit',
            type: String,
        },
        subtitle: {
            default: 'Please specify the new monthly spend limit you would like',
            type: String,
        },
    },
    data() {
        return {
            valid: true,
            monthlySpendLimit: null,
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.monthlySpendLimit = val.monthlySpendLimit;
            },
            immediate: true
        },
    },
};
</script>