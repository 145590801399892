
import { defineComponent, ref } from "vue";

export enum SimpleActionTypes {
  addNewTag = "AddNewTagAction",
  copyValue = "CopyValue",
  removeTag = "RemoveTag",
  markup = "Markup"
}

export default defineComponent({
  props: { value: String, label: String },
  setup() {
    const actionTypes = ref(Object.values(SimpleActionTypes));
    return { actionTypes };
  },
});
