<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <set-cloud-account-currencies-card :value="account" :loading="$wait.is('updatingAccountCurrencies')">
                <template #actions="{ isValid, formId, loading, form }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <patch-cloud-account-button
                        :value="{ Id: account ? account.id : null }"
                        :patches="[{ op: 'replace', path: 'UseTenantCurrencies', value: form.UseTenantCurrencies }]"
                        :form="formId"
                        :clear="() => {}"
                        :valid="isValid"
                        :loading="loading"
                        v-wait-while="'updatingAccountCurrencies'"
                        @http2xx="close().then(() => handleSuccessfulRename(form))"
                    ></patch-cloud-account-button>
                </template>
            </set-cloud-account-currencies-card>
        </template>
    </form-dialog>
</template>

<script>
import SetCloudAccountCurrenciesCard from '@/components/Settings.CloudAccounts/SetCloudAccountCurrenciesCard.vue';
import PatchCloudAccountButton from '@/components/Settings.CloudAccounts/PatchCloudAccountButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    props: {
        value: Boolean,
        account: Object,
    },
    components: { SetCloudAccountCurrenciesCard, PatchCloudAccountButton },
    methods: {
        handleSuccessfulRename(form) {
            toastSuccess(`Successfully updated currency source.`);
            this.$emit('http2xx', form);
        },
    },
};
</script>