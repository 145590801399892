<template>
    <set-alibaba-credentials-dialog
        :value="value"
        @input="$emit('input', $event)"
        :loading="$wait.is('creatingAlibabaAccount')"
    >
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #actions="{ form, formId, isValid, clear, close }">
            <post-cloud-account-button
                :value="{ ...form, Type: 'Alibaba' }"
                :clear="clear"
                :valid="isValid"
                :form="formId"
                v-wait-while="'creatingAlibabaAccount'"
                @http2xx="close().then(() => $emit('http2xx', $event))"
            ></post-cloud-account-button>
        </template>
    </set-alibaba-credentials-dialog>
</template>

<script>
import SetAlibabaCredentialsDialog from '@/components/Settings.CloudAccounts/Alibaba/SetAlibabaCredentialsDialog';
import PostCloudAccountButton from '@/components/Settings.CloudAccounts/PostCloudAccountButton';
export default {
    components: { SetAlibabaCredentialsDialog, PostCloudAccountButton },
    props: {
        value: Boolean,
    },
};
</script>