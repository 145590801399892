
import { defineComponent, ref } from "vue";
import { CloudProviderType } from "@/models";

export default defineComponent({
  setup(_, { emit }) {
    const showCloudProviderDialog = ref(false);
    const showCloudProviderId = ref<string | null>(null);
    const selectedCloudProvider = ref<CloudProviderType | null>(null);

    const tryCloseDialog = (isOpen: boolean) => {
      if (!isOpen) {
        showCloudProviderDialog.value = false;
        showCloudProviderId.value = null;
        selectedCloudProvider.value = null;
      }
      emit("input", isOpen);
    };

    const loadCloudProviderDetails = function (id: string) {
      selectedCloudProvider.value = id as CloudProviderType;
      this.$title = id;
    };

    return {
      showCloudProviderDialog,
      showCloudProviderId,
      selectedCloudProvider,
      tryCloseDialog,
      loadCloudProviderDetails,
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (this.showCloudProviderId === to.params.cloudProviderId) return;
        this.showCloudProviderId = to.params.cloudProviderId || null;
        this.showCloudProviderDialog = !!this.showCloudProviderId;
        if (
          this.selectedCloudProvider &&
          this.selectedCloudProvider.cloudProviderId === this.showCloudProviderId
        )
          return;
        if (!this.showCloudProviderId) return;
        this.loadCloudProviderDetails(this.showCloudProviderId);
      },
      immediate: true,
      deep: true,
    },
  },
});
