<template>
    <v-container>
        <content-card :title="$t('views.users.title')" :caption="$t('views.users.subtitle')" class="pb-1">
            <template #title-actions>
                <v-btn color="secondary" depressed class="mr-3" @click="startCreateAccount()">
                    <v-icon left>email</v-icon> {{ $t('invite') }}
                </v-btn>
            </template>
            <users-table :items="users" :loading="loading">
                <template #[`item.menu`]="{ item }">
                    <v-menu offset-y v-if="!item.IsMe">
                        <template #activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list light>
                            <v-list-item @click="startEditAccount(item)" v-if="!item.isTenantCreator">
                                <v-list-item-icon>
                                    <v-icon>edit</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="startDeleteAccount(item)">
                                <v-list-item-icon>
                                    <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <span v-else>N/A</span>
                </template>
            </users-table>
            <template #actions>
                <slot name="actions"></slot>
            </template>
        </content-card>
    </v-container>
</template>

<i18n>
    {
        "en": {
            "views.users.title": "User Management",
            "views.users.subtitle": "The users that have some level of visibility of this tenant."
        }
    }
</i18n>


<script>
import UsersTable from '@/components/Settings.Users/UsersTable.vue';

export default {
    props: ['users', 'loading'],
    components: {
        UsersTable
    },
    methods: {
        startCreateAccount() {
            this.$emit('createUserAccount');
        },
        startEditAccount(user) {
            this.$emit('editUserAccount', user);
        },
        startDeleteAccount(user) {
            this.$emit('deleteUserAccount', user);
        },
    }
}
</script>

