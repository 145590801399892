
import { defineComponent, ref, watch } from 'vue';
import { ICustomDashboardModel } from '@/models';
import { listCustomDashboards, createCustomDashboard, putCustomDashboard } from '@/lib/Api';
import { useGridStore } from '@/stores/grid';
import * as G from '@/merge/Grid';

export default defineComponent({
    setup() {
        const gridStore = useGridStore();
        const dashboards = ref<ICustomDashboardModel[]>([]);
        const isPuttingDashboard = ref(false);
        const isCreateFormValid = ref(true);
        const isSaveNetworkLoading = ref(false);
        const newDashboardName = ref('');
        const newDashboardDesc = ref('');
        const focusedDashboard = ref<ICustomDashboardModel | null>(null);

        const loadViewState = async () => {
            dashboards.value = await listCustomDashboards();
        };
        loadViewState();

        const handlePutDashboard = async () => {
            //
            try {
                isSaveNetworkLoading.value = true;
                const name = newDashboardName.value;
                const description = newDashboardDesc.value;
                if (focusedDashboard.value) {
                    const grid = await gridStore.updateGridName(focusedDashboard.value.id, name);
                    await putCustomDashboard(
                        focusedDashboard.value.id,
                        { name, description },
                        grid
                    );
                    focusedDashboard.value.name = name;
                    focusedDashboard.value.description = description;
                } else {
                    const doc = G.initGrid({ name, description });
                    const result = await createCustomDashboard(
                        {
                            description: newDashboardDesc.value,
                            name: newDashboardName.value,
                        },
                        doc
                    );
                    await gridStore.putGrid(result.id, doc);
                    dashboards.value = [...dashboards.value, result];
                }
                focusedDashboard.value = null;
                isPuttingDashboard.value = false;
            } finally {
                isSaveNetworkLoading.value = false;
            }
        };

        const startEditDashboard = (dash: ICustomDashboardModel) => {
            isPuttingDashboard.value = true;
            focusedDashboard.value = dash;
        };

        watch(focusedDashboard, (d) => {
            newDashboardName.value = d?.name || null;
            newDashboardDesc.value = d?.description || null;
        });

        const isDeletingDashboard = ref(false);
        const startDeleteDashboard = (dash: ICustomDashboardModel) => {
            isDeletingDashboard.value = true;
            focusedDashboard.value = dash;
        };

        const handleDeleted = () => {
            focusedDashboard.value = null;
            loadViewState();
        };

        return {
            dashboards,
            isSaveNetworkLoading,
            isCreateFormValid,
            isPuttingDashboard,
            focusedDashboard,
            newDashboardDesc,
            newDashboardName,
            handlePutDashboard,
            startEditDashboard,
            isDeletingDashboard,
            startDeleteDashboard,
            loadViewState,
            handleDeleted,
        };
    },
});
