import { ISpendByTagResult, ISpendResult, ISpendAndConsumptionGroup } from '@/models';
import * as CssVariables from '@/lib/CssVariables';
import { ChartData, Point, Chart, ChartDataset } from 'chart.js/auto'
import { formatCurrency } from '@/lib/Currencies';
import { LEGACY_COLORS_RGB } from './legacy'
import './dayJsAdapter';
import htmlLegendPlugin from './legendPlugin';

const PREFIX_PAD = 100;

export const convertTagSpendResponseToDatasets = (tagSpendResult: ISpendResult<ISpendAndConsumptionGroup>): ChartData<'bar', Point[], string> => {
    const datasets: ChartDataset<'bar', Point[]>[] = [];
    let i = 0;
    for (const grouping of tagSpendResult.groupings) {
        const color = LEGACY_COLORS_RGB[i % LEGACY_COLORS_RGB.length];
        const data = grouping.usageRecords.map(r => ({
            x: new Date(r.usageDate).getTime(),
            y: r.charges
        }))
        datasets.push({
            data,
            label: grouping.name,
            backgroundColor: `rgb(${color})`,
            borderColor: 'white',
            borderWidth: 1,
            order: (PREFIX_PAD + i),
        });        
        i++;
    }
    return {
        labels: [],
        datasets
    };
};

export interface IClickEvent {
    index: number;
    name: string;
}

export interface IOptions {
    currencyCode: string;
    onClick?: (event: IClickEvent) => void;
}

export default (chartItem: HTMLCanvasElement, options: IOptions, containerId: string): Chart => {
    const { currencyCode, onClick } = options;
    const style = getComputedStyle(chartItem);
    const contrastLines = style.getPropertyValue(CssVariables.ContrastLinesVariable);
    const contrastText = style.getPropertyValue(CssVariables.ContrastTextVariable);
    const chart = new Chart(chartItem, {
        type: 'bar',
        data: {
            labels: [],
            datasets: []
        },
        options: {
            borderColor: 'rgba(0,0, 0, 0)',
            plugins: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                htmlLegend: {
                    // ID of the container to put the legend in
                        containerID: containerId,
                },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                    labels: {
                        color: contrastText
                    },
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            return [item.dataset.label];
                        },
                        afterLabel(item) {
                            const dataset = item.dataset;
                            const points: Point[] = dataset.data as any;
                            const hoverPoint: Point = points[item.dataIndex];
                            const datasetTotal = points.map((a: Point) => a.y).reduce((a, b) => a + b);
                            const datasetAverage = datasetTotal / dataset.data.length;
                            const index: (Point | undefined)[] = this.chart.config.data.datasets.map(ds => ds.data[item.dataIndex]);
                            const indexTotal = index.map(p => p?.y || 0).reduce((a, b) => a + b);
                            const percentageOfIndexTotal = (hoverPoint.y / (indexTotal || hoverPoint.y) * 100);
                            const percentageOfAverage = ((hoverPoint.y - datasetAverage) / datasetAverage * 100);
                            return [
                                `Total: ${formatCurrency(hoverPoint.y, currencyCode)}`,
                                `${percentageOfIndexTotal.toFixed(2)}% of period (${formatCurrency(indexTotal, currencyCode)})`,
                                `${percentageOfAverage > 0 ? '+' + percentageOfAverage.toFixed(2) : percentageOfAverage.toFixed(2)}% vs. average`
                            ]
                        },
                    },
                    mode: 'point'
                }
            },
            hover: {
                mode: 'dataset',

            },
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                intersect: false,
            },
            onClick(event, [element], chart) {
                if (!element) return;
                const dataset = chart.data.datasets[element.datasetIndex];
                if (!dataset) return;
                if (onClick) {
                    onClick({
                        index: dataset.order,
                        name: dataset.label
                    });
                }
            },
            scales: {
                x: {
                    type: "time",
                    stacked: true,
                    ticks: {
                        color: contrastText
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0)'
                    },
                    axis: 'x',
                    time: {
                        unit: 'month'
                    }
                },
                y: {
                    type: 'linear',
                    stacked: true,
                    ticks: {
                        color: contrastText,
                        callback(value: number) {
                            return formatCurrency(value, currencyCode);
                        }
                    },
                    grid: {
                        color: contrastLines
                    },
                    min: 0,
                    axis: 'y',
                    title: {
                        text: `Cost (${currencyCode})`,
                        color: contrastText,
                        display: true
                    },
                    stack: 'y',
                    display: true,
                }
            }
        },
        plugins: [htmlLegendPlugin]
    });
    return chart;
};