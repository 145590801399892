
import { defineStore } from 'pinia';
import { ref } from 'vue';

type AsyncWaitFunction = (...args: any) => Promise<any>;
type WaitFunction = (...args: any) => any;

export const useWaitStore = defineStore('wait', () => {
    const waits = ref<string[]>([]);
    const start = (key: string) => {
        if (!waits.value.includes(key))
            waits.value.push(key);
    }
    const stop = (key: string) => {
        const indexOf = waits.value.indexOf(key);
        if (indexOf !== -1)
            waits.value.splice(indexOf, 1);
    }
    const is = (key: string) => {
        return waits.value.includes(key);
    }
    const whilst = async <T extends WaitFunction | AsyncWaitFunction>(key: string, callback: T) => {
        if (typeof callback !== 'function') {
            console.warn('second argument must be a function');
            return callback;
        }
        try {
            start(key);
            return await callback();
        } finally {
            stop(key);
        }
    };
    return { is, start, stop, whilst, waits };
});