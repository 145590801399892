
import PrettyListItem from "@/components/Common/PrettyListItem.vue";
import { defineComponent } from "vue";

export interface IPrettyListItemViewModel {
  top: string;
  text: string;
  bottom: string;
  key: string;
}

export default defineComponent({
  props: { items: Array },
  components: { PrettyListItem },
  computed: {
    prettyListItems():IPrettyListItemViewModel[] {
        return this.items;
    }
  }
});
