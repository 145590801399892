<template>
    <v-container>
        <v-card flat>
            <v-card-title>{{ $t('billingDetails.title') }}</v-card-title>
            <v-card-text>
               <v-row align="center">
                    <v-col>
                        <v-alert outlined text :type="billingDetails.color" :icon="billingDetails.icon">{{ billingDetails.description }}</v-alert>
                    </v-col>
                </v-row>
                <plan-card v-if="!tenantStore.hasActiveSubscription && tenantStore.isSelfService && selfServiceEnabled"></plan-card>
            </v-card-text>
            <v-divider></v-divider>
            <v-container v-if="tenantStore.hasActiveSubscription">
                <v-row>
                    <v-col>
                        <v-card flat v-if="billingDetails.plan">
                            <v-card-title>Plan overview</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>Plan name</v-col>
                                    <v-col>{{ billingDetails.plan.planName }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col>Plan description</v-col>
                                    <v-col>{{ billingDetails.plan.planDescription }}</v-col>
                                </v-row>
                                <v-row v-if="billingDetails.plan.includedSpendInAud">
                                    <v-col>Included spend (AUD)</v-col>
                                    <v-col>
                                        {{ billingDetails.totalSpendInAud | currency('AUD')  }} of <strong>{{ billingDetails.plan.includedSpendInAud | currency('AUD') }}</strong>
                                        <consumption-bar :current-consumption="(billingDetails.totalSpendInAud / billingDetails.plan.includedSpendInAud) * 100" />
                                    </v-col>
                                </v-row>
                                <v-row v-if="billingDetails.plan.overagePercentage">
                                    <v-col>Overage charged at</v-col>
                                    <v-col>{{ billingDetails.plan.overagePercentage }}</v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat v-if="billingDetails.isSelfService && selfServiceEnabled">
                            <v-card-title>Update Details</v-card-title>
                            <v-card-text>
                                To change plans, updated your payment details, or cancel your subscription please use the link below.
                            </v-card-text>
                            <v-card-actions>
                                <v-btn text
                                       color="primary"
                                       @click="loadCustomerPortalSession"
                                       :loading="$wait.is('loadingSession')">
                                    Update Subscription
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<i18n>
    {
    "en": {
    "billingDetails.title": "Billing details"
    }
    }
</i18n>

<script>
    import ApiV2 from '@/lib/ApiV2';
    import { BillingDetailsStatus } from '@/lib/BillingDetailsStatus.ts';
    import { waitFor } from '@/plugins/vue-wait';
    import ConsumptionBar from '@/components/Common/ConsumptionBar.vue';
    import Config from '@/lib/Config';
    import PlanCard from '@/components/Account.SelfService/SelectPlanCard.vue';
    import { useTenantStore } from '../../stores/tenant.ts';
    import { mapStores } from 'pinia';

    export default {
        components: { ConsumptionBar, PlanCard },
        title(ctx) {
            return ctx.$t('billingDetails.title');
        },
        data: () => ({
            billingDetails: {},
            selfServiceEnabled: Config.isSelfServiceEnabled,
        }),
        mounted() {
            this.loadBillingStatus();
        },
        computed: {
            ...mapStores(useTenantStore)
        },
        methods: {
            loadBillingStatus: waitFor('loadBillingStatus', async function () {
                const billingStatusResponse = await ApiV2.http.get('/api/billing/details').then((r) => r.data);
                this.billingDetails = new BillingDetailsStatus(billingStatusResponse);
            }),
            async loadCustomerPortalSession() {
                this.$wait.start('loadingSession');
                const customerPortalSession = await ApiV2.http.get('/api/billing/selfservice/portal-session').then((r) => r.data);
                window.location = customerPortalSession.portalUrl;
            },
        },
    };
</script>

<style scoped>
</style>