<template>
    <set-azure-ea-credentials-dialog
        :value="value"
        @input="$emit('input', $event)"
        :loading="$wait.is('creatingAzureEaAccount')"
        :edit="edit"
        :disabled="{ ...(disabled || {}), Name: true, UseTenantCurrencies: true }"
    >
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #actions="{ form, formId, isValid, clear, close }">
            <put-credential-button
                type="EaPortalApiCredential"
                :value="{ ...edit, ...form }"
                :clear="clear"
                :valid="isValid"
                :form="formId"
                v-wait-while="'creatingAzureEaAccount'"
                @http2xx="acceptSubmit($event, close)"
            ></put-credential-button>
        </template>
    </set-azure-ea-credentials-dialog>
</template>

<script>
import SetAzureEaCredentialsDialog from '@/components/Settings.CloudAccounts/Azure/SetAzureEaCredentialsDialog.vue';
import PutCredentialButton from '@/components/Settings.CloudAccounts/PutCredentialButton.vue';
export default {
    components: { SetAzureEaCredentialsDialog, PutCredentialButton },
    props: {
        value: Boolean,
        edit: Object,
        disabled: Object,
    },
    methods: {
        async acceptSubmit($event, close) {
            await close();
            this.$emit('http2xx', $event);
        },
    },
};
</script>