<template>
    <fragment>
        <v-select
            :value="value"
            @input="$emit('input', $event)"
            label="Timezone"
            :items="items"
            item-text="name"
            item-value="value"
            :disabled="disabled"
        >
        </v-select>
    </fragment>
</template>

<script>
const timeZones = [
    { name: 'Greenwich Mean Time', value: 0 },
    { name: 'European Central Time', value: 1 },
    { name: 'Eastern European Time', value: 2 },
    { name: 'Eastern African Time', value: 3 },
    { name: 'Middle East Time', value: 3.5 },
    { name: 'Near East Time', value: 4 },
    { name: 'Pakistan Lahore Time', value: 5 },
    { name: 'India Standard Time', value: 5.5 },
    { name: 'Bangladesh Standard Time', value: 6 },
    { name: 'Vietnam Standard Time', value: 7 },
    { name: 'China Taiwan Time', value: 8 },
    { name: 'Japan Standard Time', value: 9 },
    { name: 'Australia Central Time', value: 9.5 },
    { name: 'Australia Eastern Time', value: 10 },
    { name: 'Solomon Standard Time', value: 11 },
    { name: 'New Zealand Standard Time', value: 12 },
    { name: 'Midway Islands Time', value: -11 },
    { name: 'Hawaii Standard Time', value: -10 },
    { name: 'Alaska Standard Time', value: -9 },
    { name: 'Pacific Standard Time', value: -8 },
    { name: 'Phoenix Standard Time', value: -7 },
    { name: 'Central Standard Time', value: -6 },
    { name: 'Eastern Standard Time', value: -5 },
    { name: 'Puerto Rico and US Virgin Islands Time', value: -4 },
    { name: 'Canada Newfoundland Time', value: -3.5 },
    { name: 'Argentina Standard Time', value: -3 },
    { name: 'Central African Time', value: -1 },
];
export default {
    props: {
        value: Number,
        disabled: Boolean,
    },
    data() {
        return { items: timeZones };
    },
};
</script>
