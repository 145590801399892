<template>
    <v-menu offset-y bottom>
        <template #activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item :to="`/edit/custom/reports/${value ? value.id : value}`">
                <v-list-item-icon>
                    <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('reportMenu.edit') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="`/clone/custom/reports/${value ? value.id : value}`">
                <v-list-item-icon>
                    <v-icon>content_copy</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('reportMenu.clone') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <delete-report-dialog
                v-model="isDeleting"
                :report="value"
                #default="{ on }"
                @http2xx="$emit('http2xx', $event)"
            >
                <v-list-item v-on="on">
                    <v-list-item-icon>
                        <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('reportMenu.delete') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </delete-report-dialog>
        </v-list>
    </v-menu>
</template>

<i18n>
{
    "en": {
        "reportMenu.edit": "Edit",
        "reportMenu.delete": "Delete",
        "reportMenu.clone": "Clone"
    }
}
</i18n>

<script>
import DeleteReportDialog from '@/components/Domain.Reports/DeleteReportDialog.vue';
export default {
    components: {
        DeleteReportDialog,
    },
    data() {
        return {
            isCloning: false,
            isDeleting: false,
            isUpdating: false,
        };
    },
    props: {
        value: Object,
    },
};
</script>