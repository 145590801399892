
import { computed, defineComponent } from 'vue';
import ConsumptionBar from '@/components/Common/ConsumptionBar.vue';
import DeleteBudgetDialog from '@/components/Domain.Budgets/DeleteBudgetDialog.vue';
import { IBudgetResult } from '@/models';
import { mapStores } from 'pinia';
import { useTenantStore } from '@/stores';

export default defineComponent({
    components: { ConsumptionBar, DeleteBudgetDialog },
    props: {
        items: Array,
        loading: Boolean,
        routePrefix: {
            type: String,
            default: '/budgets',
        },
    },
    setup(p) {
        const records = computed(() => {
            if (!p.items) return [];
            return p.items.map((b: IBudgetResult) => ({ ...b, isDeleting: false }));
        });
        return { records };
    },
    computed: {
        ...mapStores(useTenantStore),
        headers() {
            return [
                { text: this.$t('budgetsTable.name'), value: 'name' },
                { text: this.$t('budgetsTable.view'), value: 'view', width: '20%' },                
                { text: this.$t('budgetsTable.progress'), value: 'progress', align: 'center', width: '25%' },
                { text: this.$t('budgetsTable.amount'), value: 'amount', align: 'right' },
                { text: this.$t('budgetsTable.actual'), value: 'actual', align: 'right' },
                { text: this.$t('budgetsTable.projections'), value: 'projections', align: 'right' },
                { text: this.$t('budgetsTable.actions'), value: 'actions', align: 'center', width: '5 %' },
            ];
        },
    },
});
