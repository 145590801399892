
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    me: Object,
    value: String,
    loading: Boolean,
  },
  setup() {
    const tab: string = null;
    const tabs = [
      { key: "usage", icon: "insights", text: "views.dialogCloudProvider.tabs.usage" },
    ];
    return { tab, tabs };
  },
});
