<template>
    <form-dialog :value="value" @input="$emit('input', $event)" #default="{ close }">
        <set-user-card :loading="$wait.is('creatingOrUpdatingUser')" :value="userToEdit">
            <template #actions="{ form, formId, isValid, clear }">
                <back-button @click="close()"></back-button>
                <v-spacer></v-spacer>
                <add-user-button v-if="!userToEdit" :value="form" :clear="clear" :valid="isValid" :form="formId"
                    :prefix="prefix" v-wait-while="'creatingOrUpdatingUser'" @http2xx="close().then(getUsers)">
                </add-user-button>
                <update-user-button v-else :value="form" :clear="clear" :valid="isValid" :form="formId" :prefix="prefix"
                    v-wait-while="'creatingOrUpdatingUser'" @http2xx="close().then(getUsers)">
                </update-user-button>
            </template>
        </set-user-card>
    </form-dialog>
</template>


<script>

import SetUserCard from '@/components/Settings.Users/SetUserCard.vue';
import AddUserButton from '@/components/Settings.Users/AddUserButton.vue';
import UpdateUserButton from '@/components/Settings.Users/UpdateUserButton.vue';

export default {
    props: {
        value: { type: Boolean },
        userToEdit: { type: Object, default: null },
        prefix: String
    },
    components: {
        SetUserCard,
        AddUserButton,
        UpdateUserButton
    },
    methods: {
        getUsers() {
            this.$emit('http2xx');
        }
    }

}
</script>