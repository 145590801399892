
import { defineComponent, ref } from 'vue';
import ApiV2 from '@/lib/ApiV2';
import { ISearchEntityViewModel } from '@/components/Common/EntitySearchAutocomplete.vue';
import { EntityType } from '@/models';
import { toastError, toastSuccess } from '@/components/Common/Toast.vue';

export default defineComponent({
    props: { value: Boolean },
    setup(_, { emit }) {
        const searchEntity = ref<ISearchEntityViewModel | null>(null);
        const monthlySpendLimit = ref<number | null>(null);
        const valid = ref(false);
        const loading = ref(false);
        const submitFn = async (resetFn: () => void, closeFn: () => Promise<void>) => {
            try {
                if (!searchEntity.value) return;
                if (!monthlySpendLimit.value) return;

                let url: string | null = null;
                switch (searchEntity.value.Type) {
                    case EntityType.product:
                        url = `/api/metadata/products/${searchEntity.value.Id}`;
                        break;
                    case EntityType.service:
                        url = `/api/metadata/services/${searchEntity.value.Id}`;
                        break;
                    case EntityType.subscription:
                        url = `/api/metadata/subscriptions/${searchEntity.value.Id}`;
                        break;
                    case EntityType.tag:
                        url = `/api/metadata/tagkeyvalue/${searchEntity.value.Id}`;
                        break;
                    default:
                        return;
                }
                loading.value = true;
                await ApiV2.http.patch(url, [
                    { op: 'replace', path: 'monthlySpendLimit', value: monthlySpendLimit.value },
                ]);
                toastSuccess(`Successfully updated the the spend limit for ${searchEntity.value.Name}`);
                resetFn();
                emit('http2xx');
                await closeFn();
            } catch (err) {
                toastError('There was an unexpected error creating this spend limit.');
            } finally {
                loading.value = false;
            }
        };
        return { valid, loading, searchEntity, monthlySpendLimit, submitFn };
    },
});
