
import { defineComponent, ref, watch } from 'vue';
import {
    calculateOptionsDescription,
    convertSpendOptionsToQuery,
} from '../Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import SpendUsageLineChart, {
    convertProjectionsResponseToDataset,
    convertUsageResponseToDatasets,
    convertEventsToAnnotations,
    UsageReportTypes,
} from '../Domain.Usage/SpendUsageLineChart.vue';
import { useFilterStore } from '@/stores';
import ApiV2 from '@/lib/ApiV2';
import { getSpend, getForecast } from '@/lib/Api';
import { ChartDataset } from 'chart.js';
import { generateSpendUsageOptionsFromReport, ISavedReportResultParams, SpendFieldNames } from '@/models';
export default defineComponent({
    props: { config: Object, name: String, hideActions: Boolean },
    components: { SpendUsageLineChart },
    setup(props, { emit }) {
        const filterStore = useFilterStore();

        const usageOptions = ref({
            ...filterStore.options,
        });

        watch(
            () => props.config,
            (opt) => {
                if (!opt) return;
                const params = generateSpendUsageOptionsFromReport(opt as ISavedReportResultParams);
                usageOptions.value = params;
            },
            { immediate: true, deep: true }
        );

        const handleDatasetClicked = () => {
            //emit('onEdit');
        };

        let maxDate: string = undefined;
        const newChartData = ref({
            labels: [] as string[],
            datasets: [] as ChartDataset<'bar', []>[],
        });

        const annotations = ref([]);
        const loadUsage = async (toTrends: (searchParams: URLSearchParams) => void) => {
            const params = convertSpendOptionsToQuery(usageOptions.value);
            if (!params) return;
            const [usageResponse, projectionsResponse, spikeEventsResponse] = await Promise.all([
                getSpend({ ...params, segregateBy: usageOptions.value.segregateBy, fields: [SpendFieldNames.Charges] }),
                getForecast(params, usageOptions.value.dateRangeKey),
                ApiV2.http
                    .get(`/api/events?eventType=SpikeEventAlert`, { params })
                    .catch((err) => {
                        if (err.response.status === 400) return { data: {} };
                        throw err;
                    })
                    .then((r) => r.data),
            ]);
            const projectionDataset = convertProjectionsResponseToDataset(projectionsResponse, params.granularity);
            const usageDataset = convertUsageResponseToDatasets(usageResponse, {
                reportType: UsageReportTypes.charges,
            });
            const annotationResults = convertEventsToAnnotations(spikeEventsResponse, (xMin, xMax) => {
                const toDate = new Date(xMax);
                const numDaysBetween = Math.ceil((xMax - xMin) / (1000 * 3600 * 24));
                const period = `${numDaysBetween}d`;
                const segregateBy = usageOptions.value.segregateBy === 'services' ? 'services' : 'products';
                const searchParams = new URLSearchParams({
                    toDate: toDate.toISOString(),
                    period,
                    segregateBy,
                });
                toTrends(searchParams);
            });
            newChartData.value = {
                labels: [...projectionDataset.labels, ...usageDataset.labels],
                datasets: [...projectionDataset.datasets, ...usageDataset.datasets],
            } as any;
            maxDate = projectionsResponse?.maxUsageDate || params.toDate;
            annotations.value = annotationResults;
        };

        return {
            usageOptions,
            handleDatasetClicked,
            annotations,
            newChartData,
            loadUsage,
            maxDate
        };
    },
    methods: {
        toTrends(searchParams: URLSearchParams) {
            this.$router.push(`/trends?${searchParams.toString()}`);
        },
    },
    watch: {
        usageOptions: {
            handler() {
                this.loadUsage(this.toTrends);
            },
            immediate: true,
        },
    },
    computed: {
        chartTitle() {
            return this.name || calculateOptionsDescription(this.usageOptions, (...params) => this.$t(...params));
        },
    },
});
