<template>
    <v-card flat>
        <v-card-title primary-title>
            <cloud-provider-type-icon size="20" left :value="value.cloudProviderType"></cloud-provider-type-icon>
            <h3 class="title flex-grow-1">
                {{ value.name }}
                <v-icon
                    v-for="i in (value.environmentalImpact || 0) + 1"
                    :key="i"
                    color="green"
                    :title="`${impactNames[value.environmentalImpact]} Environmental Impact`"
                >
                    eco
                </v-icon>
            </h3>
            <span class="text-right">
                <template
                    v-if="
                        value.hasResultsToShow &&
                        (value.maximumEstimateImpact > 0 || value.maximumEstimateImpactEmissions > 0)
                    "
                >
                    {{ value.maximumEstimateImpact | currency(currencyCode) }}
                    {{ value.maximumEstimateImpact > 0 && value.maximumEstimateImpactEmissions > 0 ? '/ ' : '' }}
                    {{
                        value.maximumEstimateImpactEmissions > 0
                            ? value.maximumEstimateImpactEmissions.toFixed(3) + 't CO2e'
                            : ''
                    }}
                </template>
                <template v-if="value.hasResultsToShow && !value.maximumEstimateImpact">
                    <div class="d-flex green--text text-xs-right text-no-wrap pl-1">
                        <span class="amber--text text--darken-3">{{ value.numberOfResults }} Recommendations</span>
                        <v-icon class="amber--text text--darken-3 ml-2">emoji_objects</v-icon>
                    </div>
                </template>
                <template v-else>
                    <v-icon :color="iconColor" class="pl-1">check_circle_outline</v-icon>
                </template>
            </span>
            <div class="v-expansion-panel-header__icon">
                <v-icon style="position: relative; left: 32px; margin-left: -32px">keyboard_arrow_down</v-icon>
            </div>
        </v-card-title>

        <v-card-text class="pt-0">
            {{ value.description }}
            <a v-if="value.detailsUrl" :href="value.detailsUrl" target="_blank">Learn more</a>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: Object,
        currencyCode: String,
    },
    data() {
        return { impactNames: { 0: 'Low', 1: 'Medium', 2: 'High' } };
    },
    computed: {
        iconColor() {
            return this.value.numberOfResults > 0 ? 'green' : 'grey';
        },
    },
};
</script>
