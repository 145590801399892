
import { getApiKeys } from '@/lib/Api';
import GenerateApiKeyCard from '@/components/Account.ApiManagement/GenerateApiKeyCard.vue';
import GenerateApiKeyButton from '@/components/Account.ApiManagement/GenerateApiKeyButton.vue';
import DeleteApiKeyButton from '@/components/Account.ApiManagement/DeleteApiKeyButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
    title(ctx) {
        return ctx.$t('apiManagement.title');
    },
    components: {
        GenerateApiKeyCard,
        GenerateApiKeyButton,
        DeleteApiKeyButton,
    },
    data() {
        return {
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
            loading: true,
            dialogs: {
                showCreateKey: false,
                showDeleteKey: false,
            },
            apiKeys: [],
            profiles: [],
            generatedKey: null,
            keyToDelete: null,
        };
    },
    methods: {
        setGenerated(data) {
            this.generatedKey = data.key;
        },
        removeKey(keyId) {
            this.keyToDelete = keyId;
            this.dialogs.showDeleteKey = true;
        },
        showGenerateApiKey() {
            this.dialogs.showCreateKey = true;
        },
        async successfulGenerate() {
            this.generatedKey = null;
            await this.loadKeys();
        },
        async successfulDelete() {
            toastSuccess(this.$t('apiManagement.deletedKeyToast'));
            this.keyToDelete = null;

            await this.loadKeys();
        },
        async loadKeys() {
            this.loading = true;

            try {
                const tenantId = this.tenantStore.tenantId;
                this.apiKeys = await getApiKeys()
                    .then((r) => r.filter((a) => a.tenantId === tenantId));
            } finally {
                this.loading = false;
            }
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('apiManagement.table.referenceColumn'),
                    value: 'reference',
                    sortable: true,
                },
                {
                    text: this.$t('apiManagement.table.permissions'),
                    value: 'permissions',
                    sortable: true,
                },
                {
                    text: this.$t('apiManagement.table.creationDateColumn'),
                    value: 'creationDate',
                    sortable: true,
                },
                {
                    text: this.$t('apiManagement.table.createdByEmail'),
                    value: 'createdByAccountEmail',
                    sortable: true
                },
                {
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
        ...mapStores(useTenantStore)
    },
    async mounted() {
        await this.loadKeys();
    },
});
