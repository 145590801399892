
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import ServiceComparisonsTable from '@/components/Domain.Services/ServiceComparisonsTable.vue';
import ProductComparisonsTable from '@/components/Domain.Products/ProductComparisonsTable.vue';
import UsageComparisonOptionsToolbar from '@/components/Domain.Usage/UsageComparisonOptionsToolbar.vue';
import { defineComponent, ref } from 'vue';
import { useTenantStore } from '../../stores/tenant';
import { EntityType, ITagMetadataResult } from '@/models';
import { IChipViewModel } from '@/components/Domain.Usage/UsageFilterChips.vue';
import AdditionalFiltersNotSupported from '@/views/Dialog.Tag/AdditionalFiltersNotSupported.vue';

export default defineComponent({
    computed: {
        EntityType() {
            return EntityType
        }
    },
    components: { AdditionalFiltersNotSupported, ServiceComparisonsTable, ProductComparisonsTable, UsageComparisonOptionsToolbar },
    props: {
        value: Object,
        loading: Boolean,
    },
    setup(props) {
        const filters = ref<IChipViewModel[]>([]);
        const comparisons = ref([]);
        const configuration = ref({ segregateBy: 'services', period: 'month', toDate: new Date() });
        const tagKeyValue = ref<ITagMetadataResult>();
        const loadSpendComparisons = waitFor('loadSpendComparisons', async function loadSpendComparisonsFn() {
            if (!props.value) return;
            tagKeyValue.value = props.value as ITagMetadataResult;
            const params = {
                tagKeyValue: tagKeyValue.value.tagKeyValue,
                orderBy: 'delta',
                period: configuration.value.period,
                toDate: configuration.value.toDate.toISOString(),
            };
            const response = await ApiV2.http
                .get(`/api/cost-explorer/${configuration.value.segregateBy}`, { params })
                .then((r) => r.data);
            comparisons.value = response.entities;
        });
        const tenantStore = useTenantStore();

        return { comparisons, configuration, filters, loadSpendComparisons, tagKeyValue, tenantStore };
    },
    watch: {
        $route: {
            handler(url) {
                url.query.tagKeyValues?.split(',').forEach((tkv : string) => {
                    this.filters.push({ type: EntityType.tag, name: tkv, id: tkv });
                });
            },
            immediate: true,
            deep: true,
        },
        value: {
            handler(val) {
                if (!val) return;
                this.loadSpendComparisons();
            },
            immediate: true,
        },
    },
});
