
import Downloader from '@/lib/Downloader';
import moment from '@/lib/moment';
import { IAllTagsRequestResult, ICO2EByTagResult, ITagDimension } from '@/models';

export const convertAllTagsResultToLookup = (result: IAllTagsRequestResult): { [tagId: string]: string } => {
    return result.data.reduce((obj, item) => {
        obj[`${item.key}:${item.value}`] = item.id;
        return obj;
    }, {});
};

export const convertCarbonByTagResultToDimensions = (result: ICO2EByTagResult): ITagDimension[] => {
    if (!result?.data?.length) return [];
    return result.data[0].rowDimensions;
};

export interface ITagCarbonTableItemViewModel {
    tagKey1: ITagDimension;
    tagKey2: ITagDimension | null;
    tagKey3: ITagDimension | null;
    co2e: { [usageKey: string]: number };
    kwH: { [usageKey: string]: number };
}

export const convertCarbonByTagResultToItems = (result: ICO2EByTagResult): ITagCarbonTableItemViewModel[] => {
    if (!result?.data?.length) {
        return [];
    }
    const rowMap = new Map<string, ITagCarbonTableItemViewModel>();
    result.data.forEach((item) => {
        const usageKey = item.usageDate.substring(0, 7);
        const rowKey = ''.concat(
            item.rowDimensions[0].tagValue,
            item.rowDimensions[1] != null ? item.rowDimensions[1].tagValue : '_null',
            item.rowDimensions[2] != null ? item.rowDimensions[2].tagValue : '_null'
        );
        const co2e = item.co2e;
        const kwH = item.kwH;
        if (!rowMap.has(rowKey)) {
            const row1: ITagCarbonTableItemViewModel = {
                tagKey1: item.rowDimensions[0],
                tagKey2: item.rowDimensions[1] != null ? item.rowDimensions[1] : null,
                tagKey3: item.rowDimensions[2] != null ? item.rowDimensions[2] : null,
                co2e: { [usageKey]: co2e },
                kwH: { [usageKey]: kwH },
            };
            rowMap.set(rowKey, row1);
        } else {
            const row = rowMap.get(rowKey);
            row.co2e[usageKey] = co2e;
            row.kwH[usageKey] = kwH;
        }
    });
    return [...rowMap.entries()].map(([, v]) => v);
};

export default {
    props: {
        items: Array,
        dimensions: Array,
        currencyCode: String,
        loading: Boolean,
    },
    computed: {
        usageHeaders() {
            return this.headers.filter((h) => !h.value.startsWith('tagKey'));
        },
        headers() {
            if (!this.dimensions || !this.items) return [];

            const headers = [];
            this.dimensions.forEach((d: ITagDimension | undefined | null, i: number) => {
                if (d) {
                    headers.push({
                        text: d.tagKey,
                        value: `tagKey${i + 1}`,
                        sortable: true,
                        index: new Date(-8640000000000000 + i),
                    });
                }
            });

            const headerMap = new Set();
            this.items.forEach((item: ITagCarbonTableItemViewModel) => {
                Object.keys({ ...item.co2e, ...item.kwH }).forEach((key) => {
                    if (!headerMap.has(key)) {
                        headerMap.add(key);
                        headers.push({
                            text: key,
                            value: key,
                            sortable: false,
                            index: moment.utc(key).toDate(),
                        });
                    }
                });
            });
            return headers.sort((a, b) => a.index - b.index);
        },
    },
    methods: {
        exportCsv() {
            const headerValues: string[] = this.headers.map((i) => i.value);
            const csvValues: string[][] = this.items.map((record: ITagCarbonTableItemViewModel) =>
                headerValues.map((header: string) => {
                    if (header.startsWith('tagKey')) {
                        const dimension = record[header] as ITagDimension;
                        return dimension.tagValue;
                    } else {
                        return record.co2e[header];
                    }
                })
            );
            csvValues.unshift(this.headers.map((i) => i.text));
            Downloader.exportCSV(csvValues, `TagOverview.csv`);
        },
    },
};
