var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"loading":_vm.$wait.is('loadingClusterSpend'),"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.loading)?_c('div',[_c('v-icon',{attrs:{"left":""}},[_vm._v("query_builder")]),_vm._v("Waiting for query")],1):_c('div',[_c('v-icon',{attrs:{"color":"warning","left":""}},[_vm._v("warning")]),_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t("dashboard.namespaces.nodata")))])],1)]},proxy:true},{key:`item.namespace`,fn:function({ item }){return [_c('router-link',{attrs:{"to":`/kubernetes/tagkeys/${encodeURIComponent(
        '$KubernetesNamespace'
                )}/tag/${encodeURIComponent(
        '$KubernetesNamespace'
                )}:${encodeURIComponent(
                    item.namespace.toLowerCase()
                )}?tagKeyValues=${encodeURIComponent(
        '$KubernetesCluster'
                )}:${encodeURIComponent(
                    item.cluster.toLowerCase()
                )}`}},[_vm._v(_vm._s(item.namespace))])]}},{key:`item.total`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total,_vm.currencyCode))+" ")]}},{key:`body.append`,fn:function(){return [_c('tr',[_c('th',{staticClass:"font-weight-bold"},[_vm._v("Totals")]),_c('th',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("currency")(_vm.totalSpend,_vm.currencyCode)))])])]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }