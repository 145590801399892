
import { useWaitStore, useTenantStore } from '@/stores';
import { defineComponent, ref, computed } from 'vue';
import { mapStores } from 'pinia';
import * as Api from '@/lib/Api';
import { IBudgetResult, ISpendLimitMetaResult } from '@/models';
import BudgetsTable from '@/components/Domain.Budgets/BudgetsTable.vue';
import CreateBudgetDialog from '@/components/Domain.Budgets/CreateBudgetDialog.vue';
import SpendLimitsTable from '@/components/Domain.SpendLimits/SpendLimitsTable.vue';
import SetSpendLimitSelectorDialog from '@/components/Domain.SpendLimits/SetSpentLimitSelectorDialog.vue';

export default defineComponent({
    components: { BudgetsTable, CreateBudgetDialog, SpendLimitsTable, SetSpendLimitSelectorDialog },
    title(ctx) {
        return ctx.tenantStore.hasTenantFeature('Budgets')
            ? ctx.$t('budgets.basl_title')
            : ctx.$t('budgets.sl_title');
    },
    computed: {
        ...mapStores(useTenantStore),
        pageTitle() {
            return this.tenantStore.hasTenantFeature('Budgets')
            ? this.$t('budgets.basl_title')
            : this.$t('budgets.sl_title');
        }
    },
    created() {
        this.loadBudgets(this.tenantStore.hasTenantFeature('Budgets'));
        this.loadSpendLimits();
    },
    setup() {
        const { is, whilst } = useWaitStore();
        const loading = ref(false);
        const isCreating = ref(false);
        const isCreatingLimit = ref(false);
        const budgets = ref<IBudgetResult[]>([]);
        const spendLimits = ref<ISpendLimitMetaResult[]>([]);
        const loadBudgets = async (hasPermission) => {
            if(hasPermission === false) return;
            await whilst('gettingBudgets', async function () {
                budgets.value = await Api.getAllBudgets();
            });
        };
        const loadSpendLimits = async () => {
            await whilst('gettingSpendLimits', async function () {
                spendLimits.value = await Api.getAllSpendLimits();
            });
        };                
        const totalProjectedAbove = computed(() => {
            return (
                budgets.value.filter((l) => l.projectedPercentage >= 100).length +
                spendLimits.value.filter((l) => l.projectedPercentage >= 100).length
            );
        });
        const totalActuallyAbove = computed(() => {
            return (
                budgets.value.filter((l) => l.currentPercentage >= 100).length +
                spendLimits.value.filter((l) => l.currentPercentage >= 100).length
            );
        });
        return {
            budgets,
            spendLimits,
            loadBudgets,
            loadSpendLimits,
            wait: { is },
            totalProjectedAbove,
            totalActuallyAbove,
            loading,
            isCreating,
            isCreatingLimit
        };
    },
    methods: {
        handleDialogClose(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push('/budgets');
            this.$title = this.tenantStore.hasTenantFeature('Budgets')
            ? this.$t('budgets.basl_title')
            : this.$t('budgets.sl_title');
        },
    },
});
