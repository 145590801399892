
import { defineComponent, ref } from 'vue';
import { useTenantStore, useWaitStore } from '@/stores';
import { mapStores } from 'pinia';
import { listPolicies, listAllResults, getPolicySummary } from '@/lib/Api';
import PolicyMenu from '@/components/Domain.Policies/PolicyMenu.vue';

type Severity = 'Critical' | 'Warning' | 'Information';

interface IRecentResult {
    id: number;
    policyName: string;
    policyId: number;
    count: number;
    severity: Severity;
    lastRun: Date;
}

interface IPolicyResult {
    id: number;
    policyName: string;
    dimension: string;
    severity: Severity;
    schedule: string;
    lastRun?: Date;
    lastRunRecordCount: number;
}

export default defineComponent({
    title(ctx) {
        return ctx.$t('watchdogDashboard.title');
    },
    components: { PolicyMenu },
    computed: {
        ...mapStores(useTenantStore),
    },
    setup() {
        const { is } = useWaitStore();
        const criticalCount = ref(0);
        const warningCount = ref(0);
        const policiesActive = ref(0);
        const recentResultCount = ref(0);
        const policies = ref<IPolicyResult[]>([]);
        const loadPageData = async () => {
            const [policiesResp, resultsResp, summaryResp ] = await Promise.all([
                listPolicies(),
                listAllResults(),
                getPolicySummary(),
            ]);
            policies.value = policiesResp.map((p) => ({
                id: p.id,
                severity: p.severity as Severity,
                policyName: p.name,
                dimension: p.dimension.label,
                schedule: p.schedule?.description || 'Unknown',
                lastRun: p.lastRun,
                lastRunRecordCount: p.lastRunRecordCount
            }));
            criticalCount.value = summaryResp.totalCritical;
            policiesActive.value = summaryResp.totalPolicies;
            warningCount.value = summaryResp.totalWarning;
            recentResultCount.value = summaryResp.totalResultCount;

        };
        loadPageData();
        return {
            criticalCount,
            warningCount,
            policiesActive,
            policies,
            recentResultCount,
            loadPageData,
            wait: { is },
        };
    },
});
