
import Config from '@/lib/Config';
import * as M from '@/models';
import { ref, watch, computed } from 'vue';
export default {
    props: {
        loading: Boolean,
        value: Object,
        disabled: Object,
    },
    setup(p) {
        const valid = ref(false);
        const name = ref('');
        const useTenantCurrencies = ref(false);
        const organizationName = ref('');
        const accountName = ref('');
        const userName = ref('');
        const password = ref('');
        const docsUrl = ref(Config.get().DOCS_URL);

        watch(
            p.value,
            (v: M.ICloudAccountModel | null) => {
                if (!v) return;
                name.value = v.name;
                useTenantCurrencies.value = v.useTenantCurrencies || false;
                organizationName.value = v.snowflakeDirectCredential?.clientDomain || '';
                accountName.value = v.snowflakeDirectCredential?.container || '';
                userName.value = v.snowflakeDirectCredential?.providerId || '';
                password.value = v.snowflakeDirectCredential?.token || '';
                return true;
            },
            { immediate: true, deep: true }
        );

        const formData = computed(() => ({
            Name: name.value,
            UseTenantCurrencies: useTenantCurrencies.value,
            SnowflakeDirectCredential: {
                Token: password.value,
                ClientDomain: organizationName.value,
                Container: accountName.value,
                ProviderId: userName.value
            },
        }));

        return {
            valid,
            name,
            useTenantCurrencies,
            organizationName,
            accountName,
            userName,
            password,
            docsUrl,
            formData,
        };
    }
};
