<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="items"
    item-key="id"
    :items-per-page.sync="itemsPerPage"
    :sort-by.sync="sortedBy"
    :sort-desc.sync="sortedDesc"
  >
    <template #[`item.Actions`]="{ item }">
      <slot name="item.menu" :item="item"></slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["items", "loading"],
  data() {
    return {
      sortedBy: "Name",
      sortedDesc: true,
      itemsPerPage: 10,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "fullName",
          sortable: true,
        },
        {
          text: this.$t("email"),
          value: "email",
          sortable: true,
        },
        {
          text: this.$t('actions'),
          value: "Actions",
          sortable: false
        }
      ];
    },
  },
};
</script>

<style scoped>
.v-data-table
  /deep/
  tbody
  /deep/
  tr:hover:not(.v-data-table__expanded__content) {
  background: transparent !important;
}
</style>