import { ICloudAccountNavigation } from '@/stores/models/ICloudAccountNavigation';
import { defineStore } from 'pinia';

export const useCloudAccountNavigationStore = defineStore({
    id: 'cloudAccountNavigation',
    state: (): ICloudAccountNavigation => ({
        taxonomyType: null,
        tenantId: null,
        cloudAccountId: null
    }),     
    actions: {
        updateNavigationSource(navigationDetails :ICloudAccountNavigation) {          
            this.taxonomyType = navigationDetails.taxonomyType;
            this.tenantId = navigationDetails.tenantId;
            this.cloudAccountId = navigationDetails.cloudAccountId;
        }
    },
    persist: true      
});