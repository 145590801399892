<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event ? $event.code : null)"
    :items="matchingS3Regions"
    item-text="name"
    item-value="code"
    :loading="loading"
    :search-input.sync="search"
    :rules="rules"
    :hint="!value ? $t('s3RegionAutocomplete.noRegionHint') : undefined"
    persistent-hint
    hide-no-data
    hide-selected
    :label="label"
    return-object
  >
    <template #prepend-inner>
      <v-icon v-if="!loading">search</v-icon>
      <v-progress-circular
        v-else
        :width="3"
        size="24"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </template>
  </v-autocomplete>
</template>

<i18n>
{
    "en": {
        "s3RegionAutocomplete.noRegionHint": "Please specify if your bucket is in a Chinese region."
    }
}
</i18n>

<script>
export const S3_REGIONS = [
  { code: "", name: "AWS Global" },
  { code: "cn-north-1", name: "China (Beijing)" },
  { code: "cn-northwest-1", name: "China (Ningxia)" },
];

export default {
  props: ["value", "label", "rules"],
  computed: {
    matchingS3Regions() {
      if (!this.search) return this.allRegions;
      return [ this.search ]
        .concat(this.allRegions.filter((b) => b.name.startsWith(this.search)))
        .filter((value, index, self) => {
          return self.findIndex(c => c.code == value.code) === index;
        });
    },
  },
  data() {
    return {
      allRegions: [...S3_REGIONS],
      errored: false,
      loading: false,
      search: "",
    };
  },
};
</script>
