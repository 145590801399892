<template>
    <v-form
        :value="value ? value.valid : true"
        @input="$emit('input', { ...(value || {}), valid: $event })"
        class="mt-3"
    >
        <v-skeleton-loader :loading="loading" type="list-item@6">
            <template #default>
                <empty-state
                    v-if="!value || !value.data"
                    icon="warning"
                    message="There was an error processing this form."
                ></empty-state>
                <empty-state v-else-if="!value.data.length" icon="warning" :message="$t('tagForm.noTags')">
                    <v-btn color="secondary" @click="addNewTag()" v-if="!hideCreate">
                        <v-icon left>add</v-icon>
                        {{ $t('tagForm.newTag') }}
                    </v-btn>
                </empty-state>
                <fragment v-else>
                    <v-row v-for="(tag, i) in value.data" :key="i">
                        <v-col cols="5">
                            <tag-key-autocomplete
                                v-model="tag.Key"
                                @input="$emit('input', value)"
                                :disabled="
                                    !tag.Sources ||
                                    !tag.Sources.length ||
                                    tag.Sources.length > 1 ||
                                    tag.Sources[0] !== 'CloudControl'
                                "
                            >
                            </tag-key-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <tag-value-autocomplete
                                v-model="tag.Value"
                                @input="$emit('input', value)"
                                :tag-key="tag.Key"
                                :disabled="
                                    !tag.Sources ||
                                    !tag.Sources.length ||
                                    tag.Sources.length > 1 ||
                                    tag.Sources[0] !== 'CloudControl'
                                "
                            >
                            </tag-value-autocomplete>
                        </v-col>
                        <v-col cols="1">
                            <v-btn
                                icon
                                @click="deleteTag(tag, i)"
                                class="mt-3"
                                color="error"
                                :disabled="
                                    !tag.Sources ||
                                    !tag.Sources.length ||
                                    tag.Sources.length > 1 ||
                                    tag.Sources[0] !== 'CloudControl'
                                "
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5">
                            <v-btn color="secondary" text block @click="addNewTag()" v-if="!hideCreate">
                                <v-icon left>add</v-icon>
                                {{ $t('tagForm.newTag') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <slot></slot>
                        </v-col>
                    </v-row>
                </fragment>
            </template>
        </v-skeleton-loader>
    </v-form>
</template>

<i18n>
{
    "en": {
        "tagForm.tagKey.title": "Key",
        "tagForm.tagValue.title": "Value",
        "tagForm.newTag": "New Tag",
        "tagForm.noTags": "You do not currently have any tags here"
    }
}
</i18n>

<script>
import TagKeyAutocomplete from '@/components/Domain.Tags/TagKeyAutocomplete.vue';
import TagValueAutocomplete from '@/components/Domain.Tags/TagValueAutocomplete.vue';
export default {
    components: { TagKeyAutocomplete, TagValueAutocomplete },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => ({
                valid: false,
                data: [],
            }),
        },
        hideCreate: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        addNewTag() {
            if (!this.value || !this.value.data) return;
            this.value.data.push({ Key: '', Value: '', Sources: ['CloudControl'] });
            this.$emit('input', this.value);
        },
        deleteTag(tag, index) {
            if (!this.value || !this.value.data) return;
            this.value.data = this.value.data.filter((_, i) => i !== index);
            this.$emit('input', this.value);
        },
    },
};
</script>