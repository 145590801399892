
import { defineComponent, ref } from 'vue';
import { IRegionCO2EResult, IRegionResult } from '@/models';

export const convertRegionResultsToViewItems = (
    emissions: IRegionCO2EResult,
    regions: IRegionResult[]
): IRegionTableItemViewModel[] => {
    const regionTotals = emissions.data.reduce((agg, val) => {
        const key = `${val.providerType}:${val.regionName}`.toUpperCase();
        agg[key] = { co2e: val.co2e, kwH: val.kwH };
        return agg;
    }, {});
    return regions.map((r) => {
        let total = regionTotals[r.id];
        if (!total) total = { kwH: 0, co2e: 0 };
        return {
            ...r,
            currentMonthCO2e: total.co2e,
            currentMonthKwH: total.kwH,
        };
    }).filter(r => r.currentMonthCO2e > 0);
};

export interface IRegionTableItemViewModel {
    geography: string;
    name: string;
    currentMonthCO2e: number;
    currentMonthKwH: number;
    providerType: string;
}

export default defineComponent({
    props: {
        items: Array,
        loading: Boolean,
    },
    setup() {
        const sortedBy = ref('currentMonthCO2e');
        const sortedDesc = ref(true);
        const itemsPerPage = ref(10);
        const searchText = ref<string | null>(null);
        return { sortedBy, sortedDesc, itemsPerPage, searchText };
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('regionsCarbonTable.geography.title'),
                    value: 'geography',
                    sortable: true,
                },
                {
                    text: this.$t('name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('regionsCarbonTable.currentMonthCO2e.title'),
                    value: 'currentMonthCO2e',
                    sortable: true,
                },
                {
                    text: this.$t('regionsCarbonTable.currentMonthKwH.title'),
                    value: 'currentMonthKwH',
                    sortable: true,
                },
                {
                    text: this.$t('regionsCarbonTable.currentMonthIntensity.title'),
                    value: 'currentMonthIntensity',
                    sortable: false,
                },
                {
                    text: this.$t('regionsCarbonTable.providerType.title'),
                    value: 'providerType',
                    sortable: true,
                },
            ],
        };
    },
});
