
import Config from '@/lib/Config';
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            name: '',
            useTenantCurrencies: false,
            tenancyId: '',
            userId: '',
            regionId: '',
            fingerprint: '',
            pemText: '',
            docsUrl: Config.get().DOCS_URL,
        };
    },
    methods: {
        async handleJsonCredentialsFile(file: File) {
            if (!file) return;
            this.pemText = await file.text();
        },
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                if (val.oracleUsageCredential) {
                    this.tenancyId = val.oracleUsageCredential.providerId;
                    this.pemText = val.oracleUsageCredential.token;
                    this.fingerprint = val.oracleUsageCredential.container;
                    this.userId = val.oracleUsageCredential.clientDomain;
                    this.regionId = val.oracleUsageCredential.regionCode;
                } else {
                    this.tenancyId = '';
                    this.pemText = '';
                    this.fingerprint = '';
                    this.userId = '';
                    this.regionId = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
