
import { defineComponent, ref } from 'vue';
import { getAllTagMappings, createTagMapping, deleteTagMapping, updateTagMapping, reorderTagMappings } from '@/lib/Api';
import * as M from '@/models';
import TagMappingFormCardContent from '@/components/Domain.Tags/TagMappingFormCardContent.vue';
import TagMappingHeaderToolbar from '@/components/Domain.Tags/TagMappingHeaderToolbar.vue';
import { toastError, toastSuccess } from '@/components/Common/Toast.vue';

export default defineComponent({
    components: { TagMappingFormCardContent, TagMappingHeaderToolbar },
    setup() {
        const panelsState = ref(null);
        const mappings = ref<M.IConditionalActionRuleResult[]>([]);
        const mappingValidity = ref<{ [id: string]: boolean }>({});
        const isSubmitting = ref(false);
        const isLoading = ref(true);
        const isCreatingNewMapping = ref(false);
        const isCreatingFullscreen = ref(false);
        const newMapping = ref<M.IConditionalActionRuleResult | null>(null);
        const newMappingName = ref('New Rule');
        const isNewMappingValid = ref(false);
        const errorOccurred = ref(false);
        getAllTagMappings().then((r) => {
            try {
                mappings.value = r;
                isLoading.value = false;
            } catch (err) {
                toastError('There was an error loading your mappings');
            }
        });
        const resolveError = async () => {
            try {
                isLoading.value = true;
                mappings.value = await getAllTagMappings();
                errorOccurred.value = false;
            } finally {
                isLoading.value = false;
            }
        };
        const startCreateNewMapping = () => {
            isCreatingNewMapping.value = true;
            newMappingName.value = 'New Rule';
            newMapping.value = {
                id: -1 * Math.ceil(Math.random() * 1000),
                isActive: true,
                ruleName: newMappingName.value,
                ruleDescription: 'Empty',
                ruleOrder: mappings.value.length + 1,
                stopProcessingMoreRules: false,
                tenantId: null,
                sourceTagKeyValueConditions: [{ sourceTagKeyName: '', matchCase: false, matchValues: [] }],
                addNewTagActions: [{ newTagKey: '', newTagValue: '', runOrder: 0 }],
            };
        };
        const createPlaceholderMapping = async (mapping: M.IConditionalActionRuleResult) => {
            try {
                isSubmitting.value = true;
                const newResult = await createTagMapping({
                    ...mapping,
                    ruleName: newMappingName.value,
                });
                mappings.value = [...mappings.value, newResult];
            } catch {
                errorOccurred.value = true;
            } finally {
                isCreatingNewMapping.value = false;
                isSubmitting.value = false;
            }
        };
        const updatedTargetMapping = async (mapping: M.IConditionalActionRuleResult) => {
            try {
                isSubmitting.value = true;
                await updateTagMapping(mapping.id, mapping);
                toastSuccess('Mapping successfully updated');
            } catch {
                errorOccurred.value = true;
            } finally {
                isSubmitting.value = false;
            }
        };
        const moveMappingUpwards = async (index: number) => {
            if (index > 0) {
                try {
                    isSubmitting.value = true;
                    const target = mappings.value[index];
                    const sibling = mappings.value[index - 1];
                    mappings.value[index] = sibling;
                    sibling.ruleOrder = index;
                    mappings.value[index - 1] = target;
                    target.ruleOrder = index - 1;
                    mappings.value = [...mappings.value];
                    await reorderTagMappings(mappings.value);
                } catch {
                    errorOccurred.value = true;
                } finally {
                    isSubmitting.value = false;
                }
            }
        };
        const moveMappingDownwards = async (index: number) => {
            if (index < mappings.value.length - 1) {
                try {
                    isSubmitting.value = true;
                    const target = mappings.value[index];
                    const sibling = mappings.value[index + 1];
                    mappings.value[index] = sibling;
                    sibling.ruleOrder = index;
                    mappings.value[index + 1] = target;
                    target.ruleOrder = index + 1;
                    mappings.value = [...mappings.value];
                    await reorderTagMappings(mappings.value);
                } catch {
                    errorOccurred.value = true;
                } finally {
                    isSubmitting.value = false;
                }
            }
        };
        const deleteMapping = async (index: number) => {
            if (index > -1) {
                try {
                    isSubmitting.value = true;
                    const [deletedTag] = mappings.value.splice(index, 1);
                    mappings.value = [...mappings.value];
                    if (deletedTag) {
                        await deleteTagMapping(deletedTag.id);
                        toastSuccess('Mapping successfully deleted');
                    }
                } catch {
                    errorOccurred.value = true;
                } finally {
                    isSubmitting.value = false;
                }
            }
        };
        const handleMappingActiveChange = async (mapping: M.IConditionalActionRuleResult, isActive: boolean) => {
            try {
                mapping.isActive = isActive;
                await updateTagMapping(mapping.id, mapping);
                toastSuccess(`Mapping ${mapping.ruleName} is ${isActive ? 'active' : 'inactive'}`);
            } catch {
                mapping.isActive = !isActive;
                toastError('Error toggling active state of mapping');
            }
        };
        return {
            panelsState,
            mappings,
            isCreatingNewMapping,
            createPlaceholderMapping,
            startCreateNewMapping,
            mappingValidity,
            newMappingName,
            moveMappingUpwards,
            moveMappingDownwards,
            deleteMapping,
            updatedTargetMapping,
            newMapping,
            isSubmitting,
            isNewMappingValid,
            errorOccurred,
            resolveError,
            handleMappingActiveChange,
            isLoading,
            isCreatingFullscreen,
        };
    },
});
