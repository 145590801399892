<template>
<fragment>
<v-main v-if="useMain"><slot></slot></v-main>
    <slot v-else></slot>
</fragment>
</template>

<script>
export default {
     data() { return { useMain: false } },
     watch: {
          $route: {
              handler(r) { this.useMain = !r.meta.hideMain }, immediate: true, deep: true
          }
     }
}
</script>