<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <update-entity-name-card
                :value="legacySubscriptionModel"
                :loading="$wait.is('updatingSubscriptionName')"
                #default="{ isValid, formId, loading, form }"
            >
                <back-button @click="close()"></back-button>
                <v-spacer></v-spacer>
                <patch-subscription-button
                    :value="subscription"
                    :patches="[{ op: 'replace', path: 'subscriptionName', value: form.Name }]"
                    :form="formId"
                    :clear="() => {}"
                    :valid="isValid"
                    :loading="loading"
                    v-wait-while="'updatingSubscriptionName'"
                    @http2xx="close().then(() => handleSuccessfulRename(form))"
                ></patch-subscription-button>
            </update-entity-name-card>
        </template>
    </form-dialog>
</template>

<script>
import UpdateEntityNameCard from '@/components/Common/UpdateEntityNameCard.vue';
import PatchSubscriptionButton from '@/components/Domain.Subscriptions/PatchSubscriptionButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    props: {
        value: Boolean,
        subscription: Object,
    },
    components: { UpdateEntityNameCard, PatchSubscriptionButton },
    methods: {
        handleSuccessfulRename(form) {
            toastSuccess(`Successfully renamed to ${form.Name}`);
            this.$emit('http2xx', { ...this.subscription, subscriptionName: form.Name });
        },
    },
    computed: {
        legacySubscriptionModel() {
            if (!this.subscription) return {};
            return { Id: this.subscription.subscriptionId, Name: this.subscription.subscriptionName };
        },
    },
};
</script>