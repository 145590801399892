import { render, staticRenderFns } from "./DeletePolicyDialog.vue?vue&type=template&id=da8f4460&"
import script from "./DeletePolicyDialog.vue?vue&type=script&lang=js&"
export * from "./DeletePolicyDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./DeletePolicyDialog.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports