export class Downloader {
    public exportCSV(rows: string[][], name: string) {
        let csvContent = 'data:text/csv;charset=utf-8,';

        rows.forEach((rowArray) => {
            const row = rowArray.join(',');
            csvContent += row + '\r\n';
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    public exportJSON(object: any, name: string) {
        const json = JSON.stringify(object);
        const blob1 = new Blob([json], { type: "text/plain;charset=utf-8" });
        const url = URL.createObjectURL(blob1);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
export default new Downloader();
