
import { defineComponent, ref } from 'vue';
import { IRegionCostResult, IRegionResult } from '@/models';

export const convertRegionResultsToViewItems = (
    costs: IRegionCostResult,
    regions: IRegionResult[]
): IRegionTableItemViewModel[] => {
    const regionTotals = costs.data.reduce((agg, val) => {
        const key = `${val.providerType}:${val.regionName}`.toUpperCase();
        agg[key] = val.totalCost;
        return agg;
    }, {});
    return regions.map((r) => ({
        ...r,
        currentMonth: regionTotals[r.id],
    }));
};

export interface IRegionTableItemViewModel {
    geography: string;
    name: string;
    currentMonth: number;
    providerType: string;
}

export default defineComponent({
    props: {
        items: Array,
        loading: Boolean,
        currencyCode: String,
    },
    setup() {
        const sortedBy = ref('currentMonth');
        const sortedDesc = ref(true);
        const itemsPerPage = ref(10);
        const searchText = ref<string | null>(null);
        return { sortedBy, sortedDesc, itemsPerPage, searchText };
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('regionsTable.geography.title'),
                    value: 'geography',
                    sortable: true,
                },
                {
                    text: this.$t('name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('regionsTable.currentMonth.title'),
                    value: 'currentMonth',
                    sortable: true,
                },
                {
                    text: this.$t('regionsTable.providerType.title'),
                    value: 'providerType',
                    sortable: true,
                },
            ],
        };
    },
});
