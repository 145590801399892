
import CostSummaryWidget from '@/components/Domain.Usage/CostSummaryWidget.vue';
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import moment from '@/lib/moment';
import { useTenantStore, useDatasetState } from '@/stores/tenant';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
    title(ctx) {
        if (ctx.value) {
            return ctx.value.Name;
        } else {
            return ctx.$t('views.dialogCloudAccount.title');
        }
    },
    components: { CostSummaryWidget },
    props: {
        value: Object,
        loading: {
            default: false,
            type: Boolean,
        },
    },
    setup() {
        const tab: string = null;
        const tenantStore = useTenantStore();
        const tabs = computed(() => {
            const { hasUsageDataset, hasEmissionsDataset } = useDatasetState(tenantStore);
            return [
                hasUsageDataset
                    ? { key: 'usage', icon: 'insights', text: 'views.dialogCloudAccount.tabs.usage' }
                    : null,
                hasEmissionsDataset
                    ? { key: 'carbon', icon: 'co2', text: 'views.dialogCloudAccount.tabs.carbon' }
                    : null,
                hasUsageDataset
                    ? { key: 'trends', icon: 'compare_arrows', text: 'views.dialogCloudAccount.tabs.trends' }
                    : null,
                { key: 'virtualtags', icon: 'sell', text: 'views.dialogCloudAccount.tabs.virtualtags' },
                { key: 'annotations', icon: 'forum', text: 'views.dialogCloudAccount.tabs.annotations' },
                hasUsageDataset
                    ? { key: 'copies', icon: 'content_copy', text: 'views.dialogCloudAccount.tabs.copies' }
                    : null,
            ].filter((t) => t);
        });
        const isEditingName = ref(false);
        const forecast = ref({
            actualTotal: 0,
            projectedTotal: 0,
            pastTotal: 0,
        });
        return { tab, tabs, tenantStore, isEditingName, forecast };
    },
    methods: {
        loadAccountProjections: waitFor('loadingAccountForecast', async function loadAccountProjections(id) {
            const toDate = moment.utc().endOf('month');
            const params = { toDate: toDate.format(), cloudAccountIds: [id].join(), granularity: 'Daily' };
            const response = await ApiV2.http
                .get('/api/forecast', { params })
                .catch((err) => {
                    if (err.response.status === 400) return { data: {} };
                    throw err;
                })
                .then((r) => r.data);
            const year = toDate.format('YYYY');
            const month = toDate.format('MM');
            const previousDate = moment.utc().subtract(1, 'months').endOf('month');
            const prevYear = previousDate.format('YYYY');
            const prevMonth = previousDate.format('MM');
            this.forecast.actualTotal = (response.monthlySpendTotals[year] || {})[month] || 0;
            this.forecast.projectedTotal =
                this.forecast.actualTotal + (response.monthlyForecastTotals[year] || {})[month] || 0;
            this.forecast.pastTotal = (response.monthlySpendTotals[prevYear] || {})[prevMonth] || 0;
        }),
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.loadAccountProjections(val.id);
            },
            immediate: true,
        },
    },
});
