import Vue from 'vue';
import App from '@/App.vue';
import Config from '@/lib/Config';
import '@/plugins/vuetify';
import '@/plugins/highcharts';
import '@/plugins/currency';
import '@/plugins/dateFormatting';
import '@/plugins/common';
import '@/plugins/directives';
import router from '@/plugins/router';
import '@/plugins/tracking';

import ValidationModule from '@/plugins/validation';
import '@/registerServiceWorker';
import vuetify from '@/plugins/vuetify';
import '@/scss/main.scss';
import VueWait from 'vue-wait';
import Vuelidate from 'vuelidate';
import { i18n } from '@/i18n';
import VuePageTitle from 'vue-page-title';
import { Plugin as VueFragment } from 'vue-fragment';
import VueTruncate from 'vue-truncate-filter';
import { Auth0Plugin } from '@/plugins/auth/auth0';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedState from "pinia-plugin-persistedstate"

Vue.use(Auth0Plugin, {
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(Vuelidate);
Vue.use(VueWait);
Vue.use(VueFragment);
Vue.use(ValidationModule);
Vue.use(VuePageTitle, { router, suffix: `- ${Config.get().APP_NAME}` });
Vue.use(VueTruncate);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
  wait: new VueWait(),
  pinia
} as any).$mount('#app');
