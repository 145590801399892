<template>
    <form-dialog :value="value" @input="$emit('input', $event)" #default="{ close }">
        <delete-entity-card :loading="$wait.is('deletingEntity')" :value="entityToDelete" :title="$t('entities.DeleteEntityTitle', {entityType})"      
            :subtitle="$t('entities.DeleteEntitySubtitle', {entityType})" :simple="true">
            <template #actions="{ isValid, formId, clear }">
                <back-button @click="close()"></back-button>
                <v-spacer></v-spacer>
                <delete-entity-button :value="entityToDelete" :entityType="entityType" :clear="clear" :valid="isValid" :form="formId"
                    :resourceApi="resourceApi" v-wait-while="'deletingEntity'" @http2xx="close().then(getEntities)">
                </delete-entity-button>
            </template>
        </delete-entity-card>
    </form-dialog>
</template>

<i18n>
    {
        "en": {          
            "entities.DeleteEntityTitle": "Delete {entityType}",
            "entities.DeleteEntitySubtitle": "This {entityType} will be removed from this tenant. If this was a mistake you can re-invite them and their access will be renewed."
        }
    }
</i18n>

<script>

import DeleteEntityCard from '@/components/Common/DeleteEntityCard';
import DeleteEntityButton from '@/components/Common/DeleteEntityButton';

export default {
    props: {
        value: { type: Boolean },
        entityToDelete: { type: Object, default: null },   
        entityType: { type: String},
        resourceApi: { type: String},
    },    
    components: {
        DeleteEntityCard,
        DeleteEntityButton,        
    },
    methods: {
        getEntities() {           
            this.$emit('http2xx', false);
        }
    },   
}
</script>