import moment from '@/lib/moment';
import { Granularity } from '@/models';

export function granularityForDateRange(
    range: [] | [Date] | [Date, Date]
): null | Granularity.daily | Granularity.monthly {
    if (!range.length) return null;
    if (range.length === 1) return Granularity.daily;
    const dayDifference = moment(range[1]).diff(moment(range[0]), 'days');
    return dayDifference > 90 ? Granularity.monthly : Granularity.daily;
}

export default class DateKey {
    public key: string;
    public granularity?: string;
    public total?: number;
    public isCustom: boolean;
    public isValid: boolean;
    public startDate?: Date;
    public endDate?: Date;

    constructor(key: any) {

        if (typeof key !== 'string') {
            this.isValid = false;
            return;
        } else {
            this.key = key as string;
        }

        if (key === 'custom') {
            this.granularity = null;
            this.total = null;
            this.isCustom = true;
            this.isValid = true;
        } else if (key === 'lastMonth') {
            this.granularity = 'Daily';
            this.total = null;
            this.isCustom = false;
            this.isValid = true;
        } else if (key === 'thisMonth') {
            this.granularity = 'Daily';
            this.total = null;
            this.isCustom = false;
            this.isValid = true;
        } else if (key === 'all') {
            this.granularity = 'Monthly';
            this.total = null;
            this.isCustom = false;
            this.isValid = true;
        } else if (key.endsWith('d')) {
            this.granularity = 'Daily';
            this.total = Number(key.substring(0, key.length - 1));
            this.isCustom = false;
            this.isValid = true;
        } else if (key.endsWith('m')) {
            this.granularity = 'Monthly';
            this.total = Number(key.substring(0, key.length - 1));
            this.isCustom = false;
            this.isValid = true;
        } else {
            this.isValid = false;
        }
    }

    public getEffectiveDateRange(fromDate?: Date | null, toDate?: Date | null): [] | [Date] | [Date, Date] {
        if (!this.isValid) return [ undefined, undefined ];
        const toDateMoment = toDate ? moment.utc(toDate) : moment.utc();
        if (this.key === 'custom') {
            return [fromDate, toDate].filter(r => !!r) as [] | [Date] | [Date, Date];
        } else if (this.key === 'lastMonth') {
            const relativeFrom = toDateMoment.add(-1, 'months');
            const endDate = relativeFrom.endOf('month').toDate();
            const startDate = relativeFrom.startOf('month').toDate();
            return [startDate, endDate];
        } else if (this.key === 'thisMonth') {
            const relativeFrom = toDateMoment;
            const endDate = relativeFrom.endOf('month').toDate();
            const startDate = relativeFrom.startOf('month').toDate();
            return [startDate, endDate];
        } else if (this.key === 'all') {
            const relativeFrom = toDateMoment;
            const endDate = relativeFrom.endOf('month').toDate();
            const startDate = relativeFrom.add(-13, 'months').startOf('month').toDate();
            return [startDate, endDate];
        } else if (this.key.endsWith('d')) {
            const relativeFrom = toDateMoment;
            const endDate = relativeFrom.endOf('day').toDate();
            const startDate = relativeFrom.add(-1 * this.total, 'days').startOf('day').toDate();
            return [startDate, endDate];
        } else if (this.key.endsWith('m')) {
            const relativeFrom = toDateMoment;
            const endDate = relativeFrom.endOf('month').toDate();
            const startDate = relativeFrom.add(-1 * this.total, 'months').startOf('month').toDate();
            return [startDate, endDate];
        }
        return [];
    }
}
