import { ContrastTextVariable, VuetifyPrimaryBase } from '@/lib/CssVariables';

export const createMapChart = (options: any) => {
    const { events } = options;
    return {
        chart: {
            backgroundColor: 'transparent',
            map: 'custom/world'
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        title: { text: '' },
        mapNavigation: { enabled: false },
        legend: {
            itemStyle: {
                color: `var(${ContrastTextVariable})`,
            },
            itemHoverStyle: {
                color: `var(${ContrastTextVariable})`
            },
            navigation: {
                style: {
                    color: `var(${ContrastTextVariable})`,
                },
                activeColor: VuetifyPrimaryBase
            },
        },
        colorAxis: [],
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: function clickEventHandler() {
                            if (events && events['pointClicked']) {
                                events['pointClicked'](this);
                            }
                        }
                    }
                }
            }
        },
        series: [{
            name: 'Countries',
            color: '#E0E0E0',
            enableMouseTracking: false,
            showInLegend: false
        }]
    };
};

export const createMapSeriesRecord = (groupingName: string, suffix: string, prefix: string = null) => {
    const colorLookup = {
        Azure: '#0078d4',
        Amazon: '#ec912d8c'
    } as any;
    const record = {
        type: 'mapbubble',
        name: groupingName,
        joinBy: null,
        data: [], // createMapSeriesChildRecord
        minSize: 15,
        maxSize: '10%',
        tooltip: suffix === 'intensity'
            ? {
                pointFormatter() {
                    if (this.z < 0.0015) {
                        return `${this.name}: Very Low`;
                    } else if (this.z < 0.0030) {
                        return `${this.name}: Low`;
                    } else if (this.z < 0.0045) {
                        return `${this.name}: Medium`;
                    } else if (this.z < 0.0060) {
                        return `${this.name}: High`;
                    } else {
                        return `${this.name}: Very High`;
                    }
                }
            } 
            : {
                pointFormatter() {
                    return `${prefix ?? ''}${this.name}: ${this.z.toFixed(2)} ${suffix}`;
                }
            }
    };
    if (colorLookup[groupingName]) {
        record['color'] = colorLookup[groupingName];
    }
    return record;
}

export const createMapSeriesChildRecord = (correlationId: string, areaName: string, groupingName: string, lat: number, lon: number, value: number) => {
    return {
        name: areaName,
        $$id: correlationId,
        provider: groupingName,
        lat,
        lon,
        z: value
    };
}

export const createCarbonColorAxis = (max: number) => {
    return {
        min: 0,
        max,
        stops: [
            [0, '#5effa2'],
            [0.3, '#045737'],
            [0.6, '#cc820c'],
            [1, '#85071a']
        ]
    };
}