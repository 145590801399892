<template>
    <form-dialog :value="value" @input="$emit('input', $event)" #default="{ close }">
        <delete-entity-card :loading="$wait.is('deletingUser')" :value="userToDelete" :title="$t('users.DeleteUserTitle')"
            :subtitle="$t('users.DeleteUserSubtitle')" :simple="true">
            <template #actions="{ isValid, formId, clear }">
                <back-button @click="close()"></back-button>
                <v-spacer></v-spacer>
                <delete-user-button :value="userToDelete" :clear="clear" :valid="isValid" :form="formId"
                    :prefix="prefix" v-wait-while="'deletingUser'" @http2xx="close().then(getUsers)">
                </delete-user-button>
            </template>
        </delete-entity-card>
    </form-dialog>
</template>

<i18n>
    {
        "en": {          
            "users.DeleteUserTitle": "Delete User",
            "users.DeleteUserSubtitle": "This user will be removed from this tenant. If this was a mistake you can re-invite them and their access will be renewed."
        }
    }
</i18n>

<script>

import DeleteEntityCard from '@/components/Common/DeleteEntityCard.vue';
import DeleteUserButton from '@/components/Settings.Users/DeleteUserButton.vue';

export default {
    props: {
        value: { type: Boolean },
        userToDelete: { type: Object, default: null },
        prefix: String
    },
    components: {
        DeleteUserButton,
        DeleteEntityCard
    },
    methods: {
        getUsers() {
            this.$emit('http2xx');
        }
    }
}
</script>