
import { defineComponent } from "vue";
import ReportCard from "@/components/Domain.Reports/ReportCard.vue";
import PostReportButton from "@/components/Domain.Reports/PostReportButton.vue";

export default defineComponent({
  components: { ReportCard, PostReportButton },
  methods: {
    handleSuccessfulCreation($event:any) {
      this.$router.push(`/custom/reports/${$event.id}`);
    },
  },
});
