<template>
    <http-delete-button
      v-bind="$props"
      v-on="$listeners"
      :href="`/api/customViews/${(value || {}).id}`"
      version="v2"
      @http4xx="onError"
    >
    </http-delete-button>
  </template>
  
  <i18n>
  {
      "en": {
          "deleteCustomViewButton.errorMessage": "There was an error encountered while deleting this custom view. If everything looks right please contact support so we can help you :)"
      }
  }
  </i18n>
  
  <script>
  import { toastError } from "@/components/Common/Toast.vue";
  import HttpDeleteButton from "@/components/Common/HttpDeleteButton.vue";
  
  export default {
    props: {
      ...HttpDeleteButton.props,
      value: {
        type: Object,
      },
    },
    methods: {
      onError() {
        toastError(this.$t("deleteCustomViewButton.errorMessage"));
      },
    },
  };
  </script>