<template>
    <fragment>
        <v-chip outlined label class="mr-1" alt="Cloud Connection">
            <cloud-provider-type-icon size="20" left :value="value.cloudProviderType"></cloud-provider-type-icon>
            Cloud Connection: {{ value.cloudAccountName }}
        </v-chip>
        <v-chip outlined label class="mr-1" v-if="value.offerId">
            <v-icon left>shopping_cart</v-icon>
            OfferId: {{ value.offerId }}
        </v-chip>
        <v-chip outlined label class="mr-1" v-if="value.domain">
            <v-icon left>domain</v-icon>
            Domain: {{ value.domain }}
        </v-chip>
        <v-chip outlined label class="mr-1" v-if="value.customerName">
            <v-icon left>person</v-icon>
            Customer: {{ value.customerName }}
        </v-chip>
        <v-chip outlined
                label
                class="mr-1"
                color="blue"
                v-if="value.remoteId && value.cloudProviderType === 'Azure'"
                :href="`https://portal.azure.com/#${value.domain ? `${value.domain}/` : ''}resource/subscriptions/${
                value.remoteId
            }`"
                target="_top">
            <v-icon left>insert_link</v-icon>
            {{ $t('subscriptionMetadataChips.viewInAzure') }}
        </v-chip>
    </fragment>
</template>


<i18n>
    {
    "en": {
    "subscriptionMetadataChips.viewInAzure": "View in Azure"
    }
    }
</i18n>

<script>
    export default {
        props: {
            value: Object,
        },
    };
</script>