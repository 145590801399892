
import { useTenantStore, useWaitStore, storeToRefs } from '@/stores';
import { defineComponent, computed, ref } from 'vue';
import { ICustomViewResult } from '@/models';
import { getCustomView } from '@/lib/Api';
import { formatCurrency } from "@/lib/Currencies";
import moment from "@/lib/moment";

export default defineComponent({
    data() {
        return {
            selectedTab: 'overview',
            thisMonthName: moment.utc().format("MMMM"),
            lastMonthName: moment.utc().add(-1, "months").format("MMMM"),
            creditsLastMonth: 0.0,
        }
    },
    computed: {
        percentageDownFromLastMonth() {
            if (!this.selectedCustomView) return 0;
            return (-1 * (100 - (100 / this.selectedCustomView.lastMonthActual) * this.selectedCustomView.currentMonthForecast)).toFixed(2);
        },
        lastMonthSubtitle() {
            const lastMonth = moment.utc().add(-1, "months");
            const nocreditsTxt = `${lastMonth.format("MMMM")} 1-${lastMonth.daysInMonth()}`;
            const creditsValue = formatCurrency(
                this.creditsLastMonth,
                this.currencyCode
            );
            return this.creditsLastMonth > 0
                ? this.$t("customView.titles.creditsLastMonth", { credits: creditsValue })
                : nocreditsTxt;
        },
    },
    setup() {
        const tabs = computed(() =>
            [
                { key: 'overview', icon: 'insights', text: 'customView.overview' },
                { key: 'usage', icon: 'insights', text: 'customView.usage' }
            ].filter((t) => t)
        );

        const { currencyCode } = storeToRefs(useTenantStore());
        const { is, whilst } = useWaitStore();
        const customViewId = ref<string | null>();
        const selectedCustomView = ref<ICustomViewResult | null>(null);

        const loadCustomView = async () => {
            await whilst('loadingCustomView', async () => {
                selectedCustomView.value = await getCustomView(customViewId.value);
            });
        };

        return {
            tabs,
            customViewId,
            selectedCustomView,
            currencyCode,
            loadCustomView,
            wait: { is, whilst }
        };
    },
    methods: {

    },
    watch: {
        $route: {
            handler(to) {
                if (!to) return;
                this.customViewId = to.params.id;
                this.loadCustomView();
                this.loading = false;
            },
            immediate: true,
            deep: true,
        },
    },
});
