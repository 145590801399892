<template>
    <v-list>
        <v-radio-group :value="value" @input="$emit('input', $event)" class="pa-0 ma-0" hide-details :rules="rules">
            <slot name="prepend"></slot>
            <v-list-item @click="$refs.createDialog.open()" v-if="!hideCreate">
                <v-list-item-action>
                    <v-icon>add</v-icon>
                </v-list-item-action>
                <v-list-item-content>{{ $t('tenantRadioGroup.create-button') }}</v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(tenant, i) in tenants" :key="i" @click="$emit('input', tenant.tenantId)">
                <template>
                    <v-list-item-action>
                        <v-radio :value="tenant.tenantId" color="primary"></v-radio>
                    </v-list-item-action>
                    <v-list-item-content>{{ tenant.tenantName }}</v-list-item-content>
                </template>
            </v-list-item>
            <slot name="append"></slot>
        </v-radio-group>
        <create-tenant-dialog ref="createDialog" @created="handleCreation"></create-tenant-dialog>
    </v-list>
</template>

<i18n>
{
    "en": {
        "tenantRadioGroup.create-button": "Create Tenant"
    }
}
</i18n>

<script>
import CreateTenantDialog from '@/components/Domain.Tenants/CreateTenantDialog.vue';
export default {
    props: {
        value: String,
        tenants: Array,
        hideCreate: {
            type: Boolean,
            default: false,
        },
        rules: Array,
    },
    components: { CreateTenantDialog },
    methods: {
        handleCreation($event) {
            if (!this.tenants.find((t) => t.tenantId === $event.id)) {
                this.tenants.push($event);
            }
            this.$emit('input', $event.id);
            this.$emit('created', $event);
        },
    },
};
</script>

<style scoped>
.v-input--radio-group >>> .v-input__control {
    width: 100%;
}
</style>
