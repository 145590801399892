
import { waitFor } from '@/plugins/vue-wait';
import CustomViewsTable from '@/components/Domain.CustomViews/CustomViewsTable.vue';
import CustomViewMenu from '@/components/Domain.CustomViews/CustomViewMenu.vue';
import PostCustomViewDialog from '@/components/Domain.CustomViews/PostCustomViewDialog.vue';
import { toastError } from '@/components/Common/Toast.vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { getCustomViews } from '@/lib/Api';
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return { isShowingReport: false, customViews: [], isCreating: false, customViewsLoading: true };
    },
    components: { CustomViewsTable, CustomViewMenu, PostCustomViewDialog },
    created() {
        this.loadReports();
    },
    computed: {
        ...mapStores(useTenantStore),
    },
    methods: {
        loadReports: waitFor('loadingCustomViews', async function loadingReports() {
            try {
                this.customViewsLoading = true;
                this.customViews = await getCustomViews();
                this.customViewsLoading = false;
            } catch (err) {
                toastError(this.$t('customViews.failedToLoad'));
            }
        }),
        handleDialogClose(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push('/customviews');
            this.$title = this.$t('customViews.title');
        },
    },
});
