<template>
    <v-list>
        <v-menu v-model="isMenuOpen" offset-y bottom>
            <template #activator="{ on }">
                <v-list-item v-on="on" :input-value="$route.path.startsWith('/settings')">
                    <v-list-item-action>
                        <v-icon color="primary">settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ value ? value.name : null }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>expand_more</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </template>
            <v-list>
                <v-list-item to="/settings" v-if="tenantStore.hasPermission('CanAdminister')">
                    <v-list-item-icon>
                        <v-icon>settings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('navTenantMenuList.tenantSettings') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/choose" v-if="accountStore.numTenants > 1">
                    <v-list-item-icon>
                        <v-icon>swap_horiz</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('navTenantMenuList.chooseTenant') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-list>
</template>

<i18n>
{
    "en": {
        "navTenantMenuList.tenantSettings": "Settings",
        "navTenantMenuList.chooseTenant": "Change Tenant..."
    }
}
</i18n>

<script>
import { useTenantStore } from '@/stores/tenant.ts';
import { useAccountStore } from '@/stores/account.ts';
import { mapStores } from 'pinia';

export default {
    props: ['value'],
    data() {
        return { isMenuOpen: false };
    },
    computed: {
        ...mapStores(useTenantStore, useAccountStore)
    }
};
</script>