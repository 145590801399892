
import TaxonomyType from '@/stores/models/TaxonomyType';
import { ICloudAccountNavigation } from '@/stores/models/ICloudAccountNavigation';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';

export function amazonCloudAccountTableFilter(ca) {
    return ca.providerType === 'Amazon';
}

export default {
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        routePrefix: {
            type: String,
            default: '/settings/amazon',
        },
    },
    data() {
        return {
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
        };
    },
    computed: {
        tableEntities() {
            if (!this.items) return [];
            return this.items.filter(amazonCloudAccountTableFilter).map((ca) => {
                let expiryDate =
                    ca.awsIamUsageCredential && ca.awsIamUsageCredential.expiryDate
                        ? new Date(ca.awsIamUsageCredential.expiry)
                        : null;
                // In javascript the getTime value of a date isNaN
                if (expiryDate && isNaN(expiryDate.getTime())) {
                    expiryDate = null;
                }
                const AWSConnectionAccessKeyId =
                    ca.awsIamUsageCredential?.providerId ||
                    ca.awsAssumeRoleCredential?.providerId ||
                    ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>';
                const AWSConnectionBucketName =
                    ca.awsIamUsageCredential?.container ||
                    ca.awsAssumeRoleCredential?.providerId ||
                    ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>';
                const AWSConnectionEnabled =
                    ca.awsIamUsageCredential?.valid ||
                    ca.awsAssumeRoleCredential?.valid ||
                    ca.type === 'SelfReferential';
                return {
                    Id: ca.id,
                    Name: ca.name,
                    AWSConnectionEnabled,
                    AWSConnectionAccessKeyId,
                    AWSConnectionBucketName,
                    AWSConnectionExpiry: expiryDate,
                    _original: ca,
                };
            });
        },
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'Name',
                    sortable: true,
                },
                {
                    text: this.$t('amazonWSCloudAccountsTable.accessKeyId'),
                    value: 'AWSConnectionAccessKeyId',
                    sortable: true,
                },
                {
                    text: this.$t('amazonWSCloudAccountsTable.bucketName'),
                    value: 'AWSConnectionBucketName',
                    sortable: true,
                },
                {
                    text: this.$t('amazonWSCloudAccountsTable.expiry'),
                    value: 'AWSConnectionExpiry',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
    methods: {
        updateRouteHistory(item) {
            const navigationHistory: ICloudAccountNavigation = {
                taxonomyType: TaxonomyType.settingsAWSCloudAccount,
                tenantId: null,
                cloudAccountId: item.Id,
            };
            useCloudAccountNavigationStore().updateNavigationSource(navigationHistory);
            return this.$router.push(`${this.routePrefix}/${item.Id}`);
        },
    },
};
