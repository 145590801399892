<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <delete-entity-card
                :title="$t('deletePolicyDialog.title')"
                :subtitle="$t('deletePolicyDialog.subtitle')"
                :value="{ name: policy.policyName }"
                :loading="$wait.is('deletingPolicy')"
            >
                <template #actions="{ isValid, formId, clear, loading, form }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <delete-policy-button
                        :value="policy"
                        :form="formId"
                        :clear="clear"
                        :loading="loading"
                        :valid="isValid"
                        v-wait-while="'deletingPolicy'"
                        @http2xx="close().then(() => handleSuccessfulDeletion(form))"
                    >
                    </delete-policy-button>
                </template>
            </delete-entity-card>
        </template>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "deletePolicyDialog.events.successfulDeletion": "Successfully deleted the policy '{policyName}'",
        "deletePolicyDialog.title": "Delete Policy",
        "deletePolicyDialog.subtitle": "Please enter the name of this policy to delete it"
    }
}
</i18n>

<script>
import DeleteEntityCard from '@/components/Common/DeleteEntityCard.vue';
import DeletePolicyButton from '@/components/Domain.Policies/DeletePolicyButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { DeleteEntityCard, DeletePolicyButton },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        policy: {
            type: Object,
            default: null,
        },
    },
    methods: {
        handleSuccessfulDeletion(form) {
            toastSuccess(this.$t('deletePolicyDialog.events.successfulDeletion', { policyName: form.Name }));
            this.$emit('http2xx', form);
        },
    },
};
</script>