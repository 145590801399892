<template>
    <v-dialog
        v-model="showServiceDialog"
        @input="tryCloseDialog"
        max-width="1200"
        scrollable
        transition="dialog-bottom-transition"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <router-view
            :value="selectedService"
            @input="Object.assign(selectedService, $event)"
            :loading="$wait.is('loadingServices')"
        >
            <back-button @click="tryCloseDialog(false)"></back-button>
        </router-view>
    </v-dialog>
</template>

<i18n>
{
    "en": {
        "servicesDialog.serviceNotFound": "There is no remaining reference to this service within the last 13 months of usage."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2.ts';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
export default {
    data() {
        return {
            showServiceDialog: false,
            showServiceId: null,
            selectedService: null,
        };
    },
    methods: {
        tryCloseDialog(isOpen) {
            if (!isOpen) {
                this.showServiceDialog = false;
                this.showServiceId = null;
                this.selectedService = null;
            }
            this.$emit('input', isOpen);
        },
        loadServiceDetails: waitFor('loadingServices', async function (id) {
            try {
                this.selectedService = await ApiV2.http.get(`/api/metadata/services/${id}`).then((r) => r.data);
                this.$title = this.selectedService.serviceName;
            } catch (err) {
                toastError(this.$t('servicesDialog.serviceNotFound'));
                this.tryCloseDialog(false);
            }
        }),
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showServiceId === to.params.serviceId) return;
                this.showServiceId = to.params.serviceId || null;
                this.showServiceDialog = !!this.showServiceId;
                if (this.selectedService && this.selectedService.serviceId === this.showServiceId) return;
                if (!this.showServiceId) return;
                this.loadServiceDetails(this.showServiceId);
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>