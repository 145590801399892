
import { ref, computed } from 'vue';
import TaxonomyType from '@/stores/models/TaxonomyType';
import { ICloudAccountNavigation } from '@/stores/models/ICloudAccountNavigation';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';
import * as M from '@/models';

export function snowflakeCloudAccountTableFilter(ca: M.ICloudAccountModel) {
    return ca.providerType === M.CloudProviderType.snowflake;
}

export default {
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        routePrefix: {
            type: String,
            default: '/settings/snowflake',
        },
    },
    setup(p) {
        const sortedBy = ref('Name');
        const sortedDesc = ref(true);
        const itemsPerPage = ref(10);

        const tableEntities = computed(() => {
            if (!p.items) return [];
            return p.items.filter(snowflakeCloudAccountTableFilter).map((ca: M.ICloudAccountModel) => ({
                Id: ca.id,
                Name: ca.name,
                ConnectionEnabled: ca.snowflakeDirectCredential
                    ? ca.snowflakeDirectCredential.valid
                    : ca.type === 'SelfReferential',
                OrgName: ca.snowflakeDirectCredential
                    ? ca.snowflakeDirectCredential.clientDomain || '<None Supplied>'
                    : ca.type === 'SelfReferential'
                    ? '<Hidden>'
                    : '<None Supplied>',
                AccountName: ca.snowflakeDirectCredential
                    ? ca.snowflakeDirectCredential.container || '<None Supplied>'
                    : ca.type === 'SelfReferential'
                    ? '<Hidden>'
                    : '<None Supplied>',
                _original: ca,
            }));
        });

        return { sortedBy, sortedDesc, itemsPerPage, tableEntities };
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'Name',
                    sortable: true,
                },
                {
                    text: this.$t('snowflakeCloudAccountsTable.orgName'),
                    value: 'OrgName',
                    sortable: true,
                },
                {
                    text: this.$t('snowflakeCloudAccountsTable.accountName'),
                    value: 'AccountName',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
    methods: {
        updateRouteHistory(item) {
            const navigationHistory: ICloudAccountNavigation = {
                taxonomyType: TaxonomyType.settingsSnowflakeCloudAccount,
                tenantId: null,
                cloudAccountId: item.Id,
            };
            useCloudAccountNavigationStore().updateNavigationSource(navigationHistory);
            return this.$router.push(`${this.routePrefix}/${item.Id}`);
        },
    },
};
