<template>
  <http-post-button
    v-bind="$props"
    v-on="$listeners"
    href="/api/billing/selfservice/tenant"
    version="v2"
    @http4xx="onError"
  >
  </http-post-button>
</template>

<i18n>
{
    "en": {
        "postCreateTenantButton.errorMessage": "There was an error encountered while creating this tenant. If everything looks right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from "@/components/Common/Toast.vue";
import HttpPostButton from "@/components/Common/HttpPostButton.vue";

export default {
  components: { HttpPostButton },
  props: { ...HttpPostButton.props },
  methods: {
    onError() {
      toastError(this.$t('postTenantButton.errorMessage'));
    },
  },
};
</script>