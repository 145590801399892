<template>
    <http-post-button
        v-bind="$props"
        v-on="$listeners"
        :href="`/api/annotations`"
        :value="value"
        version="v2"
        @http4xx="onError"
    >
        <v-icon left>done</v-icon>{{ $t('postAnnotationsButton.text') }}
    </http-post-button>
</template>

<i18n>
{
    "en": {
        "postAnnotationsButton.text": "Add Annotation",
        "postAnnotationsButton.errorMessage": "There was an error encountered while creating this annotation. If anything does not look right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from '@/components/Common/Toast.vue';
import HttpPostButton from '@/components/Common/HttpPostButton.vue';
import Messenger from 'vue-messenger';

export default {
    mixins: [Messenger],
    components: { HttpPostButton },
    props: {
        ...HttpPostButton.props,
        value: {
            type: Object,
        },
    },
    methods: {
        onError() {
            toastError(this.$t('postAnnotationsButton.errorMessage'));
        },
    },
};
</script>