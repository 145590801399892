
export function alibabaCloudAccountTableFilter(ca) {
    return ca.providerType === 'Alibaba';
}

export default {
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        routePrefix: {
            type: String,
            default: '/settings/alibaba',
        },
    },
    data() {
        return {
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
        };
    },
    computed: {
        tableEntities() {
            if (!this.items) return [];
            const alibabaAccounts = this.items.filter(alibabaCloudAccountTableFilter).map((ca) => {
                return {
                    Id: ca.id,
                    Name: ca.name,
                    ConnectionEnabled: ca.alibabaUsageCredential
                        ? ca.alibabaUsageCredential.valid
                        : ca.type === 'SelfReferential',
                    AccessKeyId: ca.alibabaUsageCredential
                        ? ca.alibabaUsageCredential.providerId || '<None Supplied>'
                        : ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>',
                    RegionCode: ca.alibabaUsageCredential
                        ? ca.alibabaUsageCredential.regionCode
                        : ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>',
                    _original: ca,
                };
            });

            return alibabaAccounts;
        },
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'Name',
                    sortable: true,
                },
                {
                    text: this.$t('alibabaCloudAccountsTable.accessKeyId'),
                    value: 'AccessKeyId',
                    sortable: true,
                },
                {
                    text: this.$t('alibabaCloudAccountsTable.regionCode'),
                    value: 'RegionCode',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
};
