<template>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        mobile-breakpoint="0"
        :loading-text="$t('spendLimitsTable.loadingLimits')"
        :loading="loading"
    >
        <template #no-data>
            {{ $t('spendLimitsTable.noLimits') }}
        </template>
        <template #[`item.name`]="{ item }">
            <div class="nowrap">
                <router-link v-if="item.type == 'Service'" :to="`${routePrefix}/services/${item.service.id}`">
                    <v-icon left>memory</v-icon>
                    {{ item.service.name }}
                </router-link>
                <router-link
                    v-else-if="item.type == 'Subscription'"
                    :to="`${routePrefix}/subscriptions/${item.subscription.id}`"
                >
                    <v-icon left>cloud_queue</v-icon>
                    {{ item.subscription.name }}
                </router-link>
                <router-link v-else-if="item.type == 'Product'" :to="`${routePrefix}/products/${item.product.id}`">
                    <v-icon left>shopping_cart</v-icon>
                    {{ item.product.name }}
                </router-link>
                <router-link
                    v-else-if="item.type === 'TagKeyValue'"
                    :to="`${routePrefix}/tagkeys/${item.tagKeyValue.tagKey}/tag/${item.tagKeyValue.id}`"
                >
                    <v-icon left>sell</v-icon>
                    {{ item.tagKeyValue.name }}
                </router-link>
                <span v-else> Error: Unknown type - {{ item.type }} </span>
            </div>
        </template>
        <template #[`item.progress`]="{ item }">
            <consumption-bar
                :current-consumption="item.currentPercentage"
                :predicted-consumption="item.projectedPercentage"
                :max-consumption="item.limit | currency(item.currencyCode)"
                :min-consumption="0 | currency(item.currencyCode)"
            />
        </template>
        <template #[`item.limit`]="{ item }">
            <v-col class="text-right">{{ item.limit | currency(item.currencyCode) }}</v-col>
        </template>
        <template #[`item.actual`]="{ item }">
            <v-col class="text-right">{{ item.currentFigure || 0 | currency(item.currencyCode) }}</v-col>
        </template>
        <template #[`item.projections`]="{ item }">
            <v-col v-if="item.currentFigure > item.limit" class="error--text text-right">
                <v-icon color="error">error</v-icon>
                {{ item.projectedFigure - item.limit || 0 | currency(item.currencyCode) }}
                <small>over budget</small></v-col
            >
            <v-col v-else-if="item.projectedFigure > item.limit" class="warning--text text-right">
                <v-icon color="warning">warning</v-icon>
                {{ item.projectedFigure - item.limit || 0 | currency(item.currencyCode) }}
                <small>over budget</small></v-col
            >
            <v-col v-else-if="item.projectedFigure <= item.limit" class="text-right">
                {{ item.limit - item.projectedFigure || 0 | currency(item.currencyCode) }}
                <small>under budget</small></v-col
            >
        </template>
    </v-data-table>
</template>

<i18n>
{
    "en": {
        "spendLimitsTable.noLimits": "There are no spend limits yet.",
        "spendLimitsTable.loadingLimits": "Loading spending limits",
        "spendLimitsTable.progress": "Progress",
        "spendLimitsTable.projections": "Forecast",
        "spendLimitsTable.actual": "Current Spend",
        "spendLimitsTable.limit": "Spend Limit Amount"

    }
}
</i18n>

<script>
import ConsumptionBar from '@/components/Common/ConsumptionBar.vue';
export default {
    components: { ConsumptionBar },
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        routePrefix: {
            type: String,
            default: '/explore',
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'name',
                    width: '33%',
                },
                { text: this.$t('spendLimitsTable.progress'), value: 'progress', align: 'center', width: '25%' },
                { text: this.$t('spendLimitsTable.limit'), value: 'limit', align: 'right' },
                { text: this.$t('spendLimitsTable.actual'), value: 'actual', align: 'right' },
                { text: this.$t('spendLimitsTable.projections'), value: 'projections', align: 'right' },
            ];
        },
    },
};
</script>