<template>
  <content-card :title="title" :caption="caption" :loading="loading" :light="light">
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <chart :options="chart" :constructorType="constructorType"></chart>
  </content-card>
</template>

<script>
export default {
  props: {
    title: String,
    caption: String,
    chart: Object,
    loading: {
      default: false,
      type: Boolean
    },
    light: {
      default: false,
      type: Boolean
    },
    constructorType: {
      default: undefined,
      type: String
    }
  }
};
</script>
