
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
import RegionChartCard, {
    IRegionClickedEventData,
    convertRegionResultsToChartSeries,
    SupportedSlices,
} from '@/components/Domain.Regions/RegionCarbonChartCard.vue';
import RegionsCarbonTableCard, {
    convertRegionResultsToViewItems,
} from '@/components/Domain.Regions/RegionsCarbonTableCard.vue';
import moment from '@/lib/moment';
import { IRegionCO2EResult, IRegionResult } from '@/models';
import { defineComponent } from 'vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
export default defineComponent({
    props: ['me'],
    components: { RegionChartCard, RegionsCarbonTableCard },
    title(ctx) {
        return ctx.$t('carbonMap.title');
    },
    data() {
        return {
            emissionsResult: null,
            regionsResult: null,
            tab: 2,
        };
    },
    created() {
        this.loadDataForView();
    },
    computed: {
        ...mapStores(useTenantStore),
        regionMapData() {
            if (!this.emissionsResult || !this.regionsResult) return [];
            const values = [SupportedSlices.intensity, SupportedSlices.kwh, SupportedSlices.co2e];
            return convertRegionResultsToChartSeries(this.emissionsResult, this.regionsResult, values[this.tab]);
        },
        regions() {
            if (!this.emissionsResult || !this.regionsResult) return [];
            return convertRegionResultsToViewItems(this.emissionsResult, this.regionsResult);
        },
    },
    methods: {
        handleChartClick(data: IRegionClickedEventData) {
            const { providerType, regionName } = data;
            this.$router.push(`/emissions/map/regions/${providerType}:${regionName}`);
        },
        handleDialogChange(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push('/emissions/map');
            this.$title = this.$t('carbonMap.title');
        },
        async loadDataForView() {
            const [regions, costs] = await Promise.all([this.getAllRegions(), this.getRegionTotals()]);
            this.regionsResult = regions;
            this.emissionsResult = costs;
        },
        getAllRegions: waitFor('loadingRegions', async function (): Promise<IRegionResult[]> {
            try {
                return await ApiV2.http.get('/api/regions').then((r) => r.data);
            } catch (error) {
                toastError(this.$t('carbonDashboard.error.loadingRegions'));
            }
        }),
        getRegionTotals: waitFor('loadingRegionTotals', async function (): Promise<IRegionCO2EResult> {
            try {
                const params = {
                    min: moment.utc().add(-30, 'days').startOf('day').format(),
                    max: moment.utc().endOf('day').format(),
                };
                return await ApiV2.http.get('/api/region/co2e', { params }).then((r) => r.data);
            } catch (error) {
                toastError(this.$t('carbonDashboard.error.loadingRegionTotals'));
            }
        }),
    },
});
