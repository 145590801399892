
import TaxonomyType from '@/stores/models/TaxonomyType';
import { ICloudAccountNavigation } from '@/stores/models/ICloudAccountNavigation';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';
export function oracleCloudAccountTableFilter(ca) {
    return ca.providerType === 'Oracle';
}

export default {
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        routePrefix: {
            type: String,
            default: '/settings/oracle',
        },
    },
    data() {
        return {
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
        };
    },
    computed: {
        tableEntities() {
            if (!this.items) return [];
            return this.items.filter(oracleCloudAccountTableFilter).map((ca) => {
                return {
                    Id: ca.id,
                    Name: ca.name,
                    OracleConnectionEnabled: ca.oracleUsageCredential
                        ? ca.oracleUsageCredential.valid
                        : ca.type === 'SelfReferential',
                    OracleTenancyId: ca.oracleUsageCredential
                        ? ca.oracleUsageCredential.providerId.substring(0,40) || '<None Supplied>'
                        : ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>',
                    OracleUserId: ca.oracleUsageCredential
                        ? ca.oracleUsageCredential.clientDomain.substring(0, 40)
                        : ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>',
                    _original: ca,
                };
            });
        },
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'Name',
                    sortable: true,
                },
                {
                    text: this.$t('oracleCloudAccountsTable.tenancyId'),
                    value: 'OracleTenancyId',
                    sortable: true,
                },
                {
                    text: this.$t('oracleCloudAccountsTable.userId'),
                    value: 'OracleUserId',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
    methods: {
        updateRouteHistory(item)
        {
            const navigationHistory: ICloudAccountNavigation = {
                taxonomyType : TaxonomyType.settingsOracleCloudAccount,
                tenantId : null,
                cloudAccountId : item.Id
            };    
            useCloudAccountNavigationStore().updateNavigationSource(navigationHistory);                      
            return this.$router.push(`${this.routePrefix}/${item.Id}`);
        },  
    }
};
