enum EntityType {
    service = 'Service',
    subscription = 'Subscription',
    cloudAccount = 'CloudAccount',
    tag = 'Tag',
    tagKey = 'TagKey',
    region = 'Region',
    product = 'Product',
    productCategory = 'ProductCategory',
    geography = 'Geography',
    meter = 'Meter',
    cloudProvider = 'CloudProvider',
    reservation = 'Reservation',
    customView = 'CustomView'
}

enum PluralEntityType {
    services = 'services',
    subscriptions = 'subscriptions',
    cloudAccounts = 'cloudAccounts',
    tags = 'tags',
    tagKeys = 'tagKeys',
    regions = 'regions',
    products = 'products',
    productCategories = 'productCategories',
    cloudProviders = 'cloudProviders',
    geographies = 'geographies',
    meters = 'meters',
    reservations = 'reservations'
}



export const toPlural = (type: EntityType): PluralEntityType => {
    switch (type) {
        case EntityType.cloudAccount:
            return PluralEntityType.cloudAccounts;
        case EntityType.cloudProvider:
            return PluralEntityType.cloudProviders;
        case EntityType.service:
            return PluralEntityType.services;
        case EntityType.subscription:
            return PluralEntityType.subscriptions;
        case EntityType.tag:
            return PluralEntityType.tags;
        case EntityType.tagKey:
            return PluralEntityType.tagKeys;
        case EntityType.region:
            return PluralEntityType.regions;
        case EntityType.product:
            return PluralEntityType.products;
        case EntityType.productCategory:
            return PluralEntityType.productCategories;
        case EntityType.geography:
            return PluralEntityType.geographies;
        case EntityType.meter:
            return PluralEntityType.meters;
    }
}

export const fromPlural = (type: PluralEntityType | PluralEntityType[]): EntityType | EntityType[] => {
    if (Array.isArray(type)) {
        return type.map(t => fromPlural(t) as EntityType);
    }
    switch (type) {
        case PluralEntityType.cloudAccounts:
            return EntityType.cloudAccount;
        case PluralEntityType.services:
            return EntityType.service;
        case PluralEntityType.subscriptions:
            return EntityType.subscription;
        case PluralEntityType.tags:
            return EntityType.tag;
        case PluralEntityType.tagKeys:
            return EntityType.tagKey;
        case PluralEntityType.regions:
            return EntityType.region;
        case PluralEntityType.products:
            return EntityType.product;
        case PluralEntityType.productCategories:
            return EntityType.productCategory;
        case PluralEntityType.geographies:
            return EntityType.geography;
        case PluralEntityType.meters:
            return EntityType.meter;
    }
}

export {  PluralEntityType };
export default EntityType;