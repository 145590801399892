<template>
    <v-col cols="12" md="4" >
        <v-sheet class="py-4" style="position:relative">
            <div class="text-center grey--text">
                <slot name="title">{{ title }}</slot>
            </div>
            <div class="text-h4 text-center">
                <slot>{{ value }}</slot>
            </div>
            <div class="text-center subtitle-2 grey--text" v-if="subtitle">
                <slot name="subtitle">{{subtitle}}</slot>
            </div>
        </v-sheet>
    </v-col>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        subtitle: {
            type: [Boolean, String],
            default: false
        }
    },
};
</script>