
import { PluralEntityType } from '@/models';
export const DEFAULT_SEGREGATION_TYPES = [
    PluralEntityType.products,
    PluralEntityType.services,
    PluralEntityType.subscriptions,
    PluralEntityType.meters,
    PluralEntityType.cloudAccounts,
    PluralEntityType.regions,
];
export default {
    props: {
        value: String,
        items: {
            type: Array,
            default: () => DEFAULT_SEGREGATION_TYPES,
        },
        hideOthers: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOtherMenuOpen: false,
        };
    },
    computed: {
        showTypes() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [this.value || this.items[0]];
            }

            let items = this.items;
            if (!this.items.includes(this.value)) {
                items = items.concat(this.value);
            }
            return items;
        },
        otherTypes() {
            return DEFAULT_SEGREGATION_TYPES.filter((t) => !this.items.includes(t) && t !== this.value);
        },
    },
    methods: {
        handleSegregationChange(segregation: PluralEntityType | 'other' | (PluralEntityType | 'other')[]) {
            if (segregation === 'other') return;
            this.$emit('input', segregation);
        },
    },
};
