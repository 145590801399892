
import { defineComponent, ref, onMounted, watch } from "vue";
import { useTenantStore, storeToRefs } from "@/stores";
import createClusterAllocationsBarChart, {
    convertAllocationsToDatasets
} from "@/chartjs/createClusterSpendBarChart";

export {
    createClusterAllocationsBarChart,
    convertAllocationsToDatasets
};

export interface IClickEvent {
    /**
     * Represents the value of the $KubernetesAllocation tag
     *
     * @typedef {string} allocation
     */
    allocation: string;
    /**
     * Represents the value of the $KubernetesCluster tag
     *
     * @typedef {string} cluster
     */
    cluster: string;
}

export default defineComponent({
    props: {
        data: Object,
        cluster: String
    },
    setup(props, { emit }) {
        const root = ref<HTMLDivElement>(null);
        const canvas = ref<HTMLCanvasElement>(null);
        const { currencyCode } = storeToRefs(useTenantStore());
        const renderChart = () => {
            if (!props.data) return;
            if (!root.value) return;
            root.value.innerHTML = "";
            canvas.value = document.createElement("canvas");
            root.value.appendChild(canvas.value);
            const chart = createClusterAllocationsBarChart(canvas.value, {
                currencyCode: currencyCode.value,
                clickFn(allocation) {
                    emit("click", { allocation, cluster: props.cluster });
                },
            });
            chart.data = props.data as any;
            chart.update();
        };
        onMounted(() => {
            renderChart();
        });
        watch(
            () => props.data,
            () => renderChart(),
            { immediate: true, deep: true },
        );
        return { root, canvas, renderChart };
    },
    watch: {
        "$vuetify.theme.dark": {
            handler() {
                this.renderChart();
            },
        }
    }
});
