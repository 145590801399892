<template>
    <v-dialog
        v-model="showDialog"
        @input="tryCloseDialog"
        max-width="1200"
        scrollable
        transition="dialog-bottom-transition"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <router-view
            :value="selectedEntity"
            @input="Object.assign(selectedEntity, $event)"
            :loading="$wait.is('loadingEntity')"
        >
            <back-button @click="tryCloseDialog(false)"></back-button>
        </router-view>
    </v-dialog>
</template>

<i18n>
{
    "en": {
        "reservationDialog.reservationsNotFound": "There is no reference to this reservation currently. This may happen in rare circumstances, please check back in a few minutes."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2.ts';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
export default {
    data() {
        return {
            showDialog: false,
            showReservationId: null,
            selectedEntity: null,
        };
    },
    methods: {
        tryCloseDialog(isOpen) {
            if (!isOpen) {
                this.showDialog = false;
                this.showReservationId = null;
                this.selectedEntity = null;
            }
            this.$emit('input', isOpen);
        },
        loadReservationDetails: waitFor('loadingEntity', async function (id) {
            try {
                const reservations = await ApiV2.http.get(`/api/reservations`).then((r) => r.data);
                const reservation = reservations.find(r => r.reservationId === id);
                this.selectedEntity = reservation || null;
                this.$title = this.selectedEntity?.commitmentId || 'Unknown Reservation';
            } catch (err) {
                toastError(this.$t('reservationDialog.reservationsNotFound'));
                this.tryCloseDialog(false);
            }
        }),
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showReservationId === to.params.reservationId) return;
                this.showReservationId = to.params.reservationId || null;
                this.showDialog = !!this.showReservationId;
                if (this.selectedEntity && this.selectedEntity.reservationId === this.showReservationId) return;
                if (!this.showReservationId) return;
                this.loadReservationDetails(this.showReservationId);
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>