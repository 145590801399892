import { v4 as uuidv4 } from 'uuid';

export type PartialPick<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export type OmitExcept<T, K extends string | number | symbol> = Omit<T, keyof T extends K ? never : K>;

export type Dictionary<T> = { [key: string]: T[] };

export const groupBy = <T extends { [K in Key]: any }, Key extends keyof T>(xs: T[], key: Key): Dictionary<T> => {
    return xs.reduce((rv: Dictionary<T>, x: T) => {
        (rv[x[key] as unknown as string] = rv[x[key] as unknown as string] || []).push(x);
        return rv;
    }, {} as Dictionary<T>);
}

export const unique = <TVal>(val: TVal, index: number, self: TVal[]) => !!val && self.indexOf(val) === index;

export const uuid = () => uuidv4();

export const MAX_DATETIMESTAMP = 8640000000000000;
export const MIN_DATETIMESTAMP = -1 * MAX_DATETIMESTAMP;
export const reverseTimestamp = (date: Date | number | string): number => {
    if (date instanceof Date) {
        return MAX_DATETIMESTAMP - date.getTime();
    } else if (typeof date === 'number') {
        return MAX_DATETIMESTAMP - date;
    } else if (typeof date === 'string') {
        return MAX_DATETIMESTAMP - new Date(date).getTime();
    } else {
        throw new Error(`Unsupported type ${date}`);
    }
}

export const appendValueToCommaString = (inputArray: string, value: string | string[], separator = ',') =>
    (inputArray || '')
        .split(separator)
        .concat(value)
        .filter(unique)
        .join(separator);