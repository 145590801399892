import { ISpendResult, ISpendAndConsumptionGroup, IForecastResult, IEventItemModel, Granularity, IConsumptionUsageRecord } from '@/models';
import * as CssVariables from '@/lib/CssVariables';
import { ChartData, Point, Chart, ChartDataset } from 'chart.js/auto'
import annotationPlugin, { AnnotationOptions, CoreAnnotationOptions, EventContext, LabelAnnotationOptions } from 'chartjs-plugin-annotation';
import { formatCurrency } from '@/lib/Currencies';
import { LEGACY_COLORS_RGB } from './legacy'
import pattern from 'patternomaly';
import './dayJsAdapter';
import htmlLegendPlugin from './legendPlugin';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'dayjs';
import { Annotation } from '@/models/Annotation';


Chart.register(annotationPlugin);

const PREFIX_PAD = 100;
const OTHERS_DATASET_ORDER = 99998;
const PROJECTIONS_DATASET_ORDER = 99999;

export interface IUsageDatapoint {
    x: number;
    y: number;
    id: string;
    type: string;
}

export enum UsageReportTypes {
    charges = 'charges',
    spend = 'spend',
    credits = 'credits'
}

export interface IConvertUsageReportOpts {
    labelSuffix?: string | null;
    stackGroup?: string | null;
    colorsArray?: string[] | null;
    reportType?: UsageReportTypes | UsageReportTypes[] | null;
}

const valueFn = (t: UsageReportTypes): ((u: IConsumptionUsageRecord) => number) => {
    switch (t) {
        case UsageReportTypes.spend:
            return (u) => u.spend;
        case UsageReportTypes.charges:
            return (u) => u.charges;
        case UsageReportTypes.credits:
            return (u) => u.credits;
        default:
            throw new Error('Unknown usage report type');
    }
};
  // This is code taken from their site and apparently they are using a value that doesnt exist on the typescript
  function autoScaling(ctx : EventContext & {size: number}, option : 'font' | 'padding', origValue : number) {
    const chart = ctx.chart;
    const {width, height} = chart.chartArea;
    const barWidth = chart.getDatasetMeta(0).data[0];
    if(barWidth["width"]) {
        if(option == 'padding') {
            const output = barWidth["width"] / 10;
            if(output > origValue) {
                return origValue;
            }
            return output;
        }
        else if(option === 'font') {
            const output = barWidth["width"] / 3;
            if(output > origValue) {
                return origValue;
            }
            return output;
        }
    }

    const hypo = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
    let size, value;
    if (!ctx.size) {
      ctx.size = size = hypo;
      value = origValue;
    } else {
      size = ctx.size;
      value = hypo / size * origValue;
    }
    if(value > origValue) {
        return origValue;
    }
    if (option === 'font') {
      return {size: value};
    }
    return value;
  }

export const convertUsageResponseToDatasets = (usageResult: ISpendResult<ISpendAndConsumptionGroup> | null, opts?: IConvertUsageReportOpts): ChartData<'bar', Point[], string> => {
    const { labelSuffix, stackGroup, colorsArray, reportType } = opts || {};
    const datasets: ChartDataset<'bar', IUsageDatapoint[]>[] = [];
    const colours = colorsArray ? colorsArray : LEGACY_COLORS_RGB;
    (usageResult?.groupings || []).reverse().forEach((g, i) => {
        const color = colours[i % colours.length];
        const fullColor = `rgb(${color})`;

        const dataset: ChartDataset<'bar', IUsageDatapoint[]> = {
            data: [],
            label: (g.type === '' ? 'Tenant' : g.name) + (labelSuffix ? ` (${labelSuffix})` : ''),
            backgroundColor: g.isOther ? pattern.draw('diagonal', '#87db79', '#313e4d') : fullColor,
            borderColor: 'white',
            hoverBackgroundColor: g.isOther ? pattern.draw('diagonal', '#FF10F0', '#313e4d') : '#FF10F0',
            borderWidth: 1,
            order: g.isOther ? OTHERS_DATASET_ORDER : (PREFIX_PAD + i),
            normalized: true,
            stack: stackGroup ? stackGroup : 'stack0'
        };
        const reportTypes: UsageReportTypes[] = !reportType ? [UsageReportTypes.spend] : Array.isArray(reportType) ? reportType : [reportType];
        const { id, type } = g;
        g.usageRecords.forEach(u => {
            const x = new Date(u.usageDate).getTime();
            reportTypes.forEach(t => {
                const fn = valueFn(t);
                const y = fn(u);
                dataset.data.push({ x, y, id, type });
            });
        });
        datasets.push(dataset);
    });
    return {
        labels: [],
        datasets
    };
};

export const convertProjectionsResponseToDataset = (projectionsResult: IForecastResult | null, labelSuffix?: string | null, stackGroup?: string | null, altColorHex?: string | null): ChartData<'bar', Point[], string> => {
    if (!projectionsResult) {
        return {
            labels: [],
            datasets: []
        }
    }
    const dataset: ChartDataset<'bar', Point[]> = {
        data: [],
        label: 'Projected' + (labelSuffix ? ` (${labelSuffix})` : ''),
        backgroundColor: pattern.draw('diagonal', (altColorHex ? altColorHex : '#3d3a60'), '#fff'),
        borderColor: 'white',
        borderWidth: 1,
        order: PROJECTIONS_DATASET_ORDER,
        stack: stackGroup ? stackGroup : 'stack0'
    };
    (projectionsResult?.forecasts || []).filter(x => x.forecast > 0).forEach((u) => {
        const forecastTimestamp = new Date(u.usageDate).getTime();
        dataset.data.push({
            x: forecastTimestamp,
            y: u.forecast
        });
    });
    return {
        labels: [],
        datasets: [dataset]
    }
};

export function convertEventsToAnnotations(eventResponse: IEventItemModel<any>[], onClick: (xMin: number, xMax: number) => void | null = null): CoreAnnotationOptions[] {
    const sortedEvents = eventResponse.sort((a, b) => {
        const aDate = new Date(a.year, a.month - 1, a.day);
        const bDate = new Date(b.year, b.month - 1, b.day);
        return new Date(aDate).getTime() - new Date(bDate).getTime();
    });
    const plotBands: AnnotationOptions[] = [];
    sortedEvents.forEach((value) => {
        const currentDate = new Date(Date.UTC(value.year, value.month - 1, value.day));
        const dayBefore = new Date(currentDate);
        dayBefore.setDate(currentDate.getDate() - 1);
        let toCreate = false;
        const existingBand = plotBands[plotBands.length - 1];
        if (!existingBand) {
            toCreate = true;
        } else if (existingBand.xMin == currentDate.getTime()) {
            // Multiple events on the same date; this is where we could take into account intensity values.
        } else if (existingBand.xMin == dayBefore.getTime()) {
            existingBand.xMax = currentDate.getTime();
        } else {
            toCreate = true;
        }
        if (toCreate) {
            const plotBand: AnnotationOptions = {
                type: 'box',
                xMin: currentDate.getTime(),
                xMax: currentDate.getTime(),
                backgroundColor: 'rgba(255, 99, 132, 0.25)',
                click() {
                    if (!onClick) return;
                    onClick(plotBand.xMin as number, plotBand.xMax as number);
                },
            }
            plotBands.push(plotBand);
        }
    });
    plotBands.forEach((band) => {
        const xMinDate = new Date(band.xMin as number);
        band.xMin = new Date(xMinDate.setHours(0, 0, 0, 0)).getTime();
        const xMaxDate = new Date(band.xMax as number);
        band.xMax = new Date(xMaxDate.setHours(23, 59, 59, 999)).getTime();
    });
    return plotBands;
}

export function convertAnnotatesToAnnotations(eventResponse: Annotation[], data:  ChartDataset<"bar", Point[]>[], granularity: Granularity, onClick: (xMin: number, xMax: number) => void | null = null): CoreAnnotationOptions[] {
    const flatData = data.flatMap(({data, stack}) => data.map(y => { 
        return {x : y.x,y: y.y, stack}
    }));
    const sortedEvents = eventResponse.map(x => new Date(x.annotationDate)).sort((a, b) => {
        return a.getTime() - b.getTime();
    });
    let largest = 0;
    const xValues = flatData.map(x => {return {x: x.x, stack: x.stack}}).filter(
        (value, index, self) => self.findIndex(itemB => value.stack == itemB.stack && value.x == itemB.x) === index);
    const totals: { [id: number] : number; } = {};
    xValues.forEach(x => {
        const total = flatData.filter(item => item.x == x.x && item.stack == x.stack).map(y => y.y).reduce((a, b) =>  a + b) ?? 0;
        if(total > largest) {
            largest = total;
        }
        if(!totals[x.x] || totals[x.x] < total) {
            totals[x.x] = total;
        }
    });

    // get granularity to change look
    const plotBands: LabelAnnotationOptions[] = [];
    sortedEvents.forEach((value) => {
        const xMinDate = new Date(value);
        xMinDate.setHours(0, 0, 0, 0);
        const xMaxDate = new Date(value);
        xMaxDate.setHours(23, 59, 59, 999);

        if(granularity == Granularity.monthly) {
            xMinDate.setDate(1);
            xMaxDate.setDate(moment(xMaxDate).endOf('month').day());
        }
        const dayBefore = new Date(xMinDate);
        dayBefore.setDate(xMinDate.getDate() - 1);
        let toCreate = false;
        const existingBand = plotBands[plotBands.length - 1];
        if (!existingBand) {
            toCreate = true;
        } else if (existingBand.xMin == xMinDate.getTime()) {
            // Multiple events on the same date; this is where we could take into account intensity values.
        } else if (granularity != Granularity.monthly && existingBand.xMin == dayBefore.getTime()) {
            existingBand.xMax = xMinDate.getTime();
        } else {
            toCreate = true;
        }
        if (toCreate) {
            const minDate = xMinDate.getTime();
            const maxDate = xMaxDate.getTime();
            let midday = moment(new Date(value));
            const offset = midday.utcOffset();
            if(offset >= 0) {
                midday = midday.startOf('day');
            }
            else {
                midday = midday.endOf('day')
            }
            midday = midday.add(midday.utcOffset(), 'm'); // Allign with start of day in the timezone
            const dataValues = flatData.filter(x => x.x >= minDate && x.x <= maxDate).map(x => x.x).filter(
                (value, index, self) => self.findIndex(itemB => value == itemB) === index);
            let total = 0;
            dataValues.forEach(x => {
                if(totals[x] > total) {
                    total = totals[x];
                }
            });
    
            const plotBand: any = { // has to be any as for some reason nothing else works
                type: 'label',
                backgroundColor: 'rgb(124, 181, 236)',
                content: 'ⓘ',
                font: (ctx) => autoScaling(ctx, 'font', granularity == Granularity.monthly ? 15 : 10),
                padding: (ctx) => autoScaling(ctx, 'padding', granularity == Granularity.monthly ? 5 : 3),
                xMin: xMinDate.getTime(),
                xMax: xMaxDate.getTime(),
                position: {
                    x: 'center',
                    y: 'center'
                  },
                xValue: granularity == Granularity.monthly ? xMinDate.getTime() : midday.toDate().getTime(), // using .unix seemed to make it angry
                yValue: total + (largest * .05), // Place it 5% above the top of the largest column value,
                click() {
                    if (!onClick) return;
                    onClick(plotBand.xMin as number, plotBand.xMax as number);
                },
            }
            plotBands.push(plotBand);
        }
    });
    plotBands.forEach((band) => {
        const xMinDate = new Date(band.xMin as number);
        band.xMin = new Date(xMinDate.setHours(0, 0, 0, 0)).getTime();
        const xMaxDate = new Date(band.xMax as number);
        band.xMax = new Date(xMaxDate.setHours(23, 59, 59, 999)).getTime();
    });
    return plotBands;
}

export interface IClickEvent {
    index: number;
    name: string;
    isOther: boolean;
    datasetGroupId: string;
}
export interface IRangeSelectedEvent {
    xMin: number;
    xMax: number;
    rangeTotal: number;
}

export interface IOptions {
    currencyCode: string;
    granularity: Granularity.daily | Granularity.monthly;
    onClick?: (event: IClickEvent) => void;
    onRangeSelection?: (event: IRangeSelectedEvent) => void;
    allowRangeSelection: boolean;
    type?: 'bar' | 'line',
    maxDate?: string
}

function getRange(scale, pixel0, pixel1) {
    const v0 = scale.getValueForPixel(pixel0);
    const v1 = scale.getValueForPixel(pixel1);
    return {
      min: Math.min(v0, v1),
      max: Math.max(v0, v1)
    };
  }

export default (chartItem: HTMLCanvasElement, options: IOptions, containerId: string): Chart => {
    const { currencyCode, onClick, granularity, type, onRangeSelection, allowRangeSelection, maxDate} = options;
    const style = getComputedStyle(chartItem);
    const contrastLines = style.getPropertyValue(CssVariables.ContrastLinesVariable);
    const contrastText = style.getPropertyValue(CssVariables.ContrastTextVariable);
    let selectedRange = undefined;
    zoomPlugin.zoomFunctions.default = (scale, zoom, center, limits) => false;
    zoomPlugin.zoomRectFunctions.default = (scale, from, to, limits) => {
        const range = getRange(scale, from, to);
        selectedRange = range;
        
        return true;};

    zoomPlugin.panFunctions.default = (scale, delta, limits) => false;
    const onClickProcessor = (_, [targetPtr], chart) => {
        if (!targetPtr) return;
        const targetDataset = chart.data.datasets[targetPtr.datasetIndex];
        if (!targetDataset || targetDataset.order === PROJECTIONS_DATASET_ORDER) return;
        const target: IUsageDatapoint = targetDataset.data[targetPtr.index] as any;
        if (!target) return;
        if (target.type === '') return;
        if (onClick) {
            onClick({
                index: targetDataset.order,
                isOther: targetDataset.order === OTHERS_DATASET_ORDER,
                name: targetDataset.label,
                datasetGroupId: target.id
            });
        }
    }

    
    const chart = new Chart(chartItem, {
        type: type || 'bar',
        data: {
            labels: [],
            datasets: []
        },
        options: {
            borderColor: 'rgba(0,0, 0, 0)',
            plugins: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                htmlLegend: {
                // ID of the container to put the legend in
                    containerID: containerId,
                },
                zoom: {
                    zoom: {
                        drag: {
                            enabled: allowRangeSelection
                        },
                      mode: 'x',
                      onZoomComplete(chart){
                        if(onRangeSelection && selectedRange != undefined) {
                            const minXDate = new Date(Math.floor(selectedRange.min));
                            minXDate.setHours(0,0,0,0);

                            const maxXDate = new Date(Math.ceil(selectedRange.max));
                            maxXDate.setHours(23,59,59,999);

                            const minX = minXDate.getTime(); 

                            const maxX = maxXDate.getTime();
                            const datasets = chart.chart.data.datasets;
                            const values = datasets.flatMap(x => x.data.filter((y: Point) => y.x >= minX && y.x <= maxX));
                            if(values?.length > 0) {
                                const stackTotal = values.filter(x => x).map((x: Point) => x.y)?.reduce((a,b) =>  a + b) ?? 0;
                                onRangeSelection({
                                    xMin: minX,
                                    xMax: maxX,
                                    rangeTotal: stackTotal
                                });
                            }
                        }
                        selectedRange = undefined;
                      }
                    },
                  },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                    labels: {
                        color: contrastText
                    },
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            return [item.dataset.label];
                        },
                        afterLabel(item) {
                            const dataset = item.dataset;
                            const points: Point[] = dataset.data as any;
                            const hoverPoint: Point = points[item.dataIndex];
                            const datasetTotal = points.map((a: Point) => a.y).reduce((a, b) => a + b);
                            const datasetAverage = datasetTotal / dataset.data.length;
                            const percentageOfAverage = ((hoverPoint.y - datasetAverage) / datasetAverage * 100);
                            const datasets = item.chart.data.datasets;
                            const values = datasets.filter(x => x.stack == dataset.stack).flatMap(x => x.data.filter((y: Point) => y.x == hoverPoint.x));
                            const stackTotal = values.filter(x => x).map((x: Point) => x.y).reduce((a,b) =>  a + b);
                            return [
                                `Total: ${formatCurrency(hoverPoint.y, currencyCode)}`,
                                `${percentageOfAverage > 0 ? '+' + percentageOfAverage.toFixed(2) : percentageOfAverage.toFixed(2)}% vs. average`,
                                `Column Total: ${formatCurrency(stackTotal, currencyCode)}`
                            ]
                        },
                    },
                    mode: 'point',
                },
                annotation: {
                    annotations: {}
                },
            },
            interaction: {
                intersect: true,
                mode: 'dataset'
            },
            onHover(event, [element]) {
                let newCursor = 'default';
                if (element) {
                    const targetDataset = chart.data.datasets[element.datasetIndex];
                    if (!targetDataset) return;
                    const target: IUsageDatapoint = targetDataset.data[element.index] as any;
                    if (!target) return;
                    if (target.type === '') return;
                    if (targetDataset.order !== PROJECTIONS_DATASET_ORDER) {
                        newCursor = 'pointer';
                    }
                }
                (event.native.target as HTMLElement).style.cursor = newCursor;
            },
            responsive: true,
            maintainAspectRatio: false,
            onClick(_, [targetPtr], chart) {
               onClickProcessor(_,[targetPtr], chart);
            },
            scales: {
                x: {
                    type: "time",
                    stacked: true,
                    ticks: {
                        color: contrastText
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0)'
                    },
                    axis: 'x',
                    time: {
                        unit: granularity == Granularity.monthly ? 'month' : 'day'
                    },
                    bounds: 'ticks',
                    max:  new Date(maxDate ?? Date.now()).getTime()
                },
                y: {
                    type: 'linear',
                    stacked: true,
                    ticks: {
                        color: contrastText,
                        callback(value: number) {
                            return formatCurrency(value, currencyCode);
                        }
                    },
                    grid: {
                        color: contrastLines
                    },
                    min: 0,
                    axis: 'y',
                    title: {
                        text: `Cost (${currencyCode})`,
                        color: contrastText,
                        display: true
                    },
                    stack: 'y',
                    display: true,

                }
            }
        },
        plugins: [htmlLegendPlugin, zoomPlugin]
    });
    return chart;
};