<template>
    <v-dialog
        v-model="showCloudAccountDialog"
        @input="tryCloseDialog"
        max-width="1200"
        scrollable
        transition="dialog-bottom-transition"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <router-view
            :value="selectedCloudAccount"
            @input="Object.assign(selectedCloudAccount, $event)"
            :loading="$wait.is('loadingCloudAccount')"
        >
            <back-button @click="tryCloseDialog(false)"></back-button>
        </router-view>
    </v-dialog>
</template>

<i18n>
{
    "en": {
        "cloudAccountDialog.cloudAccountNotFound": "We could not find reference to this cloud connection within this tenant."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
export default {
    data() {
        return {
            showCloudAccountDialog: false,
            showCloudAccountId: null,
            selectedCloudAccount: null,
        };
    },
    methods: {
        tryCloseDialog(isOpen) {
            if (!isOpen) {
                this.showCloudAccountDialog = false;
                this.showCloudAccountId = null;
                this.selectedCloudAccount = null;
            }
            this.$emit('input', isOpen);
        },
        loadCloudAccountDetail: waitFor('loadingCloudAccount', async function (id) {
            try {
                this.selectedCloudAccount = await ApiV2.http.get(`/api/metadata/cloudaccounts/${id}`).then((r) => r.data);
                this.$title = this.selectedCloudAccount.name;
            } catch (err) {
                toastError(this.$t('cloudAccountDialog.cloudAccountNotFound'));
                this.tryCloseDialog(false);
            }
        }),
    },
    watch: {
        $route: {
            handler(to) {
                if (this.showCloudAccountId === to.params.cloudAccountId) return;
                this.showCloudAccountId = to.params.cloudAccountId || null;
                this.showCloudAccountDialog = !!this.showCloudAccountId;
                if (this.selectedCloudAccount && this.selectedCloudAccount.Id === this.showCloudAccountId) return;
                if (!this.showCloudAccountId) return;
                this.loadCloudAccountDetail(this.showCloudAccountId);
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>