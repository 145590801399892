/*
 *   Helpful validation functions that work directly with vuetify's form element validation.
 *   NOTE: You should turn on lazy validatation in your form e.g. <v-form ref="form" :lazy-validation="true">
 *
 *   Installation (main.ts):
 *       import './plugins/validation';
 *       Vue.use(VueFragment);
 *
 *   Usage:
 *       :rules="[$rules.required(), $rules.maxLength(50)]"
 *
 *   Or, with custom messages:
 *       :rules="[$rules.required('Name is required'), $rules.maxLength(50, 'Name must be less than 50 characters')]"
 *
 *   Conditionally required:
 *       :rules="[$rules.requiredIf(() => someProp > 10), $rules.maxLength(50)]"
 *
 *   Can handle async validation:
 *       (element)
 *           <v-text-field
 *               ref="myfield"
 *               :rules="[$rules.asyncValidator({component:() => $refs.myfield, message: 'OPTIONALMESSAGE', method: someAsyncValidator})]"
 *           ></v-text-field>
 *       (async validation method)
 *           async someAsyncValidator(val) { return await this.someFunc(val); }
 */

import { i18n } from '../i18n';

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const timeRegex = /^[012]?[0-9]:[0-5][0-9]$/;
const digitRegex = /(?=.*\d)/;
const upperCaseRegex = /(?=.*[A-Z])/;
const lowerCaseRegex = /(?=.*[a-z])/;
const specialCharRegex = /(?=.*[-+_!@#$%^&*.,?])/;

export interface IAsyncValidator {
    component: any;
    method: any;
    message?: string;
}

export default {
    install(Vue: any) {
        Vue.prototype.$rules = {
            required(message?: string) {
                return (v?: string) => !!v || message || i18n.t('validation.required');
            },
            requiredIf(getter: any, message?: string) {
                return (v?: string) => !getter() || !!v || message || i18n.t('validation.required');
            },
            requiredLength(num: number, message?: string) {
                return Vue.prototype.$rules.maxLength(num, message) || Vue.prototype.$rules.minLength(num, message);
            },
            maxLength(num: number, message?: string) {
                return (v?: string | string[]) => (v || '').length <= num || message || i18n.t('validation.maxLength', { num });
            },
            minLength(num: number, message?: string) {
                return (v?: string | string[]) => (v || '').length >= num || message || i18n.t('validation.minLength', { num });
            },
            email(message?: string) {
                return (v?: string) => !v || emailRegex.test(v) || message || i18n.t('validation.email');
            },
            time(message?: string) {
                return (v?: string) => !v || timeRegex.test(v) || message || i18n.t('validation.time');
            },
            multipleOf(num: number, message?: string) {
                return (v?: number) => !v || (v || 0) % num == 0 || message || i18n.t('validation.multipleOf');
            },
            max(num: number, message?: string) {
                return (v?: number) => (v || 0) <= num || message || i18n.t('validation.max', { num });
            },
            min(num: number, message?: string) {
                return (v?: number) => (v || 0) >= num || message || i18n.t('validation.min', { num });
            },
            custom(fn: any, message?: string) {
                return () => !fn() || message || i18n.t('validation.invalid');
            },
            equalTo(comparisonValue: any, message?: string) {
                return (v?: any) => comparisonValue === v || message || i18n.t('validation.doesNotMatch');
            },
            containsDigit(message?: string) {
                return (v?: string) => !v || digitRegex.test(v) || message || i18n.t('validation.containsDigit');
            },
            containsLowercase(message?: string) {
                return (v?: string) => !v || lowerCaseRegex.test(v) || message || i18n.t('validation.containsLower');
            },
            containsUppercase(message?: string) {
                return (v?: string) => !v || upperCaseRegex.test(v) || message || i18n.t('validation.containsUpper');
            },
            containsSpecialCharacter(message?: string) {
                return (v?: string) => !v || specialCharRegex.test(v) || message || i18n.t('validation.containsSpecialChar');
            },
            test(regex:RegExp, message?: string) {
                return (v?:string) => !v || regex.test(v) || message || i18n.t('validation.regex');
            },

            /**
             * @description Create an async validator e.g. $rules.asyncValidator({component:() => $refs.REFNAME, message: 'OPTIONALMESSAGE', method: someAsyncValidator})
             * @param {IAsyncValidator} validator Model defining async validator behaviour. Validator method: e.g. async someAsyncValidator(val) { return await this.someFunc(val); }
             * @returnType {Function}
             */
            asyncValidator(validator: IAsyncValidator) {
                const message = validator.message || i18n.t('validation.invalid');

                let myValue = true;

                setTimeout(() => {
                    validator.component().rules.push(() => myValue || message);
                }, 1);

                let running = false;
                return (v: any) => {
                    if (running) {
                        return true;
                    }
                    running = true;
                    (async () => {
                        myValue = await validator.method(v);
                        validator.component().validate();
                        running = false;
                    })();
                    return true;
                };
            }
        };
    }
};
