<template>
    <fragment>
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>
        <v-card-title>
            <h2 class="title text-center full-width text-uppercase primary--text">
                <slot name="title">{{ title }}</slot>
            </h2>
            <p class="subtitle-1 text-xs-center grey--text pa-3 pb-0 text-break full-width" align="center">
                <slot name="subtitle">{{ subtitle }}</slot>
            </p>
        </v-card-title>
    </fragment>
</template>
<script>
export default {
    props: ['loading', 'title', 'subtitle']
};
</script>

<style scoped>
.centered {
    text-align: center !important;
}
</style>