<template>
    <fragment>
        <v-container>
            <content-card
                :title="$t('views.manageTenants.my.title')"
                :caption="$t('views.manageTenants.my.caption')"
                class="pb-1"
            >
                <template #title-actions>
                    <v-btn color="secondary" depressed class="mr-3" @click="startEditTenant(tenantToEdit)">
                        <v-icon left>edit</v-icon> {{ $t('edit') }}
                    </v-btn>
                </template>
                <v-col cols="5">
                    <v-text-field
                        v-model="tenantToEdit.id"
                        readonly
                        autofocus
                        :label="$t('views.manageTenants.tenantId')"
                    ></v-text-field>
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        v-model="tenantToEdit.name"
                        readonly
                        autofocus
                        :label="$t('views.manageTenants.name')"
                    ></v-text-field>
                </v-col>
                <v-col cols="5">
                    <v-text-field
                        v-model="tenantToEdit.displayCurrencyCode"
                        readonly
                        autofocus
                        :label="$t('views.manageTenants.display.currency')"
                    ></v-text-field>
                </v-col>
                <v-col cols="5" v-if="tenantStore.features.includes('AllowMpnOverride')">
                    <v-text-field
                        v-model="tenantToEdit.mpnOverride"
                        readonly
                        autofocus
                        :label="$t('views.manageTenants.display.mpn')"
                    ></v-text-field>
                </v-col>
                <v-col cols="4">
                    <div>Tag Hierarchy</div>
                    <v-treeview open-all :items="tagHierarchy"></v-treeview>
                </v-col>
            </content-card>
        </v-container>

        <form-dialog v-model="dialogs.deleteTenant" #default="{ close }">
            <delete-entity-card
                :value="tenantToDelete"
                :loading="$wait.is('deletingTenant')"
                :title="$t('views.manageTenants.delete.title')"
                :subtitle="$t('views.manageTenants.delete.subtitle')"
            >
                <template #actions="{ isValid, formId, loading }">
                    <back-button @click="close"></back-button>
                    <v-spacer></v-spacer>
                    <delete-tenant-button
                        :value="tenantToDelete"
                        :valid="isValid"
                        :loading="loading"
                        :form="formId"
                        @http2xx="close().then(goToChoose)"
                    ></delete-tenant-button>
                </template>
            </delete-entity-card>
        </form-dialog>

        <form-dialog v-model="dialogs.updateTenant" #default="{ close }">
            <set-tenant-card
                :value="tenantToEdit"
                :loading="$wait.is('updatingTenant')"
                :title="$t('views.manageTenants.edit.title')"
                :subtitle="$t('views.manageTenants.edit.subtitle')"
            >
                <template #actions="{ isValid, form, formId, loading }">
                    <back-button @click="close"></back-button>
                    <v-spacer></v-spacer>
                    <put-tenant-button
                        :value="{ ...tenantToEdit, ...form }"
                        :valid="isValid"
                        :loading="loading"
                        :form="formId"
                        @http2xx="close().then(afterUpdateTenant({ ...tenantToEdit, ...form }))"
                    >
                    </put-tenant-button>
                </template>
            </set-tenant-card>
        </form-dialog>
    </fragment>
</template>

<i18n>
{
  "en": {
      "views.manageTenants.title": "General Settings",
      "views.manageTenants.my.title": "General Settings",
      "views.manageTenants.my.caption": "Name, currency and other general settings for this tenant.",
      "views.manageTenants.edit.button": "Edit",
      "views.manageTenants.name": "Name",
      "views.manageTenants.tenantId": "Tenant ID",
      "views.manageTenants.display.currency": "Display Currency",
      "views.manageTenants.display.mpn": "Microsoft Partner Number",
      "views.manageTenants.edit.title": "Update Tenant",
      "views.manageTenants.edit.subtitle": "Update the details of this tenant. FX Rates will be applied on the underlying cloud currencies immediately.",
      "views.manageTenants.delete.button": "Delete",
      "views.manageTenants.delete.title": "Delete Tenant",
      "views.manageTenants.delete.subtitle": "Please consider this carefully before continuing, this action cannot be reversed. You will always be able to create a new tenant and re-import your usage but you will lose all custom tags, spend limits etc. forever."
  }
}
</i18n>

<script>
import DeleteTenantButton from '@/components/Domain.Tenants/DeleteTenantButton.vue';
import PutTenantButton from '@/components/Domain.Tenants/PutTenantButton.vue';
import SetTenantCard from '@/components/Domain.Tenants/SetTenantCard.vue';
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import { useTenantStore } from '../../stores/tenant.ts';
import { mapStores } from 'pinia';

export default {
    title(ctx) {
        return ctx.$t('views.manageTenants.title');
    },
    components: {
        DeleteTenantButton,
        PutTenantButton,
        SetTenantCard,
    },
    data() {
        return {
            profiles: null,
            tenantToDelete: null,
            tenantToEdit: null,
            tagHierarchy: [],
            dialogs: {
                updateTenant: false,
                deleteTenant: false,
            },
        };
    },
    created() {
        this.loadProfiles();
    },
    computed: {
        ...mapStores(useTenantStore),
    },
    methods: {
        startEditTenant(tenant) {
            this.tenantToEdit = tenant;
            this.dialogs.updateTenant = true;
        },
        startDeleteTenant(tenant) {
            this.tenantToDelete = tenant;
            this.dialogs.deleteTenant = true;
        },
        loadProfiles: waitFor('loadingProfiles', async function () {
            this.profiles = await ApiV2.http
                .get('/api/accounts/me/profiles')
                .then((r) => r.data.filter((p) => p.tenant.id === this.tenantStore.tenantId));
            if (this.profiles.length === 0) {
                this.$router.push('/choose');
            }

            this.tenantToEdit = this.profiles[0].tenant;
            this.tagHierarchy = [
                {
                    id: 1,
                    name: this.profiles[0].tenant.tagHeirarchy[0] ?? 'not defined',
                    children: this.profiles[0].tenant.tagHeirarchy[1] ? [
                        {
                            id: 2,
                            name: this.profiles[0].tenant.tagHeirarchy[1] ?? 'not defined',
                            children: this.profiles[0].tenant.tagHeirarchy[2] ? [
                                {
                                    id: 3,
                                    name: this.profiles[0].tenant.tagHeirarchy[2] ?? 'not defined',
                                },
                            ] : [],
                        },
                    ] : [],
                },
            ];
        }),
        afterUpdateTenant(updatedTenant) {
            if (this.tenantStore.tenantId === updatedTenant.Id) {
                this.tenantStore.setTenantDetailsFromV1(updatedTenant);
            }
            this.loadProfiles();
        },
        goToChoose() {
            this.$router.push('/choose');
        },
    },
};
</script>
