
import CreateOracleAccountDialog from '@/components/Settings.CloudAccounts/Oracle/CreateOracleAccountDialog.vue';
import UpdateOracleCredentialsDialog from '@/components/Settings.CloudAccounts/Oracle/UpdateOracleCredentialsDialog.vue';
import DeleteCloudAccountDialog from '@/components/Settings.CloudAccounts/DeleteCloudAccountDialog.vue';
import EditCloudAccountNameDialog from '@/components/Settings.CloudAccounts/EditCloudAccountNameDialog.vue';
import EditCloudAccountCurrenciesDialog from '@/components/Settings.CloudAccounts/EditCloudAccountCurrenciesDialog.vue';
import OracleCloudAccountsTable, {
    oracleCloudAccountTableFilter,
} from '@/components/Settings.CloudAccounts/Oracle/OracleCloudAccountsTable.vue';
import TaxonomyType from '@/stores/models/TaxonomyType';
import { ICloudAccountNavigation } from '@/stores/models/ICloudAccountNavigation';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
    title() {
        return this.$t('settings.oracle.title');
    },
    props: {
        cloudAccounts: Array,
    },
    components: {
        OracleCloudAccountsTable,
        CreateOracleAccountDialog,
        DeleteCloudAccountDialog,
        EditCloudAccountNameDialog,
        EditCloudAccountCurrenciesDialog,
        UpdateOracleCredentialsDialog,
    },
    data() {
        return {
            isCreatingOciAccount: false,
            isEditingOciAccount: false,
            isEditingOciAccountName: false,
            isEditingAccountCurrencies: false,
            isDeletingOciAccount: false,
            focusedAccount: null,
        };
    },
    computed: {
        oracleCloudAccounts() {
            return this.cloudAccounts.filter(oracleCloudAccountTableFilter);
        },
        ...mapStores(useTenantStore)
    },
    methods: {
        handleDialogChange(isOpen) {
            if (isOpen) return;
            this.$router.push('/settings/oracle');
            this.$title = this.$t('settings.oracle.title');
        },
        onCloudAccountsChanged() {
            this.$emit('onCloudAccountsChanged');
        },
        startEditName(account) {
            this.focusedAccount = account;
            this.isEditingOciAccountName = true;
        },
        startEditCloudAccount(account) {
            this.focusedAccount = account;
            this.isEditingOciAccount = true;
        },
        copyCloudAccount(item)
        {           
           const navigationSource : ICloudAccountNavigation = {
                taxonomyType : TaxonomyType.settingsOracle,
                tenantId : null,
                cloudAccountId : null
            };             
            useCloudAccountNavigationStore().updateNavigationSource(navigationSource);           
           const url = `/copy/cloudaccounts/${item.cloudAccountId}`;         
           this.$router.push(url);
        },
        startDeleteAccount(account) {
            this.focusedAccount = account;
            this.isDeletingOciAccount = true;
        },
        startEditCurrencies(account) {
            this.focusedAccount = account;
            this.isEditingAccountCurrencies = true;
        },
    },
});
