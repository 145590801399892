import Vue from "vue";
import AppLogoImage from "@/components/Common/AppLogoImage.vue";
import BackButton from "@/components/Common/BackButton.vue";
import Card from "@/components/Common/ContentCard.vue";
import ChartCard from "@/components/Common/ChartCard.vue";
import DeleteEntityDialog from "@/components/Common/DeleteEntityDialog.vue";
import DeleteEntityCard from "@/components/Common/DeleteEntityCard.vue";
import DeleteEntityButton from "@/components/Common/DeleteEntityButton.vue";
import EmptyState from "@/components/Common/EmptyState.vue";
import FormCard from "@/components/Common/FormCard.vue";
import FormDialog from "@/components/Common/FormDialog.vue";
import PageHeader from "@/components/Common/PageHeader.vue";
import HttpPatchButton from "@/components/Common/HttpPatchButton.vue";
import HttpPutButton from "@/components/Common/HttpPutButton.vue";
import HttpDeleteButton from "@/components/Common/HttpDeleteButton.vue";
import HttpPostButton from "@/components/Common/HttpPostButton.vue";
import FigureCard from "@/components/Common/FigureCard.vue";
import WidgetGrid from "@/components/Common/WidgetGrid.vue";
import TitleSheet from "@/components/Common/TitleSheet.vue";
import TitleSheets from "@/components/Common/TitleSheets.vue";
import ComparisonIcon from "@/components/Common/ComparisonIcon.vue";
import EntitySearchAutocomplete from "@/components/Common/EntitySearchAutocomplete.vue";
import EntityTypeIcon from '@/components/Common/EntityTypeIcon.vue';
import CloudProviderTypeIcon from '@/components/Common/CloudProviderTypeIcon.vue';
import TextDatePicker from '@/components/Common/TextDatePicker.vue';
import ConditionalVMain from '@/components/Common/ConditionalVMain.vue';
import { Chart } from 'highcharts-vue';

Vue.component("app-logo-image", AppLogoImage);
Vue.component("back-button", BackButton);
Vue.component("content-card", Card);
Vue.component("chart-card", ChartCard);
Vue.component("delete-entity-dialog", DeleteEntityDialog);
Vue.component("delete-entity-card", DeleteEntityCard);
Vue.component("delete-entity-button", DeleteEntityButton);
Vue.component("empty-state", EmptyState);
Vue.component("form-card", FormCard);
Vue.component("form-dialog", FormDialog);
Vue.component("page-header", PageHeader);
Vue.component("http-patch-button", HttpPatchButton);
Vue.component("http-post-button", HttpPostButton);
Vue.component("http-delete-button", HttpDeleteButton);
Vue.component("http-put-button", HttpPutButton);
Vue.component("figure-card", FigureCard);
Vue.component("widget-grid", WidgetGrid);
Vue.component("chart", Chart);
Vue.component("title-sheet", TitleSheet);
Vue.component("title-sheets", TitleSheets);
Vue.component("comparison-icon", ComparisonIcon);
Vue.component("entity-search-autocomplete", EntitySearchAutocomplete);
Vue.component("entity-type-icon", EntityTypeIcon);
Vue.component("cloud-provider-type-icon", CloudProviderTypeIcon);
Vue.component("text-date-picker", TextDatePicker);
Vue.component("conditional-v-main", ConditionalVMain);
