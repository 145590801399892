<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <add-annotation-card
                :loading="$wait.is('isAddingAnnotation')"
                :title="$t('addAnnotationDialog.addAnnotationTitle')"
                :subtitle="$t('addAnnotationDialog.addAnnotationSubtitle')"
            >
                <template #actions="{ isValid, formId, loading, form, clear }">
                    <back-button @click="close()"></back-button>
                    <v-spacer />
                    <add-annotation-button
                        :value="{ ...form, cloudAccountId: cloudAccountId, subscriptionId: subscriptionId, serviceId: serviceId }"
                        :clear="clear"
                        :valid="isValid"
                        :form="formId"
                        :loading="loading"
                        v-wait-while="'isAddingAnnotation'"
                        @http2xx="
                            close().then(() => handleSuccessfulAnnotationChange($event))
                        "
                    />
                </template>
            </add-annotation-card>
        </template>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "addAnnotationDialog.addAnnotationTitle": "Add Annotation",
        "addAnnotationDialog.addAnnotationSubtitle": "Add a new annotation for this entity",
        "addAnnotationDialog.successfulAdd": "Annotation successfully added."
    }
}
</i18n>

<script>
import AddAnnotationCard from '@/components/Domain.Annotations/AnnotationCard.vue';
import AddAnnotationButton from '@/components/Domain.Annotations/AddAnnotationButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { AddAnnotationCard, AddAnnotationButton },
    props: {
        value: Boolean,
        cloudAccountId: String,
        subscriptionId: String,
        serviceId: String,
    },
    methods: {
        handleSuccessfulAnnotationChange(response) {
            toastSuccess(this.$t('addAnnotationDialog.successfulAdd'));
            this.$emit('http2xx', response);
        },
    },
};
</script>