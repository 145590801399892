
import { defineComponent, ref, watch, computed } from 'vue';
import {
    calculateOptionsDescription,
    convertSpendOptionsToQuery,
} from '../Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import CarbonTable, {
    convertToEmissionsItemRecords,
    ICarbonTableRecordsViewModel
} from '@/components/Domain.Carbon/CarbonTable.vue';
import { useFilterStore, storeToRefs, useTenantStore } from '@/stores';
import { getSpend } from '@/lib/Api';
import {
    generateSpendUsageOptionsFromReport,
    ISavedReportResultParams,
    ISpendUsageTableRecordsViewModel,
    PluralEntityType
} from '@/models';
import { UnitOfDisplay } from '@/enums/UnitOfDisplay.enum';
import SpendUsageUnitToggle from '@/components/Domain.Usage/SpendUsageUnitToggle.vue';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';
import hash from 'object-hash';

export default defineComponent({
    props: { config: Object, name: String, hideActions: Boolean },
    components: { SpendUsageTable, CarbonTable, SpendUsageUnitToggle },
    setup(props, { emit }) {
        const filterStore = useFilterStore();
        const { currencyCode } = storeToRefs(useTenantStore());

        const usageOptions = ref({
            ...filterStore.options,
        });

        const unitOfDisplay = ref(UnitOfDisplay.cost);
        const usageTableItems = ref<ISpendUsageTableRecordsViewModel | null>(null);
        const emissionsTableItems = ref<ICarbonTableRecordsViewModel | null>(null);
        const dataset = ref('Usage');
        const costViews = ref(['Actual', 'Amortized']);

        watch(
            () => props.config,
            (opt) => {
                if (!opt) return;
                const params = generateSpendUsageOptionsFromReport(opt as ISavedReportResultParams);
                usageOptions.value = params;
                dataset.value = opt.dataset || 'Usage';
                costViews.value = (opt.costViews || ['Actual']).map(cv => cv === 'Utilized' ? 'Amortized' : cv);
            },
            { immediate: true, deep: true }
        );

        const handleDatasetClicked = () => {
            //emit('onEdit');
        };

        const segregateBy = computed(() => {
            if (!usageOptions.value) return '';
            if (Array.isArray(usageOptions.value.segregateBy))
                return usageOptions.value.segregateBy.join(',');
            return usageOptions.value.segregateBy || '';
        });

        const lastHash = ref<string | null>(null);
        const isLoadingUsage = ref(false);
        const loadUsage = async () => {
            if (isLoadingUsage.value) return;
            const optionsHash = hash([ usageOptions.value, dataset.value, costViews.value ]);
            if (lastHash.value === optionsHash) return;
            lastHash.value = optionsHash;
            
            try {
                isLoadingUsage.value = true;
                const params = convertSpendOptionsToQuery(usageOptions.value);
                if (!params) return;

                const segregateBy = usageOptions.value.segregateBy;
                const results = await Promise.all(costViews.value.map(costView => getSpend({ ...params, costView, segregateBy })));

                if (dataset.value === 'Usage') {
                    const records = results.map((r, i) => {
                        const costView = costViews[i];
                        return SpendUsageTableFunctions.convertToItemRecords(params, segregateBy, r, costView);
                    });
                    usageTableItems.value = records[0];
                    usageTableItems.value.records = records.map((r) => r.records).reduce((a, b) => [...a, ...b], []);
                } else {
                    const records = results.map(r => {
                        return convertToEmissionsItemRecords(params, r);
                    });
                    emissionsTableItems.value = records[0];
                    emissionsTableItems.value.records = records.map(r => r.records).reduce((a, b) => [...a, ...b], []);
                }

            } finally {
                isLoadingUsage.value = false;
            }
        };

        const loadTriggers = computed(() => {
            return [ usageOptions.value, dataset.value, costViews.value ];
        });

        return {
            usageOptions,
            handleDatasetClicked,
            loadUsage,
            isLoadingUsage,
            usageTableItems,
            emissionsTableItems,
            currencyCode,
            unitOfDisplay,
            dataset,
            loadTriggers,
            segregateBy
        };
    },
    methods: {
        toTrends(searchParams: URLSearchParams) {
            this.$router.push(`/trends?${searchParams.toString()}`);
        },
    },
    watch: {
        loadTriggers: {
            handler() {
                this.loadUsage(this.toTrends);
            },
            immediate: true,
        },
    },
    computed: {
        chartTitle() {
            return this.name || calculateOptionsDescription(this.usageOptions, (...params) => this.$t(...params));
        },
    },
});
