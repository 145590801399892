
import { defineComponent, ref, computed } from 'vue';
import { BudgetPeriod, ICustomViewResult } from '@/models';
import { getCustomViews } from '@/lib/Api';
export default defineComponent({
    props: { title: String, subtitle: String, loading: Boolean },
    setup() {
        const isValid = ref(false);
        const name = ref('');
        const period = ref(BudgetPeriod.Monthly);
        const periods = ref([
            { id: BudgetPeriod.Weekly, text: 'Weekly' },
            { id: BudgetPeriod.Monthly, text: 'Monthly' },
            { id: BudgetPeriod.Quarterly, text: 'Quarterly' },
            { id: BudgetPeriod.Yearly, text: 'Yearly' },
        ]);
        const periodStartDate = ref(new Date());
        const amount = ref(0);
        const growthRatePercentage = ref(0.0);
        const customViewId = ref('');
        const customViews = ref<ICustomViewResult[]>([]);
        getCustomViews().then((v) => (customViews.value = v));
        const form = computed(() => ({
            name: name.value,
            period: period.value,
            periodStartDate: periodStartDate.value,
            amount: amount.value,
            growthRatePercentage: growthRatePercentage.value,
            customViewId: customViewId.value,
        }));
        return {
            isValid,
            period,
            periodStartDate,
            amount,
            growthRatePercentage,
            customViewId,
            customViews,
            periods,
            name,
            form,
        };
    },
});
