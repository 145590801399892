
import moment from "@/lib/moment";
import DateKey from "@/lib/DateKey";
export const DEFAULT_RANGE_TYPES = ["30d", "60d", "6m", "custom"];
import { toastError } from "@/components/Common/Toast.vue";

export interface IDateRangeViewModel {
  dateRangeKey: string;
  fromDate: Date | undefined | null;
  toDate: Date | undefined | null;
}

export default {
  props: {
    value: Object,
    items: {
      type: Array,
      default: () => DEFAULT_RANGE_TYPES,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dates: [],
      isCustomDateMenuOpen: false,
    };
  },
  methods: {
    handleUpdateDateType(dateRangeKey: string) {
      const key = new DateKey(dateRangeKey);
      if (!key.isValid || key.isCustom) return;
      const viewModel: IDateRangeViewModel = {
        dateRangeKey,
        fromDate: null,
        toDate: null,
      };
      this.$emit("input", viewModel);
    },
    handleUpdateDateRange(range) {
      if (!range || range.length !== 2) {
        toastError(this.$t("dateRangeButtonToggle.formInvalidText"));
        return;
      }
      const viewModel: IDateRangeViewModel = {
        dateRangeKey: "custom",
        fromDate: moment.utc(range[0]).toDate(),
        toDate: range[1] ? moment.utc(range[1]).toDate() : undefined,
      };
      this.$emit("input", viewModel);
    },
  },
};
