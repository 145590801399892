export interface ISpendUsageTableEntityViewModel {
    id: string;
    name: string;
    type: string;
}

export interface ISpendUsageTableItemViewModel {
    entities: ISpendUsageTableEntityViewModel[];
    total: number;
    totalConsumption: number;
    unit: string;
    isOther?: boolean;
    isTotal?: boolean;
    timestamp: number;
    costView?:string;
}

export interface ISpendUsageTableSlotViewModel {
    header: string;
    year: string;
    month: string;
    day?: string;
    itemSlotName: string;
    costValuePropName: string;
    unitValuePropName: string;
}

export interface ISpendUsageTableRecordsViewModel {
    monthSlots: ISpendUsageTableSlotViewModel[];
    dailySlots: ISpendUsageTableSlotViewModel[];
    records: ISpendUsageTableItemViewModel[];
}

export const SpendUsageTableConstants = {
    consumptionKeySuffix: '_consumption',
    slotDateFormat: 'DD-MM-YYYY'
}
