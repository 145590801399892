
import { defineComponent, getCurrentInstance, computed, PropType } from 'vue';
import MD5 from '@/lib/MD5';
import ChatMessageContent from './ChatMessageContent.vue';
import { IGptChatConversationResult } from '@/models/GptChatModels'

const AVATAR_SIZE = 40;

export default defineComponent({
    props: { chat: Object as PropType<IGptChatConversationResult> },
    components: {ChatMessageContent},
    setup(p, { emit }) {
        const gravatarUrl = computed<string>(() => {
            const email = (getCurrentInstance() as any).proxy.$auth.user.email;
            if (!email) return "http://www.gravatar.com/avatar/?d=identicon";
            const md5 = MD5(email.toLowerCase());
            return `//www.gravatar.com/avatar/${md5}?s=${AVATAR_SIZE}`;
        });

        const ask = (question:string) => {
            emit('ask', question);
        };

        return { gravatarUrl, ask };
    },
});
