
import { defineComponent, ref } from "vue";
import CarbonMissingMappingDialog from "@/components/Domain.Carbon/CarbonMissingMappingDialog.vue";

export default defineComponent({
  components: { CarbonMissingMappingDialog },
  setup() {
    const isViewingDialog = ref(false);
    return { isViewingDialog };
  },
});
