<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <update-entity-name-card
                :value="account"
                :loading="$wait.is('updatingAccountName')"
                #default="{ isValid, formId, loading, form }"
            >
                <back-button @click="close()"></back-button>
                <v-spacer></v-spacer>
                <patch-cloud-account-button
                    :value="account"
                    :patches="[{ op: 'replace', path: 'Name', value: form.Name }]"
                    :form="formId"
                    :clear="() => {}"
                    :valid="isValid"
                    :loading="loading"
                    v-wait-while="'updatingAccountName'"
                    @http2xx="close().then(() => handleSuccessfulRename(form))"
                ></patch-cloud-account-button>
            </update-entity-name-card>
        </template>
    </form-dialog>
</template>

<script>
import UpdateEntityNameCard from '@/components/Common/UpdateEntityNameCard.vue';
import PatchCloudAccountButton from '@/components/Settings.CloudAccounts/PatchCloudAccountButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    props: {
        value: Boolean,
        account: Object,
    },
    components: { UpdateEntityNameCard, PatchCloudAccountButton },
    methods: {
        handleSuccessfulRename(form) {
            toastSuccess(`Successfully renamed to ${form.Name}`);
            this.$emit('http2xx', form);
        },
    },
};
</script>