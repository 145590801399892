<template>
    <div>
        <page-header :title="recommendationsHeader" icon="bar_chart">
            <template
                v-slot:toolbar-actions
                v-if="summary.maximumEstimateImpact > 0 || $wait.is('loadRecommendations')"
            >
                <span class="mr-2 mt-3">Potential monthly savings:</span>
                <v-chip
                    label
                    color="success lighten-2 mt-2"
                    outlined
                    v-if="!summary || $wait.is('loadRecommendations')"
                >
                    <v-progress-circular color="success" indeterminate size="16"></v-progress-circular>
                </v-chip>
                <v-tooltip v-else-if="summary.includesElevatedSavings === true" bottom>
                    <template #activator="{ on }">
                        <v-chip v-on="on" label color="success lighten-2 mt-2" outlined>
                            {{ summary.maximumEstimateImpact | currency(tenantStore.currencyCode) }}
                            <div v-if="summary.includesElevatedSavings === true">*</div>
                        </v-chip>
                    </template>
                    <span>{{ $t('recommendationStatus.higherplan') }} </span>
                </v-tooltip>
                <v-chip v-else label color="success lighten-2 mt-2" outlined>
                    {{ summary.maximumEstimateImpact | currency(tenantStore.currencyCode) }}
                </v-chip>
            </template>
        </page-header>
        <v-container class="pb-0">
            <recommendation-not-available-card
                class="mt-2"
                :isWhiteLabelled="isWhiteLabelled"
                v-if="showRecommendationsNotAvailable"
            ></recommendation-not-available-card>
            <recommendation-currently-available-card>
            </recommendation-currently-available-card>
            <recommendation-credentials-required-card
                class="mt-2"
                v-if="showCredentialSetupRequired"
            ></recommendation-credentials-required-card>
        </v-container>

        <!-- Data table -->
        <v-container v-if="showRecomendations">
            <v-row>
                <v-col cols="3">
                    <v-select
                        label="Show recommendations for:"
                        :items="filterOptions"
                        variant="outlined"
                        v-model="filterRecommendationsBy"
                        v-on:change="setFilter()"
                    ></v-select>
                </v-col>
            </v-row>
            <v-skeleton-loader
                class="mx-auto"
                type="card-avatar, article, actions"
                v-if="(showRecomendations && !filteredRecommendations) || $wait.is('loadRecommendations')"
            ></v-skeleton-loader>
            <v-expansion-panels v-else v-model="panelsState" multiple>
                <empty-state
                    v-if="!filteredRecommendations || !filteredRecommendations.length"
                    icon="warning"
                    :message="noDataMessage"
                ></empty-state>
                <v-expansion-panel v-for="recommendation in filteredRecommendations" :key="recommendation.id">
                    <v-expansion-panel-header hide-actions>
                        <v-flex>
                            <recommendation-header-card
                                :value="recommendation"
                                :currencyCode="tenantStore.currencyCode"
                            ></recommendation-header-card>
                        </v-flex>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="position: relative">
                        <v-card flat>
                            <recommendation-result-table
                                :items="recommendation.results.filter((r) => r.isInFilter)"
                                :recommendation="recommendation"
                                :currencyCode="tenantStore.currencyCode"
                            >
                            </recommendation-result-table>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <edit-recommendation-setting-dialog
                                    :value="recommendationsSetting"
                                    :loading="
                                        $wait.is(['loadRecommendationSettings', 'updatingRecommendationSettings'])
                                    "
                                >
                                    <template #activator="{ on }">
                                        <v-btn
                                            color="secondary"
                                            v-on="on"
                                            @click="loadRecommendationSettings(recommendation.id)"
                                            text
                                        >
                                            <v-icon left>edit</v-icon>
                                            Configure
                                        </v-btn>
                                    </template>
                                    <template #default="{ form, formId, isValid, loading, clear, close }">
                                        <put-recommendation-settings-button
                                            :recommendationId="recommendation.id"
                                            :value="{ ...form }"
                                            :clear="clear"
                                            :valid="isValid"
                                            :form="formId"
                                            :loading="loading"
                                            v-wait-while="'updatingRecommendationSettings'"
                                            @http2xx="close()"
                                        >
                                        </put-recommendation-settings-button>
                                    </template>
                                </edit-recommendation-setting-dialog>
                            </v-card-actions>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-container>
    </div>
</template>

<i18n>
{
  "en": {
    "recommendationStatus.higherplan": "Some recommendations are not available on your current subscription"
  }
}
</i18n>

<script>
import { Recommendation } from '@/lib/Recommendations.ts';
import Api2 from '@/lib/ApiV2';
import RecommendationResultTable from '@/components/View.Recommendations/ResultTable.vue';
import RecommendationHeaderCard from '@/components/View.Recommendations/RecommendationHeaderCard.vue';
import RecommendationCurrentlyAvailableCard from '@/components/View.Recommendations/RecommendationCurrentlyAvailableCard.vue';
import RecommendationCredentialsRequiredCard from '@/components/View.Recommendations/RecommendationCredentialsRequiredCard.vue';
import EditRecommendationSettingDialog from '@/components/View.Recommendations/EditRecommendationSettingDialog.vue';
import PutRecommendationSettingsButton from '@/components/View.Recommendations/PutRecommendationSettingsButton.vue';
import { waitFor } from '@/plugins/vue-wait';
import Config from '@/lib/Config';
import RecommendationNotAvailableCard from '@/components/View.Recommendations/RecommendationNotAvailableCard.vue';
import { useTenantStore, useDatasetState } from '../stores/tenant.ts';
import { mapStores } from 'pinia';
import { CloudProviderType } from '@/models';

const ALL_VENDORS_FILTER = 'All vendors';
const FILTERS_STORAGE_KEY = 'recommendations_filter'

export default {
    title(ctx) {
        return ctx.recommendationsHeader;
    },
    components: {
        RecommendationResultTable,
        RecommendationHeaderCard,
        RecommendationCurrentlyAvailableCard,
        RecommendationCredentialsRequiredCard,
        EditRecommendationSettingDialog,
        PutRecommendationSettingsButton,
        RecommendationNotAvailableCard,
    },
    data() {
        return {
            panelsState: [],
            recommendations: [],
            elevatedFeatureRecommendations: {},
            summary: {},
            credentialStatus: 0,
            recommendationsSetting: { recommendationEnabled: true, settings: [] },
            isAssistDialogOpen: false,
            filterRecommendationsBy: ALL_VENDORS_FILTER,
            filterOptions: [
                ALL_VENDORS_FILTER, 
                ...Object.keys(CloudProviderType)
                    // Right now there is only recommendations for these two providers 
                    .filter(type => type == CloudProviderType.azure.toLowerCase() || type == CloudProviderType.amazon.toLowerCase())
                    .map((provider) => provider.charAt(0).toUpperCase() + provider.slice(1))
            ]
        };
    },
    mounted() {
        this.loadRecommendations();
        this.setFilterFromStorage();
    },
    computed: {
        recommendationsHeader() {
            return `${this.totalRecommendations} ${
                this.totalRecommendations == 1 ? 'Recommendation' : 'Recommendations'
            }`;
        },
        showRecomendations() {
            return this.totalRecommendations > 0 || this.credentialStatus == 1 || this.credentialStatus == 2;
        },
        showCredentialSetupRequired() {
            return this.credentialStatus == 2 || this.credentialStatus == 3;
        },
        showRecommendationsNotAvailable() {
            return this.elevatedFeatureRecommendations && this.elevatedFeatureRecommendations.maximumEstimateImpact > 0;
        },
        totalRecommendations() {
            return this.summary && this.summary.count ? this.summary.count : 0;
        },
        totalCost() {
            return this.filteredRecommendations.map((r) => r.cost).reduce((a, v) => a + v, 0);
        },
        isWhiteLabelled() {
            return !Config.isMainSite();
        },
        ...mapStores(useTenantStore),
        filteredRecommendations() {
            if (this.filterRecommendationsBy == ALL_VENDORS_FILTER) return this.recommendations;
            return this.recommendations.filter((rec) => rec.cloudProviderType == this.filterRecommendationsBy);
        },
        noDataMessage() {
          if (this.filterRecommendationsBy == ALL_VENDORS_FILTER) return "There's currently no recommendations to show";
          return `There's currently no recommendations for ${this.filterRecommendationsBy}, please try another filter`;
        }
    },
    methods: {
        loadRecommendations: waitFor('loadRecommendations', async function loadRecommendations() {
            try {
                const recommendationResponse = await Api2.http.get('/api/recommendations').then((r) => r.data);
                this.currencyCode = this.tenantStore.currencyCode;

                this.recommendations = recommendationResponse.recommendations
                    .map((r) => new Recommendation(r))
                    .sort((a, b) => {
                        return b.results.length - a.results.length;
                    });
                
                const { hasUsageDataset } = useDatasetState(this.tenantStore);
                if (!hasUsageDataset) {
                    this.recommendations = this.recommendations.filter(r => r.minimumEstimateImpactEmissions > 0);
                }

                this.summary = { ...recommendationResponse.summary };
                this.elevatedFeatureRecommendations = {
                    ...recommendationResponse.elevatedFeatureRecommendations,
                };
                const credentialStatusResponse = await Api2.http.get('/api/recommendations/credentialstatus');
                this.credentialStatus = credentialStatusResponse.data.credentialStatus;
                this.$title = this.recommendationsHeader;
            } catch (err) {
                this.$emit('$toast.error', 'There was an issue loading the Recommendations.');
            }
        }),
        loadRecommendationSettings: waitFor('loadRecommendationSettings', async function loadSettings(id) {
            this.recommendationsSetting = await Api2.http
                .get(`/api/recommendations/${id}/settings`)
                .then((r) => r.data);
        }),
        setFilter() {
            localStorage.setItem(FILTERS_STORAGE_KEY, this.filterRecommendationsBy);
        },
        setFilterFromStorage() {
            const persistedFilter = localStorage.getItem(FILTERS_STORAGE_KEY);
            if(persistedFilter) {
                this.filterRecommendationsBy = persistedFilter;
            }
        }
    },
};
</script>

<style scoped>
.v-expansion-panel >>> .v-expansion-panel__body {
    border-top: #ccc 5px solid;
    background-color: #f6f6f7;
}
</style>
