<template>
    <http-patch-button v-bind="$props" :value="patches" :href="url" version="v2" v-on="$listeners" @http4xx="onError">
    </http-patch-button>
</template>

<i18n>
{
    "en": {
        "patchFxRateButton.errorMessage": "There was an error encountered while updating this fx rate. If everything looks right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from '@/components/Common/Toast.vue';
import HttpPatchButton from '@/components/Common/HttpPatchButton.vue';

export default {
    props: {
        ...HttpPatchButton.props,
        form: {
            type: String,
        },
        value: {
            type: Object,
        },
        patches: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        url() {
            if (!this.value) return '/';
            else {
                return (
                    '/api/fxrates/tenant/' +
                    this.value.effectiveFromYear +
                    '/' +
                    this.value.effectiveFromMonth.toString().padStart(2, '0') +
                    '/' +
                    this.value.sourceCurrencyCode +
                    '/' +
                    this.value.targetCurrencyCode
                );
            }
        },
    },
    methods: {
        onError() {
            toastError(this.$t('patchFxRateButton.errorMessage'));
        },
    },
};
</script>