
import { defineComponent, PropType } from 'vue';
import { IGptChatOverviewResult} from '@/models/GptChatModels'
import { checkExpiredChat } from "./Shared"

export default defineComponent({
    props: { chats: Array as PropType<IGptChatOverviewResult[]>, show: Boolean, loading: Boolean },
    setup(p, { emit }) {

        const sample = () => {
            emit('sample');
        };
        
        const close = () => {
            emit('close');
        };

        const select = (chat) => {
            emit('select', chat);
            emit('close');
        };
        
        return {
            checkExpiredChat,
            close,
            select,
            sample
        };
    }
});
