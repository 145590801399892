
import { toastInfo } from '@/components/Common/Toast.vue';
import { i18n } from '@/i18n';
import { ISpendParameters, CloudProviderType, EntityType } from '@/models';
import { appendValueToCommaString } from '@/lib';

export interface IChipViewModel {
    id: string;
    name: string;
    type: EntityType;
}

export function filterUsageParamsByFilterChips(chips: IChipViewModel[], params: ISpendParameters) {
    const cloneParams = { ...params };
    chips.forEach((f) => {
        if (f.type === EntityType.service) {
            cloneParams.serviceIds = appendValueToCommaString(params.serviceIds, f.id);
        } else if (f.type === EntityType.subscription) {
            cloneParams.subscriptionIds = appendValueToCommaString(params.subscriptionIds, f.id);
        } else if (f.type === EntityType.cloudAccount) {
            cloneParams.cloudAccountIds = appendValueToCommaString(params.cloudAccountIds, f.id);
        } else if (f.type === EntityType.product) {
            cloneParams.productIds = appendValueToCommaString(params.productIds, f.id);
        } else if (f.type === EntityType.productCategory) {
            const categoryName = f.id
                .split(':')
                .reverse()
                .find((x: string) => x);
            cloneParams.productCategories = appendValueToCommaString(params.productCategories, `"${categoryName}"`);
        } else if (f.type === EntityType.region) {
            const [providerType, regionName] = f.id.split(':');
            cloneParams.providerType = providerType as CloudProviderType;
            cloneParams.regionNames = appendValueToCommaString(params.regionNames, regionName);
        } else if (f.type == EntityType.tag) {
            cloneParams.tagKeyValues = appendValueToCommaString(params.tagKeyValues, f.id)
        } else if (f.type == EntityType.geography) {
            cloneParams.geographyNames = appendValueToCommaString(params.geographyNames, f.name);
        } else if (f.type == EntityType.cloudProvider) {
            cloneParams.providerType = appendValueToCommaString(params.providerType, f.name);
        } else if (f.type == EntityType.customView) {
            cloneParams.savedViewId = appendValueToCommaString(params.savedViewId, f.id);
        }
    });
    return cloneParams;
}

export function handleSeriesClicked(chips: IChipViewModel[], series) {
    if (series.metaType === EntityType.meter) {
        toastInfo(i18n.t('shared.meterInformationNotSupported'));
    } else if (!chips.find((v) => v.id === series.metaId && v.type === series.metaType)) {
        chips.push({
            id: series.metaId,
            name: series.name,
            type: series.metaType,
        });
    }
}

export default {
    props: {
        value: Array,
    },
    methods: {
        dropFilter(filter) {
            const filtersWithoutFilter = this.value.filter((i) => i != filter);
            this.$emit('input', filtersWithoutFilter);
        },
    },
};
