<template>
    <form-card :title="title" :subtitle="subtitle" :loading="loading" v-model="valid">
        <v-text-field
            v-if="nameEditable"
            v-model="name"
            :rules="nameRules"
            required
            autofocus
            :label="$t('name')"
        ></v-text-field>

        <currency-combo-box v-model="currencyCode" :rules="currencyCodeRules" v-show="!value"> </currency-combo-box>

        <v-text-field class="mb-8" v-model="mpnOverride" :label="$t('setTenantCard.mpn')" v-if="allowMpnOverride"></v-text-field>

        <tag-key-autocomplete
            class="mb-8"
            v-model="tagHeirarchy1"
            :label="$t('setTenantCard.tagHeirarchy1')"
            :dense="false"
            :filled="false"
            :chips="false"
        >
        </tag-key-autocomplete>

        <tag-key-autocomplete
            class="mb-8"
            v-model="tagHeirarchy2"
            :label="$t('setTenantCard.tagHeirarchy2')"
            :dense="false"
            :filled="false"
            :chips="false"
        >
        </tag-key-autocomplete>

        <tag-key-autocomplete
            class="mb-8"
            v-model="tagHeirarchy3"
            :label="$t('setTenantCard.tagHeirarchy3')"
            :dense="false"
            :filled="false"
            :chips="false"
        >
        </tag-key-autocomplete>

        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :form="{
                    name: name,
                    displayCurrencyCode: currencyCode,
                    mpnOverride: mpnOverride,
                    tagHeirarchy: [tagHeirarchy1, tagHeirarchy2, tagHeirarchy3],
                }"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>
<i18n>
{
    "en": {
        "setTenantCard.name.required": "A name is required for tenants",
        "setTenantCard.currencyCode.required": "A currency code is required for tenants",
        "setTenantCard.mpn": "Microsoft Partner Number",
        "setTenantCard.tagHeirarchy1": "Tag Key 1",
        "setTenantCard.tagHeirarchy2": "Tag Key 2",
        "setTenantCard.tagHeirarchy3": "Tag Key 3"
    }
}
</i18n>

<script>
import CurrencyComboBox from '@/components/Domain.Currencies/CurrencyComboBox.vue';
import TagKeyAutocomplete from '@/components/Domain.Tags/TagKeyAutocomplete.vue';

export default {
    props: {
        loading: Boolean,
        value: Object,
        disabled: Boolean,
        title: String,
        subtitle: String,
        nameEditable: {
            type: Boolean,
            default: true,
        },
    },
    components: { CurrencyComboBox, TagKeyAutocomplete },
    data() {
        return {
            valid: false,
            name: '',
            nameRules: [(v) => !!v || this.$t('setTenantCard.name.required')],
            currencyCode: 'AUD',
            mpnOverride: '',
            allowMpnOverride: false,
            currencyCodeRules: [(v) => !!v || this.$t('setTenantCard.currencyCode.required')],
            tagHeirarchy1: 'Customer',
            tagHeirarchy2: 'Product',
            tagHeirarchy3: 'Environment',
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.allowMpnOverride = val.features.includes('AllowMpnOverride');
                this.mpnOverride = val.mpnOverride;
                this.currencyCode = val.displayCurrencyCode;
                if (val.tagHeirarchy) {
                    this.tagHeirarchy1 = val.tagHeirarchy[0];
                    this.tagHeirarchy2 = val.tagHeirarchy[1];
                    this.tagHeirarchy3 = val.tagHeirarchy[2];
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
