<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <delete-entity-card
                :title="$t('deleteBudgetDialog.title')"
                :subtitle="$t('deleteBudgetDialog.subtitle')"
                :value="{ name: budget.name }"
                :loading="$wait.is('deletingBudget')"
            >

                <template #actions="{ isValid, formId, clear, loading, form }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <DeleteBudgetButton
                        :value="budget"
                        :form="formId"
                        :clear="clear"
                        :loading="loading"
                        :valid="isValid"
                        v-wait-while="'deletingBudget'"
                        @http2xx="close().then(() => handleSuccessfulDeletion(form))"
                    >
                    </DeleteBudgetButton>
                </template>
            </delete-entity-card>
        </template>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "deleteBudgetDialog.events.successfulDeletion": "Successfully deleted the budget '{name}'",
        "deleteBudgetDialog.title": "Delete Budget",
        "deleteBudgetDialog.subtitle": "Please enter the name of this budget to delete it"
    }
}
</i18n>

<script>
import DeleteEntityCard from '@/components/Common/DeleteEntityCard.vue';
import DeleteBudgetButton from '@/components/Domain.Budgets/DeleteBudgetButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { DeleteEntityCard, DeleteBudgetButton },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        budget: {
            type: Object,
            default: null,
        },
    },
    methods: {
        handleSuccessfulDeletion(form) {
            toastSuccess(this.$t('deleteBudgetDialog.events.successfulDeletion', { name: form.Name }));
            this.$emit('http2xx', form);
        },
    },
};
</script>