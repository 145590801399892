<template>
    <form-dialog :value="value" @input="$emit('input', $event)" #default="{ close }">
        <edit-annotation-card
            :loading="$wait.is('editAnnotation')"
            :value="annotation"
            :title="$t('editAnnotationDialog.editAnnotationTitle')"
            :subtitle="$t('editAnnotationDialog.editAnnotationSubtitle')"
        >
            <template #actions="{ isValid, formId, loading, form, clear }">
                <back-button @click="close()"></back-button>
                <v-spacer></v-spacer>
                <edit-annotation-button
                    :value="{ ...form }"
                    :clear="clear"
                    :annotationId="annotation.id"
                    :valid="isValid"
                    :loading="loading"
                    :form="formId"
                    v-wait-while="'editAnnotation'"
                    @http2xx="close().then(() => handleEditSuccess($event))"
                />
            </template>
        </edit-annotation-card>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "editAnnotationDialog.editAnnotationTitle": "Edit Annotation",
        "editAnnotationDialog.editAnnotationSubtitle": "Edit annotation for this service.",
        "editAnnotationDialog.successfulEdit": "Annotation successfully updated."
    }
}
</i18n>

<script>
import EditAnnotationCard from '@/components/Domain.Annotations/AnnotationCard.vue';
import EditAnnotationButton from '@/components/Domain.Annotations/EditAnnotationButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { EditAnnotationCard, EditAnnotationButton },
    props: {
        value: Boolean,
        annotation: Object,
    },
    methods: {
        handleEditSuccess(result) {
            toastSuccess(this.$t('editAnnotationDialog.successfulEdit'));
            this.$emit('http2xx', result);
        },
    },
};
</script>