<template>
    <v-dialog
        :value="true"
        no-click-animation
        persistent
        max-width="400"
        scrollable
        hide-overlay
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <choose-tenant-card :loading="$wait.is(['loadingProfiles', 'choosingProfile'])" :items="accountStore.tenants" hide-create>
            <template #actions="{ form, formId, isValid, loading }">
                <v-btn to="/logout">
                    <v-icon left>login</v-icon>
                    {{ $t('views.chooseProfile.logout') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    :form="formId"
                    :disabled="!isValid || loading"
                    :loading="loading"
                    type="submit"
                    @click.prevent="handleChooseEvent(form.tenantId)"
                    ><v-icon left>done</v-icon>{{ $t('views.chooseProfile.login') }}</v-btn
                >
            </template>
        </choose-tenant-card>
    </v-dialog>
</template>

<i18n>
{
    "en": {
        "views.chooseProfile.title": "Choose Profile",
        "views.chooseProfile.logout": "Logout",
        "views.chooseProfile.login": "Choose"
    }
}
</i18n>

<script>
import ChooseTenantCard from '@/components/Domain.Tenants/ChooseTenantCard.vue';
import { waitFor } from '@/plugins/vue-wait';
import Config from '@/lib/Config';
import { useTenantStore } from '@/stores/tenant.ts';
import { useAccountStore } from '@/stores/account.ts';
import { mapStores } from 'pinia';

export default {
    title(ctx) {
        return ctx.$t('views.chooseProfile.title');
    },
    components: { ChooseTenantCard },
    created() {
        this.loadTenants();
    },
    computed: {
        ...mapStores(useTenantStore, useAccountStore),
    },
    methods: {
        handleChooseEvent: waitFor('choosingProfile', async function (tenantId) {
            await this.tenantStore.loadTenant(tenantId);
            this.$router.push('/');
        }),
        loadTenants: waitFor('loadingProfiles', async function () {
            await this.accountStore.loadAccountTenants();
            switch (this.accountStore.numTenants) {
                case 0:
                    if (Config.isSelfServiceEnabled()) {
                        this.$router.push('/selfservice/create');
                    }
                    break;
                case 1: {
                    const onlyTenant = this.accountStore.tenants[0];
                    await this.tenantStore.changeTenantContext(onlyTenant.tenantId);
                    this.$router.push('/');
                    break;
                }
            }
        }),
    },
};
</script>