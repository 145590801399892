<template>
  <v-icon :left="left">{{ iconValue }}</v-icon>
</template>

<script>
export default {
  props: {
    value: String,
    left: Boolean,
  },
  computed: {
    iconValue() {
      switch (this.value || "") {
        case "Subscription":
          return "cloud_queue";
        case "ProductCategory":
          return "category";
        case "Product":
          return "shopping_cart";
        case "CloudAccount":
        case "CloudProvider":
          return "cloud_download";
        case "Region":
          return "location_on";
        case "Team":
          return "business";
        case "Tag":
        case "TagKey":
          return "sell";
        case "Geography":
          return "public";
        case "Reservation":
          return "account_balance";
        case "CustomView":
          return "table_view";
        case "":
        default:
          return "memory";
      }
    },
  },
};
</script>
