<template>
    <set-rhipe-credentials-dialog
        :value="value"
        @input="$emit('input', $event)"
        :loading="$wait.is('creatingRhipeAccount')"
    >
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #actions="{ form, formId, isValid, clear, close }">
            <post-cloud-account-button
                :value="{ ...form, Type: 'Rhipe' }"
                :clear="clear"
                :valid="isValid"
                :form="formId"
                v-wait-while="'creatingRhipeAccount'"
                @http2xx="close().then(() => $emit('http2xx', $event))"
            ></post-cloud-account-button>
        </template>
    </set-rhipe-credentials-dialog>
</template>

<script>
import SetRhipeCredentialsDialog from '@/components/Settings.CloudAccounts/Distributors/SetRhipeCredentialsDialog';
import PostCloudAccountButton from '@/components/Settings.CloudAccounts/PostCloudAccountButton';
export default {
    components: { SetRhipeCredentialsDialog, PostCloudAccountButton },
    props: {
        value: Boolean,
    },
};
</script>