<template>
    <entity-search-autocomplete
        v-bind="$props"
        :filled="filled"
        :types="['TagKey']"
        :value="tagKeyEntity"
        :prepend-icon="null"
        @input="handleTagKeySelection"
        :label="label || $t('tagKeyAutocomplete.tagKey.label')"
        hide-details
        support-create
    ></entity-search-autocomplete>
</template>

<i18n>
{
    "en": {
        "tagKeyAutocomplete.tagKey.label": "Key"
    }
}
</i18n>

<script>
import EntitySearchAutocomplete from '@/components/Common/EntitySearchAutocomplete.vue';

export default {
    props: {
        ...EntitySearchAutocomplete.props,
        filled: {
            type: Boolean,
            default: true
        },
        value: String,
    },
    components: { EntitySearchAutocomplete },
    computed: {
        tagKeyEntity() {
            if (!this.value) return null;
            return {
                Id: this.value,
                Name: this.value,
                Description: 'TagKey',
                Provider: '',
                Type: 'TagKey',
                EntityIndex: 7,
            };
        },
    },
    methods: {
        handleTagKeySelection(tagKey) {
            if (!tagKey) this.$emit('input', null);
            else this.$emit('input', tagKey.Id);
        },
    },
};
</script>
