
import SetFxRateCard from '@/components/Domain.FxRates/SetFxRateCard.vue';
export default {
    props: ['value', 'loading', 'disabled', 'edit'],
    components: { SetFxRateCard },
    methods: {
        open() {
            this.$emit('input', true);
        },
    },
};
