<template>
    <form-card
        :title="$t('setCloudAccountCurrenciesCard.title')"
        :subtitle="$t('setCloudAccountCurrenciesCard.subtitle')"
        :loading="loading"
        v-model="valid"
    >
        <v-alert color="warning" text>
            <v-icon left color="warning">warning</v-icon>
            {{ $t('setCloudAccountCurrenciesCard.alert') }}
        </v-alert>
        <v-checkbox
            v-model="useTenantCurrencies"
            :label="$t('useTenantCurrencies')"
            :disabled="disabled && disabled.UseTenantCurrencies"
        >
            <template #append>
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn icon small v-on="on" v-bind="attrs">
                            <v-icon>info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('useTenantCurrencies.tooltip') }}</span>
                </v-tooltip>
            </template>
        </v-checkbox>
        <template #actions="formId, reset">
            <slot
                name="actions"
                :form="{ UseTenantCurrencies: useTenantCurrencies }"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "setCloudAccountCurrenciesCard.title": "Set Currency Source",
        "setCloudAccountCurrenciesCard.subtitle": "Change the currency source of this cloud connection.",
        "setCloudAccountCurrenciesCard.alert": "Changes to the currency source of a cloud connection only apply to the current month on."
    }
}
</i18n>

<script>
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            useTenantCurrencies: false,
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.useTenantCurrencies = val.UseTenantCurrencies;
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

