
import AlibabaRegionAutocomplete from '@/components/Settings.CloudAccounts/Alibaba/AlibabaRegionAutocomplete.vue';
import Config from '@/lib/Config';
export default {
    components: { AlibabaRegionAutocomplete },
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            valid: false,
            docsUrl: Config.get().DOCS_URL,
            name: '',
            nameRules: [(v) => !!v || this.$t('setAlibabaCredentialsCard.name.required')],
            useTenantCurrencies: false,
            accessKeyId: '',
            accessKeyIdRules: [(v) => !!v || this.$t('setAlibabaCredentialsCard.accessKeyId.required')],
            regionCode: '',
            showSecretAccessKey: false,
            secretAccessKey: '',
            secretAccessKeyRules: [(v) => !!v || this.$t('setAlibabaCredentialsCard.secretAccessKey.required')],
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                if (val.alibabaUsageCredential) {
                    this.accessKeyId = val.alibabaUsageCredential.providerId;
                    this.secretAccessKey = val.alibabaUsageCredential.token;
                    this.regionCode = val.alibabaUsageCredential.regionCode;
                } else {
                    this.accessKeyId = '';
                    this.secretAccessKey = '';
                    this.regionCode = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
