
import GptCreate from "@/components/Domain.GptChat/GptCreate.vue";
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import { defineComponent } from "vue";
import { checkExpiredChat } from "@/components/Domain.GptChat/Shared"
import ChatConversation from '@/components/Domain.GptChat/ChatConversation.vue';
import ChatsDialog from '@/components/Domain.GptChat/ChatsDialog.vue';
import { 
    IGptChatOverviewResult, 
    IGptChatConversationResult, 
    IGptChatMessageResult
} from '@/models/GptChatModels'

export default defineComponent({
    title(ctx) {
        return ctx.$t('views.gptChats.title');
    },
    components: {ChatConversation, ChatsDialog, GptCreate},
    async mounted() {
        const chatId = this.$route.query.chatId
        this.getChats(chatId);
    },
    data() {
        return {
            email: (this as any).$auth.user.email as string,
            showChatsDialog: false,
            chats: [] as Array<IGptChatOverviewResult>,
            currentChat: null as IGptChatConversationResult,
            chatMessage: ""
        };
    },
    computed: {
        disableChat() {
            if (this.currentChat == null) return true;
            return checkExpiredChat(this.currentChat.createdAtUtc);
        }
    },
    methods: {
        ask(question:string) {
            this.chatMessage = question;
            this.submitAndRefresh();
        },

        chatCreated(chat:IGptChatOverviewResult) {
            this.getChats(chat.chatId);
        },

        getChats: waitFor('getChats', async function getChats(chatId: string | null) {
            if (this.currentChat == null && !chatId) this.showChatsDialog = true;
            const response = await ApiV2.http.get(`/api/gpt/`).then((r) => r.data);
            this.chats = response;

            if (chatId) {
                await this.selectChat(chatId);
            }
        }),

        selectChat: waitFor('getChat', async function selectChat(chatId : string) {
            this.$router.replace({ query: {chatId: chatId} })
            this.showChatsDialog = false;
            const response = await ApiV2.http
                .get<IGptChatConversationResult>(`/api/gpt/${chatId}`)
                .then((r) => r.data);
            this.currentChat = response;
            this.chatMessage = "";
            this.scrollDown();
        }),

        submitAndRefresh: waitFor('addChatMessage', async function addChatMessage() {
            const chatId = this.currentChat?.chatId;
            const message = this.chatMessage;

            this.currentChat.messages.push({isUser: true, message: message});
            this.currentChat = {...this.currentChat};

            this.chatMessage = "";
            this.scrollDown();

            const response = await ApiV2.http
                .post<IGptChatMessageResult>(`/api/gpt/${chatId}/message`, {
                    chatId: chatId,
                    question: message
                })
                .then((r) => r.data)
                .catch(() => ({chatId: '', message: "There was an error! Please try again."}));

            this.currentChat.messages.push(response.message);
            this.currentChat = {...this.currentChat};
            this.scrollDown();
        }),

        scrollDown() {
            this.$vuetify.goTo(999999)
        }
    }
})
