
import CarbonMissingMappingCard from "@/components/Domain.Carbon/CarbonMissingMappingCard.vue";
import { defineComponent, ref, watch } from "vue";
import { getMissingCo2eReport } from "@/lib/Api";
import { ISpendParameters, Granularity } from "@/models";
export default defineComponent({
  components: { CarbonMissingMappingCard },
  props: {
    value: Boolean,
  },
  setup(p) {
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 3);
    const fixedParameters: Partial<ISpendParameters> = {
      fromDate: fromDate.toISOString(),
      toDate: new Date().toISOString(),
      granularity: Granularity.monthly,
    };
    const items = ref([]);
    watch(
      () => p.value,
      async (val, oldVal) => {
        if (!val || oldVal) return;
        getMissingCo2eReport(fixedParameters).then((r) => {
          items.value = r;
        });
      },
      { immediate: true }
    );

    return { items };
  },
});
