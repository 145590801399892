
import SubscriptionsDialog from '@/components/Settings.CloudAccounts/Azure/ShowAzureSubscriptionsDialog.vue';
import TaxonomyType from '@/stores/models/TaxonomyType';
import { ICloudAccountNavigation } from '@/stores/models/ICloudAccountNavigation';
import { useCloudAccountNavigationStore } from '@/stores/cloudAccountNavigation';

export function azureCloudAccountTableFilter(ca) {
    return ca.providerType === 'Azure';
}

export default {
    components: {
        SubscriptionsDialog,
    },
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        routePrefix: {
            type: String,
            default: '/settings/azure',
        },
    },
    methods: {
        showAzureSubscriptions(item) {
            if (
                !item._original.azureActiveDirectoryApplicationCredential ||
                !item._original.azureActiveDirectoryApplicationCredential.id
            ) {
                return;
            }

            this.selectedCredential = {
                cloudAccountId: item._original.cloudAccountId,
                credentialId: item._original.azureActiveDirectoryApplicationCredential.id,
            };
            this.showSubscriptions = true;
        },
        updateRouteHistory(item)
        {
            const navigationHistory: ICloudAccountNavigation = {
                taxonomyType : TaxonomyType.settingsAzureCloudAccount,
                tenantId : null,
                cloudAccountId : item.Id
            };    
            useCloudAccountNavigationStore().updateNavigationSource(navigationHistory);                      
            return this.$router.push(`${this.routePrefix}/${item.Id}`);
        },  
    },
    computed: {
        tableEntities() {
            if (!this.items) return [];
            const entities = this.items.filter(azureCloudAccountTableFilter).map((ca) => {
                const tableEntity: any = {
                    Id: ca.id,
                    Name: ca.name,
                    ARMConnectionEnabled: ca.azureActiveDirectoryApplicationCredential
                        ? ca.azureActiveDirectoryApplicationCredential.valid
                        : false,
                    ARMConnectionTenantId: ca.azureActiveDirectoryApplicationCredential
                        ? ca.azureActiveDirectoryApplicationCredential.clientDomain
                        : false,
                    _original: ca,
                };
                switch (ca.type) {
                    case 'Azure':
                        tableEntity.UsageConnectionType1 = 'Azure App Credential';
                        tableEntity.UsageConnectionEnabled = tableEntity.ARMConnectionEnabled;
                        tableEntity.EnhancedConnectionEnabled = tableEntity.ARMConnectionEnabled;
                        break;
                    case 'AzureEa':
                        tableEntity.UsageConnectionType1 = 'Enterprise Agreement';
                        tableEntity.UsageConnectionType2 = 'Azure App Credential';
                        tableEntity.UsageConnectionEnabled = ca.eaPortalApiCredential
                            ? ca.eaPortalApiCredential.valid
                            : false;
                        tableEntity.EnhancedConnectionEnabled = ca.azureActiveDirectoryApplicationCredential
                            ? ca.azureActiveDirectoryApplicationCredential.valid
                            : false;
                        break;
                    case 'Rhipe':
                        tableEntity.UsageConnectionType1 = 'Rhipe Customer';
                        tableEntity.UsageConnectionType2 = 'Azure App Credential';
                        tableEntity.UsageConnectionEnabled = ca.rhipeApiCredential
                            ? ca.rhipeApiCredential.valid
                            : false;
                        tableEntity.EnhancedConnectionEnabled = ca.azureActiveDirectoryApplicationCredential
                            ? ca.azureActiveDirectoryApplicationCredential.valid
                            : false;
                        break;
                    case 'SelfReferential':
                        tableEntity.UsageConnectionType1 = 'From Partner';
                        tableEntity.UsageConnectionType2 = 'Azure App Credential';
                        tableEntity.UsageConnectionEnabled = true;
                        tableEntity.EnhancedConnectionEnabled = ca.azureActiveDirectoryApplicationCredential
                            ? ca.azureActiveDirectoryApplicationCredential.valid
                            : false;
                        break;
                }
                return tableEntity;
            });
            return entities;
        },
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'Name',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
    data() {
        return {
            expanded: [],
            allStatuses: true,
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
            showSubscriptions: false,
            selectedCredential: {
                cloudAccountId: null,
                credentialId: null,
            },
        };
    },
};
