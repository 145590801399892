
import { defineComponent, ref } from 'vue';
import CreateFxRateDialog from '@/components/Domain.FxRates/CreateFxRateDialog.vue';
import FxRateMenu from '@/components/Domain.FxRates/FxRateMenu.vue';
import CurrencyService from '@/lib/Currencies';
import ApiV2 from '@/lib/ApiV2';
import moment from '@/lib/moment';

export default defineComponent({
    components: { CreateFxRateDialog, FxRateMenu },
    setup() {
        const tenantFxRates = ref([]);
        const fixedFxRates = ref([]);
        const priorMonths = ref(
            Array(12)
                .fill(null)
                .map((_, i) => moment().add(-1 * i, 'months'))
                .map((d) => ({ value: d.toDate(), text: d.format("MMM 'YY") }))
        );
        const targetCurrencyCode = ref(null);
        const currencyCodes = ref([]);
        const selectedMonth = ref(priorMonths.value[0].value);
        const isAdding = ref(false);
        const getTenantCurrencies = async () => {
            tenantFxRates.value = await ApiV2.http.get('/api/fxrates/tenant').then((r) => r.data);
        };
        const getFixedCurrencies = async () => {
            const year = selectedMonth.value.getFullYear();
            const month = selectedMonth.value.getMonth() + 1;
            fixedFxRates.value = await ApiV2.http
                .get(`/api/fxrates/system/${year}/${month}`, {
                    params: { targetCurrencyCode: targetCurrencyCode.value },
                })
                .then((r) => r.data);
        };
        const getKnownCurrencyCodes = async () => {
            currencyCodes.value = await CurrencyService.getAllCurrencyCodes();
        };
        getKnownCurrencyCodes();
        getTenantCurrencies();
        getFixedCurrencies();
        return {
            tenantFxRates,
            fixedFxRates,
            priorMonths,
            selectedMonth,
            currencyCodes,
            targetCurrencyCode,
            getTenantCurrencies,
            getFixedCurrencies,
            isAdding,
        };
    },
});
