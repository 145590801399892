enum TaxonomyType {
    manage = 'manage',
    settingsAzure = 'settingsAzure',
    settingsAzureCloudAccount = 'settingsAzureCloudAccount',    
    settingsAWS = 'settingsAWS',
    settingsAWSCloudAccount = 'settingsAWSCloudAccount',    
    settingsOracle = 'settingsOracle',
    settingsOracleCloudAccount = 'settingsOracleCloudAccount',   
    settingsSnowflake = 'settingsSnowflake',
    settingsSnowflakeCloudAccount = 'settingsSnowflakeCloudAccount',
}

export default TaxonomyType;