<template>
    <form-card :title="$t('selfservice.createTenant.title')" v-model="valid">
        <v-text-field
            v-model="name"
            :rules="[$rules.required()]"
            required
            autofocus
            :label="$t('selfservice.createTenant.name')"
        ></v-text-field>
        <v-text-field
            v-model="billingEmail"
            :rules="[$rules.required(), $rules.email()]"
            required
            :label="$t('selfservice.createTenant.billingEmail')"
        ></v-text-field>

        <currency-combo-box v-model="displayCurrencyCode" :rules="[$rules.required()]"> </currency-combo-box>

        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :form="{
                    CustomerName: name,
                    BillingEmailAddress: billingEmail,
                    DisplayCurrencyCode: displayCurrencyCode,
                }"
                :formId="formId"
                :isValid="valid"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
    {
    "en": {
    "selfservice.createTenant.title": "Create a new Customer Account",
    "selfservice.createTenant.name": "Customer Name",
    "selfservice.createTenant.billingEmail": "Billing Email Address"
    }
    }
</i18n>

<script>
import CurrencyComboBox from '@/components/Domain.Currencies/CurrencyComboBox.vue';
export default {
    components: { CurrencyComboBox },
    data() {
        return {
            valid: false,
            name: '',
            displayCurrencyCode: '',
            billingEmail: '',
        };
    },
};
</script>