<template>
    <http-delete-button v-bind="$props" v-on="$listeners" :href="url" version="v2" @http4xx="onError">
    </http-delete-button>
</template>

<i18n>
{
    "en": {
        "deleteFxRateButton.errorMessage": "There was an error encountered while deleting this fx rate. If everything looks right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from '@/components/Common/Toast.vue';
import HttpDeleteButton from '@/components/Common/HttpDeleteButton.vue';

export default {
    props: {
        ...HttpDeleteButton.props,
        value: {
            type: Object,
        },
    },
    computed: {
        url() {
            if (!this.value) return '/';
            else {
                return (
                    '/api/fxrates/tenant/' +
                    this.value.effectiveFromYear +
                    '/' +
                    this.value.effectiveFromMonth.toString().padStart(2, '0') +
                    '/' +
                    this.value.sourceCurrencyCode +
                    '/' +
                    this.value.targetCurrencyCode
                );
            }
        },
    },
    methods: {
        onError() {
            toastError(this.$t('deleteFxRateButton.errorMessage'));
        },
    },
};
</script>