<template>
    <card-base :light="light">
        <v-card-title>
            <div>
                <h2 class="title">
                    <v-icon v-if="titleIcon" left>{{ titleIcon }}</v-icon>
                    {{ title }}
                </h2>
                <h3 class="body-2" v-if="caption">{{ caption }}</h3>
            </div>
            <v-layout row justify-end><v-spacer /><slot name="title-actions"></slot></v-layout>
        </v-card-title>

        <v-progress-linear class="ma-0" :active="loading" indeterminate></v-progress-linear>

        <slot name="body">
            <v-card-text class="pt-1">
                <slot></slot>
            </v-card-text>
        </slot>

        <v-card-actions>
            <slot name="actions"></slot>
        </v-card-actions>
    </card-base>
</template>

<script>
import CardBase from '@/components/Common/ContentCardBase.vue';

export default {
    props: {
        title: String,
        titleIcon: String,
        caption: String,
        loading: Boolean,
        light: {
            type: Boolean,
            default: false,
        },
    },
    components: { CardBase },
};
</script>

