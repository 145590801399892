
import { defineComponent, ref } from "vue";
import { CloudAccountType, CloudCredentialType } from "@/models";
import ApiV2 from "@/lib/ApiV2";
import Config from "@/lib/Config";
import FeatureNames from "@/lib/FeatureNames";
import PrettyListItem from "@/components/Common/PrettyListItem.vue";
import CreateOracleAccountDialog from "@/components/Settings.CloudAccounts/Oracle/CreateOracleAccountDialog.vue";
import CreateAmazonWSCloudAccountDialog from "@/components/Settings.CloudAccounts/Amazon/CreateAmazonWSCloudAccountDialog.vue";
import CreateAmazonAssumeRoleCloudAccountDialog from "@/components/Settings.CloudAccounts/Amazon/CreateAmazonAssumeRoleCloudAccountDialog.vue";
import CreateAmazonAccountMenu from "@/components/Settings.CloudAccounts/Amazon/CreateAmazonAccountMenu.vue";
import CreateAzureCloudAccountDialog from "@/components/Settings.CloudAccounts/Azure/CreateAzureCloudAccountDialog.vue";
import CreateAzureEaCloudAccountDialog from "@/components/Settings.CloudAccounts/Azure/CreateAzureEaCloudAccountDialog.vue";
import CreateRhipeCloudAccountDialog from "@/components/Settings.CloudAccounts/Distributors/CreateRhipeCloudAccountDialog.vue";
import CreateAlibabaCloudAccountDialog from "@/components/Settings.CloudAccounts/Alibaba/CreateAlibabaCloudAccountDialog.vue";
import CreateGoogleAccountDialog from "@/components/Settings.CloudAccounts/Google/CreateGoogleAccountDialog.vue";
import UsersView from "@/views/Settings/UsersView.vue";

export default defineComponent({
  title(ctx) {
    return ctx.$t("gettingStarted.title");
  },
  components: {
    PrettyListItem,
    CreateOracleAccountDialog,
    CreateAmazonWSCloudAccountDialog,
    CreateAmazonAssumeRoleCloudAccountDialog,
    CreateAzureCloudAccountDialog,
    CreateAzureEaCloudAccountDialog,
    CreateRhipeCloudAccountDialog,
    CreateAlibabaCloudAccountDialog,
    CreateAmazonAccountMenu,
    CreateGoogleAccountDialog,
    UsersView,
  },
  setup() {
    const configuredTypes = ref([]);

    const loadCloudAccountTypes = async () => {
      const cloudAccounts = await ApiV2.http
        .get("/api/metadata/cloudaccounts")
        .then((r) => r.data);
      const cloudAccountTypes = cloudAccounts
        .map((a) => a.type)
        .filter((value, index, self) => self.indexOf(value) === index);
      configuredTypes.value = cloudAccountTypes;
    };

    const onCloudAccountsChanged = () => {
      loadCloudAccountTypes();
    };

    loadCloudAccountTypes();

    const isCreatingAwsAccountNew = ref(false);
    const isCreatingAwsAccountLegacy = ref(false);
    const isCreatingAzureArmAccount = ref(false);
    const isCreatingAzureEaAccount = ref(false);
    const isCreatingRhipeAccount = ref(false);
    const isCreatingGcpAccount = ref(false);
    const isCreatingAlibabaAccount = ref(false);
    const isCreatingOracleAccount = ref(false);

    const handleCreateNewAmazonAccount = (
      key: CloudCredentialType.awsAssumeRoleUsage | CloudCredentialType.awsIamUsage
    ) => {
      if (key === CloudCredentialType.awsAssumeRoleUsage) {
        isCreatingAwsAccountNew.value = true;
      } else if (key === CloudCredentialType.awsIamUsage) {
        isCreatingAwsAccountLegacy.value = true;
      }
    };

    const onClickAccountType = (type: string) => {
      switch (type) {
        case CloudAccountType.azure:
          isCreatingAzureArmAccount.value = true;
          break;
        case CloudAccountType.azureEa:
          isCreatingAzureEaAccount.value = true;
          break;
        case CloudAccountType.rhipe:
          isCreatingRhipeAccount.value = true;
          break;
        case CloudAccountType.amazon:
          isCreatingAwsAccountLegacy.value = true;
          break;
        case CloudAccountType.google:
          isCreatingGcpAccount.value = true;
          break;
        case CloudAccountType.alibaba:
          isCreatingAlibabaAccount.value = true;
          break;
        case CloudAccountType.oracle:
          isCreatingOracleAccount.value = true;
          break;
      }
    };

    const cloudAccountTypes = ref([
      CloudAccountType.azure,
      CloudAccountType.amazon,
      CloudAccountType.google,
      CloudAccountType.alibaba,
      CloudAccountType.oracle,
    ]);

    if (Config.isFeatureEnabled(FeatureNames.rhipeEnabled)) {
      cloudAccountTypes.value.push(CloudAccountType.rhipe);
    }

    return {
      e1: ref(1),
      cloudAccountTypes,
      configuredTypes,
      isCreatingAwsAccountLegacy,
      isCreatingAwsAccountNew,
      isCreatingAzureArmAccount,
      isCreatingAzureEaAccount,
      isCreatingRhipeAccount,
      isCreatingGcpAccount,
      isCreatingAlibabaAccount,
      isCreatingOracleAccount,
      onCloudAccountsChanged,
      onClickAccountType,
      handleCreateNewAmazonAccount,
    };
  },
});
