export class BillingPlan {
    public planName: string;
    public planId: number;
    public planDescription: string;
    public includedSpendInAud?: number;
    public overagePercentage?: number;

    constructor(plan: any) {
        this.planId = plan.planId;
        this.planName = plan.planName;
        this.planDescription = plan.planDescription;
        this.includedSpendInAud = plan.includedSpendInAud;
        this.overagePercentage = plan.overagePercentage;
    }
}

export class BillingDetailsStatus {
    public billingStatus: number;
    public remainingDays: any;
    public plan?: BillingPlan;
    public totalSpendInAud: number;
    public isSelfService: boolean;

    constructor(status: any) {
        if (status) {
            this.billingStatus = status.billingStatus;
            this.remainingDays = status.remainingDays;
            this.plan = (status.planDetails) ? new BillingPlan(status.planDetails) : null;
            this.totalSpendInAud = status.totalSpendInAud;
            this.isSelfService = status.isSelfService;
        }
    }

    get color() {
        switch (this.billingStatus) {
            case 1: // FREE_TRIAL_COUNTDOWN
                return 'warning';
            case 2: // FULL_ACCOUNT_BILLED
            case 3: // FULL_ACCOUNT_BILLED_PROVIDER
                return 'success';
            default: // NO_BILLING_AGREEMENT
                return 'warning';
        }
    }

    get icon() {
        switch (this.billingStatus) {
            case 1: // FREE_TRIAL_COUNTDOWN
                return 'watch_later';
            case 2: // FULL_ACCOUNT_BILLED
            case 3: // FULL_ACCOUNT_BILLED_PROVIDER
                return 'check';
            case 4: // EXPIRED
            default: // NO_BILLING_AGREEMENT
                return 'warning';
        }
    }

    get description() {
        let description = "";
        switch (this.billingStatus) {
            case 1: // FREE_TRIAL_COUNTDOWN
                description = `You are currently on a free trial which ends in ${this.remainingDays} days.`;
                break;
            case 2: // FULL_ACCOUNT_BILLED
                description = "Full Account. Monthly Billing.";
                break;
            case 3: // FULL_ACCOUNT_BILLED_PROVIDER
                description = "Usage for this account is billed to your provider.";
                break;
            case 4: // EXPIRED
                description = "Your billing plan has expired which means no data will be loaded.";
                break;
            default: // NO_BILLING_AGREEMENT
                description = "There is no subscription in place for this account which means no data will be loaded.";
                break;
        }

        return description;
    }
}
