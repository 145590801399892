<template>
    <v-btn
        :type="type"
        :color="color"
        depressed
        :disabled="!valid || loading"
        :loading="loading"
        :form="form"
        @click.prevent="submit()"
        :block="block"
        :outlined="outlined"
    >
        <slot><v-icon left>done</v-icon>Update</slot>
    </v-btn>
</template>

<script>
import ApiV2 from '@/lib/ApiV2';

export default {
    props: {
        value: {
            type: Array,
        },
        href: {
            type: String,
        },
        clear: {
            type: Function,
            default: () => void 0,
        },
        valid: {
            type: Boolean,
            default: true,
        },
        form: {
            type: String,
        },
        color: {
            type: String,
            default: 'secondary',
        },
        type: {
            type: String,
            default: 'submit',
        },
        block: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        isJsonPatch: {
            type: Boolean,
            default: false,
        },
        version: {
            type: String,
            default: 'v2',
        },
    },
    data() {
        return { loading: false };
    },
    methods: {
        async submit() {
            this.$emit('start');
            this.loading = true;
            const jsonPatchHeaders = { headers: { 'Content-Type': 'application/json-patch+json' } };
            try {
                const Api = ApiV2;
                const response = this.isJsonPatch
                    ? await Api.http.patch(this.href, this.value, jsonPatchHeaders).then((r) => r.data)
                    : await Api.http.patch(this.href, this.value).then((r) => r.data);
                this.clear();
                this.$emit('http2xx', response);
            } catch (err) {
                this.$emit('http4xx', err.response);
            } finally {
                this.loading = false;
                this.$emit('stop');
            }
        },
    },
};
</script>
