var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('fragment',[_c('page-header',{attrs:{"title":_vm.$t('Customers'),"icon":"content_copy","extended":"","extension-height":10}},[_c('v-btn',{attrs:{"color":"secondary","fab":"","absolute":"","depressed":"","bottom":"","large":"","right":""},on:{"click":function($event){return _vm.exportCustomerCsv()}}},[_c('v-icon',[_vm._v("cloud_download")])],1)],1),_c('widget-grid',{staticClass:"mt-7"},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('figure-card',{attrs:{"title":_vm._f("currency")(_vm.totalRevenue,_vm.tenantStore.currencyCode),"description":_vm.tenantStore.hasTenantFeature('HideProfit') ? _vm.$t('views.customerUsage.cards.totalCost') : _vm.$t('views.customerUsage.cards.totalRevenue'),"caption":_vm.$t('views.customerUsage.dateRangeFormat', {
            startPeriodString: _vm.startPeriodString,
            endPeriodString: _vm.endPeriodString,
          })}})],1),(!_vm.tenantStore.hasTenantFeature('HideProfit'))?_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('figure-card',{attrs:{"title":_vm._f("currency")(_vm.totalProfit,_vm.tenantStore.currencyCode),"description":_vm.$t('views.customerUsage.cards.totalProfit'),"caption":_vm.$t('views.customerUsage.dateRangeFormat', {
            startPeriodString: _vm.startPeriodString,
            endPeriodString: _vm.endPeriodString,
          })}})],1):_vm._e(),(!_vm.tenantStore.hasTenantFeature('HideProfit'))?_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('figure-card',{attrs:{"title":`${_vm.totalMargin ? _vm.totalMargin.toFixed(2) : 0}%`,"description":_vm.$t('views.customerUsage.cards.margin'),"caption":_vm.$t('views.customerUsage.dateRangeFormat', {
            startPeriodString: _vm.startPeriodString,
            endPeriodString: _vm.endPeriodString,
          })}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('figure-card',{attrs:{"title":_vm.numberCustomers,"description":_vm.$t('views.customerUsage.cards.totalCustomers'),"caption":_vm.$t('views.customerUsage.dateRangeFormat', {
            startPeriodString: _vm.startPeriodString,
            endPeriodString: _vm.endPeriodString,
          })}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('content-card',{attrs:{"title":"Spend by Month"}},[_c('customers-chart',{attrs:{"data":_vm.chartDatasets}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('customers-table',{ref:"customers-table",attrs:{"items":_vm.summary ? _vm.summary.customers : [],"currencyCode":_vm.tenantStore.currencyCode,"loading":_vm.$wait.is('loadingCustomerStats'),"hideProfit":_vm.tenantStore.hasTenantFeature('HideProfit')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }