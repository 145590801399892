<template>
    <fragment>
        <v-alert border="left" text type="info" elevation="0" class="mt-3">
            {{ $t('serviceDialog.tags.sourceDescription') }}
        </v-alert>
        <tag-form :loading="loading || $wait.is('getServiceTags')" :value="formData" hide-create> </tag-form>
    </fragment>
</template>

<i18n>
{
    "en": {
        "serviceDialog.tags.sourceDescription": "The tags visible here are sourced from the last 60 days of usage records for this service. To update the tags of this service, please tag the corresponding resource in the source cloud provider. We recommend utilizing resource policies where appropriate to speed this up."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2.ts';
import { waitFor } from '@/plugins/vue-wait';
import TagForm from '@/components/Domain.Tags/TagForm.vue';
import moment from '@/lib/moment';
export default {
    props: ['value', 'loading'],
    components: { TagForm },
    data() {
        return {
            formData: { valid: false, data: [] },
        };
    },
    watch: {
        value: {
            handler(svc) {
                if (!svc) return;
                this.getServiceTags(svc.serviceId);
            },
            immediate: true,
        },
    },
    methods: {
        getServiceTags: waitFor('getServiceTags', async function (id) {
            const params = {
                toDate: moment.utc().format(),
                fromDate: moment.utc().add(-60, 'days').format()
            };
            this.formData.data = await ApiV2.http.get(`/api/metadata/services/${id}/recordtags`, { params }).then((r) =>
                r.data.map((t) => ({
                    Key: t.tagKey,
                    Value: t.tagValue,
                    Source: 'Record',
                    Sources: ['Record'],
                    Editable: false,
                }))
            );
        }),
    },
    computed: {
        tagsForSubmission() {
            if (!this.formData || !this.formData.data) return [];
            return this.formData.data.map((t) => ({ Name: `${t.Key.trim()}:${t.Value.trim()}` }));
        },
    },
};
</script>