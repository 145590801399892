
import { defineComponent, ref } from "vue";
import { RawUsageFields } from "@/models";

const KNOWN_FIELD_TYPES = Object.values(RawUsageFields);

export default defineComponent({
  props: { value: String, label: String, hideDetails: Boolean, items: Array },
  setup() {
    const fieldTypes = ref(KNOWN_FIELD_TYPES);
    return { fieldTypes };
  },
});
