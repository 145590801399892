// Function to convert a string to a Uint8Array
export const stringToUint = (string: string): Promise<Uint8Array> => {
    const blob = stringToBlob(string);
    return blobToUint8Array(blob);
};

// Function to serialize a string to a Blob
export const stringToBlob = (string: string): Blob => {
    return new Blob([string], { type: 'text/plain' });
};

// Function to convert a Blob to a Uint8Array
export const blobToUint8Array = (blob: Blob): Promise<Uint8Array> => {
    return new Promise<Uint8Array>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            resolve(new Uint8Array(event.target.result as ArrayBuffer));
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsArrayBuffer(blob);
    });
};

// Function to deserialize a Blob back to a string
export const blobToString = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            resolve(event.target.result as string);
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsText(blob);
    });
};
