enum CloudProviderType {
    azure = 'Azure',
    amazon = 'Amazon',
    google = 'Google',
    softLayer = 'Softlayer',
    vmWare = 'VmWare',
    alibaba = 'Alibaba',
    custom = 'Custom',
    oracle = 'Oracle',
    snowflake = 'SnowFlake'
}

export default CloudProviderType;