
import { defineComponent, ref } from "vue";

export enum SimpleConditionTypes {
  sourceRowField_insensitive = "SourceRowField_Insensitive",
  sourceRowField_sensitive = "SourceRowField_Sensitive",

  sourceTagKeyValue_insensitive = "SourceTagKeyValue_Insensitive",
  sourceTagKeyValue_sensitive = "SourceTagKeyValue_Sensitive",

  subscription_insensitive = "Subscription_Insensitive",
  subscription_sensitive = "Subscription_Sensitive",

  cloudAccount_Insensitive = "CloudAccount_Insensitive",
  cloudAccount_Sensitive = "CloudAccount_Sensitive"
}

export default defineComponent({
  props: { label: String, value: String },
  setup() {
    const conditionTypes = ref(Object.values(SimpleConditionTypes));
    return { conditionTypes };
  },
});
