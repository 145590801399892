
import PrettyListItemMenu, {
  IPrettyListItemViewModel,
} from "@/components/Common/PrettyListItemMenu.vue";
import { defineComponent } from "vue";
import { CloudAccountType } from '@/models';
export default defineComponent({
  components: { PrettyListItemMenu },
  props: {
    value: null,
    items: {
      type: Array,
      default: () => [
        CloudAccountType.azure,
        CloudAccountType.amazon,
        CloudAccountType.google,
        CloudAccountType.microsoftCsp,
        CloudAccountType.rhipe,
        CloudAccountType.vmWare,
        CloudAccountType.alibaba,
      ],
    },
  },
  computed: {
    prettyListItems(): IPrettyListItemViewModel[] {
      if (!this.items) return [];
      return this.items.map((i: string) => ({
        key: i,
        top: this.$t(`createCloudAccountMenu.${i}.top`),
        text: this.$t(`createCloudAccountMenu.${i}.text`),
        bottom: this.$t(`createCloudAccountMenu.${i}.bottom`),
      }));
    },
  },
});
