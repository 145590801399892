import Vue from "vue";
import VueWait from "vue-wait";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { waitFor,  } from "vue-wait";
Vue.use(VueWait);

const START_EVENT = 'start';
const STOP_EVENT = 'stop';
Vue.directive('wait-while', {
    bind(_, binding, vnode: any) {
        const instance = vnode.context.__$waitInstance;
        vnode.child.$on(START_EVENT, () => instance.start(binding.value));
        vnode.child.$on(STOP_EVENT, () => instance.end(binding.value));
    },
    update(_, binding, vnode: any) {
        const instance = vnode.context.__$waitInstance;
        if (vnode.data?.props) {
            vnode.data.props['loading'] = instance.is(binding.value);
        }
    }
});

export const startStop = (emit: (event: string) => void, func: any, forceSync = false): any => {
    if (typeof func !== 'function') {
        return func;
    }
    if (forceSync === true) {
        return (...args: any[]) => {
            try {
                emit(START_EVENT);
                return func.apply(this as any, args);
            } finally {
                emit(STOP_EVENT);
            }
        };
    }
    return async (...args: any[]) => {
        try {
            emit(START_EVENT);
            return await func.apply(this, args);
        } finally {
            emit(STOP_EVENT);
        }
    };
};

export const wait = new VueWait();

type AsyncWaitFunction = (...args: any) => Promise<any>;
type WaitFunction = (...args: any) => any;
export const compositionWaitFor = <T extends WaitFunction | AsyncWaitFunction>(bindTo:any, waiter: string, callback: T): T => {
    return (waitFor.bind(bindTo))(waiter, callback);
};
export const compositionWaitIs = (bindTo:any, key:string):boolean => {
    return false;
}

export { waitFor };
