<template>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="tableEntities"
        item-key="Id"
        :items-per-page.sync="itemsPerPage"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
    >
        <template #top>
            <slot name="top"></slot>
        </template>
        <template #[`item.Actions`]="{ item }">
            <slot name="item.Actions" :item="item"></slot>
        </template>
    </v-data-table>
</template>

<i18n>
{
    "en": {
        "cloudAccountCopiesTable.customerName": "Customer Name",
        "cloudAccountCopiesTable.sharedCloudAccountName": "Shared Cloud Connection Name",
        "cloudAccountCopiesTable.markupPercentage": "Markup Percentage",
        "cloudAccountCopiesTable.tagAllocations": "Tags"
    }
}
</i18n>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        items: Array,
    },
    data() {
        return {
            sortedBy: 'Name',
            sortedDesc: false,
            itemsPerPage: 15,
        };
    },
    computed: {
        tableEntities() {
            if (!this.items) return [];
            return this.items.map((i) => ({
                ...i.rules,
                Id: i.id,
                Name: i.name,
                TenantId: i.tenant.id,
                TenantName: i.tenant.name,
            }));
        },
        headers() {
            return [
                                {
                    text: this.$t('cloudAccountCopiesTable.customerName'),
                    value: 'TenantName',
                    sortable: true,
                },
                {
                    text: this.$t('cloudAccountCopiesTable.sharedCloudAccountName'),
                    value: 'Name',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
};
</script>