<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <delete-fx-rate-card
                :loading="$wait.is('deleteFxRate')"
                :value="account"
                :title="$t('deleteFxRateDialog.title')"
                :subtitle="$t('deleteFxRateDialog.subtitle')"
                :simple="true"
            >
                <template #actions="{ isValid, formId, clear }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <delete-fx-rate-button
                        :value="account"
                        :clear="clear"
                        :valid="isValid"
                        :form="formId"
                        v-wait-while="'deleteFxRate'"
                        @http2xx="close().then(() => $emit('http2xx', $event))"
                    ></delete-fx-rate-button>
                </template>
            </delete-fx-rate-card>
        </template>
    </form-dialog>
</template>

<i18n>
{
    "en": {
        "deleteFxRateDialog.title": "Delete FX Rate",
        "deleteFxRateDialog.subtitle": "Warning: This process is irreversible! Your FX Rate data will be deleted permanently and you will need to re-enter your rates if you want to see your data again. Changes will only take place on the next import."
    }
}
</i18n>

<script>
import DeleteFxRateCard from '@/components/Common/DeleteEntityCard';
import DeleteFxRateButton from '@/components/Domain.FxRates/DeleteFxRateButton.vue';
export default {
    components: { DeleteFxRateCard, DeleteFxRateButton },
    props: {
        value: Boolean,
        account: Object,
    },
};
</script>