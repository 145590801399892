
import PrettyListItemMenu, {
  IPrettyListItemViewModel,
} from "@/components/Common/PrettyListItemMenu.vue";
import { defineComponent } from "vue";
import { CloudCredentialType } from "@/models";
import Config from "@/lib/Config";
import { useTenantStore } from '@/stores';
import { mapStores } from 'pinia';
export default defineComponent({
  components: { PrettyListItemMenu },
  props: {
    value: null,
    items: {
      type: Array,
      default: () => [
        CloudCredentialType.awsAssumeRoleUsage,
        CloudCredentialType.awsIamUsage,
      ],
    },
  },
  computed: {
    ...mapStores(useTenantStore),
    prettyListItems(): IPrettyListItemViewModel[] {
      if (!this.items) return [];

      if (this.tenantStore.hasTenantFeature("Pax8Isolated"))
        return new Array({
          key: "AwsAssumeRoleUsage",
          top: this.$t(`createAmazonAccountMenu.AwsPax8AssumeRoleUsage.top`),
          text: this.$t(`createAmazonAccountMenu.AwsPax8AssumeRoleUsage.text`),
          bottom: this.$t(`createAmazonAccountMenu.AwsPax8AssumeRoleUsage.bottom`)
        });      

      return this.items
        .filter(
          (i:string) =>
            i != CloudCredentialType.awsAssumeRoleUsage ||
            Config.isFeatureEnabled("EnabledAwsAssumeRole")
        )
        .map((i: string) => ({
          key: i,
          top: this.$t(`createAmazonAccountMenu.${i}.top`),
          text: this.$t(`createAmazonAccountMenu.${i}.text`),
          bottom: this.$t(`createAmazonAccountMenu.${i}.bottom`),
        }));
    },
  },
});
