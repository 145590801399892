import { render, staticRenderFns } from "./KubernetesDashboard.vue?vue&type=template&id=10d92e74&scoped=true&"
import script from "./KubernetesDashboard.vue?vue&type=script&lang=ts&"
export * from "./KubernetesDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./KubernetesDashboard.vue?vue&type=style&index=0&id=10d92e74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d92e74",
  null
  
)

/* custom blocks */
import block0 from "./KubernetesDashboard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports