<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <set-google-json-credentials-card :disabled="disabled" :loading="loading" :value="edit">
                <template #actions="{ form, isValid, clear, formId }">
                    <back-button @click="close().then(clear)"></back-button>
                    <v-spacer></v-spacer>
                    <slot name="actions" :form="form" :formId="formId" :isValid="isValid" :clear="clear" :close="close">
                    </slot>
                </template>
            </set-google-json-credentials-card>
        </template>
    </form-dialog>
</template>

<script>
import SetGoogleJsonCredentialsCard from '@/components/Settings.CloudAccounts/Google/SetGoogleJsonCredentialsCard';
export default {
    props: ['value', 'loading', 'disabled', 'edit'],
    components: { SetGoogleJsonCredentialsCard },
    methods: {
        open() {
            this.$emit('input', true);
        },
    },
};
</script>