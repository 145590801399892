
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
import RegionChartCard, {
    IRegionClickedEventData,
    convertRegionResultsToChartSeries,
} from '@/components/Domain.Regions/RegionUsageChartCard.vue';
import RegionsTableCard, { convertRegionResultsToViewItems } from '@/components/Domain.Regions/RegionsTableCard.vue';
import moment from '@/lib/moment';
import { IRegionCostResult, IRegionResult } from '@/models';
import { defineComponent } from 'vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import CurrencyService from '@/lib/Currencies';
import { Fragment } from 'vue-fragment';


export default defineComponent({
    props: ['me'],
    components: { RegionsTableCard, RegionChartCard },
    title(ctx) {
        return ctx.$t('regionOverview.title');
    },
    data() {
        return {
            regions: [],
            regionMapData: [],
            filteredRegions: [],
            filteredRegionMapData: [],
            filterValue: null,
        };
    },
    created() {
        this.loadDataForView();
    },
    computed: {
        ...mapStores(useTenantStore),
    },
    methods: {
        handleChartClick(data: IRegionClickedEventData) {
            const { providerType, regionName } = data;
            this.$router.push(`/regionoverview/regions/${providerType}:${regionName}`);
        },
        handleDialogChange(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push('/regionoverview');
            this.$title = this.$t('regionOverview.title');
        },
        async loadDataForView() {
            const [regions, costs, symbol] = await Promise.all([this.getAllRegions(), this.getRegionTotals(), CurrencyService.getCurrencySymbol(this.tenantStore.currencyCode)]);
            this.regions = convertRegionResultsToViewItems(costs, regions).filter(x => x.currentMonth > 0);
            this.filteredRegions = this.regions;
            this.regionMapData = convertRegionResultsToChartSeries(costs, regions, this.tenantStore.currencyCode, symbol);
            this.filteredRegionMapData = this.regionMapData;
        },
        getAllRegions: waitFor('loadingRegions', async function (): Promise<IRegionResult[]> {
            try {
                return await ApiV2.http.get('/api/regions').then((r) => r.data);
            } catch (error) {
                toastError(this.$t('regionOverview.error.loadingRegions'));
            }
        }),
        getRegionTotals: waitFor('loadingRegionTotals', async function (): Promise<IRegionCostResult> {
            try {
                const params = {
                    min: moment.utc().add(-30, 'days').startOf('day').format(),
                    max: moment.utc().endOf('day').format(),
                };
                return await ApiV2.http.get('/api/region/cost', { params }).then((r) => r.data);
            } catch (error) {
                toastError(this.$t('regionOverview.error.loadingRegionTotals'));
            }
        }),
    },
    watch: {
        filterValue: {
            handler(val) {
                if(val == "" || val == 0 ){
                    this.filteredRegions = this.regions;
                    this.filteredRegionMapData = this.regionMapData;
                    return;
                }
                this.filteredRegions =  this.regions.filter(x => x.currentMonth > val);
                // cheap and dirty deep clone
                const list = JSON.parse(JSON.stringify( this.regionMapData )).filter(x => (x.data && x.data?.some(y => y.z > val)) || !x.data);
                list.forEach(element => {
                    if(element.data) {
                        element.data = element.data.filter(x => x.z > val);
                    }
                });
                this.filteredRegionMapData = list;
            }
        }
    }
});
