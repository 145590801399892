
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        me: Object,
        value: Object,
        loading: Boolean,
    },
    setup() {
        const tab: string = null;
        const tabs = [
            { key: 'usage', icon: 'insights', text: 'views.dialogProductCategory.tabs.usage' }
        ];
        return { tab, tabs };
    },
});
