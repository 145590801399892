<template>
    <customView-card-dialog
        :value="value"
        @input="$emit('input', $event)"
        :customView="customView"
        :loading="$wait.is('updatingCustomView')"
    >
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ form, formId, isValid, loading, close }">
            <put-customView-button
                :value="{ ...form, id: customView.id }"
                :clear="() => {}"
                :valid="isValid"
                :form="formId"
                :loading="loading"
                v-wait-while="'updatingCustomView'"
                @http2xx="close().then(() => handleSuccessfulCreation(form))"
            ></put-customView-button>
        </template>
    </customView-card-dialog>
</template>

<i18n>
{
    "en": {
        "putCustomViewDialog.events.successfulUpdates": "Successfully updated the customView '{customViewName}'"
    }
}
</i18n>

<script>
import CustomViewCardDialog from '@/components/Domain.CustomViews/CustomViewCardDialog.vue';
import PutCustomViewButton from '@/components/Domain.CustomViews/PutCustomViewButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { CustomViewCardDialog, PutCustomViewButton },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        customView: {
            type: Object,
            default: null,
        },
    },
    methods: {
        handleSuccessfulCreation(form) {
            toastSuccess(this.$t('putCustomViewDialog.events.successfulUpdates', { customViewName: form.name }));
            this.$emit('http2xx', form);
            this.$emit('editComplete');
        },
    },
};
</script>
