<template>
    <v-skeleton-loader :loading="loading" type="list-item@6">
        <v-timeline align-top dense clipped v-if="annotations.length > 0">
            <v-timeline-item v-for="annotation in allAnnotations" :key="annotation.id">
                <v-card flat outlined>
                    <v-card-text>
                        
                        <p>{{ annotation.text }}</p>
                        <p class="caption text-xs-center grey--text" v-if="annotation.serviceName && showProduct">{{ annotation.serviceName }}</p>
                        <div class="d-flex justify-space-between">
                        <div style="align-content: end;">
                            <router-link :to="`/explore/services/${annotation.serviceId}/annotations`"  v-if="showNavigate">
                                {{ $t('view') }} ►
                            </router-link>
                        </div>

                        <div class="text-right">
                            {{ annotation.annotationCreatedBy }}
                            <v-spacer />
                            {{ annotation.date }}
                            <v-menu offset-y v-if="allowEdit">
                                <template #activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <v-list light>
                                    <v-list-item v-if="annotation.isEditable" @click="startEditAnnotation(annotation)">
                                        <v-list-item-icon>
                                            <v-icon>edit</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="startDeleteAnnotation(annotation)">
                                        <v-list-item-icon>
                                            <v-icon>delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    </v-card-text>
                </v-card>
            </v-timeline-item>
        </v-timeline>
        <empty-state icon="history" :message="$t('annotationsTimeline.noAnnotations')" v-else>
            <slot name="no-data"></slot>
        </empty-state>
        <delete-annotation-dialog
            v-model="dialogs.deleteAnnotation"
            :annotation="annotationToDelete"
            @http2xx="onAnnotationChangeSuccess"
        >
        </delete-annotation-dialog>
        <edit-annotation-dialog
            v-model="dialogs.editAnnotation"
            :annotation="annotationToEdit"
            @http2xx="onAnnotationChangeSuccess"
        >
        </edit-annotation-dialog>
    </v-skeleton-loader>
</template>

<i18n>
{
    "en": {
        "annotationsTimeline.noAnnotations": "There are no annotations here yet."
    }
}
</i18n>

<script>
import moment from '@/lib/moment';

import DeleteAnnotationDialog from '@/components/Domain.Annotations/DeleteAnnotationDialog.vue';
import EditAnnotationDialog from '@/components/Domain.Annotations/EditAnnotationDialog.vue';
export default {
    props: ['annotations', 'loading', 'serviceId', 'allowEdit', 'showNavigate', 'showProduct'],
    data() {
        return {
            annotationToDelete: null,
            annotationToEdit: null,
            dialogs: {
                deleteAnnotation: false,
                editAnnotation: false,
            },
        };
    },
    components: {
        DeleteAnnotationDialog,
        EditAnnotationDialog,
    },
    computed: {
        allAnnotations() {
            if (!this.annotations) return [];
            return this.annotations.map((e) => ({
                id: e.id,
                text: e.text,
                date: moment.utc(e.annotationDate).local().format('DD MMM YYYY'),
                annotationCreatedBy: e.annotationCreatedBy,
                isEditable: e.isEditable,
                serviceId: e.serviceId,
                serviceName: e.serviceName
            }));
        },
    },
    methods: {
        startDeleteAnnotation(annotation) {
            this.annotationToDelete = annotation;
            this.dialogs.deleteAnnotation = true;
        },
        startEditAnnotation(annotation) {
            this.annotationToEdit = annotation;
            this.dialogs.editAnnotation = true;
        },
        onAnnotationChangeSuccess(successMessage) {
            this.$emit('onAnnotationChangeSuccess', successMessage);
        },
        onNavigate(annotation) {
            this.$emit('onNavigate', annotation);
        },
    },
};
</script>
