
import { waitFor } from '@/plugins/vue-wait';
import CreateCustomerDialog from '@/views/CustomersManage/CreateCustomerDialog.vue';
import ApiV2 from '@/lib/ApiV2';
import DeleteEntityDialog from '@/components/Common/DeleteEntityDialog.vue';
import { useTenantStore } from '../../stores/tenant';
import { mapStores } from 'pinia';
import moment from '@/lib/moment';

export function sum(x, y) {
    return x + y;
}

export default {
    title(ctx) {
        return ctx.$t('views.customersManage.title');
    },
    components: {
        CreateCustomerDialog,
        DeleteEntityDialog,
    },
    methods: {
        getTenantCustomers: waitFor('getTenantCustomers', async function getTenantCustomers() {
            let params =  undefined; 
            if(this.toDate) {
                params = {params: {startMonth: moment.utc(this.toDate).format("YYYY-MM-DD")}}; 
            }            
            this.customers = await ApiV2.http.get(`api/customers`, params).then((r) => r.data);
        }),
        handleSuccessfulCustomerChange() {
            this.getTenantCustomers();
        },
        startDeleteAccount(customer) { 
            this.customerToDelete = customer;
            this.deleteCustomer = true;            
        },
        endDeleteAccount(flag) { 
            this.deleteCustomer = flag;
            this.getTenantCustomers();
        }
    },
    data() {
        return {
            customers: [],
            sortBy: 'name',
            customerToDelete: null,
            deleteCustomer: false,
            toDate: new Date(),
            maxPeriod:  moment.utc(new Date()).format("YYYY-MM-DD"),
            minPeriod:  moment.utc(new Date()).add(-13, 'month').format("YYYY-MM-DD"),
        };
    },
    computed: {
        totalShared() {
            return !this.customers ? 0 : this.customers.map((item) => item.numberOfSharedAccounts).reduce(sum, 0);
        },
        totalRevenue() {
            return !this.customers ? 0 : this.customers.map((item) => item.lastMonthRevenue).reduce(sum, 0);
        },
        totalCost() {
            return !this.customers ? 0 : this.customers.map((item) => item.lastMonthCost).reduce(sum, 0);
        },
        totalProfit() {
            return !this.customers ? 0 : this.customers.map((item) => item.lastMonthProfit).reduce(sum, 0);
        },
        totalOther() {
            return !this.customers ? 0 : this.customers.map((item) => item.numberOfOtherAccounts).reduce(sum, 0);
        },
        totalOtherSpend() {
            return !this.customers ? 0 : this.customers.map((item) => item.lastMonthOtherSpend).reduce(sum, 0);
        },
        period() {
            return !this.customers ? '' : this.customers.length > 0 ? '(Period ' + this.customers[0].period + ')' : '';
        },
        numberCustomers() {
            if (!this.customers) return 0.0;
            return this.customers.length;
        },
        headers() {
            const flagValue = this.tenantStore.hasTenantFeature('HideProfit') ?? false;
            const  headers = flagValue === false ? [{ // Includes Profit, Revenue and Margin 
                    text: this.$t('views.customersManage.name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.sharedAccounts'),
                    align: 'end',
                    value: 'numberOfSharedAccounts',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.lastMonthRevenue'),
                    align: 'end',
                    value: 'lastMonthRevenue',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.lastMonthCost'),
                    align: 'end',
                    value: 'lastMonthCost',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.profit'),
                    align: 'end',
                    value: 'lastMonthProfit',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.lastMonthMargin'),
                    align: 'end',
                    value: 'lastMonthMargin',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.otherAccounts'),
                    align: 'end',
                    value: 'numberOfOtherAccounts',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.lastMonthOther'),
                    align: 'end',
                    value: 'lastMonthOtherSpend',
                    sortable: true,
                },
                {
                    text: this.$t('actions'),
                    value: "actions",
                    sortable: false
                }] : // No Profit, Revenue and Margin 
                [{
                    text: this.$t('views.customersManage.name'),
                    value: 'name',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.sharedAccounts'),
                    align: 'end',
                    value: 'numberOfSharedAccounts',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.lastMonthCost'),
                    align: 'end',
                    value: 'lastMonthCost',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.otherAccounts'),
                    align: 'end',
                    value: 'numberOfOtherAccounts',
                    sortable: true,
                },
                {
                    text: this.$t('views.customersManage.lastMonthOther'),
                    align: 'end',
                    value: 'lastMonthOtherSpend',
                    sortable: true,
                },
                {
                    text: this.$t('actions'),
                    value: "actions",
                    sortable: false
                }];

            return headers;
        },
        ...mapStores(useTenantStore)
    },
    async created() {
        this.getTenantCustomers();
    },
    watch: {
        toDate: {
            handler: function() {
                this.getTenantCustomers();
            }
        } 
    }
} as any;
