<template>
    <form-dialog v-model="show">
        <template #activator="props">
            <slot name="activator" v-bind="props"></slot>
        </template>
        <set-tenant-card
            :loading="$wait.is('creatingTenant')"
            :title="$t('views.createTenantDialog.title')"
            :subtitle="$t('views.createTenantDialog.subtitle')"
        >
            <template #actions="{ form, formId, isValid, clear }">
                <back-button @click="show = false"></back-button>
                <v-spacer></v-spacer>
                <post-tenant-button
                    :value="{ ...form }"
                    :clear="clear"
                    :valid="isValid"
                    :form="formId"
                    @http2xx="onSubmit"
                    v-wait-while="'creatingTenant'"
                >
                </post-tenant-button>
            </template>
        </set-tenant-card>
    </form-dialog>
</template>


<i18n>
{
    "en": {
        "views.createTenantDialog.title": "Create Tenant",
        "views.createTenantDialog.subtitle": "The tenant you create here will be charged under your current billing agreement unless other arrangements are made specifically."
    }
}
</i18n>

<script>
import EventBus from '@/lib/EventBus';
import SetTenantCard from '@/components/Domain.Tenants/SetTenantCard.vue';
import PostTenantButton from '@/components/Domain.Tenants/PostTenantButton.vue';
import { useAccountStore } from '@/stores/account.ts';

export default {
    props: ['customer'],
    components: { SetTenantCard, PostTenantButton },
    data() {
        return {
            show: false,
            currencies: [],
        };
    },
    methods: {
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
        onSubmit(resp) {
            EventBus.$emit('$toast.success', 'Your tenant was successfully created.');
            useAccountStore().loadAccountTenants();
            this.$emit('created', resp);
            this.close();
        },
    },
};
</script>
