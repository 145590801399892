
import { IEmbedWidgetData } from '@/merge/Grid';
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: { config: Object, name: String, hideActions: Boolean },
    components: {  },
    setup(props) {
        const embedData = computed(() => {
            return props.config as IEmbedWidgetData | null | undefined;
        })
        return { embedData }
    },
    methods: {
    },
    watch: {
    },
    computed: {
        cardTitle() {
            return this.name;
        },
    },
});
