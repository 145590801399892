import { render, staticRenderFns } from "./FormCardTitle.vue?vue&type=template&id=323dd246&scoped=true&"
import script from "./FormCardTitle.vue?vue&type=script&lang=js&"
export * from "./FormCardTitle.vue?vue&type=script&lang=js&"
import style0 from "./FormCardTitle.vue?vue&type=style&index=0&id=323dd246&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323dd246",
  null
  
)

export default component.exports