
import { defineComponent, ref } from "vue";
import { useTenantStore } from "@/stores/tenant";
import { storeToRefs } from "pinia";
export default defineComponent({
  props: {
    loading: Boolean,
    items: Array,
    title: String,
    subtitle: String,
  },
  setup(_, { emit }) {
    const handleInput = (val) => {
      emit("input", val);
    };
    const { currencyCode } = storeToRefs(useTenantStore());
    return {
      valid: ref(false),
      currencyCode,
      handleInput,
    };
  },
});
