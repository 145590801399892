import Vue from 'vue';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
dayjs.extend(calendar);

Vue.filter('calendarFormatFromUtc', function (value: Date | string) {
    return dayjs(value).local().calendar(null, {
        sameElse: 'DD/MM/YYYY'
    });
});

Vue.filter('calendarFormat', function (value: Date | string) {
    return dayjs(value).calendar(null, {
        sameElse: 'DD/MM/YYYY'
    });
});

Vue.filter('dateFormat', function (value: Date, format: string) {
    return dayjs(value).format(format);
});
