
import { defineComponent, ref } from 'vue';
import { ICloudAccountModel } from '@/models';

export function customCloudAccountTableFilter(ca: ICloudAccountModel) {
    return ca.type === 'Custom';
}

export default defineComponent({
    props: {
        items: Array,
        loading: Boolean,
        routePrefix: {
            type: String,
            default: '/settings/custom',
        },
    },

    setup() {
        const headers = ref([
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false },
        ]);

        return {
            headers,
            sortedBy: 'name',
            sortedDesc: true,
            itemsPerPage: 10,
        };
    },
});
