<template>
    <v-form v-model="isValid" :id="formId" ref="form">
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-left">Tag Key</th>
                    <th class="text-left">Tag Value</th>
                    <th class="text-left">Period</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="tenantStore.hasPermission('CanContribute')">
                    <td>
                        <v-text-field
                            :rules="[$rules.required()]"
                            v-model="newTagKey"
                            hide-details
                            class="py-3"
                            outlined
                            dense
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            :rules="[$rules.required()]"
                            v-model="newTagValue"
                            hide-details
                            class="py-3"
                            outlined
                            dense
                        ></v-text-field>
                    </td>
                    <td>
                        <v-select
                            v-model="newTagPeriod"
                            :rules="[$rules.required()]"
                            hide-details
                            class="py-3"
                            outlined
                            dense
                            :items="supportedPeriods"
                            item-text="name"
                            item-value="id"
                        ></v-select>
                    </td>
                    <td>
                        <slot
                            name="default"
                            :formId="formId"
                            :reset="reset"
                            :resetValidation="resetValidation"
                            :isValid="isValid"
                            :form="newTag"
                            :loading="loading"
                        ></slot>
                    </td>
                </tr>
                <tr v-for="(item, i) in items" :key="i">
                    <td>{{ item.tagKey }}</td>
                    <td>{{ item.tagValue }}</td>
                    <td>{{ `${item.month} / ${item.year}` }}</td>
                    <td>
                        <slot name="item" :item="item"></slot>
                    </td>
                </tr>
                <tr v-if="!items || items.length === 0">
                    <td colspan="4" class="text-center">
                        {{ $t('virtualTagsTable.noRecords') }}
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-form>
</template>

<i18n>
{
    "en": {
        "virtualTagsTable.noRecords": "There are no virtual tags configured for this service."
    }
}
</i18n>

<script>
import moment from '@/lib/moment';
import UniqueIdentifier from '@/lib/UniqueIdentifier.ts';
import { useTenantStore } from '../../stores/tenant.ts';
import { mapStores } from 'pinia';
export default {
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isValid: false,
            supportedPeriods: [
                { id: 'ThisMonth', name: 'This Month' },
                { id: 'LastMonth', name: 'Last Month' },
            ],
            newTagKey: null,
            newTagValue: null,
            newTagPeriod: 'ThisMonth',
            formId: UniqueIdentifier.create(),
        };
    },
    computed: {
        newTag() {
            if (!this.isValid) return null;
            let period = moment.utc();
            if (this.newTagPeriod === 'LastMonth') period = period.add(-1, 'months');
            return {
                tagKey: this.newTagKey,
                tagValue: this.newTagValue,
                year: period.year(),
                month: period.month() + 1,
            };
        },
        ...mapStores(useTenantStore)
    },
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.v-data-table /deep/ tbody /deep/ tr:hover:not(.v-data-table__expanded__content) {
    background: transparent !important;
}
</style>
