import { ChartData, Chart, ChartDataset } from 'chart.js/auto'
import { formatCurrency } from '@/lib/Currencies';
import * as CssVariables from '@/lib/CssVariables';

export interface IOptions {
    currencyCode: string;
    clickFn: (allocation: string) => void | null;
}

export interface IClusterSpendDataPoint {
    x: number;
    y: string;
    allocation: string;
}

const AllocationTypes : {[allocation: string]: { label: string, description: string[] }} = {
    'unallocated': { label: 'Un-utilised', description: ['This is the cost of unused compute space in the cluster', 'It is the spend left after each pod has been allocated a cost based on its usage.', 'This is a good indicator of whether the cluster provisioning should be tweaked'] },
    'allocated': { label: 'Utilised', description: ['This is the cost that can be attributed to a running service (a pod).', 'The pod cost is a calculation of its total usage of the node resources on a particular day'] },
    'sharedcost': { label: 'Overheads', description: ['Clusters have an overhead cost charged by each cloud vendor', 'This is typically the hourly charge for having a cluster running', 'As well as public IP and load balancing'] },
}

export const convertAllocationsToDatasets = (allocations: { [allocation: string]: number }): ChartData<'bar', IClusterSpendDataPoint[], string> => {
    const colours = ["#6AA84F", "#E69138", "#3D85C6", "#A64D79"];

    const datasets: ChartDataset<'bar', IClusterSpendDataPoint[]>[] = [];
    Object.keys(allocations).forEach((key, index) => {
        const label = AllocationTypes[key] ? AllocationTypes[key].label : key;
        datasets.push({
            data: [{
                allocation: key.toLowerCase(),
                x: allocations[key],
                y: label
            }],
            label: label,
            backgroundColor: colours[index % colours.length],
            borderWidth: 1,
            borderColor: 'white'
        });
    });
    return {
        datasets
    };
};

export default (chartItem: HTMLCanvasElement, options: IOptions): Chart => {
    const { currencyCode } = options;
    const style = getComputedStyle(chartItem);
    const contrastLines = style.getPropertyValue(CssVariables.ContrastLinesVariable);
    const contrastText = style.getPropertyValue(CssVariables.ContrastTextVariable);
    const onClickProcessor = (_, [targetPtr], chart) => {
        if (!options.clickFn) return;
        const targetDataset = chart.data.datasets[targetPtr.datasetIndex];
        const target: IClusterSpendDataPoint = targetDataset.data[targetPtr.index] as any;
        options.clickFn(target.allocation);
    }
    const chart = new Chart(chartItem, {
        type: 'bar',
        data: {
            labels: [],
            datasets: []
        },
        options: {
            indexAxis: 'y',
            borderColor: 'rgba(0,0, 0, 0)',
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            const dataset = item.dataset;
                            const points: IClusterSpendDataPoint[] = dataset.data as any;
                            const hoverPoint: IClusterSpendDataPoint = points[item.dataIndex];
                            return [
                                `Spend: ${formatCurrency(hoverPoint.x, currencyCode)}`,
                                ...AllocationTypes[hoverPoint.allocation] ? AllocationTypes[hoverPoint.allocation].description : []
                            ]
                        }
                    },
                    mode: 'point'
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'category',
                    ticks: {
                        color: contrastText
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0)'
                    },
                    stacked: true
                },
                x: {
                    ticks: {
                        color: contrastText,
                        callback(value: number) {
                            return formatCurrency(value, currencyCode);
                        }
                    },
                    grid: {
                        color: contrastLines
                    },
                    title: {
                        text: `Cost (${currencyCode})`,
                        color: contrastText,
                        display: true
                    },
                    display: true,
                    stacked: true
                }
            },
            onClick(_, [targetPtr], chart) {
                onClickProcessor(_, [targetPtr], chart);
            },
            interaction: {
                intersect: true,
                mode: 'point'
            },
            onHover(event, [element]) {
                let newCursor = 'default';
                if (element) {
                    const dataset = chart.data.datasets[element.datasetIndex];
                    if (dataset) {
                        newCursor = 'pointer';
                    }
                }
                (event.native.target as HTMLElement).style.cursor = newCursor;
            },
        }
    });
    return chart;
}