<template>
    <set-rhipe-credentials-dialog
        :value="value"
        @input="$emit('input', $event)"
        :loading="$wait.is('creatingRhipeAccount')"
        :edit="edit"
        :disabled="{ ...(disabled || {}), Name: true, UseTenantCurrencies: true }"
    >
        <template #actions="{ form, formId, isValid, clear, close }">
            <put-credential-button
                type="RhipeApiCredential"
                :value="{ ...edit, ...form }"
                :clear="clear"
                :valid="isValid"
                :form="formId"
                v-wait-while="'creatingRhipeAccount'"
                @http2xx="acceptSubmit($event, close)"
            ></put-credential-button>
        </template>
    </set-rhipe-credentials-dialog>
</template>

<script>
import SetRhipeCredentialsDialog from '@/components/Settings.CloudAccounts/Distributors/SetRhipeCredentialsDialog';
import PutCredentialButton from '@/components/Settings.CloudAccounts/PutCredentialButton';
export default {
    components: { SetRhipeCredentialsDialog, PutCredentialButton },
    props: {
        value: Boolean,
        edit: Object,
        disabled: Object
    },
    methods: {
        async acceptSubmit($event, close) {
            await close();
            this.$emit('http2xx', $event);
        },
    },
};
</script>