import { defineStore } from 'pinia';
import { ref } from 'vue';
import KeyedBlobsDb from '../databases/KeyedBlobsDb';
import { v4 } from 'uuid';
import * as G from '../merge/Grid';
import { ISavedReportResultParams } from '@/models';

export const useGridStore = defineStore('grid', () => {
    const currentDocKey = ref(null);

    const gridDoc = ref<G.CCGrid | null>(null);
    const cachedPositionalUpdates = ref<{ [elementId: string]: G.Position }>({});
    const cachedSizingUpdates = ref<{ [elementId: string]: G.Rectangle }>({});

    const load = async (key?: string) => {
        if (key) currentDocKey.value = key;
        await initAndCommitCurrent();
    };

    const putGrid = async (key: string, doc: G.CCGrid) => {
        gridDoc.value = await KeyedBlobsDb.changeDoc<G.CCGrid>(key, {
            initFn: () => doc,
            changeFn: () => {
                /* Do nothing */
            },
        });
    };

    const initAndCommitGrid = async (id: string, p?: { fn?: (grid) => G.CCGrid; name?: string, description?: string }) => {
        const { fn, name, description } = p || {};
        return await KeyedBlobsDb.changeDoc<G.CCGrid>(id, {
            initFn: () => G.initGrid({ name: name || 'Unnamed Grid', description: description || 'A grid of usage components' }),
            changeFn: fn,
        });
    };

    const initAndCommitCurrent = async (p?: { fn?: (grid) => G.CCGrid; name?: string, description?:string }) => {
        gridDoc.value = await initAndCommitGrid(currentDocKey.value, p);
        return gridDoc.value;
    };

    const addFeedElement = async (
        name: string,
        type: G.WidgetTypes,
        config: ISavedReportResultParams | G.INoteWidgetData | G.IEmbedWidgetData,
        pos: G.Position & G.Rectangle
    ) => {
        const id = v4();
        const { h, w, x, y } = pos;
        const element = (grid) => {
            if (type === G.WidgetTypes.note) {
                if ('title' in config) {
                    return G.addGridElement({ id, h, w, x, y, type, config, name }, grid)
                } else {
                    throw new Error('Unsupported configuration for note widget');
                }
            } else if (type === G.WidgetTypes.embed) {
                if ('url' in config) {
                    return G.addGridElement({ id, h, w, x, y, type, config, name }, grid)
                } else {
                    throw new Error('Unsupported configuration for embed widget');
                }
            } 
            else {
                if ('title' in config) {
                    throw new Error('Note widget configuration supplied to standard widget');
                } else if ('urlTitle' in config) {
                    throw new Error('Embed widget configuration supplied to standard widget');
                } 
                else {
                    return G.addGridElement({ id, h, w, x, y, type, config, name }, grid)
                }
            }
        };
        await initAndCommitCurrent({ fn: element });
    };

    const updateGridName = async (id: string, name: string) => {
        return await initAndCommitGrid(id, { fn: (grid) => G.patchGridFn({ name }, grid), name });
    };

    const updateFeedElement = async (
        id: string,
        name: string,
        type: G.WidgetTypes,
        config: ISavedReportResultParams | G.INoteWidgetData | G.IEmbedWidgetData
    ) => {
        const fn = (grid) => {
            if (type === G.WidgetTypes.note) {
                if ('title' in config) {
                    return G.patchGridElement(
                        id,
                        {
                            name,
                            type,
                            config,
                        },
                        grid
                    );
                } else {
                    throw new Error('Unsupported configuration for note widget');
                }
            } else if (type === G.WidgetTypes.embed) {
                if ('urlTitle' in config) {
                    return G.patchGridElement(
                        id,
                        {
                            name,
                            type,
                            config,
                        },
                        grid
                    );
                } else {
                    throw new Error('Unsupported configuration for embed widget');
                }

            } else {
                if ('title' in config) {
                    throw new Error('Note widget configuration supplied to standard widget');
                } else if ('urlTitle' in config) {
                    throw new Error('Embed widget configuration supplied to standard widget');
                } 
                else {
                    return G.patchGridElement(
                        id,
                        {
                            name,
                            type,
                            config,
                        },
                        grid
                    );
                }
            }
        }
        return await initAndCommitCurrent({ fn, name });
    };

    const removeGridElement = async (nodeId: string) => {
        const fn = (grid) => G.removeGridElement(nodeId, grid);
        return await initAndCommitCurrent({ fn });
    };

    const commitCachedValues = async () => {
        const positional: { [id: string]: Partial<G.Position & G.Rectangle> & G.Identified } = {};
        Object.entries(cachedPositionalUpdates.value).forEach(([id, v]) => {
            positional[id] = { id, x: v.x, y: v.y };
        });
        Object.entries(cachedSizingUpdates.value).forEach(([id, s]) => {
            const val = positional[id] || { id };
            val.w = s.w;
            val.h = s.h;
            positional[id] = val;
        });
        const fn = (grid) => G.reshapeGridElements(Object.values(positional), grid);
        const result = await initAndCommitCurrent({ fn });
        cachedPositionalUpdates.value = {};
        cachedSizingUpdates.value = {};
        return result;
    };

    return {
        currentDocKey,
        gridDoc,
        cachedPositionalUpdates,
        cachedSizingUpdates,
        commitCachedValues,
        load,
        addFeedElement,
        updateFeedElement,
        removeGridElement,
        initAndCommitCurrent,
        initAndCommitGrid,
        putGrid,
        updateGridName,
    };
});
