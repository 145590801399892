<template>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="tableEntities"
        item-key="id"
        :items-per-page.sync="itemsPerPage"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
    >
        <template #no-data>
            {{ $t('googleCloudAccountsTable.noData') }}
        </template>
        <template #[`item.Name`]="{ item }">
            <router-link :to="`${routePrefix}/${item.Id}`"> {{ item.Name }}</router-link>
        </template>
        <template #[`item.GoogleProjectId`]="{ item }">
            <span :class="item.GoogleConnectionEnabled ? '' : 'error--text'">
                {{ item.GoogleProjectId }}
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-icon right small v-on="on" :color="item.GoogleConnectionEnabled ? 'success' : 'error'">{{
                            item.GoogleConnectionEnabled ? 'done' : 'error'
                        }}</v-icon>
                    </template>
                    <span>
                        {{
                            item.GoogleConnectionEnabled
                                ? $t('connected')
                                : $t('googleCloudAccountsTable.disconnectedUsageConnection')
                        }}
                    </span>
                </v-tooltip>
            </span>
        </template>
        <template #[`item.GoogleConnectionExpiry`]="{ item }">
            <span v-if="!item.GoogleConnectionExpiry">-</span>
            <span v-else-if="item.GoogleConnectionExpiry.getFullYear() > 3000">Never</span>
            <span v-else :class="item.GoogleConnectionExpiry < new Date() ? 'error--text' : ''">{{
                item.GoogleConnectionExpiry | calendarFormat
            }}</span>
        </template>
        <template #[`item.Actions`]="{ item }">
            <slot name="item.menu" :item="item._original"></slot>
        </template>
    </v-data-table>
</template>

<i18n>
{
    "en": {
        "googleCloudAccountsTable.projectId": "Project ID",
        "googleCloudAccountsTable.dataSetId": "Dataset ID",
        "googleCloudAccountsTable.expiry": "Expiry",
        "googleCloudAccountsTable.noData": "No google cloud connections configured.",
        "googleCloudAccountsTable.disconnectedUsageConnection": "Please update your usage connection with valid credentials"
    }
}
</i18n>

<script>
export function googleCloudAccountTableFilter(ca) {
    return ca.providerType === 'Google';
}

export default {
    props: {
        items: Array,
        loading: {
            type: Boolean,
            default: false,
        },
        routePrefix: {
            type: String,
            default: '/settings/google',
        },
    },
    data() {
        return {
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
        };
    },
    computed: {
        tableEntities() {
            if (!this.items) return [];
            return this.items.filter(googleCloudAccountTableFilter).map((ca) => {
                let expiryDate =
                    ca.googleJsonUsageCredential && ca.googleJsonUsageCredential.expiryDate ? new Date(ca.googleJsonUsageCredential.expiry) : null;
                // In javascript the getTime value of a date isNaN
                if (expiryDate && isNaN(expiryDate.getTime())) {
                    expiryDate = null;
                }
                return {
                    Id: ca.id,
                    Name: ca.name,
                    GoogleConnectionEnabled: ca.googleJsonUsageCredential
                        ? ca.googleJsonUsageCredential.valid
                        : ca.type === 'SelfReferential',
                    GoogleProjectId: ca.googleJsonUsageCredential
                        ? ca.googleJsonUsageCredential.providerId || '<None Supplied>'
                        : ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>',
                    GoogleDatasetId: ca.googleJsonUsageCredential
                        ? ca.googleJsonUsageCredential.clientDomain
                        : ca.type === 'SelfReferential'
                        ? '<Hidden>'
                        : '<None Supplied>',
                    GoogleConnectionExpiry: expiryDate,
                    _original: ca,
                };
            });
        },
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'Name',
                    sortable: true,
                },
                {
                    text: this.$t('googleCloudAccountsTable.projectId'),
                    value: 'GoogleProjectId',
                    sortable: true,
                },
                {
                    text: this.$t('googleCloudAccountsTable.dataSetId'),
                    value: 'GoogleDatasetId',
                    sortable: true,
                },
                {
                    text: this.$t('googleCloudAccountsTable.expiry'),
                    value: 'GoogleConnectionExpiry',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
};
</script>

<style scoped>
.v-data-table /deep/ tbody /deep/ tr:hover:not(.v-data-table__expanded__content) {
    background: transparent !important;
}
</style>