<template>
    <fragment>
        <usage-comparison-options-toolbar
        class="mt-3"
            v-model="configuration"
            @input="loadServiceComparisons()"
            :loading="$wait.is('loadServiceComparisons')"
            :segregate-types="['services', 'products']"
        ></usage-comparison-options-toolbar>
        <service-comparisons-table
            v-if="configuration.segregateBy === 'services'"
            :items="comparisons"
            :currency="tenantStore.currencyCode"
        ></service-comparisons-table>
        <product-comparisons-table
            v-else-if="configuration.segregateBy === 'products'"
            :items="comparisons"
            :currency="tenantStore.currencyCode"
        ></product-comparisons-table>
    </fragment>
</template>

<script>
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import { useTenantStore } from '../../stores/tenant.ts';
import { mapStores } from 'pinia';
import ServiceComparisonsTable from '@/components/Domain.Services/ServiceComparisonsTable.vue';
import ProductComparisonsTable from '@/components/Domain.Products/ProductComparisonsTable.vue';
import UsageComparisonOptionsToolbar from '@/components/Domain.Usage/UsageComparisonOptionsToolbar.vue';
export default {
    components: { ServiceComparisonsTable, ProductComparisonsTable, UsageComparisonOptionsToolbar },
    props: ['value', 'loading'],
    data() {
        return {
            comparisons: [],
            configuration: { segregateBy: 'services', period: 'month', toDate: new Date() },
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.loadServiceComparisons();
            },
            immediate: true,
        },
    },
    computed: {
        ...mapStores(useTenantStore)
    },
    methods: {
        loadServiceComparisons: waitFor('loadServiceComparisons', async function loadCompare() {
            if (!this.value) return;
            const params = {
                cloudAccountIds: this.value.cloudAccountId,
                orderBy: 'delta',
                period: this.configuration.period,
                toDate: this.configuration.toDate.toISOString(),
            };
            const response = await ApiV2.http
                .get(`/api/cost-explorer/${this.configuration.segregateBy}`, { params })
                .then((r) => r.data);
            this.comparisons = response.entities;
        }),
    },
};
</script>