export const LEGACY_COLORS_HEX = [
    '#7cb5ec', 
    '#434348', 
    '#90ed7d', 
    '#f7a35c', 
    '#8085e9', 
    '#f15c80', 
    '#e4d354', 
    '#2b908f', 
    '#f45b5b', 
    '#91e8e1'
];
export const LEGACY_COLORS_RGB = [
    '124, 181, 236', 
    '67, 67, 72', 
    '144, 237, 125',
    '247, 163, 92',
    '128, 133, 233',
    '241, 92, 128',
    '228, 211, 84',
    '43, 144, 143',
    '244, 91, 91',
    '145, 232, 225'
]