<template>
    <form-card :title="$t('showAzureSubscriptions.title')" v-model="valid">
        <template #subtitle
            >Azure subscriptions that are visible to the selected Azure Resource Manager credential. You can find more
            information on how to configure the Azure AD app credentials
            <a
                target="blank"
                :href="`${docsUrl}/Setup/Azure`"
                >here </a
            >.
        </template>
        <v-data-table
            dense
            :loading="$wait.is('getSubscriptions')"
            :headers="headers"
            :items="subscriptions"
            hide-default-footer
        >
            <template #no-data>
                {{ $t('showAzureSubscriptions.noSubscriptions') }}
            </template>
            <template #[`item.Name`]="{ item }">
                {{ item.Name }}
            </template>
        </v-data-table>

        <template #actions="{ reset }">
            <slot name="actions" :clear="reset"></slot>
        </template>
    </form-card>
</template>


<i18n>
{
  "en": {
    "showAzureSubscriptions.title": "Azure App Credential Subscriptions",
    "showAzureSubscriptions.subscriptions": "Subscriptions",
    "showAzureSubscriptions.noSubscriptions": "No Subscriptions visible."
  }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2';
import Config from '@/lib/Config';
import { waitFor } from '@/plugins/vue-wait';

export default {
    props: ['value'],
    data() {
        return {
            valid: true,
            loading: false,
            subscriptions: [],
            docsUrl: Config.get().DOCS_URL
        };
    },
    methods: {
        getSubscriptions: waitFor('getSubscriptions', async function () {
            const cloudAccountId = this.value.cloudAccountId;
            const credentialId = this.value.credentialId;
            const data = await ApiV2.http
                .get(
                    `/api/metadata/cloudaccounts/${cloudAccountId}/aadapplicationcredential/${credentialId}/subscriptions`
                )
                .then((r) => r.data);

            this.subscriptions = data.map((item) => ({ Name: item }));
        }),
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('showAzureSubscriptions.subscriptions'),
                    value: 'Name',
                    sortable: true,
                },
            ];
        },
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.subscriptions = [];
                this.getSubscriptions();
            },
            immediate: true,
        },
    },
};
</script>