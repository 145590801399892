var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('content-card',{attrs:{"title":"Shared Data","caption":'The cloud connections you are actively sharing with this customer.'},scopedSlots:_vm._u([(_vm.tenantStore.hasPermission('CanManageBilling'))?{key:"title-actions",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","depressed":"","to":_vm.createNewShare}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" New Share ")],1)],1)]},proxy:true}:null],null,true)},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customer.sharedDataCloudAccounts,"items-per-page":15,"sort-by":_vm.sortBy,"loading":_vm.$wait.is('getTenantCustomers')},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:`item.rules.currentMarkup.markupPercentage`,fn:function({ item }){return [(item.rules && item.rules.currentMarkup && item.rules.currentMarkup.markupPercentage)?_c('div',{class:item.rules.currentMarkup.markupPercentage < 0
                            ? 'red--text text-right'
                            : item.rules.currentMarkup.markupPercentage > 0
                            ? 'green--text text-right'
                            : ''},[_vm._v(" "+_vm._s(item.rules.currentMarkup.markupPercentage.toFixed(2))+"% ")]):_vm._e()]}},{key:`item.current90DayMargin`,fn:function({ item }){return [(item.current90DayMargin)?_c('div',{class:item.current90DayMargin < 0
                            ? 'red--text text-right'
                            : item.current90DayMargin > 0
                            ? 'green--text text-right'
                            : ''},[_vm._v(" "+_vm._s(item.current90DayMargin.toFixed(2))+"% ")]):_vm._e()]}},{key:`item.rules.origin`,fn:function({ item }){return [(item.rules && item.rules.origin)?_c('div',[_vm._v(_vm._s(item.rules.origin))]):_c('div',[_vm._v("Your Cost")])]}},{key:`item.Actions`,fn:function({ item }){return [(_vm.tenantStore.hasPermission('CanManageBilling'))?_c('v-menu',{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":`/copy/cloudaccounts/${item.parentCloudAccountId}?copy=${item.id}`}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Edit Share ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.startDeleteCopy(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Delete Share ")])],1)],1)],1)],1):_vm._e()]}}],null,true)}),_c('form-dialog',{scopedSlots:_vm._u([{key:"default",fn:function({ close }){return [_c('delete-entity-card',{attrs:{"loading":_vm.$wait.is('deletingCopy'),"value":_vm.copyToDelete,"title":_vm.$t('deleteCopyCard.title'),"subtitle":_vm.$t('deleteCopyCard.subtitle')},scopedSlots:_vm._u([{key:"actions",fn:function({ isValid, clear, formId, loading }){return [_c('back-button',{on:{"click":close}}),_c('v-spacer'),_c('delete-shared-copy-button',{attrs:{"cloudAccountId":_vm.copyToDelete ? _vm.copyToDelete.ParentCloudAccountId : null,"value":_vm.copyToDelete,"valid":isValid,"clear":clear,"form":formId,"loading":loading},on:{"http2xx":function($event){close().then(() => _vm.loadCustomer())}}})]}}],null,true)})]}}]),model:{value:(_vm.showDeleteCopy),callback:function ($$v) {_vm.showDeleteCopy=$$v},expression:"showDeleteCopy"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }