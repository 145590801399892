enum CloudCredentialType {
    eaPortalApi = 'EaPortalApi',
    awsIamUsage = 'AwsIamUsage',
    loginMicrosoftAuthorization = 'LoginMicrosoftAuthorization',
    oracleIamUsage = 'OracleIamUsage',
    rhipeApi = 'RhipeApi',
    googleJsonUsage = 'GoogleJsonUsage',
    azureActiveDirectoryApplication = 'AzureActiveDirectoryApplication',
    eaPortalChinaApi = 'EaPortalChinaApi',
    alibabaChinaUsage = 'AlibabaChinaUsage',
    awsAssumeRoleUsage = 'AwsAssumeRoleUsage'
}

export default CloudCredentialType;