import { ChartData, Chart, ChartDataset } from 'chart.js/auto'
import { formatCurrency } from '@/lib/Currencies';
import * as CssVariables from '@/lib/CssVariables';
import { ICompareResult, IProductCompareResult, IServiceCompareResult, ISubscriptionCompareResult } from '@/models';
import htmlLegendPlugin from './legendPlugin';

export interface IOptions {
    currencyCode: string;
    clickFn: (entityId: string) => void | null;
}

export interface ITrendsDatapoint {
    x: number;
    y: string;
    activePeriodSpend: number;
    priorPeriodSpend: number;
    entityId: string;
}

export const convertCompareResultToDatasets = <TType extends IProductCompareResult | IServiceCompareResult | ISubscriptionCompareResult>(compareResult: ICompareResult<TType>, nameFn: (e: TType) => string, idFn: (e: TType) => string): ChartData<'bar', ITrendsDatapoint[], string> => {
    const labels: string[] = [];
    const increaseDataset: ChartDataset<'bar', ITrendsDatapoint[]> = {
        data: [],
        label: 'Increase',
        backgroundColor: '#e45757',
        borderWidth: 1,
        borderColor: 'white'
    };
    const decreaseDataset: ChartDataset<'bar', ITrendsDatapoint[]> = {
        data: [],
        label: 'Decrease',
        backgroundColor: '#55a257',
        borderWidth: 1,
        borderColor: 'white'
    };
    const datasets: ChartDataset<'bar', ITrendsDatapoint[]>[] = [increaseDataset, decreaseDataset];
    compareResult.entities.sort((a, b) => Math.abs(b.deltaSpend) - Math.abs(a.deltaSpend)).slice(0, 10).forEach((e) => {
        if (e.deltaSpend === 0) return;
        const entityName = nameFn(e);
        const entityId = idFn(e);
        const increaseSpend = e.deltaSpend > 0 ? e.deltaSpend : 0;
        const decreaseSpend = e.deltaSpend < 0 ? e.deltaSpend : 0;
        const metaData = { activePeriodSpend: e.activePeriodSpend, priorPeriodSpend: e.priorPeriodSpend, entityId };
        increaseDataset.data.push({ x: increaseSpend, y: entityName, ...metaData });
        decreaseDataset.data.push({ x: decreaseSpend, y: entityName, ...metaData });
    });
    return {
        labels,
        datasets
    };
};

export const convertProductCompareResultToDatasets = (compareResult: ICompareResult<IProductCompareResult>): ChartData<'bar', ITrendsDatapoint[], string> => {
    return convertCompareResultToDatasets(compareResult, p => p.productName, p => p.productId);
};

export const convertServiceCompareResultToDatasets = (compareResult: ICompareResult<IServiceCompareResult>): ChartData<'bar', ITrendsDatapoint[], string> => {
    return convertCompareResultToDatasets(compareResult, p => p.serviceName, p => p.serviceId);
};

export const convertSubscriptionCompareResultToDatasets = (compareResult: ICompareResult<ISubscriptionCompareResult>): ChartData<'bar', ITrendsDatapoint[], string> => {
    return convertCompareResultToDatasets(compareResult, p => p.subscriptionId, p => p.subscriptionId);
};

export default (chartItem: HTMLCanvasElement, options: IOptions, containerId: string): Chart => {
    const { currencyCode } = options;
    const style = getComputedStyle(chartItem);
    const contrastLines = style.getPropertyValue(CssVariables.ContrastLinesVariable);
    const contrastText = style.getPropertyValue(CssVariables.ContrastTextVariable);
    const chart = new Chart(chartItem, {
        type: 'bar',
        data: {
            labels: [],
            datasets: []
        },
        options: {
            indexAxis: 'y',
            borderColor: 'rgba(0,0, 0, 0)',
            plugins: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                htmlLegend: {
                    // ID of the container to put the legend in
                        containerID: containerId,
                },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                    labels: {
                        color: contrastText
                    },
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            const dataset = item.dataset;
                            const points: ITrendsDatapoint[] = dataset.data as any;
                            const hoverPoint: ITrendsDatapoint = points[item.dataIndex];
                            return [
                                `Past Cost: ${formatCurrency(hoverPoint.priorPeriodSpend, currencyCode)}`,
                                `Current Cost: ${formatCurrency(hoverPoint.activePeriodSpend, currencyCode)}`,
                                `Difference: ${hoverPoint.x > 0 ? '+' : ''}${formatCurrency(hoverPoint.x, currencyCode)}`,

                            ]
                        }
                    },
                    mode: 'point'
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    type: 'category',
                    ticks: {
                        color: contrastText
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0)'
                    },
                    stacked: true
                },
                x: {
                    ticks: {
                        color: contrastText,
                        callback(value: number) {
                            return formatCurrency(value, currencyCode);
                        }
                    },
                    grid: {
                        color: contrastLines
                    },
                    title: {
                        text: `Cost (${currencyCode})`,
                        color: contrastText,
                        display: true
                    },
                    display: true,
                    stacked: true
                }
            },
            onClick(_, [targetPtr], chart) {
                if (!options.clickFn) return;
                const targetDataset = chart.data.datasets[targetPtr.datasetIndex];
                const target: ITrendsDatapoint = targetDataset.data[targetPtr.index] as any;
                options.clickFn(target.entityId);
            },
            interaction: {
                intersect: true,
                mode: 'point'
            },
            onHover(event, [element]) {
                let newCursor = 'default';
                if (element) {
                    const dataset = chart.data.datasets[element.datasetIndex];
                    if (dataset) {
                        newCursor = 'pointer';
                    }
                }
                (event.native.target as HTMLElement).style.cursor = newCursor;
            },
        },
        plugins: [htmlLegendPlugin]
    });
    return chart;
}