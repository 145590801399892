import { ICustomerUsageSummaryModel } from '@/models';
import * as CssVariables from '@/lib/CssVariables';
import { ChartData, Point, Chart, ChartDataset } from 'chart.js/auto';
import { formatCurrency } from '@/lib/Currencies';
import { LEGACY_COLORS_RGB } from './legacy';
import './dayJsAdapter';
import pattern from 'patternomaly';
import htmlLegendPlugin from './legendPlugin';

const PREFIX_PAD = 100;
const MARGIN_DATASET_ORDER = 98;
const PROFIT_DATASET_ORDER = 99;

export const convertCustomerUsageSummaryToDatasets = (
    usageResult: ICustomerUsageSummaryModel,
    showProfit: boolean
): ChartData<'bar' | 'line', Point[], string> => {
    const datasets: ChartDataset<'bar' | 'line', Point[]>[] = [];
    usageResult.customers.forEach((g, i) => {
        const color = LEGACY_COLORS_RGB[i % LEGACY_COLORS_RGB.length];
        const customerDataset: ChartDataset<'bar', Point[]> = {
            data: [],
            label: g.customerName + "'s Cost",
            backgroundColor: `rgb(${color})`,
            borderColor: 'white',
            borderWidth: 1,
            order: PREFIX_PAD + i,
        };
        customerDataset.data = g.monthlyChargeSummaries.map((u) => ({
            x: new Date(u.year, u.month - 1, 1).getTime(),
            y: u.cost - u.profit,
        }));
        datasets.push(customerDataset);
    });
    if (showProfit) {
        const marginDataset: ChartDataset<'line', Point[]> = {
            type: 'line',
            data: [],
            label: 'Margin',
            backgroundColor: 'rgba(255, 0, 0, 0.8)',
            borderColor: 'rgb(75, 192, 192)',
            fill: false,
            tension: 0.1,
            order: MARGIN_DATASET_ORDER,
            yAxisID: 'y2',
        };
        const profitDataset: ChartDataset<'bar', Point[]> = {
            data: [],
            label: 'Total Profit',
            backgroundColor: pattern.draw('zigzag', '#17becf'),
            order: PROFIT_DATASET_ORDER,
            borderWidth: 1,
            borderColor: 'white',
        };
        usageResult.monthlyChargeTotals.forEach((t) => {
            const date = new Date(t.year, t.month - 1, 1);
            marginDataset.data.push({ x: date.getTime(), y: t.margin });
            profitDataset.data.push({ x: date.getTime(), y: t.profit });
        });
        marginDataset.data = marginDataset.data.sort((a, b) => b.x - a.x);
        profitDataset.data = profitDataset.data.sort((a, b) => b.x - a.x);
        datasets.push(marginDataset);
        datasets.push(profitDataset);
    }
    return {
        labels: [],
        datasets,
    };
};

export interface IOptions {
    currencyCode: string;
}

export default (chartItem: HTMLCanvasElement, options: IOptions, containerId: string): Chart => {
    const { currencyCode } = options;
    const style = getComputedStyle(chartItem);
    const contrastLines = style.getPropertyValue(CssVariables.ContrastLinesVariable);
    const contrastText = style.getPropertyValue(CssVariables.ContrastTextVariable);
    const chart = new Chart(chartItem, {
        type: 'bar',
        data: {
            labels: [],
            datasets: [],
        },
        options: {
            borderColor: 'rgba(0,0, 0, 0)',
            plugins: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: containerId,
                },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                    labels: {
                        color: contrastText,
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                intersect: true,
                mode: 'point',
            },
            scales: {
                x: {
                    type: 'time',
                    stacked: true,
                    ticks: {
                        color: contrastText,
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0)',
                    },
                    axis: 'x',
                    time: {
                        unit: 'month',
                    },
                },
                y: {
                    type: 'linear',
                    stacked: true,
                    ticks: {
                        color: contrastText,
                        callback(value: number) {
                            return formatCurrency(value, currencyCode);
                        },
                    },
                    grid: {
                        color: contrastLines,
                    },
                    min: 0,
                    axis: 'y',
                    title: {
                        text: `Cost (${currencyCode})`,
                        color: contrastText,
                        display: true,
                    },
                    stack: 'y',
                    display: true,
                },
                y2: {
                    type: 'linear',
                    stacked: false,
                    ticks: {
                        color: contrastText,
                        callback(value: number) {
                            return value.toFixed(2) + '%';
                        },
                    },
                    position: 'right',
                    grid: {
                        drawOnChartArea: false, // only want the grid lines for one axis to show up
                    },
                },
            },
        },
        plugins: [htmlLegendPlugin],
    });
    return chart;
};
