<template>
    <v-btn
        :type="type"
        :color="color"
        depressed
        :disabled="!valid || loading"
        :loading="loading"
        :form="form"
        @click.prevent="submit()"
        :block="block"
        :outlined="outlined"
    >
        <slot><v-icon left>done</v-icon>Submit</slot>
    </v-btn>
</template>

<script>
import ApiV2 from '@/lib/ApiV2';

export default {
    props: {
        value: {
            type: [Object, String, Array],
        },
        href: {
            type: String,
        },
        clear: {
            type: Function,
            default: () => void 0,
        },
        valid: {
            type: Boolean,
            default: true,
        },
        form: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: 'secondary',
        },
        type: {
            type: String,
            default: 'submit',
        },
        headers: {
            type: Object,
            default: undefined,
        },
        block: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        version: {
            type: String,
            default: "v2"
        }
    },
    data() {
        return { loading: false };
    },
    methods: {
        async submit() {
            this.$emit('start');
            this.loading = true;
            try {
                const Api = ApiV2;
                const response = await Api.http.post(this.href, this.value, this.headers).then((r) => r.data);
                this.clear();
                this.$emit('http2xx', response);
            } catch (err) {
                this.$emit('http4xx', err);
            } finally {
                this.loading = false;
                this.$emit('stop');
            }
        },
    },
};
</script>