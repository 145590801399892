import Api from "@/lib/ApiV2";

export const formatCurrency = (val: number, code: string) => {
    if (val === null || isNaN(val)) {
        return val;
    }

    return code
        ? val.toLocaleString(undefined, {
            style: "currency",
            currency: code
        })
        : val.toLocaleString();
};

export class CurrencyService {
    private currencies: any[];

    public async getAllCurrencies(): Promise<any[]> {
        if (this.currencies) return this.currencies;
        return (this.currencies = await Api.http
            .get("/api/metadata/currencies")
            .then(c => c.data));
    }

    public async getAllCurrencyCodes(): Promise<string[]> {
        return this.getAllCurrencies().then(r => r.map(c => c.code));
    }

    public async getCurrencySymbol(code: string): Promise<string> {
        const all = await this.getAllCurrencies();
        
        const symbolIndex = all.findIndex(c => c.code === code);
        return symbolIndex !== -1 ? all[symbolIndex].symbol : null;
    }
}

export default new CurrencyService();
