
import { defineComponent, watch, ref, computed } from 'vue';
import { IConditionalActionRuleResult } from '@/models';
import { useTenantStore } from '@/stores/tenant';
import { storeToRefs } from 'pinia';
import TagMappingActionTypeSelect, { SimpleActionTypes } from './TagMappingActionTypeSelect.vue';
import TagMappingConditionTypeSelect, { SimpleConditionTypes } from './TagMappingConditionTypeSelect.vue';

import TagMappingConditionLeftInput from './TagMappingConditionLeftInput.vue';
import TagMappingConditionRightInput from './TagMappingConditionRightInput.vue';
import TagMappingActionLeftInput from './TagMappingActionLeftInput.vue';
import TagMappingActionRightInput from './TagMappingActionRightInput.vue';

interface ISimpleConditionViewModel {
    type: SimpleConditionTypes;
    condition: string;
    test: string[];
}

interface ISimpleActionViewModel {
    type: SimpleActionTypes;
    left: string;
    right: string;
}

export default defineComponent({
    props: { value: Boolean, init: Object },
    components: {
        TagMappingActionTypeSelect,
        TagMappingConditionTypeSelect,
        TagMappingConditionLeftInput,
        TagMappingConditionRightInput,
        TagMappingActionLeftInput,
        TagMappingActionRightInput,
    },
    setup(p) {
        const actions = ref<ISimpleActionViewModel[]>([]);
        const conditions = ref<ISimpleConditionViewModel[]>([]);
        const isExitCondition = ref(false);
        const actionTypes = ref(Object.values(SimpleActionTypes));
        const conditionTypes = ref(Object.values(SimpleConditionTypes));
        const { tenantId } = storeToRefs(useTenantStore());

        watch(
            () => p.init,
            (v: IConditionalActionRuleResult | null) => {
                const newActions: (ISimpleActionViewModel & { runOrder: number })[] = [];
                v.applyMarkupActions?.forEach((a) => {
                    newActions.push({
                        type: SimpleActionTypes.markup,
                        left: a.markup.toString(),
                        right: '',
                        runOrder: a.runOrder
                    });
                });
                v.addNewTagActions?.forEach((a) => {
                    newActions.push({
                        type: SimpleActionTypes.addNewTag,
                        left: a.newTagKey,
                        right: a.newTagValue,
                        runOrder: a.runOrder,
                    });
                });
                v.removeTagActions?.forEach((a) => {
                    newActions.push({
                        type: SimpleActionTypes.removeTag,
                        left: a.tagKey,
                        right: '',
                        runOrder: a.runOrder,
                    });
                });
                v.copyValueToNewTagActions?.forEach((a) => {
                    newActions.push({
                        type: SimpleActionTypes.copyValue,
                        left: a.existingTagKeyToCopy,
                        right: a.newTagKey,
                        runOrder: a.runOrder,
                    });
                });
                actions.value = newActions.sort((a, b) => a.runOrder - b.runOrder);
                const newConditions: ISimpleConditionViewModel[] = [];
                v.subscriptionConditions?.forEach((c) => {
                    newConditions.push({
                        type: c.matchCase
                            ? SimpleConditionTypes.subscription_sensitive
                            : SimpleConditionTypes.subscription_insensitive,
                        condition: '',
                        test: c.matchSubscriptionNames,
                    });
                });
                v.cloudAccountConditions?.forEach((c) => {
                    newConditions.push({
                        type: c.matchCase
                            ? SimpleConditionTypes.cloudAccount_Sensitive
                            : SimpleConditionTypes.cloudAccount_Insensitive,
                        condition: '',
                        test: c.matchCloudAccountNames
                    })
                });
                v.sourceRowFieldConditions?.forEach((c) => {
                    newConditions.push({
                        type: c.matchCase
                            ? SimpleConditionTypes.sourceRowField_sensitive
                            : SimpleConditionTypes.sourceRowField_insensitive,
                        condition: c.sourceRowFieldName,
                        test: c.matchValues,
                    });
                });
                v.sourceTagKeyValueConditions?.forEach((c) => {
                    newConditions.push({
                        type: c.matchCase
                            ? SimpleConditionTypes.sourceTagKeyValue_sensitive
                            : SimpleConditionTypes.sourceTagKeyValue_insensitive,
                        condition: c.sourceTagKeyName,
                        test: c.matchValues,
                    });
                });
                conditions.value = newConditions;
                isExitCondition.value = v.stopProcessingMoreRules;
            },
            { immediate: true, deep: true }
        );

        const appendCondition = () => {
            conditions.value.push({
                condition: '',
                test: [],
                type: SimpleConditionTypes.sourceRowField_insensitive,
            });
        };

        const deleteCondition = (i: number) => {
            if (i > -1) {
                conditions.value.splice(i, 1);
                conditions.value = [...conditions.value];
            }
        };

        const appendAction = () => {
            actions.value.push({
                left: '',
                right: '',
                type: SimpleActionTypes.addNewTag,
            });
        };

        const deleteAction = (i: number) => {
            if (i > -1) {
                actions.value.splice(i, 1);
                actions.value = [...actions.value];
            }
        };

        const moveActionUpwards = (index: number) => {
            if (index > 0) {
                const target = actions.value[index];
                const sibling = actions.value[index - 1];
                actions.value[index] = sibling;
                actions.value[index - 1] = target;
                actions.value = [...actions.value];
            }
        };

        const moveActionDownwards = async (index: number) => {
            if (index < actions.value.length - 1) {
                const target = actions.value[index];
                const sibling = actions.value[index + 1];
                actions.value[index] = sibling;
                actions.value[index + 1] = target;
                actions.value = [...actions.value];
            }
        };

        const form = computed<IConditionalActionRuleResult | null>(() => {
            const data: IConditionalActionRuleResult = {
                id: p.init?.id,
                isActive: p.init?.isActive || true,
                ruleDescription: p.init?.ruleDescription,
                ruleName: p.init?.ruleName,
                ruleOrder: p.init?.ruleOrder,
                tenantId: tenantId.value,
                stopProcessingMoreRules: isExitCondition.value,
                addNewTagActions: [],
                copyValueToNewTagActions: [],
                removeTagActions: [],
                sourceRowFieldConditions: [],
                sourceTagKeyValueConditions: [],
                subscriptionConditions: [],
                applyMarkupActions: [],
                cloudAccountConditions: [],
            };
            actions.value.forEach((a, i) => {
                if (a.type === SimpleActionTypes.addNewTag) {
                    data.addNewTagActions.push({
                        newTagKey: a.left,
                        newTagValue: a.right,
                        runOrder: i,
                    });
                } else if (a.type === SimpleActionTypes.copyValue) {
                    data.copyValueToNewTagActions.push({
                        existingTagKeyToCopy: a.left,
                        newTagKey: a.right,
                        runOrder: i,
                    });
                } else if (a.type === SimpleActionTypes.removeTag) {
                    data.removeTagActions.push({
                        tagKey: a.left,
                        runOrder: i,
                    });
                } else if (a.type === SimpleActionTypes.markup) {
                    data.applyMarkupActions.push({
                        markup: Number(a.left),
                        runOrder: i,
                    });
                }
            });
            conditions.value.forEach((c) => {
                if (
                    c.type === SimpleConditionTypes.sourceRowField_insensitive ||
                    c.type === SimpleConditionTypes.sourceRowField_sensitive
                ) {
                    data.sourceRowFieldConditions.push({
                        matchCase: c.type === SimpleConditionTypes.sourceRowField_sensitive,
                        matchValues: c.test,
                        sourceRowFieldName: c.condition,
                    });
                } else if (
                    c.type === SimpleConditionTypes.sourceTagKeyValue_insensitive ||
                    c.type === SimpleConditionTypes.sourceTagKeyValue_sensitive
                ) {
                    data.sourceTagKeyValueConditions.push({
                        matchCase: c.type === SimpleConditionTypes.sourceTagKeyValue_sensitive,
                        matchValues: c.test,
                        sourceTagKeyName: c.condition,
                    });
                } else if (
                    c.type === SimpleConditionTypes.subscription_insensitive ||
                    c.type === SimpleConditionTypes.subscription_sensitive
                ) {
                    data.subscriptionConditions.push({
                        matchCase: c.type === SimpleConditionTypes.subscription_sensitive,
                        matchSubscriptionNames: c.test,
                    });
                } else if (
                    c.type === SimpleConditionTypes.cloudAccount_Insensitive ||
                    c.type === SimpleConditionTypes.cloudAccount_Sensitive
                ) {
                    data.cloudAccountConditions.push({
                        matchCase: c.type === SimpleConditionTypes.cloudAccount_Sensitive,
                        matchCloudAccountNames: c.test,
                    });
                }
            });
            return data;
        });

        return {
            actions,
            conditions,
            isExitCondition,
            actionTypes,
            conditionTypes,
            form,
            appendCondition,
            deleteCondition,
            appendAction,
            deleteAction,
            moveActionUpwards,
            moveActionDownwards,
        };
    },
});
