   
import { defineComponent } from 'vue';
import { UnitOfDisplay } from '@/enums/UnitOfDisplay.enum';

export default defineComponent({
    props: {
        value: String,
    },
    data() {
        return {
            selectedValue: this.value ?? UnitOfDisplay.cost,
            displayOptions: UnitOfDisplay
        };
    },
    methods: {
        unitChanged() {
            this.$emit('input', this.selectedValue); 
        }
    }
});
