
import { computed, defineComponent, PropType } from 'vue';
import { ITagMetadataResult } from '@/models';
import { IChipViewModel } from '@/components/Domain.Usage/UsageFilterChips.vue';
import Config from '@/lib/Config';

export default defineComponent({
    props: {
        filters: {
            type: Object as PropType<IChipViewModel[]>,
            required: true
        },
        tagKeyValue: {
            type: Object as PropType<ITagMetadataResult>,
            required: true
        }
    },
    setup(props) {
        const filterText = computed(() => {
            return props.filters.map((item: IChipViewModel) => item.id.split(":")[0]).join(", ");
        })

        return {
            filterText,
            supportEmail: Config.get().SUPPORT_EMAIL,
            showSupportEmail: Config.isFeatureEnabled('ShowSupportButton') && Config.get().SUPPORT_EMAIL,
        };
    }
});
