<template>
  <http-delete-button
    v-bind="$props"
    v-on="$listeners"
    :href="annotationId ? `/api/annotations/${annotationId}` : null"
    version="v2"
    @http4xx="onError"
  >
  </http-delete-button>
</template>

<i18n>
{
    "en": {
        "deleteAnnotationButton.errorMessage": "There was an error encountered while deleting this annotation. If anything does not look right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from "@/components/Common/Toast.vue";
import HttpDeleteButton from "@/components/Common/HttpDeleteButton.vue";

export default {
  components: { HttpDeleteButton },
  props: {
    ...HttpDeleteButton.props,
    annotationId: [String, Number]
  },
  methods: {
    onError() {
      toastError(this.$t("deleteAnnotationButton.errorMessage"));
    },
  },
};
</script>