
import { defineComponent, computed } from 'vue';
import {
    VuetifyErrorDarken1,
    VuetifyWarningDarken1,
    VuetifySuccessDarken1,
    VuetifySuccessLighten2,
    VuetifyErrorLighten2,
    VuetifyWarningLighten2,
} from '@/lib/CssVariables';
export default defineComponent({
    props: {
        currentConsumption: Number,
        predictedConsumption: { type: Number, default: null },
        minConsumption: { type: String, default: null },
        maxConsumption: { type: String, default: null },
    },
    setup(props) {
        const currentColor = computed(() => {
            if (props.currentConsumption >= 100) {
                return VuetifyErrorDarken1;
            } else if (props.predictedConsumption >= 100) {
                return VuetifyWarningDarken1;
            } else {
                return VuetifySuccessDarken1;
            }
        });
        const predictedColor = computed(() => {
            if (props.currentConsumption >= 100) {
                return VuetifyErrorLighten2;
            } else if (props.predictedConsumption >= 100) {
                return VuetifyWarningLighten2;
            } else {
                return VuetifySuccessLighten2;
            }
        });
        return {
            backgroundColor: '#f0f2f7',
            currentColor,
            predictedColor,
        };
    },
});
