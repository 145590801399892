
import { defineComponent, ref } from 'vue';
import { getMailingLists, createMailingList, putMailingList } from '@/lib/Api';
import ModalListInput from '@/components/Common/ModalListInput.vue';
import UpdateEntityNameCard from '@/components/Common/UpdateEntityNameCard.vue';

interface IMailingListViewModel {
    id: number;
    name: string;
    emails: string[];
}

export default defineComponent({
    components: { ModalListInput, UpdateEntityNameCard },
    setup() {
        const mailingLists = ref<IMailingListViewModel[]>([]);
        const isCreatingOrEditing = ref(false);
        const targetMailingList = ref<Partial<IMailingListViewModel>>({});
        const loading = ref(false);
        const getPageData = async () => {
            try {
                loading.value = true;
                mailingLists.value = await getMailingLists();
            } finally {
                loading.value = false;
            }
        };
        getPageData();
        const submitNameForm = async (data: { Name: string }) => {
            try {
                loading.value = true;
                if (targetMailingList.value.id) {
                    await putMailingList({
                        id: targetMailingList.value.id,
                        name: data.Name,
                        emails: targetMailingList.value.emails || [],
                    });
                } else {
                    await createMailingList({
                        id: -1,
                        name: data.Name,
                        emails: [],
                    });
                }
            } finally {
                loading.value = false;
            }
            await getPageData();
        };
        const updateMailingListEmails = async (ml: IMailingListViewModel, emails: string[]) => {
            try {
                loading.value = true;
                ml.emails = emails;
                await putMailingList(ml);
            } finally {
                loading.value = false;
            }
            await getPageData();
        };
        const startCreateList = () => {
            targetMailingList.value = {};
            isCreatingOrEditing.value = true;
        };
        const startEditingList = (body: IMailingListViewModel) => {
            targetMailingList.value = body;
            isCreatingOrEditing.value = true;
        };
        return {
            mailingLists,
            getPageData,
            isCreatingOrEditing,
            targetMailingList,
            submitNameForm,
            startCreateList,
            startEditingList,
            loading,
            updateMailingListEmails,
        };
    },
});
