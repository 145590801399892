<template>
  <http-delete-button
    v-bind="$props"
    v-on="$listeners"
    :href="`/api/metadata/cloudaccounts/${cloudAccountId}/copies/${(value || {}).Id}`"
    @http4xx="onError"
    version="v2"
  >
  </http-delete-button>
</template>

<i18n>
{
    "en": {
        "deleteCloudAccountCopyButton.errorMessage": "There was an error encountered while deleting the shared cloud connection. If everything looks right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from "@/components/Common/Toast.vue";
import HttpDeleteButton from "@/components/Common/HttpDeleteButton.vue";

export default {
  props: {
    ...HttpDeleteButton.props,
    value: {
      type: Object,
    },
    cloudAccountId: String
  },
  methods: {
    onError() {
      toastError(this.$t("deleteCloudAccountCopyButton.errorMessage"));
    },
  },
};
</script>