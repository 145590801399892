<template>
    <v-menu offset-y bottom>
        <template #activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
            </v-btn>
        </template>
        <v-list>
            <DeleteCustomViewDialog
                v-model="isDeleting"
                :customView="value"
                #default="{ on }"
                @http2xx="$emit('http2xx', $event)"
            >
                <v-list-item v-on="on">
                    <v-list-item-icon>
                        <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('customViewMenu.delete') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </DeleteCustomViewDialog>
        </v-list>
    </v-menu>
</template>

<i18n>
{
    "en": {
        "customViewMenu.delete": "Delete"
    }
}
</i18n>

<script>
import DeleteCustomViewDialog from '@/components/Domain.CustomViews/DeleteCustomViewDialog.vue';
export default {
    components: {
        DeleteCustomViewDialog
    },
    data() {
        return {
            isDeleting: false,
        };
    },
    props: {
        value: Object,
    }
};
</script>