import KeyedBlobs from "@/databases/KeyedBlobsDb";

export interface IUserPreferences {
    costExplorerTab?: string | number | null;
    starredCostExplorerTab?: string | number | null;
}

export const getUserPreferences = async (): Promise<IUserPreferences> => {
    return KeyedBlobs.getJson("CC:UserPreferences");
};

export const setUserPreferences = async (preferences: IUserPreferences) => {
    return KeyedBlobs.putJson("CC:UserPreferences", preferences);
};
