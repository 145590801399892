
import SetFxRateDialog from '@/components/Domain.FxRates/SetFxRateDialog.vue';
import EditFxRateButton from '@/components/Domain.FxRates/EditFxRateButton.vue';
export default {
    components: { SetFxRateDialog, EditFxRateButton },
    props: {
        value: Boolean,
        edit: Object,
    },
};
