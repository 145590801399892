import Vue from 'vue';
import './highcharts.proj4.js';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsMapModule from 'highcharts/modules/map';
import HighchartsCustomWorld from './highcharts.custom.world.js';
import fillFactory from 'highcharts-pattern-fill/pattern-fill-v2.js';

fillFactory(Highcharts);
HighchartsMapModule(Highcharts);
Highcharts.maps["custom/world"] = HighchartsCustomWorld;
Vue.use(HighchartsVue);
