<template>
    <form-dialog :value="value" @input="$emit('input', $event)">
        <template #activator="bindings">
            <slot name="activator" v-bind="bindings"></slot>
        </template>
        <template #default="{ close }">
            <customView-card :value="customView" :init="customView" :loading="loading">
                <template #actions="actionBindings">
                    <back-button @click="close()"></back-button>
                    <v-spacer />
                    <slot v-bind="{ ...actionBindings, close }"></slot>
                </template>
            </customView-card>
        </template>
    </form-dialog>
</template>

<script>
import CustomViewCard from '@/components/Domain.CustomViews/SetCustomViewCard.vue';
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        customView: {
            type: Object,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { CustomViewCard },
};
</script>
