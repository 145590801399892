
import Api from '@/lib/ApiV2';
import UniqueIdentifier from '@/lib/UniqueIdentifier';
import { CloudProviderType, EntityType, ISearchEntity } from '@/models';

import PostCustomViewDialog from '@/components/Domain.CustomViews/PostCustomViewDialog.vue';
import { toastError } from '@/components/Common/Toast.vue';
import { getCustomViews } from '@/lib/Api';
import { waitFor } from '@/plugins/vue-wait';

export interface ISearchEntityViewModel {
    Type: EntityType;
    Id: string;
    Name: string;
    Description: string;
    Provider: CloudProviderType | null;
    EntityIndex: number;
}

export const convertToViewModel = (e: ISearchEntity): ISearchEntityViewModel => ({
    Type: e.type,
    Id: e.id,
    Name: e.name,
    Description: e.description,
    Provider: e.provider,
    EntityIndex: e.entityIndex,
});

export const convertFromViewModel = (e:ISearchEntityViewModel): ISearchEntity => ({
    type: e.Type,
    id: e.Id,
    name: e.Name,
    description: e.Description,
    provider: e.Provider as CloudProviderType,
    entityIndex: e.EntityIndex,
});

export interface ISpendUsageOptions {
    sysServiceIds: Array<{
        id: string;
        name: string;
    }>;
    sysProductIds: Array<{
        id: string;
        name: string;
    }>;
    subscriptionIds: Array<{
        id: string;
        name: string;
    }>;
    cloudAccountIds: Array<{
        id: string;
        name: string;
    }>;
    tagKeyValues: Array<string>;
    regions: Array<string>;

    tagKeys: Array<{
        id: string;
        name: string;
    }>;
    geographies: Array<string>;
    cloudProviderTypes: Array<string>;
    productCategories: Array<string>;
}

export const convertToSpendUsageOptions = (e: ISearchEntityViewModel[]): ISpendUsageOptions | null => {

    if (!e) return null;
    if (!e.length) return null;

    const cv: ISpendUsageOptions = {
        sysProductIds: [],
        subscriptionIds: [],
        cloudAccountIds: [],
        sysServiceIds: [],
        tagKeyValues: [],
        regions: [],
        tagKeys: [],
        geographies: [],
        cloudProviderTypes: [],
        productCategories: [],
    };

    e.forEach((item) => {
        if (item.Type === EntityType.service) {
            cv.sysServiceIds.push({ id: item.Id, name: item.Name });
        } else if (item.Type === EntityType.product) {
            cv.sysProductIds.push({ id: item.Id, name: item.Name });
        } else if (item.Type === EntityType.subscription) {
            cv.subscriptionIds.push({ id: item.Id, name: item.Name });
        } else if (item.Type === EntityType.cloudAccount) {
            cv.cloudAccountIds.push({ id: item.Id, name: item.Name });
        } else if (item.Type === EntityType.tag) {
            cv.tagKeyValues.push(item.Name);
        } else if (item.Type === EntityType.region) {
            cv.regions.push(item.Name);
        } else if (item.Type === EntityType.geography) {
            cv.geographies.push(item.Name);
        } else if (item.Type === EntityType.cloudProvider) {
            cv.cloudProviderTypes.push(item.Id);
        } else if (item.Type === EntityType.productCategory) {
            cv.productCategories.push(item.Name);
        } 
    });
    return cv;
};

export default {
    props: {
        value: {
            types: [Object, Array],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        multiple: {
            types: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        types: {
            types: Array,
            default: () => [
                EntityType.service,
                EntityType.subscription,
                EntityType.cloudAccount,
                EntityType.tag,
                EntityType.region,
                EntityType.product,
                EntityType.geography,
                EntityType.cloudProvider,
            ],
        },
        flat: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        filled: {
            type: Boolean,
            default: false,
        },
        solo: {
            type: Boolean,
            default: false,
        },
        soloInverted: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        chips: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        prependIcon: {
            type: String,
            default: 'search',
        },
        searchPrefix: {
            type: String,
            default: '',
        },
        supportCreate: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Array,
            default: null
        },
        disableSaveCustomView: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        selection: {
            get() {
                if (!this.value) return null;
                if (this.multiple) {
                    return this.value.map((i) => i.Id);
                } else {
                    return this.value.Id;
                }
            },
            set(val) {
                if (!val) {
                    this.$emit('input', null);
                    return;
                }
                if (this.multiple) {
                    this.$emit(
                        'input',
                        this.items.filter((i) => val.includes(i.Id))
                    );
                } else {
                    this.$emit(
                        'input',
                        this.items.find((i) => i.Id === val)
                    );
                }
            },
        },
        spendUsageOptions: {
            get() {
                const options = convertToSpendUsageOptions(this.value);

                return options;
            }            
        }
    },
    components: { PostCustomViewDialog },
    data() {
        return {
            searchText: '',
            items: [],
            autoCompleteId: UniqueIdentifier.create(),
            isCreating: false
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                if (this.multiple) {
                    const toAdd = this.value.filter((item) => !this.items.find((x) => x.Id === item.Id));
                    if (toAdd.length) this.items.push(...toAdd);
                } else {
                    if (!this.value.Id) return;
                    if (!this.items) {
                        this.items = [this.value];
                    } else if (!this.items.find((x) => x.Id === this.value.Id)) {
                        this.items.push(this.value);
                    }
                }
            },
            immediate: true,
            deep: true,
        },
        searchText(val, old) {
            if (!val || !old) return;
            this.queryEntitiesWithCurrentQuery();
        },
        searchPrefix(val, old) {
            if (!val || !old) return;
            this.queryEntitiesWithCurrentQuery();
        },
    },
    methods: {
        handleInput(val) {
            if (!val) {
                this.$emit('input', null);
                return;
            }
            if (this.multiple) {
                this.$emit(
                    'input',
                    val.map((v) => this.items.find((i) => i.Id === v))
                );
            } else {
                this.$emit(
                    'input',
                    this.items.find((i) => i.Id === val)
                );
            }
        },
        createDummyEntity(searchText) {
            this.$emit('input', {
                Id: searchText,
                Name: searchText,
                Description: null,
                Provider: '',
                Type: 'Synthetic',
                EntityIndex: 1,
            });
        },
        queryEntitiesWithCurrentQuery() {
            this.queryEntities(
                this.searchText,
                this.types || [
                    EntityType.service,
                    EntityType.subscription,
                    EntityType.cloudAccount,
                    EntityType.tag,
                    EntityType.region,
                    EntityType.product,
                    EntityType.geography,
                ],
                0,
                30
            );
        },
        async queryEntities(queryString, types, page, pageSize) {
            if (this.$wait.is(`queryEntities-${this.autoCompleteId}`)) return;
            try {
                this.$wait.start(`queryEntities-${this.autoCompleteId}`);
                const searchParams = new URLSearchParams();
                const searchQuery = `${this.searchPrefix || ''}${queryString || ''}`;
                searchParams.append('q', searchQuery);
                if (typeof types !== 'undefined' && types.join) {
                    searchParams.append('types', types.join());
                }
                if (typeof page !== 'undefined') {
                    searchParams.append('page', page);
                }
                if (typeof pageSize !== 'undefined') {
                    searchParams.append('pageSize', pageSize);
                }
                const searchItems = (this.items = await Api.http
                    .get(`/api/search?${searchParams.toString()}`)
                    .then((r) => r.data.map(convertToViewModel)));
                if (!this.value) {
                    this.items = searchItems;
                } else if (this.multiple) {
                    const ids = new Set(searchItems.map((d) => d.Id));
                    this.items = [...searchItems, ...this.value.filter((d) => !ids.has(d.Id))];
                } else {
                    this.items = (searchItems || []).concat([this.value]);
                }
            } finally {
                this.$wait.end(`queryEntities-${this.autoCompleteId}`);
            }
        },
        handleSaveCustomView() {
            this.isCreating = true;
            this.$emit('save-custom-view', this.searchText);
        },
        loadReports: waitFor('loadingCustomViews', async function loadingReports() {
            try {
                this.customViewsLoading = true;
                this.customViews = await getCustomViews();
                this.customViewsLoading = false;
            } catch (err) {
                toastError(this.$t('customViews.failedToLoad'));
            }
        }),    
    },
} as unknown;
