
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import { defineComponent, ref } from 'vue';
import Downloader from '@/lib/Downloader';
import { IRawUsageRecord } from '@/models';
export default defineComponent({
    props: ['value', 'loading'],
    setup() {
        const records = ref([]);
        const headers = [
            ['chargeDate', 'Charge Date'],
            ['cost', 'Cost'],
            ['unitsConsumed', 'Consumption'],
            ['meterName', 'Meter Name'],
            ['regionName', 'Region'],
        ].map(([value, text]) => ({
            text,
            align: 'left',
            sortable: true,
            value,
        }));
        const sortBy = ref('chargeDate');
        const sortDesc = ref(true);
        const getServiceRawRecords = waitFor('gettingRawRecords', async function getFn(serviceId: string) {
            const rawRecords: IRawUsageRecord[] = await ApiV2.http
                .get(`/api/raw/services/${serviceId}`)
                .then((r) => r.data);
            records.value = rawRecords.map((val, i) => ({ ...val, i }));
        });
        const downloadAsJson = () => {
            Downloader.exportJSON(records.value, 'Usage.json');
        };
        const downloadAsCsv = () => {
            const csvHeaders = [
                'chargeDate',
                'cost',
                'currency',
                'unit',
                'unitsConsumed',
                'meterId',
                'meterName',
                'resourceName',
                'regionName',
                'productName',
                'tags',
                'metaData',
            ];
            const values = records.value.map((i) =>
                csvHeaders.map((h) => {
                    if (h === 'metaData' || h === 'tags') {
                        return Object.entries(i[h])
                            .map(([k, v]) => `${k}:${v}`)
                            .join('|');
                    } else {
                        return i[h];
                    }
                })
            );
            Downloader.exportCSV([csvHeaders, ...values], 'Usage.csv');
        };
        return { records, getServiceRawRecords, headers, expanded: [], sortBy, sortDesc, downloadAsJson, downloadAsCsv };
    },
    watch: {
        value: {
            handler(svc) {
                if (!svc) return;
                this.getServiceRawRecords(svc.serviceId);
            },
            immediate: true,
        },
    },
});
