export const OthersPatternUrl = 'url(#others-pattern)';

export const ContrastBandVariable = 'var(--contrast-band)';
export const ContrastTextVariable = "--contrast-text";
export const ContrastLinesVariable = "--contrast-lines";

export const VuetifyPrimaryBase = 'var(--v-primary-base)';
export const VuetifyErrorDarken1 = 'var(--v-error-darken1)';
export const VuetifyWarningDarken1 = 'var(--v-warning-darken1)';
export const VuetifySuccessDarken1 = 'var(--v-success-darken1)';

export const VuetifyErrorLighten2 = 'var(--v-error-lighten2)';
export const VuetifyWarningLighten2 = 'var(--v-warning-lighten2)';
export const VuetifySuccessLighten2 = 'var(--v-success-lighten2)';