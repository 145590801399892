
import EventBus from "@/lib/EventBus";

export function toastError(data) {
  EventBus.$emit('$toast.error', data);
}

export function toastSuccess(data) {
  EventBus.$emit('$toast.success', data);
}

export function toastInfo(data) {
  EventBus.$emit('$toast.info', data);
}

export default {
  data() {
    return {
      show: false,
      color: "",
      timeout: 4000,
      text: ""
    };
  },
  created() {
    EventBus.$on("$toast.error", this.onError);
    EventBus.$on("$toast.success", this.onSuccess);
    EventBus.$on("$toast.info", this.onInfo);
  },
  destroyed() {
    EventBus.$off("$toast.error", this.onError);
    EventBus.$off("$toast.success", this.onSuccess);
    EventBus.$off("$toast.info", this.onInfo);
  },
  methods: {
    render(text, color) {
      this.text = text;
      this.color = color;
      this.show = true;
    },
    onError(text) {
      this.render(text, "error");
    },
    onSuccess(text) {
      this.render(text, "success");
    },
    onInfo(text) {
      this.render(text, "info");
    }
  }
};
