<template>
    <v-btn text @click="handleClick">
        <v-icon left>arrow_back</v-icon>
        {{ $t('back') }}
    </v-btn>
</template>

<script>
export default {
    props: {
        route: {
            type: Boolean,
            default: false,
        },
        navigateToStep: {
            type: [String,Number],
            default: '-1'
        }
    },
    methods: {
        handleClick($event) {
            if (this.route && window.history.length > 2) {
                this.$router.go(this.navigateToStep);
            } else {
                this.$emit('click', $event);
            }
        },
    },
};
</script>