

export const ALIBABA_REGIONS = [
    { code: 'cn-qingdao', name: 'Qingdao' },
    { code: 'cn-beijing', name: 'Beijing' },
    { code: 'cn-zhangjiakou', name: 'Zhangjiakou' },
    { code: 'cn-huhehaote', name: 'Hohhot' },
    { code: 'cn-wulanchabu', name: 'Ulanqab' },
    { code: 'cn-hangzhou', name: 'Hangzhou' },
    { code: 'cn-shanghai', name: 'Shanghai' },
    { code: 'cn-shenzhen', name: 'Shenzhen' },
    { code: 'cn-heyuan', name: 'Heyuan' },
    { code: 'cn-guangzhou', name: 'Guangzhou' },
    { code: 'cn-chengdu', name: 'Chengdu' },
    { code: 'ap-southeast-1', name: 'Sydney'}
];

export default {
    props: ['value', 'label', 'rules'],
    computed: {
        matchingAlibabaRegions() {
            if (!this.search) return this.allRegions;
            return [this.search]
                .concat(this.allRegions.filter(b => b.name.startsWith(this.search)))
                .filter((value, index, self) => {
                    return self.findIndex(c => c.code == value.code) === index;
                });
        }
    },
    data() {
        return {
            allRegions: [...ALIBABA_REGIONS],
            errored: false,
            loading: false,
            search: ''
        };
    }
};
