
import SetSnowflakeCredentialsCard from '@/components/Settings.CloudAccounts/Snowflake/SetSnowflakeCredentialsCard.vue';
export default {
    props: {
        value: Boolean,
        loading: Boolean,
        disabled: Object,
        edit: Object,
    },
    components: { SetSnowflakeCredentialsCard },
    setup(_, { emit }) {
        const open = () => emit('input', true);
        return { open };
    },
};
