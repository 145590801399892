<template>
    <content-card
        :title="$t('copyCloudAccount.finish.title')"
        :caption="$t('copyCloudAccount.finish.caption')"
        :loading="loading"
    >
        <v-text-field
            :value="value.name"
            @input="$emit('input', { ...value, name: $event })"
            :label="$t('copyCloudAccount.finish.name.label')"
        ></v-text-field>
        <template #actions>
            <slot :isValid="!!value.name"></slot>
        </template>
    </content-card>
</template>

<i18n>
{
    "en": {
        "copyCloudAccount.finish.title": "Finish",
        "copyCloudAccount.finish.caption": "Finally what would you like to call the shared cloud connection.",
        "copyCloudAccount.finish.name.label": "Shared cloud connection name"
    }
}
</i18n>

<script>
export default {
    props: {
        value: Object,
        cloudAccount: Object,
        childCustomers: Array,
        tenants: Array,
        loading: Boolean,
    },
    data() {
        return { newName: null };
    },
};
</script>