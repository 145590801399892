import Config from '@/lib/Config';
import AuthClient from '@/lib/Auth';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import Vue from 'vue';

const config = Config.get();

export class ApiV2Client {

    get anonymous(): AxiosInstance {
        const instance = axios.create({ baseURL: config.API_2_URL });
        axiosRetry(instance, { retryDelay: axiosRetry.exponentialDelay, retries: config.API_MAX_RETRY_COUNT });
        return instance;
    }

    get http(): AxiosInstance {
        const instance = axios.create({ baseURL: config.API_2_URL });

        axiosRetry(instance, { retryDelay: axiosRetry.exponentialDelay, retries: config.API_MAX_RETRY_COUNT });
        instance.interceptors.request.use(
            async cfg => {
                const token = await Vue.prototype.$auth.getTokenSilently();
                cfg.headers.Authorization = token ? `Bearer ${token}` : '';
                cfg.headers['X-Tenant-Id'] = AuthClient.tenantId ? AuthClient.tenantId : '';
                return cfg;
            },
            error => {
                Promise.reject(error);
            }
        );
        instance.interceptors.response.use(
            cfg => {
                return cfg;
            },
            error => {
                if (401 === error.response.status) {
                    AuthClient.tenantId = null;
                    window.location.href = '/logout';
                } else {
                    return Promise.reject(error);
                }
            }
        );
        return instance;
    }
}

export default new ApiV2Client();
