<template>
    <form-card :title="title" :subtitle="subtitle" :loading="loading" v-model="valid" :lazyValidation="false">
        <v-alert v-if="warningText" text color="warning">
            <v-icon color="warning">warning</v-icon>
            Warning: 
            {{  warningText }}
        </v-alert>

        <v-checkbox
            v-if="simple"
            v-model="checkToDelete"
            :rules="checkToDeleteRules"
            :label="$t('deleteEntityCard.checkToDelete.label')"
            required
        ></v-checkbox>

        <v-text-field
            v-else
            v-model="nameConfirmation"
            :rules="nameConfirmationRules"
            :placeholder="$t('deleteEntityCard.placeholder', { entityName })"
            persistent-hint
            :label="$t('name')"
            required
            autofocus
            :counter="entityName ? entityName.length : null"
        ></v-text-field>

        <template #actions="{ formId, reset }">
            <slot
                name="actions"
                :isValid="valid"
                :clear="reset"
                :formId="formId"
                :loading="loading"
                :form="{ Name: entityName }"
            ></slot>
        </template>
    </form-card>
</template>
<i18n>
{
  "en": {
    "deleteEntityCard.placeholder": "Enter '{entityName}' to continue",
    "deleteEntityCard.checkToDelete.label": "Are you sure you want to delete?"
  }
}
</i18n>
<script>
export default {
    props: ['value', 'loading', 'title', 'subtitle', 'simple', 'warningText'],
    computed: {
        entityName() {
            if (typeof this.value === 'string') return this.value;
            if (this.value) return this.value.Name || this.value.name || '<none given>';
            return '<none given>';
        },
    },
    data() {
        return {
            valid: true,
            checkToDelete: false,
            checkToDeleteRules: [(v) => !!v || 'Confirmation required'],
            nameConfirmation: '',
            nameConfirmationRules: [
                (v) => !!v || 'Confirmation required',
                (v) => v === this.entityName || `Must match '${this.entityName}'`,
            ],
        };
    },
};
</script>