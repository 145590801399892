<template>
    <http-delete-button
        v-bind="$props"
        v-on="$listeners"
        :href="value ? `/api/metadata/tenant/${value.Id}` : null"
        @http4xx="onError"
        version="v2"
    >
    </http-delete-button>
</template>

<i18n>
{
    "en": {
        "deleteTenantButton.errorMessage": "There was an error encountered while deleting this tenant. If everything looks right please contact support so we can help you :)",
        "deleteTenantButton.unauthorisedMessage": "You do not have permission to delete this tenant."
    }
}
</i18n>

<script>
import { toastError, toastInfo } from '@/components/Common/Toast.vue';
import HttpDeleteButton from '@/components/Common/HttpDeleteButton.vue';

export default {
    components: { HttpDeleteButton },
    props: {
        ...HttpDeleteButton.props,
        value: {
            type: Object,
        },
    },
    methods: {
        onError(err) {
            const response = err.response;
            if (response && response.status == 403) {
                toastInfo(this.$t('deleteTenantButton.unauthorisedMessage'));
            } else {
                toastError(this.$t('deleteTenantButton.errorMessage'));
            }
        },
    },
};
</script>