<template>
    <fragment>
        <v-chip outlined label class="mr-1">
            <v-icon left>place</v-icon>
            Geography: {{ value.geography }}
        </v-chip>
    </fragment>
</template>

<script>
export default {
    props: {
        value: Object,
    },
};
</script>