<template>
    <SetAmazonAssumeRoleCredentialsDialog
        :value="value"
        @input="$emit('input', $event)"
        :loading="$wait.is('creatingAwsAccount')"
        :edit="edit"
        :disabled="{ ...(disabled || {}), Name: true, UseTenantCurrencies: true, IsPax8: true }"
    >
        <template #actions="{ form, formId, isValid, clear, close }">
            <put-credential-button
                type="AwsAssumeRoleCredential"
                :value="{ ...edit, ...form }"
                :clear="clear"
                :valid="isValid"
                :form="formId"
                v-wait-while="'creatingAwsAccount'"
                @http2xx="acceptSubmit($event, close)"
            ></put-credential-button>
        </template>
    </SetAmazonAssumeRoleCredentialsDialog>
</template>

<script>
import SetAmazonAssumeRoleCredentialsDialog from '@/components/Settings.CloudAccounts/Amazon/SetAmazonAssumeRoleCredentialsDialog.vue';
import PutCredentialButton from '@/components/Settings.CloudAccounts/PutCredentialButton';
export default {
    components: { SetAmazonAssumeRoleCredentialsDialog, PutCredentialButton },
    props: {
        value: Boolean,
        edit: Object,
        disabled: Object,
    },
    methods: {
        async acceptSubmit($event, close) {
            await close();
            this.$emit('http2xx', $event);
        },
    },
};
</script>
