import {ICustomViewSummaryResult} from '@/models';

export default interface IBudgetResult {
    id: number;
    name: string;
    tenantId: string;
    customView: ICustomViewSummaryResult;
    period: BudgetPeriod;
    periodStartDate: Date;
    budgetType: BudgetType;
    budgetPeriodString: string;
    amount: number;
    growthRatePercentage?: number;
    currencyCode: string;
    daysLeftInPeriod: number;
    currentPeriodStartDate?: Date;
    currentPeriodEndDate?: Date;
    currentSpend?: number;
    currentPercentage?: number;
    projectedSpend?: number;
    projectedPercentage?: number;
    progressPercentage?: number;
    budgetSeries: IBudgetSeriesResult[];
}

export interface IBudgetSeriesResult {
    period: string;
    actualCost: number;
    budgetedCost: number;
    varianceCost: number;
    variancePercentage: number;
}

export enum BudgetType {
    SpecifiedAmount = 0,
}

export enum BudgetPeriod {
    Weekly = 0,
    Monthly = 1,
    Quarterly = 2,
    Yearly = 3,
}

export interface IBudgetCreateModel {
    name: string;
    customViewId: number;
    period: BudgetPeriod;
    periodStartDate: Date;
    budgetType: BudgetType;
    amount: number;
    growthRatePercentage?: number;
}