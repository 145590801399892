
import { storeToRefs, useTenantStore, useWaitStore } from '@/stores';
import {
    Granularity,
    ISpendByTagResult,
    ITagSpendLine
} from '@/models';
import { watch, computed, defineComponent, ref, ComputedRef } from 'vue';
import { getTagUsage } from '@/lib/Api';
import { toastError } from '@/components/Common/Toast.vue';
import NamespaceSpendTable, { INamespaceSpendTableItemViewModel, convertTagResultToNamespaceSpend } from '@/components/Domain.Kubernetes/NamespaceSpendTable.vue';
import ClusterSpendBarChart, {
    convertAllocationsToDatasets,
    IClickEvent,
} from '@/components/Domain.Kubernetes/ClusterSpendBarChart.vue';
import { ChartData } from 'chart.js/auto';
import { IClusterSpendDataPoint } from '@/chartjs/createClusterSpendBarChart';
import DateRangeButtonToggle, { IDateRangeViewModel } from '@/components/Domain.Usage/DateRangeButtonToggle.vue';
import DateKey from '@/lib/DateKey';
import moment from '@/lib/moment';

interface IClusterSpendBreakdown {
    cluster: string,
    total: number,
    namespaces: INamespaceSpendTableItemViewModel[],
    clusterAllocationDatasets: ChartData<'bar', IClusterSpendDataPoint[], string>
}

interface IClusterSpendItemViewModel {
    cluster: string;
    total: number;
    allocations: { [allocation: string]: number };
}

export default defineComponent({
    components: { DateRangeButtonToggle, ClusterSpendBarChart, NamespaceSpendTable },
    title() {
        return this.$t('dashboard.title');
    },
    setup() {
        const { currencyCode } = storeToRefs(useTenantStore());
        const { is, whilst } = useWaitStore();

        const clusterSpendData = ref<ISpendByTagResult | null>(null);
        const panelState = ref<number[]>([]);
        const dateRange = ref<IDateRangeViewModel>({ dateRangeKey: '30d', fromDate: null, toDate: moment.utc().toDate()  });
        const effectiveDateRange = ref<[] | [Date] | [Date, Date]>([]);

        /**
         * Loads the cluster spend data, for all clusters within the time period
         *
         * @async
         * @function loadClusterSpend
         * @returns {Promise<void>} - A Promise that resolves when the cluster spend data is loaded.
         */
        const loadClusterSpend = async (dateRange: IDateRangeViewModel): Promise<void> => {
            await whilst('loadingClusterSpend', async () => {
                try {
                    const key = new DateKey(dateRange.dateRangeKey);
                    effectiveDateRange.value = key.getEffectiveDateRange(dateRange.fromDate, dateRange.toDate);
                    if (effectiveDateRange.value?.length < 1) {
                        toastError('Please select a date range to view cluster spend');
                        return;
                    }
                    const tagUsageData: ISpendByTagResult = await getTagUsage(
                        effectiveDateRange.value[0],
                        effectiveDateRange.value.length > 1 ? effectiveDateRange.value[1] : effectiveDateRange.value[0],
                        ['$KubernetesCluster', '$KubernetesAllocation', '$KubernetesNamespace'],
                        Granularity.daily
                    );

                    if (!tagUsageData || tagUsageData.data.length === 0) {
                        clusterSpendData.value = null;
                        return;
                    }

                    clusterSpendData.value = tagUsageData;
                } catch (err) {
                    toastError('There was an issue loading the cluster spend');
                    clusterSpendData.value = null;
                }
            });
        };

        /**
         * Computes the per-cluster total spend based on the tag usage data.
         * Additionally, computes the total spend per allocation (utilised, unutilised, shared cost)
         * @returns {IClusterSpendItemViewModel[]} - An array of tag usage items
         */
        const clusterSpend : ComputedRef<IClusterSpendItemViewModel[]> = computed(() => {
            if (!clusterSpendData.value?.data?.length) {
                return [];
            }
            const rowMap = new Map<string, IClusterSpendItemViewModel>();
            clusterSpendData.value.data.forEach((item: ITagSpendLine) => {
                const cluster = item.rowDimensions[0];
                const allocation = item.rowDimensions[1];
                if (!allocation.tagValue) return;

                if (!rowMap.has(cluster.tagValue)) {
                    const row: IClusterSpendItemViewModel = {
                        cluster: cluster.tagValue,
                        total: 0,
                        allocations: {}
                    };
                    rowMap.set(cluster.tagValue, row);
                }
                const row = rowMap.get(cluster.tagValue);
                const totalSpend = Math.round(item.totalSpend * 100) / 100;
                if (!row.allocations[allocation.tagValue]) row.allocations[allocation.tagValue] = 0;
                row.total += totalSpend; // total per cluster
                row.allocations[allocation.tagValue] += totalSpend; // total per allocation
            });
            return [...rowMap.entries()].map(([, v]) => v);
        });

        /**
         * Represents a computed variable that holds an array of cluster spend breakdowns.
         * A cluster spend breakdown includes the total cluster cost, as well as costs per namespace
         * @name clustersViewModel
         * @type {ComputedRef<IClusterSpendBreakdown[]>}
         */
        const clustersViewModel : ComputedRef<IClusterSpendBreakdown[]> = computed(() => {
            if (!clusterSpendData.value?.data?.length) {
                return [];
            }

            const clusterMap = new Map<string, IClusterSpendBreakdown>();
            clusterSpendData.value.data.forEach((item : ITagSpendLine) => {
                const cluster = item.rowDimensions[0];
                if (clusterMap.has(cluster.tagValue)) return;

                const clusterSpendItem = clusterSpend.value.find((item: IClusterSpendItemViewModel) => item.cluster === cluster.tagValue);
                if (!clusterSpendItem) return;

                const clusterRow : IClusterSpendBreakdown = {
                    cluster: cluster.tagValue,
                    total: clusterSpendItem.total,
                    namespaces: convertTagResultToNamespaceSpend(cluster.tagValue, clusterSpendData.value),
                    clusterAllocationDatasets: convertAllocationsToDatasets(clusterSpendItem.allocations)
                };

                clusterMap.set(cluster.tagValue, clusterRow);
            });

            return [...clusterMap.entries()].map(([, v]) => v);
        });

        /**
         * Creates human-readable format of the effective date range
         * @name effectiveDates
         * @type {ComputedRef<{ fromDate: string, toDate: string }>}
         */
        const effectiveDates : ComputedRef<{ fromDate: string, toDate: string}> = computed(() => {
            if (effectiveDateRange.value.length < 1) return { fromDate: "", toDate: "" };

            const fromDate = moment.utc(effectiveDateRange.value[0]).format("DD MMMM YYYY");
            const toDate = effectiveDateRange.value.length > 1 ? moment.utc(effectiveDateRange.value[1]).format("DD MMMM YYYY") : fromDate;
            return { fromDate, toDate };
        });

        watch(clustersViewModel, (newClusters : IClusterSpendBreakdown[]) => {
            if (newClusters.length === 1) {
                panelState.value = [0]; // if we only have one cluster, default its panel to expanded
            }
        });

        watch(dateRange, async (dateRange: IDateRangeViewModel) => {
            await loadClusterSpend(dateRange);
        }, { immediate: true });

        return {
            dateRange,
            effectiveDates,
            clusters: clustersViewModel,
            clusterSpend,
            panelState,
            clusterSpendData,
            currencyCode,
            loadClusterSpend,
            wait: { is, whilst }
        };
    },

    methods: {
        handleClusterSpendChartClick(event: IClickEvent) {
            this.$router.push(`/kubernetes/tagkeys/${encodeURIComponent(
                        '$KubernetesAllocation'
                                )}/tag/${encodeURIComponent(
                        '$KubernetesAllocation'
                                )}:${encodeURIComponent(
                                    event.allocation.toLowerCase()
                                )}?tagKeyValues=${encodeURIComponent(
                    '$KubernetesCluster'
                                )}:${encodeURIComponent(
                                    event.cluster.toLowerCase()
                                )}`
            );
        },
        handleUpdateDateRange($event: IDateRangeViewModel) {
            this.dateRange = $event;
        },
        handleDialogChange(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push('/kubernetes');
            this.$title = this.$t('dashboard.title');
        }
    },
});
