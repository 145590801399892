
import ApiV2 from '@/lib/ApiV2';
import { defineComponent } from 'vue';
import { waitFor } from '@/plugins/vue-wait';
import moment from '@/lib/moment';
import CarbonBarChart, {
    convertCO2eResponseToDatasets,
    convertProjectionsResponseToDataset,
} from '@/components/Domain.Carbon/CarbonBarChart.vue';
import { convertSpendOptionsToQuery } from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import TagCarbonTable, {
    convertCarbonByTagResultToDimensions,
    convertCarbonByTagResultToItems,
} from '@/components/Domain.Tags/TagCarbonTable.vue';
import { useTenantStore, useDatasetState } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { toastError } from '@/components/Common/Toast.vue';
import { ICO2EByTagResult, Granularity } from '@/models';
import { getCO2e, getForecastCO2e } from '@/lib/Api';
import CarbonMissingFooter from '@/components/Domain.Carbon/CarbonMissingFooter.vue';
import Config from '@/lib/Config';

export default defineComponent({
    props: ['me'],
    title(ctx) {
        return ctx.$t('carbonDashboard.title');
    },
    components: {
        CarbonBarChart,
        TagCarbonTable,
        CarbonMissingFooter,
    },
    created() {
        this.getTagCarbonData();
        this.getFixedCO2EForTenant(this.fixedCarbonOptions);
        this.getRecommendationSummary();
        this.getRecommendationStatus();
    },
    data() {
        return {
            thisMonthName: moment.utc().format('MMMM'),
            lastMonthName: moment.utc().add(-1, 'months').format('MMMM'),

            currentTotalCo2E: 0.0,
            currentTotalKwH: 0.0,
            lastMonthCo2e: 0.0,
            lastMonthKwH: 0.0,

            cummulativeCo2E: 0.0,
            cummulativeKwH: 0.0,

            fixedCarbonOptions: {
                dateRangeKey: '12m',
                granularity: Granularity.monthly,
                topXResults: 30,
                segregateBy: 'products',
            },
            newChartData: { datasets: [], labels: [] },
            maxDate: undefined,
            datasetIndexes: null,
            tagTableItems: [],
            tagTableDimensions: [],
            recommendationSummary: {},
            recommendationStatus: {},

            isUsageDatasetWarningOpen: false,
            supportEmail: Config.get().SUPPORT_EMAIL,
        };
    },
    watch: {
        carbonOptions: {
            handler(options) {
                if (!options) return;
                if (this.$wait.is('getTenantMonthlyCO2E')) return;
                this.getCO2EForTenant(options);
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        ...mapStores(useTenantStore),
        recommendationStatusText() {
            if (this.$wait.is('getRecommendationStatus') || !this.recommendationStatus) {
                return this.$t('dashboard.recommendationStatus.loading');
            }
            const value = this.recommendationStatus.credentialStatus;
            if (value === undefined) {
                return this.$t('dashboard.recommendationStatus.unknown');
            }
            if (value === 0 || value === 'Unavailable') {
                return this.$t('dashboard.recommendationStatus.unavailable');
            }
            if (value === 1 || value === 'AllGood') {
                return this.$t('dashboard.recommendationStatus.allGood');
            }
            if (value === 2 || value === 'PartiallyGood') {
                return this.$t('dashboard.recommendationStatus.partiallyGood');
            }
            if (value === 3 || value === 'ActionRequired') {
                return this.$t('dashboard.recommendationStatus.actionRequired');
            }
            return this.$t('dashboard.recommendationStatus.unknown');
        },
    },
    methods: {
        getFixedCO2EForTenant: waitFor('getTenantMonthlyCO2E', async function getFixedCO2EForTenant(options) {
            const usageParams = convertSpendOptionsToQuery(options);
            if (!usageParams) return;
            const [carbonResponse, forecastResponse] = await Promise.all([
                getCO2e(options.segregateBy, usageParams),
                getForecastCO2e(usageParams, options.dateRangeKey),
            ]);
            const [usageDataset, datasetIndexes] = convertCO2eResponseToDatasets(carbonResponse);
            this.datasetIndexes = datasetIndexes;
            const forecastDataset = convertProjectionsResponseToDataset(forecastResponse);
            this.newChartData = {
                labels: [...usageDataset.labels, ...forecastDataset.labels],
                datasets: [...usageDataset.datasets, ...forecastDataset.datasets],
            };
            this.maxDate = forecastResponse?.maxUsageDate || usageParams.toDate;
            this.cummulativeCo2E = carbonResponse.totalCO2E;
            this.cummulativeKwH = carbonResponse.totalKwH;
            const tm = moment.utc();
            const lm = moment.utc().add(-1, 'months');
            this.currentTotalCo2E = carbonResponse.monthlyCO2ETotals?.[tm.format('YYYY')]?.[tm.format('MM')] || 0.0;
            this.currentTotalKwH = carbonResponse.monthlyKwHTotals?.[tm.format('YYYY')]?.[tm.format('MM')] || 0.0;
            this.lastMonthCo2e = carbonResponse.monthlyCO2ETotals?.[lm.format('YYYY')]?.[lm.format('MM')] || 0.0;
            this.lastMonthKwH = carbonResponse.monthlyKwHTotals?.[lm.format('YYYY')]?.[lm.format('MM')] || 0.0;
        }),
        getTagCarbonData: waitFor('loadingTagCarbon', async function loadTagSpend() {
            try {
                const min = moment.utc().add(-3, 'months').startOf('month').toISOString();
                const max = moment.utc().endOf('month').toISOString();
                const dims = this.tenantStore.getHierarchy().join(',');
                const tagUsageData: ICO2EByTagResult = await ApiV2.http
                    .get(`/api/report/tagoverview/co2e?min=${min}&max=${max}&dimensions=${dims}`)
                    .then((resp) => resp.data);
                this.tagTableItems = convertCarbonByTagResultToItems(tagUsageData);
                this.tagTableDimensions = convertCarbonByTagResultToDimensions(tagUsageData);
            } catch (err) {
                toastError('There was an issue loading the data.');
                this.tagTableItems = [];
                this.tagTableDimensions = [];
                this.tagChartItems = [];
                this.filterPanels = 0;
            }
        }),
        handleDatasetClicked({ index, isOther }) {
            if (isOther) {
                this.fixedCarbonOptions.topXResults = this.fixedCarbonOptions.topXResults + 10;
            } else if (this.fixedCarbonOptions.segregateBy === 'tags') {
                const datasetGroupId = this.datasetIndexes[index];
                const tag = `${encodeURIComponent(this.tagKey)}:${encodeURIComponent(datasetGroupId)}`;
                this.$router.push(`/emissions/overview/tagkeys/${encodeURIComponent(this.tagKey)}/tag/${tag}/carbon`);
            } else {
                const datasetGroupId = this.datasetIndexes[index];
                this.$router.push(
                    `/emissions/overview/${this.fixedCarbonOptions.segregateBy}/${datasetGroupId}/carbon`
                );
            }
        },
        handleDialogChange(isOpen: boolean) {
            if (isOpen) return;
            this.$router.push('/emissions/overview');
            this.$title = this.$t('carbonDashboard.title');
        },
        getRecommendationSummary: waitFor('getRecommendationSummary', async function getRecommendationSummary() {
            if (this.tenantStore.features.includes('Recommendations/View')) {
                this.recommendationSummary = await ApiV2.http
                    .get('/api/recommendations/summary')
                    .then((r) => r.data.data);
            } else {
                this.recommendationSummary = {};
            }
        }),
        getRecommendationStatus: waitFor('getRecommendationStatus', async function getRecommendationStatus() {
            if (this.tenantStore.features.includes('Recommendations/View')) {
                this.recommendationStatus = await ApiV2.http
                    .get('/api/recommendations/credentialstatus')
                    .then((r) => r.data);
            } else {
                this.recommendationStatus = {};
            }
        }),
        handleSavingsClicked() {
            const { hasUsageDataset } = useDatasetState(this.tenantStore);
            if (hasUsageDataset) {
                if (this.recommendationStatus.credentialStatus > 1) {
                    this.$router.push('/settings');
                } else {
                    this.$router.push('/recommendations');
                }
            } else {
                this.isUsageDatasetWarningOpen = true;
            }
        },
    },
});
