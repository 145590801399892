<template>
    <form-dialog :value="value" @input="$emit('input', $event)" max-width="600">
        <template #activator="bindings">
            <slot v-bind="bindings"></slot>
        </template>
        <template #default="{ open }">
            <update-entity-spend-limit-card
                :value="service"
                :loading="$wait.is('updateServiceSpendLimit')"
                #default="{ isValid, formId, loading, form }"
            >
                <back-button @click="open()"></back-button>
                <v-spacer></v-spacer>
                <patch-service-button
                    :value="service"
                    :patches="[{ op: 'replace', path: 'monthlySpendLimit', value: form.monthlySpendLimit }]"
                    :form="formId"
                    :clear="() => {}"
                    :valid="isValid"
                    :loading="loading"
                    v-wait-while="'updateServiceSpendLimit'"
                    @http2xx="handleSuccessfulSpendLimit"
                ></patch-service-button>
            </update-entity-spend-limit-card>
        </template>
    </form-dialog>
</template>

<script>
import UpdateEntitySpendLimitCard from '@/components/Common/UpdateEntitySpendLimitCard.vue';
import PatchServiceButton from '@/components/Domain.Services/PatchServiceButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    props: {
        value: Boolean,
        service: Object,
    },
    components: { UpdateEntitySpendLimitCard, PatchServiceButton },
    methods: {
        handleSuccessfulSpendLimit(response) {
            toastSuccess(`Successfully updated spend limit to ${response.monthlySpendLimit}`);
            this.$emit('input', false);
            this.$emit('http2xx', { ...this.service, monthlySpendLimit: Number(response.monthlySpendLimit) });
        },
    },
};
</script>