<template>
    <content-card
        :title="$t('copyCloudAccount.tags.title')"
        :caption="$t('copyCloudAccount.tags.caption')"
        :loading="loading"
    >
        <tag-form v-model="tags" :loading="$wait.is('loadingTags')"> </tag-form>
        <template #actions>
            <slot :isValid="tags.valid"></slot>
        </template>
    </content-card>
</template>

<i18n>
{
    "en": {
        "copyCloudAccount.tags.title": "Filter by Tags",
        "copyCloudAccount.tags.caption": "You may optionally filter the usage in the copied cloud connection to only include spend, services or subscriptions with the given tags."
    }
}
</i18n>

<script>
import TagForm from '@/components/Domain.Tags/TagForm.vue';
export default {
    props: {
        value: Object,
        cloudAccount: Object,
        childCustomers: Array,
        tenants: Array,
        loading: Boolean,
    },
    components: { TagForm },
    data() {
        return {
            tags: { valid: true, data: [] },
        };
    },
    created() {
        this.tags.data = this.value.rules.tagAllocations.map((tagKeyValue) => {
            const parts = tagKeyValue.split(':');
            return { Key: parts[0], Value: parts[1], Sources: ['CloudControl'] };
        });
    },
    destroyed() {
        const newTagAllocations = [];
        this.tags.data.forEach((t) => {
            const tag = `${t.Key}:${t.Value}`;
            newTagAllocations.push(tag);
        });
        this.$emit('input', { ...this.value, rules: { ...this.value.rules, tagAllocations: newTagAllocations } });
    },
};
</script>