
import { defineComponent, ref } from 'vue';
import MonacoEditor from '../Common/MonacoEditor.vue';
import Config from '@/lib/Config';
import * as vm from "@/lib/vm";

export const DEFAULT_SCRIPT_TEXT = `module.exports = {
    beforeTransform(ctx, cb) {
        var error = null;
        cb(error);
    },
    transformRecord(record, ctx, cb) {
        var error = null;
        cb(error, record);
    },
    afterTransform(ctx, cb) {
        var error = null;
        var newRecords = [];
        cb(error, newRecords);
    }
};`;

const DEFAULT_SCRIPT_TEST_JSON = `module.exports = {
    records: [
        {
            cost: 10,
            chargeDate: new Date(),
            currencyCode: 'USD',
            metaData: new Map(),
            meterId: 'test-meter-123',
            meterName: 'Test Meter 123',
            productName: 'Test Product',
            regionName: 'Test Region 123',
            serviceId: 'test-svc-123',
            serviceName: 'Test Service 123',
            serviceTypes: [],
            sourceCost: 5,
            unit: 'Hrs Consumed',
            tags: new Map(),
            priceChannels: new Map(),
            unitsConsumed: 123
        }
    ],
    context: {
        sysTenantId: '6352f484-09dd-4b4b-9f95-b75bff11261c',
        sysCloudAccountId: '33177086-8318-478c-80b7-c4cf8ca44979',
        sysSubscriptionId: '83f8d689-f197-4281-85c4-a52f776cf5c7',
        subscriptionId: 'be7aefd7-9ea1-47f2-80f3-d9fa7407eeb8',
        subscriptionName: 'My BizSpark Plus',
        isCopy: false,
        importDate: 'Tue May 03 2022 18:50:27 GMT+1000 (Australian Eastern Standard Time)',
        uploadExpiryDate: 'Tue May 04 2022 18:50:27 GMT+1000 (Australian Eastern Standard Time)',
        allowedEditFields: undefined,
        filePartName: 'Primary',
        userData: {}
    }
};`;

export const DEFAULT_VS_OPTIONS = {
    readonly: true,
    automaticLayout: true,
    language: 'javascript',
};

export interface IPartialTransformContext {
    sysTenantId: string;
    sysCloudAccountId: string;
    sysSubscriptionId: string;
    subscriptionId: string;
    subscriptionName: string;
    isCopy: boolean;
    importDate: string;
    uploadExpiryDate: string;
    allowedEditFields?: string[];
    filePartName: string;
}

export interface ITransformContext extends IPartialTransformContext {
    userData: { [key: string]: string };
}

export interface ITestData {
    context: ITransformContext;
    records: IUsageRecord[];
}

export interface IUsageRecord {
    chargeDate: Date;
    sourceCost: number;
    cost: number;
    priceChannels: Map<string, number>;
    currencyCode: string;
    unit: string;
    unitsConsumed: number;
    meterId: string;
    meterName: string;
    serviceId: string;
    serviceName: string;
    serviceTypes: string[];
    regionName: string;
    productName: string;
    tags: Map<string, string>;
    metaData: Map<string, string>;
}

export interface IScriptFactory {
    beforeTransform(ctx: ITransformContext, callback: (err: Error) => void): void;
    transformRecord(
        data: IUsageRecord,
        ctx: ITransformContext,
        callback: (err: Error, data: IUsageRecord) => void
    ): void;
    afterTransform(ctx: ITransformContext, callback: (err: Error, data: IUsageRecord[]) => void): void;
}

export default defineComponent({
    props: { value: String },
    components: { MonacoEditor },
    setup(props) {
        const docsUrl = Config.get().DOCS_URL;
        const testJson = ref(DEFAULT_SCRIPT_TEST_JSON);
        const resultJson = ref('[]');
        const scriptOptions = { ...DEFAULT_VS_OPTIONS };
        const testJsonOptions = { ...DEFAULT_VS_OPTIONS };
        const resultOptions = { ...DEFAULT_VS_OPTIONS, readOnly: true };
        const activeTab = ref(0);
        const runTest = async () => {
            try {
                const inputs = vm.evaluate(testJson.value) as ITestData;
                const factory = vm.evaluate(props.value) as IScriptFactory;
                await new Promise<void>((resolve, reject) => {
                    factory.beforeTransform(inputs.context, (err) => {
                        if (err) reject(err);
                        else resolve();
                    });
                });
                const newRecords = [];
                for (const record of inputs.records) {
                    const newRecord = await new Promise<IUsageRecord>((resolve, reject) => {
                        factory.transformRecord(record, inputs.context, (err, data) => {
                            if (err) reject(err);
                            else resolve(data);
                        });
                    });
                    newRecords.push(newRecord);
                }
                const lastly = await new Promise<IUsageRecord[]>((resolve, reject) => {
                    factory.afterTransform(inputs.context, (err, lastRecords) => {
                        if (err) reject(err);
                        else resolve(lastRecords);
                    });
                });
                newRecords.push(...lastly);
                resultJson.value = JSON.stringify(newRecords, null, '\t');
            } catch (err) {
                resultJson.value = '' + err;
            } finally {
                activeTab.value = 2;
            }
        };
        return {
            scriptOptions,
            testJson,
            testJsonOptions,
            resultJson,
            resultOptions,
            activeTab,
            docsUrl,
            runTest,
        };
    },
});
