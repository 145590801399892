<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-icon :color="iconColor" v-on="on" v-bind="attrs">
                {{ iconType }}
            </v-icon>
        </template>
        <span>{{ iconTooltip }}</span>
    </v-tooltip>
</template>

<i18n>
{
    "en": {
        "credentialStatusIcon.tooltip.unknown": "An error has occurred, this is an unknown credential status",
        "credentialStatusIcon.tooltip.unavailable": "Please connect the appropriate credentials to continue viewing {integrationType}.",
        "credentialStatusIcon.tooltip.allGood": "All {integrationType} are correctly configured.",
        "credentialStatusIcon.tooltip.partiallyGood": "Although you may be seeing some {integrationType} you do not have all the credentials necessary.",
        "credentialStatusIcon.tooltip.actionRequired": "Please review your accounts as not all {integrationType} are coming through."
    }   
}
</i18n>

<script>
export default {
    props: {
        value: [String, Number],
        integrationType: {
            type: String,
            default: 'recommendations',
        },
    },
    computed: {
        iconColor() {
            if (this.value === undefined) return null;
            if (this.value === 0 || this.value === 'Unavailable') return null;
            if (this.value === 1 || this.value === 'AllGood') return 'success';
            if (this.value === 2 || this.value === 'PartiallyGood') return 'warning';
            if (this.value === 3 || this.value === 'ActionRequired') return 'error';
            return null;
        },
        iconType() {
            if (this.value === undefined) return 'help';
            if (this.value === 0 || this.value === 'Unavailable') return 'help';
            if (this.value === 1 || this.value === 'AllGood') return 'done_all';
            if (this.value === 2 || this.value === 'PartiallyGood') return 'done';
            if (this.value === 3 || this.value === 'ActionRequired') return 'error';
            return 'help';
        },
        iconTooltip() {
            if (this.value === undefined) return this.$t('credentialStatusIcon.tooltip.unknown');
            if (this.value === 0 || this.value === 'Unavailable') {
                return this.$t('credentialStatusIcon.tooltip.unavailable', { integrationType: this.integrationType });
            }
            if (this.value === 1 || this.value === 'AllGood') {
                return this.$t('credentialStatusIcon.tooltip.allGood', { integrationType: this.integrationType });
            }
            if (this.value === 2 || this.value === 'PartiallyGood') {
                return this.$t('credentialStatusIcon.tooltip.partiallyGood', { integrationType: this.integrationType });
            }
            if (this.value === 3 || this.value === 'ActionRequired') {
                return this.$t('credentialStatusIcon.tooltip.actionRequired', {
                    integrationType: this.integrationType,
                });
            }
            return this.$t('credentialStatusIcon.tooltip.unknown');
        },
    },
};
</script>