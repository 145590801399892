<template>
    <v-container>
        <content-card :title="$t('settings.alibaba.title')" :caption="$t('settings.alibaba.subtitle')">
            <template #title-actions>
                <create-alibaba-cloud-account-dialog v-model="isCreatingAccount" @http2xx="onCloudAccountsChanged">
                    <template #activator="{ on }">
                        <v-btn color="secondary" depressed class="mr-3" v-on="on">
                            <v-icon left>add</v-icon> {{ $t('add') }}
                        </v-btn>
                    </template>
                </create-alibaba-cloud-account-dialog>
            </template>
            <alibaba-cloud-accounts-table :items="alibabaCloudAccounts" :loading="$wait.is('getCloudAccounts')">
                <template #[`item.menu`]="{ item }">
                    <v-menu>
                        <template #activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="startEditName(item)">
                                <v-list-item-icon>
                                    <v-icon>edit</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('edit name') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="startEditCurrencies(item)">
                                <v-list-item-icon>
                                    <v-icon>currency_exchange</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('edit currencies') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="startEditCloudAccount(item)" v-if="item.Type !== 'SelfReferential'">
                                <v-list-item-icon>
                                    <v-icon>miscellaneous_services</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('edit usage connection') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                :to="`/copy/cloudaccounts/${item.cloudAccountId}`"
                                v-if="tenantStore.hasTenantFeature('CopyCloudAccount')"
                            >
                                <v-list-item-icon>
                                    <v-icon>content_copy</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('copy cloud connection') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="startDeleteAccount(item)" v-if="item.Type !== 'SelfReferential'">
                                <v-list-item-icon>
                                    <v-icon>delete_forever</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </alibaba-cloud-accounts-table>
            <update-alibaba-credentials-dialog
                v-model="isEditingAccount"
                :edit="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            >
            </update-alibaba-credentials-dialog>
            <delete-cloud-account-dialog
                v-model="isDeletingAccount"
                :account="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            ></delete-cloud-account-dialog>
            <edit-cloud-account-name-dialog
                v-model="isEditingAccountName"
                :account="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            ></edit-cloud-account-name-dialog>
            <edit-cloud-account-currencies-dialog
                v-model="isEditingAccountCurrencies"
                :account="focusedAccount"
                @http2xx="onCloudAccountsChanged"
            ></edit-cloud-account-currencies-dialog>
            <router-view @input="handleDialogChange" />
        </content-card>
    </v-container>
</template>

<i18n>
{
    "en":{
        "settings.alibaba.title": "Alibaba Cloud Connections",
        "settings.alibaba.subtitle": "All cloud connections importing Alibaba Cloud usage data in this tenant."
    }
}
</i18n>

<script>
import AlibabaCloudAccountsTable, {
    alibabaCloudAccountTableFilter,
} from '@/components/Settings.CloudAccounts/Alibaba/AlibabaCloudAccountsTable.vue';
import CreateAlibabaCloudAccountDialog from '@/components/Settings.CloudAccounts/Alibaba/CreateAlibabaCloudAccountDialog.vue';
import UpdateAlibabaCredentialsDialog from '@/components/Settings.CloudAccounts/Alibaba/UpdateAlibabaCredentialsDialog.vue';
import DeleteCloudAccountDialog from '@/components/Settings.CloudAccounts/DeleteCloudAccountDialog.vue';
import EditCloudAccountNameDialog from '@/components/Settings.CloudAccounts/EditCloudAccountNameDialog.vue';
import EditCloudAccountCurrenciesDialog from '@/components/Settings.CloudAccounts/EditCloudAccountCurrenciesDialog.vue';
import { useTenantStore } from '../../stores/tenant.ts';
import { mapStores } from 'pinia';

export default {
    title() {
        return this.$t('settings.alibaba.title');
    },
    props: {
        cloudAccounts: Array,
    },
    data() {
        return {
            focusedAccount: null,
            isEditingAccountName: false,
            isEditingAccountCurrencies: false,
            isEditingAccount: false,
            isCreatingAccount: false,
            isDeletingAccount: false,
        };
    },
    components: {
        AlibabaCloudAccountsTable,
        DeleteCloudAccountDialog,
        EditCloudAccountNameDialog,
        UpdateAlibabaCredentialsDialog,
        CreateAlibabaCloudAccountDialog,
        EditCloudAccountCurrenciesDialog,
    },
    computed: {
        alibabaCloudAccounts() {
            return this.cloudAccounts.filter(alibabaCloudAccountTableFilter);
        },
        ...mapStores(useTenantStore)
    },
    methods: {
        handleDialogChange(isOpen) {
            if (isOpen) return;
            this.$router.push('/settings/alibaba');
            this.$title = this.$t('settings.alibaba.title');
        },
        onCloudAccountsChanged() {
            this.$emit('onCloudAccountsChanged');
        },
        startEditCloudAccount(account) {
            this.focusedAccount = account;
            this.isEditingAccount = true;
        },
        startEditName(account) {
            this.focusedAccount = account;
            this.isEditingAccountName = true;
        },
        startEditCurrencies(account) {
            this.focusedAccount = account;
            this.isEditingAccountCurrencies = true;
        },
        startDeleteAccount(account) {
            this.focusedAccount = account;
            this.isDeletingAccount = true;
        },
    },
};
</script>