
import { defineComponent, ref } from 'vue';
import { IGptChatOverviewResult } from '@/models/GptChatModels'
import ApiV2 from '@/lib/ApiV2';
import { useRouter } from 'vue-router/composables';
import FeatureNames from "@/lib/FeatureNames";
import { useTenantStore } from '@/stores/tenant';

export default defineComponent({
    props: { entityType: String, entityId: String, text: Boolean, noNav: Boolean },
    setup(p, { emit }) {
        const show = ref<boolean>(false);
        const loading = ref<boolean>(false);
        const router = useRouter();
        const tenantStore = useTenantStore();
        const enabled = ref<boolean>(tenantStore.hasTenantFeature(FeatureNames.enableCloudIntelligence));
        
        const go = async () => {
            loading.value = true;
            const response = await ApiV2.http
                .post<IGptChatOverviewResult>(`/api/gpt`, {entityId: p.entityId, entityType: p.entityType} as IGptChatOverviewResult)
                .then((r) => r.data);
            
            //on return:
            if (show.value) {
                //user didn't 'Close'
                if (!p.noNav) router.push(`/cloud-intelligence/?chatId=${response.chatId}`);
                emit('created', response);
            }
        } 

        return {
            enabled,
            show, 
            loading,
            go,
            tenantStore,
            close: () => {show.value = false; loading.value = false;}
        };
    }
});
