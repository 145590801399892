<template>
    <form-card :title="title || $t('setRecommendationSettingsCard.title')" :loading="loading" v-model="valid">
        <v-checkbox v-model="isEnabled" :label="$t('setRecommendationSettingsCard.enabled')"></v-checkbox>
        <v-text-field
            :key="i"
            v-for="(definition, i) in definitions"
            v-model="definition.value"
            :label="definition.label"
            :type="definition.type === 'decimal' ? 'number' : 'text'"
            required
        ></v-text-field>
        <template #actions="{ formId, reset }">
            <slot
                :form="{ recommendationEnabled: isEnabled, settings: [...definitions] }"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "setRecommendationSettingsCard.title": "Configure Recommendation Settings",
        "setRecommendationSettingsCard.enabled": "Enabled"
    }
}
</i18n>

<script>
/* Value bound to RecommendationSettingsUpdateModel */
export default {
    props: {
        value: Object,
        title: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valid: true,
            isEnabled: true,
            definitions: [],
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.valid = true;
                this.isEnabled = val.recommendationEnabled;
                this.definitions = val.settings.map((s) => ({ ...s }));
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>