<template>
    <v-menu>
        <template #activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
            </v-btn>
        </template>
        <v-list>
            <update-fx-rate-dialog v-model="isUpdating" :edit="value" @http2xx="$emit('http2xx', $event)">
                <template #activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-icon>
                            <v-icon>edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </update-fx-rate-dialog>
            <delete-fx-rate-dialog v-model="isDeleting" :account="value" @http2xx="$emit('http2xx', $event)">
                <template #activator="{ on }">
                    <v-list-item v-on="on">
                        <v-list-item-icon>
                            <v-icon>delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </delete-fx-rate-dialog>
        </v-list>
    </v-menu>
</template>

<script>
import UpdateFxRateDialog from '@/components/Domain.FxRates/EditFxRateDialog.vue';
import DeleteFxRateDialog from '@/components/Domain.FxRates/DeleteFxRateDialog.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    components: { UpdateFxRateDialog, DeleteFxRateDialog },
    props: { value: Object },
    setup() {
        const isUpdating = ref(false);
        const isDeleting = ref(false);
        return { isUpdating, isDeleting };
    },
});
</script>
