
import { waitFor } from '@/plugins/vue-wait';
import ReportsTable from '@/components/Domain.Reports/ReportsTable.vue';
import ReportMenu from '@/components/Domain.Reports/ReportMenu.vue';
import { toastError } from '@/components/Common/Toast.vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { getSavedReports } from '@/lib/Api';
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return { isShowingReport: false, savedReports: [], isCreating: false };
    },
    components: { ReportsTable, ReportMenu },
    created() {
        this.loadReports();
    },
    computed: {
        ...mapStores(useTenantStore),
    },
    methods: {
        loadReports: waitFor('loadingReports', async function loadingReports() {
            try {
                this.savedReports = await getSavedReports();
            } catch (err) {
                toastError(this.$t('customReports.failedToLoad'));
            }
        }),
    },
});
