import { IBudgetResult } from '@/models';
import * as CssVariables from '@/lib/CssVariables';
import { ChartData, Chart, ChartDataset } from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation';
import { formatCurrency } from '@/lib/Currencies';

import './dayJsAdapter';
import htmlLegendPlugin from './legendPlugin';

Chart.register(annotationPlugin);

const PROJECTIONS_DATASET_ORDER = 99999;

export interface IBudgetSeriesDatapoint {
    x: string;
    y: number;
}

export const convertBudgetResultResponseToDatasets = (budgetResult: IBudgetResult | null): ChartData<'bar' | 'line', IBudgetSeriesDatapoint[], string> => {
    const datasets: ChartDataset<'bar' | 'line', IBudgetSeriesDatapoint[]>[] = [];
    const labels: string[] = [];
    const budgetDataSet: ChartDataset<'bar', IBudgetSeriesDatapoint[]> = {
            data: [],
            label: `Budget Amount`,
            backgroundColor: 'teal',
            borderColor: 'white',
            borderWidth: 1,
            normalized: true,
            maxBarThickness: 40
        };
    const actualDataSet: ChartDataset<'line', IBudgetSeriesDatapoint[]> = {
            type: 'line',
            data: [],
            label: `Actual Spend`,
            backgroundColor: 'orange',
            borderColor: 'orange',
            borderWidth: 1,
            normalized: true,
        };
        
    (budgetResult?.budgetSeries || []).forEach((g) => {
            labels.push(g.period);
            budgetDataSet.data.push({x: g.period, y: g.budgetedCost})
            actualDataSet.data.push({x: g.period, y: g.actualCost})
    });
    datasets.push(actualDataSet);
    datasets.push(budgetDataSet);
    return {
        labels,
        datasets
    };
};

export interface IClickEvent {
    index: number;
    name: string;
    isOther: boolean;
    datasetGroupId: string;
}

export interface IOptions {
    currencyCode: string;
}

export default (chartItem: HTMLCanvasElement, options: IOptions, containerId: string): Chart => {
    const { currencyCode } = options;
    const style = getComputedStyle(chartItem);
    const contrastLines = style.getPropertyValue(CssVariables.ContrastLinesVariable);
    const contrastText = style.getPropertyValue(CssVariables.ContrastTextVariable);
    const chart = new Chart(chartItem, {
        type: 'bar',
        data: {
            labels: [],
            datasets: []
        },
        options: {
            borderColor: 'rgba(0,0, 0, 0)',
            plugins: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                htmlLegend: {
                // ID of the container to put the legend in
                    containerID: containerId,
                },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                    labels: {
                        color: contrastText
                    },
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            return [item.dataset.label];
                        },
                        afterLabel(item) {
                            const dataset = item.dataset;
                            const points: IBudgetSeriesDatapoint[] = dataset.data as any;
                            const hoverPoint: IBudgetSeriesDatapoint = points[item.dataIndex];
                            return [
                                `${formatCurrency(hoverPoint.y, currencyCode)}`
                            ]
                        },
                    },
                    mode: 'point'
                },
                annotation: {
                    annotations: []
                }
            },
            interaction: {
                intersect: true,
                mode: 'dataset'
            },
            onHover(event, [element]) {
                let newCursor = 'default';
                if (element) {
                    const dataset = chart.data.datasets[element.datasetIndex];
                    if (dataset && dataset.order !== PROJECTIONS_DATASET_ORDER) {
                        newCursor = 'pointer';
                    }
                }
                (event.native.target as HTMLElement).style.cursor = newCursor;
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: "category",
                    stacked: false,
                    ticks: {
                        color: contrastText
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0)'
                    },
                    axis: 'x',
                },
                y: {
                    type: 'linear',
                    ticks: {
                        color: contrastText,
                        callback(value: number) {
                            return formatCurrency(value, currencyCode);
                        }
                    },
                    grid: {
                        color: contrastLines
                    },
                    min: 0,
                    axis: 'y',
                    title: {
                        text: `Budget (${currencyCode})`,
                        color: contrastText,
                        display: true
                    },
                    stack: 'y',
                    display: true,
                }
            }
        },
        plugins: [htmlLegendPlugin]
    });
    return chart;
};