<template>
    <fragment>
        <v-menu v-model="isOpen" offset-y bottom>
            <template #activator="bindings">
                <slot v-bind="bindings">
                    <v-btn x-small depressed class="ml-2" v-on="bindings.on">
                        manage<v-icon right x-small>menu_open</v-icon>
                    </v-btn>
                </slot>
            </template>
            <v-list>
                <v-list-item @click="isEditingSpendLimit = true">
                    <v-list-item-icon>
                        <v-icon>savings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('productManageMenu.editSpendLimit') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
        <edit-product-spend-limit-dialog
            v-model="isEditingSpendLimit"
            :product="value"
            @http2xx="$emit('input', $event)"
        >
        </edit-product-spend-limit-dialog>
    </fragment>
</template>

<i18n>
{
    "en": {
        "productManageMenu.editSpendLimit": "Set Spend Limit"
    }
}
</i18n>

<script>
import EditProductSpendLimitDialog from '@/components/Domain.Products/EditProductSpendLimitDialog.vue';
export default {
    components: { EditProductSpendLimitDialog },
    props: {
        value: Object,
    },
    data() {
        return {
            isOpen: false,
            isEditingSpendLimit: false,
        };
    }
};
</script>